import { connect } from 'react-redux';

import ConfirmEmail from '../../components/signup/confirm-email';

import {
    setIsEmailVerified,
    setSignupDetails,
} from '../../actions/signupAction';

export function mapStateToProps(state) {
	/*console.log('ConfirmEmail-mapStateToProps=');
	console.log(state);*/
    return {
        signup: state.signup,
    };
}

export function mapDispatchToProps(dispatch) {
  return {
  	//onSetIsEmailVerified: bool => dispatch(setIsEmailVerified(bool)),
  	onSetSignupDetails: data => dispatch(setSignupDetails(data)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);