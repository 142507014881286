import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
class LeadershipList extends Component{

    handleEdit = (leadership) => {
        this.props.handleEdit(leadership);
    }

    handleDelete = (leadership) => {
        this.props.handleDelete(leadership);
    }

    render(){
        const leadershipList = this.props.leadershipList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Title</th>
                                <th>Link</th>
                                {/*<th>Status</th>*/}
                                <th></th>
                            </tr>

                            {
                                (leadershipList && leadershipList.length > 0) ? (

                                    leadershipList.map((lead, index) => (
                            <tr key={index} >
                                <td width="30%">

                                    <div className="user-with-thumb flex align-items-center">
                                        <div className="user-thumb thumb-lg"><img src={(lead.image) ? lead.image : UserThumb} alt={lead.title} /></div>
                                        <div className="user-text">
                                            <div className="user-name">
                                            {lead.title}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td width="30%">
                                {
                                    (lead.link && lead.link.length > 0) && (
                                        <div className="post-img-link">
                                            <a href={lead.link} target="_blank">{lead.link}</a>
                                        </div>
                                        )
                                }
                                </td>
                                
                                {/*
                                    <td width="30%">
                                    {(lead.status === true || lead.status === 'true')?'Active':'In-active'}
                                </td>
                                */}
                                
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><button onClick={() => this.handleEdit(lead)} className="button-like-link" >Edit</button></li>
                                                            <li><button onClick={() => this.props.handleDelete(lead)} className="button-like-link" >Delete</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))

                                    ) : (


                                   
                                <tr>
                                <td colSpan="4">
                                {(this.props.showLoading) ? 'Loading...' : ('No record(s) found!')}
                                </td>
                                </tr>
                                
                               

                                    )
                            }

                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default LeadershipList;