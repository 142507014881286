export const statesList = {
	"India": [
		"Andaman and Nicobar Islands",
		"Andhra Pradesh",
		"Arunachal Pradesh",
		"Assam",
		"Bihar",
		"Chandigarh",
		"Chhattisgarh",
		"Dadra and Nagar Haveli and Daman and Diu",
		"Delhi",
		"Goa",
		"Gujarat",
		"Haryana",
		"Himachal Pradesh",
		"Jammu and Kashmir",
		"Jharkhand",
		"Karnataka",
		"Kerala",
		"Ladakh",
		"Lakshadweep",
		"Madhya Pradesh",
		"Maharashtra",
		"Manipur",
		"Meghalaya",
		"Mizoram",
		"Nagaland",
		"Odisha",
		"Puducherry",
		"Punjab",
		"Rajasthan",
		"Sikkim",
		"Tamil Nadu",
		"Telangana",
		"Tripura",
		"Uttar Pradesh",
		"Uttarakhand",
		"West Bengal",
	],
	"United States of America": [
		"Alabama",
		"Alaska",
		"Arizona",
		"Arkansas",
		"California",
		"Colorado",
		"Connecticut",
		"Delaware",
		"Florida",
		"Georgia",
		"Hawaii",
		"Idaho",
		"Illinois",
		"Indiana",
		"Iowa",
		"Kansas",
		"Kentucky",
		"Louisiana",
		"Maine",
		"Maryland",
		"Massachusetts",
		"Michigan",
		"Minnesota",
		"Mississippi",
		"Missouri",
		"Montana",
		"Nebraska",
		"Nevada",
		"New Hampshire",
		"New Jersey",
		"New Mexico",
		"New York",
		"North Carolina",
		"North Dakota",
		"Ohio",
		"Oklahoma",
		"Oregon",
		"Pennsylvania",
		"Rhode Island",
		"South Carolina",
		"South Dakota",
		"Tennessee",
		"Texas",
		"Utah",
		"Vermont",
		"Virginia",
		"Washington",
		"West Virginia",
		"Wisconsin",
		"Wyoming",
		"American Samoa",
		"Baker Island",
		"Guam",
		"Howland Island",
		"Jarvis Island",
		"Johnston Atoll",
		"Kingman Reef",
		"Midway Islands",
		"Navassa Island",
		"Northern Mariana Islandsl",
		"Palmyra Atoll",
		"Puerto Rico",
		"Virgin Islands",
		"Wake Island",
	],
	"Germany": [
		"Baden-Württemberg",
		"Bavaria",
		"Berlin",
		"Brandenburg",
		"Bremen",
		"Hamburg",
		"Hesse",
		"Lower Saxony",
		"Mecklenburg-Vorpommern",
		"North Rhine-Westphalia",
		"Rhineland-Palatinate",
		"Saarland",
		"Saxony",
		"Saxony-Anhalt",
		"Schleswig-Holstein",
		"Thuringia",
	],
	"Albania" : [
		"Berat",
		"Diber",
		"Durres",
		"Elbasan",
		"Fier",
		"Gjirokaster",
		"Korce",
		"Kukes",
		"Lezhe",
		"Shkoder",
		"TIrana",
		"Vlore",
	],
	"United Kingdom": [
		"East Midlands, England",
		"East, England",
		"London",
		"North East, England",
		"North West, England",
		"Northern Ireland",
		"Scotland",
		"South East, England",
		"South West, England",
		"Wales",
		"West Midlands, England",
		"Yorkshire and the Humber, England",
	],
	"Switzerland": [
		"Aargau (AG)",
		"Appenzell (Appenzell Ausserrhoden (AR) / Appenzell Innerrhoden (AI))",
		"Basel (Basel Stadt (BS)/ Basel Land (BL))",
		"Bern / Berne (BE)",
		"Freiburg / Fribourg (FR)",
		"Genève (GE)",
		"Glarus (GL)",
		"Graubünden (GR)",
		"Jura (JU)",
		"Luzern (LU)",
		"Neuchâtel (NE)",
		"Sankt Gallen (SG)",
		"Schaffhausen (SH)",
		"Schwyz (SZ)",
		"Solothurn (SO)",
		"Thurgau (TG)",
		"Ticino (TI)",
		"Unterwalden (Obwalden (OW) / Nidwalden (NW))",
		"Uri (UR)",
		"Valais / Wallis (VS)",
		"Vaud (VD)",
		"Zug (ZG)",
		"Zürich (ZH)",
	],
	"Turkey": [
		"Adana",
		"Adıyaman",
		"Afyonkarahisar",
		"Ağrı",
		"Amasya",
		"Ankara",
		"Antalya",
		"Artvin",
		"Aydın",
		"Balıkesir",
		"Bilecik",
		"Bingöl",
		"Bitlis",
		"Bolu",
		"Burdur",
		"Bursa",
		"Çanakkale",
		"Çankırı",
		"Çorum",
		"Denizli",
		"Diyarbakır",
		"Edirne",
		"Elâzığ",
		"Erzincan",
		"Erzurum",
		"Eskişehir",
		"Gaziantep",
		"Giresun",
		"Gümüşhane",
		"Hakkâri",
		"Hatay",
		"Isparta",
		"Mersin",
		"Istanbul",
		"İzmir",
		"Kars",
		"Kastamonu",
		"Kayseri",
		"Kırklareli",
		"Kırşehir",
		"Kocaeli",
		"Konya",
		"Kütahya",
		"Malatya",
		"Manisa",
		"Kahramanmaraş",
		"Mardin",
		"Muğla",
		"Muş",
		"Nevşehir",
		"Niğde",
		"Ordu",
		"Rize",
		"Sakarya",
		"Samsun",
		"Siirt",
		"Sinop",
		"Sivas",
		"Tekirdağ",
		"Tokat",
		"Trabzon",
		"Tunceli",
		"Şanlıurfa",
		"Uşak",
		"Van",
		"Yozgat",
		"Zonguldak",
		"Aksaray",
		"Bayburt",
		"Karaman",
		"Kırıkkale",
		"Batman",
		"Şırnak",
		"Bartın",
		"Ardahan",
		"Iğdır",
		"Yalova",
		"Karabük",
		"Kilis",
		"Osmaniye",
		"Düzce",
	],
	"United Arab Emirates": [
		"Abu Dhabi",
		"Dubai",
		"Sharjah",
		"Ajman",
		"Umm Al-Quwain",
		"Fujairah",
		"Ras Al Khaimah",
	],
	"Australia": [
		"New South Wales",
		"Queensland",
		"South Australia",
		"Tasmania",
		"Victoria",
		"Western Australia",
	],
	"New Zealand": [
		"Auckland",
		"New Plymouth ",
		"Hawke's Bay",
		"Wellington",
		"Nelson",
		"Marlborough",
		"Westland",
		"Canterbury",
		"Otago",
		"Southland",
	],
	"Japan": [
		"Aichi",
		"Akita",
		"Aomori",
		"Chiba",
		"Ehime",
		"Fukui",
		"Fukuoka",
		"Fukushima",
		"Gifu",
		"Gunma",
		"Hiroshima",
		"Hokkaido",
		"Hyōgo",
		"Ibaraki",
		"Ishikawa",
		"Iwate",
		"Kagawa",
		"Kagoshima",
		"Kanagawa",
		"Kōchi",
		"Kumamoto",
		"Kyoto",
		"Mie",
		"Miyagi",
		"Miyazaki",
		"Nagano",
		"Nagasaki",
		"Nara",
		"Niigata",
		"Ōita",
		"Okayama",
		"Okinawa",
		"Osaka",
		"Saga",
		"Saitama",
		"Shiga",
		"Shimane",
		"Shizuoka",
		"Tochigi",
		"Tokushima",
		"Tokyo",
		"Tottori",
		"Toyama",
		"Wakayama",
		"Yamagata",
		"Yamaguchi",
		"Yamanashi",
	],
	"Mexico": [
		"Aguascalientes",
		"Baja California",
		"Baja California Sur",
		"Campeche",
		"Chiapas",
		"Chihuahua",
		"Coahuila1 4",
		"Colima6",
		"Durango",
		"Federal District",
		"Guanajuato",
		"Guerrero",
		"Hidalgo",
		"Jalisco",
		"México",
		"Michoacán",
		"Morelos",
		"Nayarit",
		"Nuevo León4",
		"Oaxaca",
		"Puebla",
		"Querétaro",
		"Quintana Roo",
		"San Luis Potosí",
		"Sinaloa",
		"Sonora2",
		"Tabasco5",
		"Tamaulipas4",
		"Tlaxcala",
		"Veracruz",
		"Yucatán3",
		"Zacatecas",
		"Morelos",
	],
	"Brazil": [
		"Minas Gerais",
		"Rio de Janeiro",
		"Bahia",
		"Paraná",
		"Rio Grande do Sul",
		"Pernambuco",
		"Ceará",
		"Pará",
		"Santa Catarina",
		"Maranhão",
		"Goiás",
		"Amazonas",
		"Espírito Santo",
		"Paraíba",
		"Rio Grande do Norte",
		"Mato Grosso",
		"Alagoas",
		"Piauí",
		"Distrito Federal",
		"Mato Grosso do Sul",
		"Sergipe",
		"Rondônia",
		"Tocantins",
		"Acre",
		"Amapá",
		"Roraima",
		"Sau Polo",
	],
	"Italy": [
		"Aosta Valley",
		"Valle d'Aosta",
		"Apulia",
		"Puglia",
		"Basilicata",
		"Calabria",
		"Campania",
		"Emilia-Romagna",
		"Friuli Venezia Giulia",
		"Lazio",
		"Liguria",
		"Lombardy",
		"Lombardia",
		"Marche",
		"Molise",
		"Piedmont",
		"Piemonte",
		"Sardinia",
		"Sardegna",
		"Sicily",
		"Sicilia",
		"Trentino-South Tyrol",
		"Trentino-Alto Adige",
		"Tuscany",
		"Toscana",
		"Umbria",
		"Veneto",
		"Italy",
		"Italia",
	],
	"Sweden": [
		"Angermannia",
		"Blechingia",
		"Bahusia",
		"Dalecarlia",
		"Dalia",
		"Gestricia",
		"Gotlandia",
		"Hallandia",
		"Helsingia",
		"Herdalia",
		"Jemtia",
		"Lapponia Suecana",
		"Medelpadia",
		"Nericia",
		"Norbothnia",
		"Olandia",
		"Ostrogothia",
		"Scania",
		"Smolandia",
		"Sudermannia",
		"Uplandia",
		"Wermlandia",
		"Westrobothnia",
		"Westrogothia",
		"Westmannia",
	]

};