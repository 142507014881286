import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import UserThumb from '../images/user1.jpg';
import Loader from './loader';
const mapStateToProps = function mapStateToProps(state) {
	return {
		user: state.userDetails,
	};
}

const mapDispatchToProps = dispatch => ({
	//onSetUserDetails: user => dispatch(setUserDetails(user)),
})

class Topbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			user: (localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {},
		};
	}
	render() {
		return (
			<div className="top-right">
				<ul className="top-actions flex">
					<li>
						<div className="box-user-top dropdown-parent active">
							<UncontrolledButtonDropdown>
								<DropdownToggle tag="span" className="user-top">
									<div className="user-thumb"><img src={(this.state.user && this.state.user.photo_url) ? this.state.user.photo_url : UserThumb} alt={(this.state.user && this.state.user.name) ? this.state.user.name : ''} id="top-photo" /></div>
									<div className="user-loggedin-info">
										<span>Signed in as</span>
										<span className="user-name">{(this.state.user && this.state.user.name) ? this.state.user.name : ''}</span>
									</div>    
								</DropdownToggle>

								<DropdownMenu>
									<div className="dropdown">
										<div className="dropdown-group">
											<div className="user-info">
												<div className="user-name">{(this.state.user && this.state.user.name) ? this.state.user.name : ''}</div>
												<div className="user-email">{(this.state.user && this.state.user.email) ? this.state.user.email : ''}</div>
											</div>
											<ul className="nav">
												{/*<li><Link to="/admin/profile">Profile & account</Link></li>*/}
												<li><Link to="/admin/change-password">Change Password</Link></li>
												{(this.state.showLoader)?(
													<li><Loader /></li>
													):null}
											</ul>
										</div>
										<div className="dropdown-group">
											<ul className="nav">
												<li><a href="#" className="color-danger" onClick={this.props.signOut} >Log Out</a></li>
											</ul>
										</div>
									</div>
								</DropdownMenu>

							</UncontrolledButtonDropdown>
						</div>
					</li>
				</ul>
		</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);