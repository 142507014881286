import React, { Component } from 'react';
import axios from 'axios';
import Loader from './../../../components/loader';
var config = require('../../../config.js');
class AddModuleActions extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'id':'',
            'name':'',
            'description':'',
        };
        this.state = {
            showLoader: false,
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : newData,
            errors: {},
        };
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var editData = this.state.editData
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            editData[name] = (event.target.checked) ? 1 : 0
        }
        else
        {
            editData[name] = event.target.value;
        }
        this.setState({editData});
    }

    handleSubmit = async () => {
        if(this.state.editData && this.state.editData.name !== '' && this.state.editData.description !== '')
        {
            var _this1 = this;
            _this1.setState({showLoader: true});
            const data = _this1.state.editData;
            var url = config.master_api_url+'module-actions/add';
            // var url = 'http://127.0.0.1:8081/master/module-actions/add';
            if(_this1.state.editData && _this1.state.editData.id)
            {
                url = config.master_api_url+'module-actions/update/'+_this1.state.editData.id;
                // url = 'http://127.0.0.1:8081/master/module-actions/update/'+_this1.state.editData.id;
            }
            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (response) {
                if(response.data.status === 'success')
                {                    
                    if(_this1.state.editData && _this1.state.editData.id)
                    {
                        _this1.props.moduleActionsEdited(response.data.record);
                    }
                    else
                    {
                        _this1.props.moduleActionsAdded(response.data.record)
                    }                   
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
                _this1.setState({showLoader: false});
            }).catch(function (error) {
                alert(error);
                _this1.setState({showLoader: false});
            });
        } else {
            var errors = {}
            if(_this1.state.editData.name === ''){
                errors['name'] = 'Please enter name';
            }
            if(_this1.state.editData.description === ''){
                errors['description'] = 'Please enter description'
            }
            this.setState({errors})
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        {(this.state.showLoader)? <Loader /> : null}
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.editData && this.state.editData.id) ? 'Update': 'Add'} Module Actions</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <label>Name</label>
                                        <input
                                            className="form-style"
                                            name = "name" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter name"
                                            type="text"
                                            value = {(this.state.editData && this.state.editData.name) ? this.state.editData.name : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.name) ? this.state.errors.name : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Description</label>
                                        <input 
                                            className="form-style"
                                            name = "description" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter description"
                                            type="text"
                                            value = {(this.state.editData && this.state.editData.description) ? this.state.editData.description : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.description) ? this.state.errors.description : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddModuleActions} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addModuleActionsActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddModuleActions;