import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logoMain from '../../logo-color.png';

import EmailContainer from '../../containers/signup/EmailContainer';
import ConfirmEmailContainer from '../../containers/signup/ConfirmEmailContainer';
import CompanyNameContainer from '../../containers/signup/CompanyNameContainer';
import DetailContainer from '../../containers/signup/DetailContainer';
import ThankyouContainer from '../../containers/signup/ThankyouContainer';
import OrderSuccessContainer from '../../containers/signup/OrderSuccessContainer';

import Thankyou2 from '../../components/signup/thankyou2';
import PaymentLinkGenerated from '../../components/signup/payment-link-generated';



import $ from 'jquery';

import * as firebase from "firebase/app";

const mapDispatchToProps = dispatch => ({
  /*hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  }*/
})

var config = require('../../config.js');

const api_url = config.api_url;

function isJSON(data) {
   var ret = true;
   try {
      JSON.parse(data);
   }catch(e) {
      ret = false;
   }
   return ret;
}

class Signup extends Component {

    constructor(props) {
      super(props);

      var signupDetails = {}

      if(localStorage.getItem('signupDetails') ){
        if( isJSON(localStorage.getItem('signupDetails')) ){
          signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
        }
        else{
          signupDetails = localStorage.getItem('signupDetails')
        }
      }

      this.state = {
        hash:props.location.hash,
        signupDetails: signupDetails,
      }

      // console.log('signupDetails=', signupDetails)

      // sessionStorage.clear();

      var referralCode = sessionStorage.getItem('referralCode');

      this.updateSignupDetails = this.updateSignupDetails.bind(this);

      // alert(referralCode);
    }

    componentWillReceiveProps(nextProps){
        //console.log(nextProps);

        var hash = nextProps.location.hash;

        this.setState({hash});
    }

    updateSignupDetails(details){
      // console.log('updateSignupDetails-details=')
      // console.log(details)

      var signupDetails = {}

      if(details.name && details.email){
        signupDetails = details

        localStorage.setItem('signupDetails', JSON.stringify(signupDetails));
      }

      this.setState({signupDetails})

    }

  render() {
    return (
      <div className="singup-pages">
        {(this.state.hash === '' || this.state.hash === '#') ? (
          
          <EmailContainer
          state={this.state}
          updateSignupDetails={this.updateSignupDetails}
          signupDetails={this.state.signupDetails}
          />
          
          ) : ('')}

        {(this.state.hash === '#confirmemail') ? (
          <ConfirmEmailContainer state={this.state} updateSignupDetails={this.updateSignupDetails} />
          ) : ('')}

        {(this.state.hash === '#companyname') ? (
          <CompanyNameContainer state={this.state} updateSignupDetails={this.updateSignupDetails} />
          ) : ('')}

        {(this.state.hash === '#details') ? (
          <DetailContainer state={this.state} updateSignupDetails={this.updateSignupDetails} />
          ) : ('')}

        {(this.state.hash === '#thankyou') ? (
          <ThankyouContainer state={this.state} updateSignupDetails={this.updateSignupDetails} />
          ) : ('')}

        {(this.state.hash === '#success') ? (
          <OrderSuccessContainer state={this.state} updateSignupDetails={this.updateSignupDetails} />
          ) : ('')}

        {(this.state.hash === '#payment-link-generated') ? (
          <PaymentLinkGenerated state={this.state} />
          ) : ('')}


        {/*(this.state.hash === '#thankyou2') ? (
          <Thankyou2 state={this.state} updateSignupDetails={this.updateSignupDetails} />
          ) : ('')*/}

      
      </div>    
    );
  }
}
export default connect(null, mapDispatchToProps)(Signup);