import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logoMain from '../brand_f.png';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    setLogoTitle,
    setLogoUrl,
} from './../actions/sidebarAction';

import { get_id_token, isUserPrimary, isUserAdmin, isUserPrimaryOrAdmin } from '../utils.js';
var config = require('../config.js');
const syscore_api_url = config.syscore_api_url;
var logo_url = logoMain;
//console.log(localStorage.getItem('tenant_details'));
const mapStateToProps = function mapStateToProps(state) {
  // console.log('Channel-mapStateToProps=');
  // console.log(state);
    return {
        deletedBusinessChannelId: state.deletedBusinessChannelId,
        logoTitle: state.logoTitle,
        logoUrl: state.logoUrl,
        referralCode: state.referralCode,
    };
}

const mapDispatchToProps = dispatch => ({  
    onSetLogoTitle: title => dispatch(setLogoTitle(title)),
    onSetLogoUrl: logoUrl => dispatch(setLogoUrl(logoUrl)),
    //onSetReferralCode: referralCode => dispatch(setReferralCode(referralCode)),
})

class Sidebar extends Component {
    constructor(props){
        super(props);

        var current_pathname = props.history.location.pathname.replace("Microsoft", "W3Schools");
        var pathname = current_pathname.replace("/admin/", "");

        // console.log('toggleActive-props.history.location');
        // console.log(props.history.location);

        // console.log('toggleActive-pathname');
        // console.log(pathname);

        var activeRoute = (pathname === '/admin')?'dashboard':pathname;

        this.state = {
            logoUrl: logo_url,
            logoTitle: '',
            referralCode: '',
            activeIndex: -1,
            activeRoute: activeRoute,
            addChannelActive: false,
            activeClass: "",
            showClass: "",
            businessChannelsList: [],
            deletedBusinessChannelId: [],
            currenUser: {},
            current_user_claims: {},
        };


        var tenant_details = (localStorage.getItem('tenant_details'))?JSON.parse(localStorage.getItem('tenant_details')):{};
        
        if(tenant_details.logo_url && tenant_details.logo_url !== ''){
            logo_url = tenant_details.logo_url;
        }
        var logoTitle = (tenant_details.company_name)?tenant_details.company_name:'';
        var logoUrl = (tenant_details.logo_url)?tenant_details.logo_url:'';
        var referral_code = (tenant_details.referral_code)?tenant_details.referral_code:'';

        props.onSetLogoTitle(logoTitle);
        props.onSetLogoUrl(logoUrl);
        //props.onSetReferralCode(referral_code);

        this.toggleActive = this.toggleActive.bind(this);
        this._handleClick = this._handleClick.bind(this);
    }

    toggleActive(routeName) {
        /*this.setState({ activeIndex: index });
        if(this.state.activeIndex === index){
            this.setState({activeIndex: -1})
        }*/

        // console.log('toggleActive-this.props.location');
        // console.log(this.props.location);

        this.setState({ activeRoute: routeName });
        if(this.state.activeRoute === routeName){
            // this.setState({activeRoute: 'dashboard'});
        }
    }
    _handleClick(e) {
        this.setState({
            active: e.target.innerHTML
        });
    }

    stopLink(e){
        e.preventDefault();
    }

  render() {
    
    return (
      <React.Fragment>
        <div className="left-panel">
            <div className="left-top top-full">
                <Link to="/dashboard" className="logo">
                    <div className="brand-icon"><img src={this.state.logoUrl} alt={this.state.logoTitle} /></div>
                    <div className="brand-name">{this.state.logoTitle}</div>
                </Link>
                <span className="close left-close"><i></i></span>
            </div>
            <div className="left-main">
                <div className="custom-scroller theme-dark">
                  <Scrollbars className="customScrollBar dark">
                    <div className="left-group">
                        <ul className="left-nav">
                            <li className={this.state.activeRoute==='dashboard' ? "active" : ""} onClick={() => this.toggleActive('dashboard')} ><Link to="/admin">Dashboard</Link></li>
                            <li className={this.state.activeRoute==='workspace' ? "active" : ""} onClick={() => this.toggleActive('workspace')} >
                                <Link to="/admin/workspace">Workspace</Link>
                            </li>


                            
                            {/*<li className={this.state.activeRoute==='affiliates' ? "active" : ""} onClick={() => this.toggleActive('affiliates')} >
                                <Link to="/admin/affiliates" >Affiliate Program</Link>
                            </li>*/}

                            <li className={this.state.activeRoute==='orders' ? "active" : ""} onClick={() => this.toggleActive('orders')}>
                                <Link to="/admin/orders">Orders </Link>
                            </li>
                            
                            <li className={this.state.activeRoute==='packages' ? "active" : ""} onClick={() => this.toggleActive('packages')}>
                                <Link to="/admin/packages">Packages </Link>
                            </li>

                            <li className={this.state.activeRoute==='coupons' ? "active" : ""} onClick={() => this.toggleActive('coupons')}>
                                <Link to="/admin/coupons">Coupons </Link>
                            </li>

                            <li className={(this.state.activeRoute==='users' || this.state.activeRoute==='groups') ? "has-subnav active" : "has-subnav"} onClick={() => this.toggleActive('users')} >
                                <a >Users </a>
                                <ul className="subnav">
                                    <li onClick={() => this.toggleActive('users')}><a href="/admin/users"> - Users List </a></li>
                                    <li onClick={() => this.toggleActive('groups')}><a href="/admin/groups"> - Users Groups </a></li>
                                </ul>
                            </li>
                            {/*<li className={this.state.activeRoute==='manage-coach' ? "active" : ""} onClick={() => this.toggleActive('manage-coach')}>
                                <Link to="/admin/manage-coach">Manage Coach </Link>
                            </li>
                            <li className={this.state.activeRoute==='cms-pages' ? "active" : ""} onClick={() => this.toggleActive('cms-pages')}>
                                <Link to="/admin/cms-pages">CMS Pages </Link>
                            </li>*/}

                            <li className={(this.state.activeRoute==='affiliates' || this.state.activeRoute==='payments') ? "has-subnav active" : "has-subnav"} onClick={() => this.toggleActive('affiliates')} >
                                <a >Affiliate Program </a>
                                <ul className="subnav">
                                    <li className={this.state.activeRoute==='affiliates' ? "active" : ""} onClick={() => this.toggleActive('affiliates')}><Link to="/admin/affiliates"> - Affiliates </Link></li>
                                    <li className={this.state.activeRoute==='payments' ? "active" : ""} onClick={() => this.toggleActive('payments')}><Link to="/admin/payments"> - Payments </Link></li>
                                </ul>
                            </li>

                            <li className={this.state.activeRoute==='enquiries' ? "active" : ""} onClick={() => this.toggleActive('enquiries')}>
                                <Link to="/admin/enquiries">Contact Form Enquiries </Link>
                            </li>
                            <li className={this.state.activeRoute==='activity' ? "active" : ""} onClick={() => this.toggleActive('activity')}>
                                <Link to="/admin/activity">Activity Log </Link>
                            </li>
                            {/*<li className={this.state.activeRoute==='website-settings' ? "active" : ""} onClick={() => this.toggleActive('website-settings')} >
                                <Link to="/admin/website-settings">Website Settings </Link>
                            </li>*/}

                            <li className={(this.state.activeRoute==='mentor_request' || this.state.activeRoute==='mentors') ? "has-subnav active" : "has-subnav"} onClick={() => this.toggleActive('mentor_request')} >
                                <a >Manage Mentorship </a>
                                <ul className="subnav">
                                    <li onClick={() => this.toggleActive('mentor_request')}><a href="/admin/mentor_request"> - Mentor Request </a></li>
                                    <li onClick={() => this.toggleActive('mentors')}><a href="/admin/mentors"> - Mentors </a></li>
                                </ul>
                            </li>

                            <li className={(this.state.activeRoute==='leadership' || this.state.activeRoute==='quizzes' || this.state.activeRoute==='courses' || this.state.activeRoute==='groups') ? "has-subnav active" : "has-subnav"} onClick={() => this.toggleActive('leadership')} >
                                <a >Manage Leadership </a>
                                <ul className="subnav">
                                    <li onClick={() => this.toggleActive('leadership')}><a href="/admin/leadership"> - Top Resources </a></li>
                                    <li onClick={() => this.toggleActive('quizzes')}><a href="/admin/quizzes"> - Quizzes </a></li>
                                    <li onClick={() => this.toggleActive('courses')}><a href="/admin/courses"> - Courses </a></li>
                                    <li onClick={() => this.toggleActive('blogs')}><a href="/admin/blogs"> - Blogs </a></li>
                                    <li onClick={() => this.toggleActive('books')}><a href="/admin/books"> - Books </a></li>
                                </ul>
                            </li>

                            <li className={(this.state.activeRoute==='website-settings' || this.state.activeRoute==='website-labels' || this.state.activeRoute==='modules' || this.state.activeRoute==='module-actions' || this.state.activeRoute==='static-pages') ? "has-subnav active" : "has-subnav"} onClick={() => this.toggleActive('website-settings')} >
                                <a >Master Settings </a>
                                <ul className="subnav">
                                    <li onClick={() => this.toggleActive('master_settings')}><a href="/admin/website-settings"> - Website Settings </a></li>
                                    <li onClick={() => this.toggleActive('master_settings')}><a href="/admin/website-labels"> - Website Labels </a></li>
                                    {/*<li onClick={() => this.toggleActive('master_settings')}><a href="/admin/modules"> - Modules </a></li>
                                       <li onClick={() => this.toggleActive('master_settings')}><a href="/admin/module-actions"> - Module Actions </a></li>*/}
                                    <li onClick={() => this.toggleActive('master_settings')}><a href="/admin/email-templates"> - Email Templates </a></li>
                                    <li onClick={() => this.toggleActive('master_settings')}><a href="/admin/static-pages"> - Static Pages </a></li>
                                </ul>
                            </li>

                            <li><a href="#" className="color-danger" onClick={this.props.signOut} >Log Out</a></li>
                        </ul>
                    </div>
                  </Scrollbars>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}
// export default Sidebar;
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);