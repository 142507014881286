import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

class StaticPagesList extends Component{

    staticPagesEdit = (pages) => {
        this.props.staticPagesEdit(pages);
    }

    staticPagesDelete = (pages) => {
        var conf = window.confirm('Are you sure you want to delete '+pages.title+' ?');
        if(conf)
        {
            this.props.staticPagesDelete(pages);
        }
    }

    render(){
        const staticPagesList = this.props.state.staticPagesList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Title</th>
                                <th>Slug</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            {staticPagesList.map((item, index) => (
                            <tr key={index} >
                                <td width="20%">
                                    {item.title}
                                </td>
                                <td width="40%">
                                    {item.slug}
                                </td>
                                <td width="40%">
                                    {(item.status === 1) ? 'Active':'Inactive'}
                                </td>
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href={`/${item.slug}`} target="_blank" >View</a></li>
                                                            <li><a onClick={()=>this.staticPagesEdit(item)} >Edit</a></li>
                                                            <li><a onClick={()=>this.staticPagesDelete(item)} >Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default StaticPagesList;