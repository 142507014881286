import {useEffect} from 'react';
import { useCountUp } from 'react-countup';

const Counter = (props) => {
    const {monthlyPlan, YearlyPlan, updatePlan} = props;
    const { countUp, update } = useCountUp({
        start: monthlyPlan,
        end: YearlyPlan,
        delay: 1000,
        duration: 0.5,
    });

    useEffect(() => {
        update(updatePlan)
    });

  return (
      countUp
  );
}
export default Counter;