import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Navbar from '../front/Navbar'
import Footer from '../front/Footer'
import Loader from '../loader'

import $ from 'jquery';

import * as firebase from "firebase/app";

var config = require('../../config.js');

const api_url = config.api_url;

function isJSON(data) {
   var ret = true;
   try {
      JSON.parse(data);
   }catch(e) {
      ret = false;
   }
   return ret;
}

export default class Email extends React.Component {

    constructor(props) {
      super(props);

      var referralCode = sessionStorage.getItem('referralCode');

      this.state = {
        showLoader:false,
        name:'',
        email:'',
        tnc:'',
        formErrors:[],
        signup_message:'',
        affiliate_code: (referralCode)?referralCode:'',
      };
      this.handleChange = this.handleChange.bind(this);
      this.validateForm = this.validateForm.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

      // console.log('props=', props)
    }

    handleChange(event){
      var name = event.target.name;

      if(event.target.name === 'tnc'){
        this.setState({[name]: (event.target.checked) ? 1 : 0});
      }
      else{
        this.setState({[name]: event.target.value});
      }
    }

    validateForm(){

      let signupDetails = this.props.signupDetails

      let isCustomPlan = (signupDetails && signupDetails.is_custom_plan) ? signupDetails.is_custom_plan : false
      let customEmailsArr = (signupDetails && signupDetails.custom_emails) ? JSON.parse(signupDetails.custom_emails) : []

      var signup_message = false;

      var errorsArr = []
      var formErrors = []

      var errorCount = 0

      if(this.state.name === ''){
        formErrors['name'] = 'Please enter your name.'
        errorCount++
      }

      if(this.state.email === ''){
        formErrors['email'] = 'E-mail address can not be empty.'
        errorCount++
      }
      else if(isCustomPlan && !customEmailsArr.includes(this.state.email) ){
        formErrors['email'] = 'The plan you are trying to buy is not valid.'
        errorCount++
      }

      if(this.state.tnc !== 1){
        formErrors['tnc'] = 'Please accept the Terms of Service and Privacy Policy.'
        errorCount++
      }

      if(errorCount > 0){
        signup_message = errorsArr.join("<br />");
        this.setState({formErrors});
        return false;
      }

      this.setState({formErrors});

      return true

    }

    handleSubmit(event){
      event.preventDefault();
      var _this1 = this;

      if(this.validateForm()){
        //alert(this.state.email)
        
            //localStorage.setItem('signup_email', _this1.state.email);

            var data = {};

            data['name'] = _this1.state.name;
            data['email'] = _this1.state.email;
            data['affiliate_code'] = _this1.state.affiliate_code;

            const url = api_url+'signup/email';
            // const url = 'http://127.0.0.1:8081/signup/email';

            $.ajax({
                  type: 'POST',
                  url: url,
                  contentType: 'application/json',
                  data: JSON.stringify(data),
                  dataType: 'json',
                  beforeSend: function(){
                    _this1.setState({showLoader: true});
                  },
                  success: function(resp) {

                    if(resp){
                      _this1.setState({showLoader: false});
                    }
                    //console.log(_this1.props);
                    //console.log(_this1.state.email);

                    // var signupData = {};
                    // signupData = data;

                    //console.log(signupData);

                    //_this1.props.onSetSignupEmail(_this1.state.email);
                    // _this1.props.onSetSignupDetails(signupData);

                    //console.log(_this1.props);

                    if(resp.success){
                      // var otp = resp.otp;
                      // console.log(otp);
                      // var signupData = {};
                      // signupData = data;
                      
                      ///signupData['otp'] = otp;
                      // _this1.props.onSetSignupDetails(signupData);


                      var signupDetails = {}

                      if(localStorage.getItem('signupDetails') ){
                        if( isJSON(localStorage.getItem('signupDetails')) ){
                          signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
                        }
                        else{
                          signupDetails = localStorage.getItem('signupDetails')
                        }
                        if(signupDetails && signupDetails['packageId'])
                        {
                          data['packageId'] = signupDetails['packageId']
                          data['billing'] = signupDetails['billing']
                          data['currency'] = signupDetails['currency']
                        }
                      }

                      signupDetails = data

                      _this1.props.updateSignupDetails(signupDetails);
                        //_this1.setState({invitations:resp.invitations});
                        //_this2.props.history.push("#confirmemail");
                        window.location.hash = '#confirmemail';

                      }
                      else if(resp.error){
                        _this1.setState({signup_message:resp.error});
                      }
                  }
            }).catch(function(error){
              console.log(error);
              _this1.setState({showLoader: false});
            });

            //_this1.props.history.push('confirmemail');
            //window.location = 'signup2';
          
        //window.location = 'signup2';
        //return true;          
      }
      else
      {
        // const signup_message = 'E-mail address can not be empty';
        // _this1.setState({signup_message});
        return false;
      }      
    }

  render() {
    return (
      <React.Fragment>
        <Navbar fixed hideNav />
        <div className="fp-main-full-inner flex align-items-center justify-content-center container">

            <div className="login-box">
                <div className="login-box-inner">
                    <div className="login-box-header">
                        <div className="fp-heading2">Sign Up</div>
                        <p className="fp-text-light">You can create your workspace from here.</p>
                    </div>
                    <div className="form-group-box">
                     {(this.state.showLoader) ? <Loader /> : null}
                      <div className="user-form-box signup-form-main">

                        {(this.state.signup_message) ? (
                        <div className="msg-error" dangerouslySetInnerHTML={{__html: this.state.signup_message }}></div>
                        ) : ('')}

                        <form onSubmit={this.handleSubmit}>
                            <div className="user-form-inner">

                              <div className="input-box">
                                  <label>Name</label>
                                  <input type="text" name="name" placeholder="Enter your name" className="form-style" onChange={this.handleChange} />

                                  {
                                    (this.state.formErrors.name) ? (
                                      <div className="msg-error" dangerouslySetInnerHTML={{__html: this.state.formErrors.name }}></div>
                                      ) : (null)
                                  }

                              </div>

                                <div className="input-box">
                                    <label>Email</label>
                                    <input type="text" name="email" placeholder="email@address.com" id="signup_email" className="form-style" onChange={this.handleChange} />

                                    {
                                      (this.state.formErrors.email) ? (
                                        <div className="msg-error" dangerouslySetInnerHTML={{__html: this.state.formErrors.email }}></div>
                                        ) : (null)
                                    }

                                </div>


                                <div className="input-box terms-text">
                                  <label className="checkbox">
                                    <input type="checkbox" name="tnc" onChange={this.handleChange} checked={(this.state.tnc === 1) ? true : false} /> <span>By clicking below, you agree to the Open Growth HUB <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.</span>
                                  </label>

                                  {
                                    (this.state.formErrors.tnc) ? (
                                      <div className="msg-error" dangerouslySetInnerHTML={{__html: this.state.formErrors.tnc }} ></div>
                                      ) : (null)
                                  }

                                </div>


                                <div className="msg-error" id="signup_error"></div>
                                <div className="input-box">
                                    {/* <button type="button" className="btn-main btn-form btn-submit btn-full" id="signup-btn">Continue</button> */}
                                    {/*<Link to="/signup2" className="btn-main btn-form btn-submit btn-full">Continue</Link>*/}
                                    <button type="submit" className="btn-main btn-form btn-submit btn-full" id="signup-btn">Continue</button>
                                </div>
                            </div>
                        </form>
                        <div className="user-form-bottom">
                            <div className="user-new">Already have an account? You can sign in on <Link to="/login">http://{`{your_workspace}`}.opengrowthhub.com</Link> {/*<Link to="/login">Sign in</Link> here.*/}</div>
                        </div>                        
                    </div>                      
                  </div>
                </div>
            </div>
        </div> 
        <Footer hideNav />
      </React.Fragment>   
    );
  }
}