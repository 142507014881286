import React, {Component} from 'react';
import Navbar from '../../components/front/Navbar';
import Footer from '../../components/front/Footer';
import {checkMark, crossMark} from  '../../components/SvgIcons';
import Tooltip from '../../components/tooltip';
import Counter from './Counter';

const available = <span className="svg-icon available" dangerouslySetInnerHTML={{__html: checkMark}}></span>
const notAvailable = <span className="svg-icon notAvailable" dangerouslySetInnerHTML={{__html: crossMark}}></span>


class PlansAndPricing extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            scrollTop: 0,
            plan: "monthly"
        };
    }
    
    onScroll = () => {
        const scrollY = window.scrollY
        this.setState({
          scrollTop: scrollY
        });
      }

    handleMonthlyPlan = () => {
        this.setState({
            plan: "monthly"
        });
        
    }

    handleAnnuallyPlan = () => {
        this.setState({
            plan: "annually"
        })
    }
    
      
      render(){
        document.addEventListener('scroll', this.onScroll)
        return(
            <React.Fragment>
                <Navbar fixed active={2} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                       <div className="fp-header-caption">
                            <div className="fp-page-title">Plans <span>{`&`}</span> Pricing</div>
                            <p className="fp-text-lg">Scale your plan to suit your needs. Upgrade or downgrade anytime.</p>
                            <div className="tab-group">
                                <button className={`tab-btn ${this.state.plan == 'monthly'? "active": ""}`} onClick={this.handleMonthlyPlan}>Monthly billing</button>
                                <button className={`tab-btn ${this.state.plan == 'annually'? "active": ""}`} onClick={this.handleAnnuallyPlan}>Annual billing</button>
                            </div>
                       </div>
                    </div>
                </header>
                <section className="fp-section fp-section-mentorship-registration pt-0">
                    <div className="container">
                    
                        <table className={`pricing-and-plan-table ${this.state.scrollTop > 292 ? "sticky":"" }`}>
                            <thead>
                                <tr>
                                    <th>
                                        <p><span className="tbl-head">Plan {`&`} {this.state.plan == 'monthly'? "monthly": "Yearly"} Price</span> Additional taxes may apply depending on your country. {/*<a href="#">Learn more</a>*/}</p>
                                    </th>
                                    <th>
                                        <span className="packege-name">Free</span>
                                        <span className="package-price"><small>$</small> 0</span>
                                        <span className="pricing-button"><button className="fp-btn-main fp-btn-sm">Downgrade</button></span>
                                    </th>
                                    <th>
                                        <span className="packege-name">Pro</span>
                                        <span className="package-price"><small>$</small> 
                                        <Counter 
                                            monthlyPlan = "99"
                                            YearlyPlan = "299"
                                            updatePlan = {this.state.plan == 'monthly'? '99' : '299'}
                                        />
                                        </span>
                                        <span className="pricing-button">
                                            {this.state.plan == 'monthly'? 
                                            <button className="fp-btn-main fp-btn-sm" disabled>Current Plan</button>
                                            :
                                            <button className="fp-btn-main fp-btn-sm">Upgrade</button>
                                            }
                                        </span>
                                        <span className="popular-tag">MOST POPULAR</span>
                                    </th>
                                    <th>
                                        <span className="packege-name">Premium</span>
                                        <span className="package-price"><small>$</small>
                                        <Counter 
                                            monthlyPlan = "149"
                                            YearlyPlan = "649"
                                            updatePlan = {this.state.plan == 'monthly'? '149' : '649'}
                                        />
                                        </span>
                                        <span className="pricing-button"><button className="fp-btn-main fp-btn-sm">Upgrade</button></span>
                                    </th>
                                    <th>
                                        <span className="packege-name">Enterprise</span>
                                        <span className="package-price"><small>Call us</small></span>
                                        <span className="pricing-button"><button className="fp-btn-main fp-btn-sm">Call</button></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>User 
                                        <Tooltip 
                                        content="The number of users that can have shared access to your workspace."  
                                        readMoreLink="/" 
                                        readMoreText="Learn more" />
                                    </td>
                                    <td>10 User</td>
                                    <td>Upto 50</td>
                                    <td>Upto 100</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>Space</td>
                                    <td>10 GB</td>
                                    <td>25 GB</td>
                                    <td>100 GB</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>Direct Message
                                        <Tooltip 
                                        content="Direct message to your friends" />
                                    </td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr className="hasSubChildren">
                                    <td>
                                        <strong>Channel 
                                            <Tooltip 
                                            content="In channel you can handle your members accordingly. and communicate with people"  
                                            readMoreLink="/" 
                                            readMoreText="Read more" />
                                        </strong>
                                        <p>Private Channel</p>
                                        <p>Business Channel</p>
                                        <p>Public Channel</p>
                                    </td>

                                    <td>
                                        <strong></strong>
                                        <p>10 Public</p>
                                        <p>5 public</p>
                                        <p>Unlimited</p>
                                    </td>

                                    <td>
                                        <strong></strong>
                                        <p>20 Public</p>
                                        <p>10 Public</p>
                                        <p>Unlimited</p>
                                    </td>

                                    <td>
                                        <strong></strong>
                                        <p>100</p>
                                        <p>50 Public</p>
                                        <p>Unlimited</p>
                                    </td>
                                    
                                    <td>
                                        <strong></strong>
                                        <p>Unlimited</p>
                                        <p>Unlimited</p>
                                        <p>Unlimited</p>
                                    </td>

                                </tr>
                                <tr>
                                    <td>Private Channel</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Task Module</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Mentorship Module</td>
                                    <td>{notAvailable}</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Timesheet</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Internal Community</td>
                                    <td>{notAvailable}</td>
                                    <td>Upto 5</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>External Community</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Private QA</td>
                                    <td>{notAvailable}</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Fourm</td>
                                    <td>{notAvailable}</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Meetings</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Huddle</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Stuck</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Reports</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>311 Planning</td>
                                    <td>{notAvailable}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Parking Lot</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>CMS Pages</td>
                                    <td>Upto 5</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>Leadership</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                                <tr>
                                    <td>Acadmey</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                    <td>{available}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        )
    }
}
export default PlansAndPricing;