import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Loader from './../../../components/loader';
import AddPaymentPopup from './../../../components/admin/affiliates/popup-add-payment-details';

import $ from 'jquery';

import { get_id_token, formatDate } from '../../../utils.js';

var config = require('../../../config.js');
const master_api_url = config.master_api_url;
const http_type = config.http_type;
const frontend_domain = config.frontend_domain;

class AffilatesList extends Component {

	constructor(props){
		super(props);

		var tenant_details = (localStorage.getItem('tenant_details'))?JSON.parse(localStorage.getItem('tenant_details')):{};

		var referralCode = (tenant_details.referral_code)?tenant_details.referral_code:'';

		this.state = {
			showWaiting: false,
			showLoader: false,
			showAddPaymentPopup: false,
			referralCode: referralCode,
			currentWorkspace: {},
			workspaceList: [],
		};

		this.getWorkspaceList = this.getWorkspaceList.bind(this);
		this.changeStatus = this.changeStatus.bind(this);
		this.handleAddPaymentButton = this.handleAddPaymentButton.bind(this);
		this.showHidePaymentPopup = this.showHidePaymentPopup.bind(this);
	}

	getWorkspaceList(){

		var _this1 = this;

			var data = {};

			data['affiliate_code'] = this.state.referralCode;

			// console.log('data=');
			// console.log(data); return false;

			const url = master_api_url+'workspace/list';
			// const url = 'http://127.0.0.1:8081/master/workspace/list';

			$.ajax({
				type: 'POST',
				url,
				data: JSON.stringify(data),
				contentType: 'application/json',
				dataType: 'json',
				beforeSend: function(){
					_this1.setState({showWaiting: true});
				},
				success: function(resp) {
					if(resp){
						_this1.setState({showWaiting: false});
					}
					if(resp.status){
						if(resp.result){
							var workspaceList = resp.result;
							_this1.setState({workspaceList});
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
				// alert(error);
				_this1.setState({showWaiting:false});
			});

	}

	changeStatus(workspaceDetails){

		var _this1 = this;

		var conf = window.confirm("Are you really want to change status of this user?");

		// var id_token = await get_id_token();

		if(conf){

			var data = {};

			// data['id_token'] = id_token;

			var id = workspaceDetails.id;
			var currStatus = workspaceDetails.affiliate_status;

			data['currStatus'] = currStatus;

			// console.log('data=');
			// console.log(data); return false;

			const url = master_api_url+'workspace/change_status/'+id;
			// const url = 'http://127.0.0.1:8081/master/workspace/change_status/'+id;

			$.ajax({
				type: 'POST',
				url,
				data: JSON.stringify(data),
				contentType: 'application/json',
				dataType: 'json',
				beforeSend: function(){
					_this1.setState({showLoader: true});
				},
				success: function(resp) {
					if(resp){
						_this1.setState({showLoader: false});
					}
					if(resp.success){

						var newStatusName = (resp.newStatus)?'Approved':'Pending';

						if(resp.newStatus === true){
							$("#status-box-"+id+" span").text('Approved');
							$("#status-box-"+id+" span").removeClass("label-danger");
							$("#status-box-"+id+" span").addClass("label-green");
						}
						else{
							$("#status-box-"+id+" span").text('Pending');
							$("#status-box-"+id+" span").removeClass("label-green");
							$("#status-box-"+id+" span").addClass("label-danger");
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
				// alert(error);
				_this1.setState({showLoader:false});
			});

		}

	}

	handleAddPaymentButton(workspaceDetails){
		this.setState({currentWorkspace: workspaceDetails});
		this.showHidePaymentPopup(true)
	}

	showHidePaymentPopup(showHide){
		this.setState({showAddPaymentPopup: showHide});
	}

	componentDidMount(){
		this.getWorkspaceList();
	}


  render() {
	return (
	<div className="page-main-holder">
				
		<div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Affiliates</h2></div></div></div>
		<div className="container-area">
			<div className="user-profile-container">
				{(this.state.showLoader)?<Loader />:null}


				<div className="profile-main-container">
					<div className="profile-main-inner">
						<div className="profile-inner-view">

							<div className="profile-section-top">
								<ul className="nav-tab">
									<li className="active"><Link to="/admin/affiliates">Workspaces</Link></li>
									<li><Link to="/admin/payments">Payments</Link></li>
								</ul>
							</div>


							<div className="box">
								<div className="box-title"><h4>Workspace Transactions</h4></div>
								<div className="box-body">

									<div className="table-box">
										<table className="table" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<th width="20%">Workspace Name</th>
													<th width="10%">Order No.</th>
													<th width="10%">Amount</th>
													<th width="10%">Status</th>
													<th width="10%">Date</th>
													<th width="10%">Action</th>
												</tr>

												{ (this.state.workspaceList.length) ? (this.state.workspaceList.map((workspace, idx)=>(

													<tr key={idx}>
													<td><a href={`${http_type+workspace.workspace}.${frontend_domain}`} target="_blank" >{workspace.workspace}</a></td>
													<td>{workspace.order_no}</td>
													<td>{(workspace.affiliate_amount) ? `$${workspace.affiliate_amount}` : ''}</td>

													<td id={`status-box-${workspace.id}`}>

														<span className={`label label-${(workspace.affiliate_status)?'green':'danger'}`} onClick={() => this.changeStatus(workspace)} >{(workspace.affiliate_status)?'Approved':'Pending'}</span>
													</td>
													<td>{formatDate(workspace.created_at, 'MMM DD, YYYY')}</td>

													<td><a onClick={() => this.handleAddPaymentButton(workspace)}>Add Payment details</a></td>

													</tr>

													))):(

													<tr>
														<td colSpan="5">
															{(this.state.showWaiting)?'please wait, while we are fetching data...':'No record(s) found.'}
														</td>
													</tr>

												)}

											   

											</tbody>
										</table>
									</div>

									{/*<div className="btn-outer">
										<button type="button" onClick={this.getAffiliatOrders} className="btn-main btn-form">Get Orders</button>
									</div>*/}

									{
										(this.state.showAddPaymentPopup)?(
											<AddPaymentPopup
											state={this.state}
											showHidePaymentPopup={this.showHidePaymentPopup}
											/>
											):('')
									}

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
  }
}
export default AffilatesList