import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import OrdersList from '../../../components/admin/orders/orders-list'
import AddOrder from '../../../components/admin/orders/orders-add';
import AddOrderFeatures from '../../../components/admin/orders/orders-add-features';
import Loader from '../../../components/loader'
import Alert from '../../../components/alert'
import { callByJsonData } from '../../../ajax-call.js';
import { formatDate } from '../../../utils.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class Orders extends Component{

    constructor(props){
        super(props);
        // console.log('props=',props)
        const urlParams = new URLSearchParams(props.location.search)
        var workspace_id = (urlParams.get('workspace_id')) ? urlParams.get('workspace_id') : ''
        // console.log('workspace_id=',workspace_id)
        this.state = {
            showClass:'',
            activeClass:'',
            addOrdersActive:false,
            addOrdersFeaturesActive:false,
            showLoader:true,
            search_text:'',
            order_id:'',
            invoice_number:'',
            ordersList: [],
            selectedOrder: {},
            msgSuccess: '',
            msgError: '',
            workspace_id: workspace_id,
            from_date: '',
            to_date: '',
        }
    }

    componentDidMount(){
        this.getOrders();
    }

    getOrders = async () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;        
        if(_this1.state.workspace_id)
        {
            data['workspace_id'] = _this1.state.workspace_id;
        }
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        if(_this1.state.order_id)
        {
            data['order_id'] = _this1.state.order_id;
        }
        if(_this1.state.invoice_number)
        {
            data['invoice_number'] = _this1.state.invoice_number;
        }
        if(_this1.state.from_date)
        {
            // data['from_date'] = _this1.state.from_date;
            data['from_date'] = formatDate(_this1.state.from_date, 'YYYY-MM-DD')
        }
        if(_this1.state.to_date)
        {
            // data['to_date'] = _this1.state.to_date;
            data['to_date'] = formatDate(_this1.state.to_date, 'YYYY-MM-DD')
        }
        let url = masterApiUrl+'orders/list'
        _this1.setState({showLoader:true});
        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){
                _this1.setState({ordersList:resp.result});
            }
            _this1.setState({showLoader:false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader:false});
        });

    }

    handleCopyOrder = async (orderData) => {

        console.log('handleCopyOrder-orderData=', orderData)
        // return false
        if(window.confirm("Are you really want to make a copy of this order?")){

            var data = {};

            let url = masterApiUrl+'orders/copy/'+orderData.id

            this.setState({showLoader: true});

            callByJsonData(data, url, 'POST')
            .then((resp) => {
                if(resp){
                    this.setState({showLoader: false});
                }
                
                if(resp.result){
                    this.onOrderSaved(resp.result, 'copy');
                }
            })
            .catch((error) => {
                console.log('copy-order-error=', error)
                this.setState({showLoader: false});
            });

        }

    }

    testUpdate = async (orderData) => {

        console.log('testUpdate-orderData=', orderData)
        // return false

        var data = orderData;

        let url = masterApiUrl+'orders/update_test/'+orderData.id

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            
        })
        .catch((error) => {
            
        });

    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else if(name === 'search_text' || name === 'order_id' || name === 'invoice_number')
        {
            this.setState({[name]: event.target.value, from_date:'', to_date:''});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    handleCreateOrders = (activeType=true) => {
        if(this.state.addOrdersActive === true || activeType === false) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addOrdersActive: false})
            },620);
        } else {
            this.setState({addOrdersActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    handleCreateOrdersFeatures = () => {
        if(this.state.addOrdersFeaturesActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addOrdersFeaturesActive: false})
            },620);
        } else {
            this.setState({addOrdersFeaturesActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }


    cancelAddOrders = () => {
        var selectedOrder = {}
        this.setState({selectedOrder});
        this.handleCreateOrders();
    }

    onOrderSaved = (orderData, saveType='') => {
        let alertMsg = ''
        if(saveType === 'add' || saveType === 'copy'){
            let ordersList = this.state.ordersList;
            ordersList.push(orderData);
            this.setState({ordersList});

            alertMsg = 'Order has been added successfully.'

            if(saveType === 'copy'){
                alertMsg = 'Order has been copied successfully.'
            }
        }
        else if(saveType === 'update'){
            const ordersList = this.state.ordersList;
            const index = ordersList.findIndex(row => row.id === orderData.id);
            if(index >= 0){
                ordersList[index] = orderData;
                this.setState({ordersList});
            }

            alertMsg = 'Order has been updated successfully.'
            
        }

        if(alertMsg && alertMsg.length > 0){
            this.setState({msgSuccess: alertMsg})
        }
        
        this.handleCreateOrders(false);
    }

    onEditOrder = (selectedOrder) => {
        this.setState({selectedOrder: selectedOrder});
        this.handleCreateOrders();
    }

    ordersEdited = (orders) => {
        const state = this.state.ordersList;
        const index = state.findIndex(row => row.id === orders.id);
        state[index] = orders;
        this.setState({ordersList: state});
        this.handleCreateOrders();
    }

    ordersDeleted = (orders) => {
        var state = this.state.ordersList;
        const index = state.findIndex(row => row.id === orders.id);
        if (index === -1) {
            state = [];
        }
        state = [...state.slice(0, index), ...state.slice(index + 1)];
        this.setState({ordersList: state});
    }

    cancelAddOrdersFeatures = () => {
        var selectedOrder = {}
        this.setState({selectedOrder});
        this.handleCreateOrdersFeatures();
    }

    onEditOrderFeatures = (selectedOrder) => {
        this.setState({selectedOrder: selectedOrder});
        this.handleCreateOrdersFeatures();
    }

    onOrderFeaturesSaved = (orders) => {
        const state = this.state.ordersList;
        const index = state.findIndex(row => row.id === orders.id);
        state[index] = orders;
        this.setState({ordersList: state});
        this.handleCreateOrdersFeatures();
    }

    handleFromDate = (from_date) => {
        let to_date = ''
        this.setState({from_date, to_date});
    }

    handleToDate = (to_date) => {
        this.setState({to_date});
    }

    render(){
        let currentDate = new Date()
    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Orders</h2></div></div></div>
        <div className="container-area">
            
            {
                this.state.showLoader ? <Loader /> : ''
            }
            {
              this.state.msgSuccess ? (<Alert message={this.state.msgSuccess} type='success' />) : ''
            }
            {
              this.state.msgError ? (<Alert message={this.state.msgError} type='error' />) : ''
            }
            
            <div className="inv-body">
                <div className="box">
                    {(!this.state.workspace_id)&&(
                    <div className="box-title">
                        <div className="box-filter-left no-float align-items-center">
                            <div className="sort-box box-search  flex">
                                <div className="mr-4 flex-auto">
                                    <input type="text" name="search_text" onChange={this.handleChange} className="form-style" placeholder="Search by name/email" />
                                </div>
                                <div className="mr-4 flex-auto">
                                    <input type="text" name="order_id" onChange={this.handleChange} className="form-style" placeholder="Order ID" />
                                </div>
                                <div className="mr-4 flex-auto">
                                    <input type="text" name="invoice_number" onChange={this.handleChange} className="form-style" placeholder="Invoice Number" />
                                </div>
                                {(this.state.search_text || this.state.order_id || this.state.invoice_number)?(null):(
                                <div className="mr-4 flex-auto">
                                    <div className="input-date-box full-width">
                                        <i className="icon-date"></i>
                                        <DatePicker 
                                            dateFormat="P" 
                                            name="from_date" 
                                            selected={this.state.from_date} 
                                            onChange={this.handleFromDate} 
                                            placeholderText="Form date" 
                                            className="form-style" 
                                            maxDate={currentDate}
                                            />
                                    </div>
                                </div>
                                )}
                                {(this.state.search_text || this.state.order_id || this.state.invoice_number)?(null):(
                                <div className="mr-4 flex-auto">
                                    <div className="input-date-box full-width">
                                        <i className="icon-date"></i>
                                        <DatePicker 
                                            dateFormat="P" 
                                            name="to_date" 
                                            selected={this.state.to_date} 
                                            onChange={this.handleToDate} 
                                            placeholderText="To date" 
                                            className="form-style" 
                                            minDate={this.state.from_date}
                                            maxDate={currentDate}
                                            />
                                    </div>
                                </div>
                                )}
                                <div>
                                    <button onClick={this.getOrders} className="btn-main btn-lg">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    {/*<div className="box-title">
                        <div className="section-btn-outer align-self-end">
                            <button onClick={this.handleCreateOrders} className="btn-main btn-lg">Create New Orders</button>
                        </div>
                    </div>*/}

                    <div className="box-body">
                        {(this.state.ordersList && this.state.ordersList.length > 0)?(
                        <OrdersList
                        ordersList={this.state.ordersList}
                        onEditOrder={this.onEditOrder}
                        onCopyOrder={this.handleCopyOrder}
                        onEditOrderFeatures={this.onEditOrderFeatures}
                        testUpdate={this.testUpdate}
                        />
                        ):(
                        <div>{(this.state.showLoader)?('Loading...'):('No record found')}</div>
                        )}
                    </div>
                    {
                        this.state.addOrdersActive ? (
                            <AddOrder
                            orderData={this.state.selectedOrder}
                            close={this.cancelAddOrders}
                            onSaved={this.onOrderSaved}
                            addOrdersActive={this.state.addOrdersActive}
                            />
                        ) : null
                    }
                    {
                        this.state.addOrdersFeaturesActive ? (
                            <AddOrderFeatures
                            orderData={this.state.selectedOrder}
                            cmsPagesList={this.state.cmsPagesList}
                            cancelAddOrdersFeatures={this.cancelAddOrdersFeatures}
                            onOrderFeaturesSaved={this.onOrderFeaturesSaved}
                            addOrdersFeaturesActive={this.state.addOrdersFeaturesActive}
                            />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default Orders;