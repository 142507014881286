import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
var config = require('../../../config.js');
class WorkspacePermissions extends Component{
    constructor(props) {
        super(props);
        this.state = {
            editData: props.state.editData,
            workspacePermissionsList:{},
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var workspacePermissionsList = this.state.workspacePermissionsList;
        var name = event.target.name;
        //alert(name);
        const result_arr = []
        workspacePermissionsList.forEach(function(data, key) {
            var new_row = data;
            //alert("status['"+new_row.id+"']"+" == "+name)
            if("status['"+new_row.id+"']" === name)
            {
                new_row.status = (event.target.checked) ? true : false
            }
            result_arr.push(new_row);
        })
        /*if(event.target.type === 'checkbox')
        {
            editData[name] = (event.target.checked) ? true : false
        }
        else
        {
            editData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('editData')
        // console.log(editData)
        this.setState({editData});*/
        //console.log(result_arr)
        this.setState({workspacePermissionsList:result_arr});
    }


    handleChecklistItem = (e,item) => {
        var state = this.state.workspacePermissionsList;
        const index = state.findIndex(row => row.id === item.id);
        if (index === -1) {
            state = [item,...state];
        }
        else
        {
            if(e.target.type === 'checkbox')
            {
                state[index] = {id: item.id, name: item.name, status: ((e.target.checked) ? true : false)};
            }
            else
            {
                state[index] = {id: item.id, name: e.target.value, status: item.status};
            }
        }
        this.setState({workspacePermissionsList: state});
    }


    componentDidMount(){
        this.getWorkspacePermissions();
    }

    getWorkspacePermissions = async () => {
        var _this1 = this;
        if(_this1.state.editData.id)
        {            
            var data = {};
            //data['packages_id'] = this.state.editData.id;
            axios({
                method: 'post',
                url: config.master_api_url+'workspace/permissions-list/'+this.state.editData.id,
                // url: 'http://127.0.0.1:8081/master/workspace/permissions-list/'+this.state.editData.id,
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (resp) {
                if(resp.data.result){
                    _this1.setState({workspacePermissionsList:resp.data.result});
                }
                _this1.setState({loadingData:false});
            }).catch(function (error) {
                //console.log(error);
                alert(error);
                _this1.setState({loadingData:false});
            });
        }
    }


    handleSubmit = async () => {
        // return false;
        var _this1 = this;
        if(_this1.state.editData.id)
        {
            axios({
                method: 'post',
                url: config.master_api_url+'workspace/permissions-update/'+_this1.state.editData.id,
                // url: 'http://127.0.0.1:8081/master/workspace/permissions-update/'+_this1.state.editData.id,
                data: _this1.state.workspacePermissionsList,
                headers:{'content-type': 'application/json'}
            }).then(function (response) {
                //console.log(response);
                if(response.data.status === 'success')
                {
                    _this1.props.cancelWorkspacePermissions();
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
            }).catch(function (error) {
                //console.log(error);
                alert(error);
            });
        } else {
            alert('Something went wrong, please try again.');
        }
    }

    render(){
        var workspacePermissionsList = this.state.workspacePermissionsList;
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.editData && this.state.editData.id) ? 'Update': 'Add'} Workspace Permissions</h2>
                                </div>
                                <div className="form-fields-box">

                                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                                        <tbody>
                                            <tr>
                                                <th width="50%">Permissions</th>
                                                <th width="50%">Status</th>
                                            </tr>
                                            {(workspacePermissionsList.length > 0)?(
                                                workspacePermissionsList.map((item, index) => (
                                            <tr key={index} >
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>              
                                                    <div className="input-box">
                                                        <label className="checkbox"><input type="checkbox" checked={(item.status === true) ? true : false} onChange={(e) => this.handleChecklistItem(e,item)} /> <span>Active</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                          ))
                                                ):null
                                            }
                                        </tbody>
                                    </table>                                     
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelWorkspacePermissions} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.workspacePermissionsActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default WorkspacePermissions;