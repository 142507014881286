import React, { Component } from 'react';
import Loader from '../../loader'
import { callByFormData } from '../../../ajax-call.js';
import { isUrlValid, validateImages } from '../../../utils.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class AddBook extends Component{
    constructor(props) {
        super(props);

        let bookData = (props.bookData && props.bookData.id) ? props.bookData : {}
        
        this.state = {
            showLoader: false,
            bookData: bookData,
            title: (bookData.title) ? bookData.title : '',
            link: (bookData.link) ? bookData.link : '',
            is_featured: (bookData.hasOwnProperty('is_featured')) ? bookData.is_featured : true,
            status: (bookData.hasOwnProperty('status')) ? bookData.status : true,
            file: '',
            fileUrl: (bookData.image) ? bookData.image : '',
            image_delete: false,
            formErrors: {},
            errors: {},
        };
    }        

    handleChange = (event) => {
        var fieldName = event.target.name;

        if(event.target.type === 'checkbox'){
            this.setState({[fieldName]: event.target.checked});
        }
        else{
            this.setState({[fieldName]: event.target.value});
        }
        
    }


    handleImageChange = (event) => {
        let validationResult = validateImages(event)

        let formErrors = this.state.formErrors

        if(validationResult && validationResult['is_file_valid']){
            // console.log('validationResult=', validationResult)
            var fileUrl = URL.createObjectURL(event.target.files[0]);
            var file = event.target.files[0];
            this.setState({file:file,fileUrl:fileUrl});
        }
        else if(validationResult['errors']){
            formErrors['image'] = validationResult['errors']
        }
        this.setState({formErrors})

        this.gotoErrors()
    }


  removePic = () => {
    this.setState({file:'',fileUrl:'',image_delete: true});
  }


    validateForm = () => {

        var formErrors = {}

        var errrorsCount = 0

        if(this.state.title.trim() === ''){
            formErrors['title'] = 'Please enter title.'
            errrorsCount++
        }


        if(this.state.link && this.state.link !== ''){
            // console.log('isUrlValid(this.state.link)=', isUrlValid(this.state.link))
            if(!isUrlValid(this.state.link)){
                formErrors['link'] = 'Please enter valid link.'
                errrorsCount++
            }
        }

        this.setState({formErrors})

        if(errrorsCount > 0){            

            this.gotoErrors()

            return false
        }

        return true

    }

    gotoErrors = () => {
        setTimeout(function(){
            var msgErrorElmnt = document.getElementsByClassName("msg-error");

            if(msgErrorElmnt && msgErrorElmnt[0]){
                // console.log(msgErrorElmnt)
                msgErrorElmnt[0].parentElement.scrollIntoView();
            }
        }, 100);
    }

    handleSubmit = async () => {
        var _this1 = this;
        let isFormValid = true
        // if(this.validateForm()){
        if(isFormValid){

            let bookId = (this.state.bookData && this.state.bookData.id)

            const formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('link', this.state.link);
            formData.append('is_featured', this.state.is_featured);
            formData.append('status', this.state.status);
            formData.append('image', _this1.state.file);
            formData.append('image_delete', _this1.state.image_delete);
            
            var url = masterApiUrl+'books/add';
            
            if(bookId && parseInt(bookId) > 0){
                url = url+'/'+bookId
            }

            this.setState({showLoader: true})

            let apiResp = callByFormData(formData, url, 'POST')
            .then((resp) => {

                if(resp){
                    this.setState({showLoader: false})
                }

                if(resp.success && resp.result){
                    let saveType = 'add'
                    this.props.bookSaved(resp.result, saveType)
                }
                if(resp.errors){
                    let formErrors = {}
                    Object.keys(resp.errors).map((fieldName) => {
                        // console.log('fieldName=', fieldName);
                        formErrors[fieldName] = resp.errors[fieldName]
                    })
                    this.setState({formErrors})
                }

            })
            .catch((error) => {
                console.log('book-save-error=', error)
                this.setState({showLoader: false})
            });

        }
    }

    render(){


        return(
            <React.Fragment>
                
                <div className="form-panel active show">

                    {
                        this.state.showLoader ? <Loader /> : ''
                    }
                
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.bookData && this.state.bookData.id) ? 'Update': 'Add'} Book</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="user-input-img-box flex">
                                        <div className="user-input-box flex-auto">
                                          <div className="input-box">
                                            <label>Title</label>
                                            <input type="text" name="title" placeholder="Enter title" className="form-style" value = {this.state.title} onChange={this.handleChange}/>
                                            {this.state.formErrors.title ? (<span className="msg-error">{this.state.formErrors.title}</span>) : null}
                                          </div>
                                          <div className="input-box">
                                            <label>Link</label>
                                            <input type="text" name="link" placeholder="Enter link" className="form-style" value = {this.state.link} onChange={this.handleChange} />
                                            {this.state.formErrors.link ? (<span className="msg-error">{this.state.formErrors.link}</span>) : null}
                                          </div>

                                          <div className="input-box">
                                            <label className="checkbox"><input type="checkbox" name="is_featured" checked={this.state.is_featured} onChange={this.handleChange} /> <span>Featured</span></label>
                                          </div>

                                        </div>
                                        <div className="user-img-box">
                                          <div className="input-box upload-pic-box">
                                            <label>Upload image</label>
                                            <div className="upload-pic-body">
                                              {
                                                (this.state.fileUrl === '') ? (
                                                  <div className="upload-btn btn-main btn-outline">Select <input type="file" onChange={this.handleImageChange} /></div>
                                                ) : (
                                                  <div className="uploaded-img-preview">
                                                  <img src={this.state.fileUrl} />
                                                  <span className="remove-uploaded msg-error" onClick={this.removePic}>X Remove</span>
                                                </div>
                                                )
                                              }
                                            </div>

                                            {this.state.formErrors.image ? (<span className="msg-error">{this.state.formErrors.image}</span>) : null}

                                          </div>
                                        </div>
                                      </div>

                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddBook} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.bookData && this.state.bookData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>

            </React.Fragment>
        )
    }
}
export default AddBook;