import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Accordion extends Component{
    state = {
        data: this.props.data ? this.props.data : [],
        active: 0
    }
    handleClick = (i) => {
        this.setState({
            active: i
        })
    }
    render(){
        return(
            <div className="fp-acr-panel">
                <ul className="fp-acr-list">
                    {
                        this.state.data.map((item,i) => {
                            return (
                                <li key={i} className={this.state.active === i ? 'active' : ''}>
                                    <div className="fp-acr-title" onClick={() => this.handleClick(i)}>{item.title}</div>
                                    <div className="fp-acr-body">
                                        <p className="fp-text-light fp-para">{item.description}</p>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}
export default Accordion