import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import MentorList from '../../../components/admin/mentorship/mentor-list'
import ViewMentor from '../../../components/admin/mentorship/mentor-view';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader'
import { callByJsonData } from '../../../ajax-call.js';
import { get_id_token } from '../../../utils.js';
import Select from 'react-select';
var config = require('../../../config.js');

class MentorRequest extends Component{

    constructor(props){
        super(props);
        this.state = {
            offset:0,
            showClass:'',
            activeClass:'',
            viewMentorActive: false,
            showLoader: false,
            showLoading: false,
            search_text:'',
            mentorList:[],
            editData:{},
            name:'',
            email:'',
            status:'',
            hasMore: false,
            noMoreRecords: false,
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox'){
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else{
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getMentorList();
    }

    loadMore = (event) => {
        this.setState({loadingMore: true})
        this.getMentorList(true)
    }

    getMentorList = async (isLoadMore=false) => {
        this.setState({showLoader: true, showLoading: true})
        var _this1 = this;
        let offset = this.state.offset
        if(!isLoadMore){
            offset = 0
        }
        var data = {};
        data['id_token'] = await get_id_token()
        data['offset'] = offset;
        data['limit'] = 100;
        if(_this1.state.search_text){
            data['search_text'] = _this1.state.search_text;
        }
        if(_this1.state.name !== ''){
            data['name'] = _this1.state.name;
        }
        if(_this1.state.email !== ''){
            data['email'] = _this1.state.email;
        }
        if(_this1.state.status !== ''){
            data['status'] = _this1.state.status;
        }
        else
        {
            data['all_status'] = 1;
        }
        data['order_by'] = 'global_request_date';
        data['master_query'] = true;
        var url = config.api_url+'mentorship/list'
        // var url = 'http://127.0.0.1:8081/'+'mentorship/list'        
        var headers = {'tenantId':'master_tenant','Content-Type': 'application/json'}
        let apiResp = callByJsonData(data, url, 'POST', headers)
        .then((resp) => {

            if(resp){
                this.setState({showLoader: false, showLoading: false})
            }

            if(resp.success){

                let mentorList = resp.result
                if(isLoadMore === true){
                    mentorList = _this1.state.mentorList.concat(resp.result)
                }
                _this1.setState({mentorList, offset: resp.next_page_token})

                if(resp.result && resp.result.length > 0)
                {
                    if(resp.next_page_token)
                    {
                        _this1.setState({hasMore: true })
                    }
                    else
                    {
                        _this1.setState({hasMore: false })
                    }
                }
                else
                {
                    _this1.setState({hasMore: false })
                }
            }

        })
        .catch((error) => {
            console.log('mentor-list-error=',error)
            this.setState({showLoader: false, showLoading: false})
        });

    }

    handleDelete = async (mentorData) => {
        if(mentorData && mentorData.id > 0){

            var conf = window.confirm("Are you really want to delete this record?")

            if(conf){
                this.setState({showLoader: true})
                var _this1 = this;                
                var data = {};
                var url = config.api_url+'mentorship/delete/'+mentorData.id
                // var url = 'config.api_url'+'mentorship/delete/'+mentorData.id
                var headers = {'tenant-id':'master_tenant','Content-Type': 'application/json'}
                let apiResp = callByJsonData(data, url, 'POST', headers)
                .then((resp) => {

                    if(resp){
                        this.setState({showLoader: false})
                    }

                    if(resp.success){
                        _this1.mentorSaved(mentorData, 'delete')
                    }

                })
                .catch((error) => {
                    console.log('mentor-delete-error=',error)
                    this.setState({showLoader: false})
                });

            }

        }

    }

    handleCreateMentor = () => {
        if(this.state.viewMentorActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({viewMentorActive: false})
            },620);
        } else {
            this.setState({viewMentorActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelViewMentor = () => {
        this.setState({editData: {}, viewMentorActive: false})
    }

    mentorSaved = (mentorData, saveType='') => {
        this.getMentorList()
        this.cancelViewMentor()
        /*if(mentorData && mentorData.id){
            let mentorList = this.state.mentorList

            let mentorIndx = mentorList.findIndex((l) => l.id === mentorData.id)

            if(saveType === 'delete'){
                if(mentorIndx >= 0){
                    mentorList.splice(mentorIndx, 1)
                }
            }
            else{

                if(mentorIndx >= 0){
                    mentorList[mentorIndx] = mentorData
                }
                else{
                    mentorList.unshift(mentorData)
                }

            }

            this.setState({mentorList})
            this.cancelAddMentor()
        }*/
    }

    handleView = (mentor) => {
        this.setState({editData: mentor});
        this.handleCreateMentor();
    }

    render(){
        const status_arr = [];
        status_arr.push({ value: 0, label: 'Pending' })
        status_arr.push({ value: 1, label: 'Approved' })
        status_arr.push({ value: 2, label: 'Rejected' })
        return (
        <div className="page-main-holder">
            <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Mentor</h2></div></div></div>
            <div className="container-area">
                {
                    this.state.showLoader ? <Loader /> : ''
                }
                <div className="inv-body">
                    <div className="box">
                        <div className="box-title">
                            <div className="box-filter-left no-float align-items-center">
                                <div className="sort-box box-search  flex">
                                    <div className="mr-4 flex-auto">
                                        <input type="text" name="name" onChange={this.handleChange} className="form-style" placeholder="Search by name" />
                                    </div>
                                    <div className="mr-4 flex-auto">
                                        <input type="text" name="email" onChange={this.handleChange} className="form-style" placeholder="Search by email" />
                                    </div>
                                    <div className="mr-4 flex-auto">
                                        <Select 
                                        name="status"
                                        value={ status_arr.find(o => o.value === this.state.status) }
                                        options={status_arr}
                                        className="form-style mentor-form"
                                        placeholder="Search status"
                                        onChange={(val)=> {this.handleChange({target: { type:'select', name:'status', value: val.value }})}}
                                        />
                                    </div>
                                    <div>
                                        <button onClick={this.getMentorList} className="btn-main btn-lg">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="box-title">
                            <div className="box-filter-left flex align-items-center">
                                <div className="sort-box box-search flex-1">
                                    <div className="filter-search-box">
                                        <i className="icon-search"></i>
                                        <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search coach" />
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        {/*<div className="box-title">
                            <div className="section-btn-outer align-self-end">
                                <button onClick={this.handleCreateMentor} className="btn-main btn-lg">Create New Mentor</button>
                            </div>
                        </div>*/}

                        <div className="box-body">
                            <MentorList
                            showLoading={this.state.showLoading}
                            mentorList={this.state.mentorList}
                            handleView={this.handleView}
                            handleDelete={this.handleDelete}
                            />
                            {(this.state.hasMore)?(
                            <div className="box-body text-center">
                                <button className="btn-main" onClick={this.loadMore}>Load More</button>
                            </div>
                            ):null}
                        </div>
                        {
                            (this.state.viewMentorActive === true) && (
                                <ViewMentor
                                mentorData={this.state.editData}
                                cancelViewMentor={this.cancelViewMentor}
                                mentorSaved={this.mentorSaved}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default MentorRequest;