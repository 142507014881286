import { connect } from 'react-redux';

import {
    setSignupDetails,
} from '../../actions/signupAction';

import OrderSuccess from '../../components/signup/order-success';

export function mapStateToProps(state) {
	/*console.log('topBar=');
	console.log(state);*/
    return {
        //email_verified: state.signup.emailVerified,
        signup: state.signup,
    };
}

export function mapDispatchToProps(dispatch) {
  return {
  	onSetSignupDetails: data => dispatch(setSignupDetails(data)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccess);