import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
var config = require('../../../config.js');
class WorkspaceDelete extends Component{
    constructor(props) {
        super(props);
        var code = 'oghub@12';
        var newData = {
            'security_code':'',
        };
        this.state = {
            workspaceData: this.props.state.workspaceData,
            editData: newData,
            code: code,
            errors: {},
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var editData = this.state.editData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox')
        {
            editData[name] = (event.target.checked) ? true : false
        }
        else
        {
            editData[name] = event.target.value;
        }
        
        this.setState({editData});
    }

    handleSubmit = async () => {
        var _this1 = this;
        if(_this1.state.editData && _this1.state.editData.security_code !== '')
        {      
            
            if(_this1.state.editData.security_code === _this1.state.code)
            {
                
                _this1.props.workspaceDeleted(_this1.state.workspaceData);

            } else {
               
            var errors = {}            
                errors['security_code'] = 'Please enter correct security code';
            
                _this1.setState({errors})  

            }   
            
            
        } else {
            
            var errors = {}
            if(_this1.state.editData.security_code === ''){
                errors['security_code'] = 'Please enter security code';
            }
            
            _this1.setState({errors})
        }        
    }

    render(){
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.workspaceData && this.state.workspaceData.workspace) ? this.state.workspaceData.workspace+' - ': ''} Verify security code</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <label>Code</label>
                                        <input 
                                            className="form-style"
                                            name = "security_code" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter security code"
                                            type="text"
                                            value = {(this.state.editData && this.state.editData.security_code) ? this.state.editData.security_code : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.security_code) ? this.state.errors.security_code : ''}</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelDeleteWorkspace} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>Verify</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addVarifyActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default WorkspaceDelete;