import React, {Component} from 'react'
import renderHTML from 'react-render-html'

class Feature extends Component{
    render(){
        return(
            <section className={"fp-section fp-section-feature " + this.props.class}>
                <div className="container">
                    <div className="fp-feature-box row align-items-center">
                        <div className="fp-feature-media col-md-6">
                            <div className="fp-feature-media-inner">
                                <div className="fp-feature-media-main"><img src={this.props.image} /></div>
                            </div>
                        </div>
                        <div className="fp-feature-info col-md-6">
                            <div className="fp-feature-info-inner">
                                <div className="fp-heading">{renderHTML(this.props.title)}</div>
                                <p className="fp-text-md fp-para">{this.props.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Feature