import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Loader from './../../loader'
import AlertClosable from '../../../components/alert-closable'
import { callByJsonData } from '../../../ajax-call.js';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from '../../../utils.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'


export const billingTypesArr = [
{ value: 'monthly', label: 'Monthly' },
{ value: 'annualy', label: 'Annualy' }
]

export function getBillingTypeLabel(value){
    let billingLabel = ''
    if(value){
        // console.log('getBillingTypeLabel(value)=', value)
        for(var i in billingTypesArr){
            let billingType = billingTypesArr[i]
            
            if(billingType['value'] === value){
                billingLabel = billingType['label']
                break
            }
        }
    }

    return billingLabel
}

class AddCoupons extends Component{
    constructor(props) {
        super(props);
        var newData = {
            id:'',
            name:'',
            code:'',
            percentage:'',
            monthly_price:'',
            annual_price:'',
            status:true,
            from_date: '',
            to_date: '',
        };

        let couponData = (props.couponData && props.couponData.id) ? props.couponData : {}


        this.state = {
            packagesList: [],
            couponData: (props.couponData && props.couponData.id) ? props.couponData : newData,
            errors: {},
            formErrors: {},
            showLoader: false,
            msgSuccClosable: false,
            msgErrClosable: false,
        };
        // console.log('props.state');
        // console.log(props.state);
    }        

    handleChange = (event) => {
        var couponData = this.state.couponData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox'){
            if(name === 'billing_cycle'){
                couponData[name] = event.target.value
            }
            else{
                couponData[name] = (event.target.checked) ? true : false
            }
            
        }
        else{
            couponData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('couponData')
        // console.log(couponData)
        this.setState({couponData});
    }

    componentDidMount(){
        this.getPackages();
    }

    getPackages = async () => {
        var _this1 = this;
        let couponData = this.state.couponData
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        let url = masterApiUrl+'packages/list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){

                let result = resp.result

                if(result && result.length > 0){
                    result.sort((a,b) => {
                        if(parseInt(a.sort_order) > parseInt(b.sort_order)){
                            return 1
                        }

                        return -1
                    })

                    var packagesList = []
                    result.map((item, index) => {
                        var package_ids = []
                        if(couponData.package_ids)
                        {
                            package_ids = JSON.parse(couponData.package_ids)
                        }
                        var status = (package_ids.includes(item.id)) ? 1 : 0
                        packagesList.push({id:item.id, title:item.name, status:status})
                    })
                    _this1.setState({packagesList});
                }                
            }
            _this1.setState({showLoader:false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader:false});
        });

    }


    validateForm(){

        // return true

        var formErrors = {}

        var errrorsCount = 0

        let couponData = this.state.couponData

        if(couponData.name === ''){
            formErrors['name'] = 'Please enter coupon name.'
            errrorsCount++
        }

        if(couponData.code === ''){
            formErrors['code'] = 'Please enter coupon code.'
            errrorsCount++
        }

        if(couponData.percentage === ''){
            formErrors['percentage'] = 'Please enter coupon percentage.'
            errrorsCount++
        }
        else if(couponData.percentage && parseInt(couponData.percentage) < 0){
            formErrors['percentage'] = 'Percentage should be positive number.'
            errrorsCount++
        }

        this.setState({formErrors})

        if(errrorsCount > 0){            

            this.scrollToErrors()

            return false
        }

        return true

    }


    scrollToErrors = () => {
        setTimeout(function(){
            var msgErrorElmnt = document.getElementsByClassName("msg-error");

            if(msgErrorElmnt && msgErrorElmnt[0]){
                // console.log(msgErrorElmnt)
                msgErrorElmnt[0].parentElement.scrollIntoView();
            }
        }, 100);
    }

    handleSubmit = () => {
        var _this1 = this;
        if(this.validateForm()){

            let data = this.state.couponData
            data['packagesList'] = this.state.packagesList            
            var url = masterApiUrl+'coupons/add';
            
            if(_this1.state.couponData && _this1.state.couponData.id !== ''){
                url = masterApiUrl+'coupons/update/'+_this1.state.couponData.id;
            }

            this.setState({showLoader: true})

            callByJsonData(data, url, 'POST')
            .then((resp) => {
                if(resp){
                    this.setState({showLoader: false})
                }
                if(resp.success){
                    let saveType = 'add'
                    if(_this1.state.couponData && _this1.state.couponData.id !== ''){
                        saveType = 'update'
                    }

                    _this1.props.onSaved(resp.record, saveType)
                }
                else if(resp.errors){
                    this.setState({formErrors: resp.errors})
                    this.scrollToErrors()
                }
                else if(resp.error){
                    this.setState({msgErrClosable: resp.error})
                }
                else{
                    this.setState({msgErrClosable: 'Something went wrong, please try again.'})
                }
            })
            .catch((error) => {
                console.log('save-coupon-error=', error)
                this.setState({msgErrClosable: 'Something went wrong, please try again.'})
                this.setState({showLoader: false})
            });
        

        }
    }

    closeAlert = () => {
        this.setState({msgSuccClosable: false, msgErrClosable: false})
    }

    handleFromDate = (from_date) => {
        var couponData = this.state.couponData
        if(from_date)
        {
            // data['from_date'] = _this1.state.from_date;
            couponData['from_date'] = formatDate(from_date, 'YYYY-MM-DD')
        }
        else
        {
            couponData['from_date'] = ''
        }
        couponData['to_date'] = ''
        this.setState({couponData});
    }

    handleToDate = (to_date) => {
        var couponData = this.state.couponData
        if(to_date)
        {
            // data['from_date'] = _this1.state.from_date;
            couponData['to_date'] = formatDate(to_date, 'YYYY-MM-DD')
        }
        else
        {
            couponData['to_date'] = ''
        }
        this.setState({couponData});
    }


    handleSelectPackages = (e,item) => {
        var state = this.state.packagesList;
        const index = state.findIndex(row => row.id === item.id);
        item['status'] = (e.target.checked) ? 1 : 0;
        if (index !== -1) {
            state[index] = item
        }
        this.setState({packagesList: state});
    }


    render(){
        const formErrors = this.state.formErrors
        const packagesList = this.state.packagesList
        const couponData = this.state.couponData
        // console.log('couponData=', couponData)
        let currentDate = new Date()
        return(
            <React.Fragment>
                <div className={"form-panel active show" + " " + this.state.formScrollable}>
            
                {
                    this.state.showLoader ? <Loader /> : ''
                }

                {
                    this.state.msgErrClosable ? (<AlertClosable message={this.state.msgErrClosable} type='error'  onClose={this.closeAlert} />) : ''
                }
                    
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(couponData && couponData.id) ? 'Update': 'Add'} Coupons</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <label>Name</label>
                                        <input 
                                            className="form-style"
                                            name = "name" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter name"
                                            type="text"
                                            value = {(couponData && couponData.name) ? couponData.name : '' }
                                            />
                                            <div className="msg-error">{(formErrors.name) ? formErrors.name : ''}</div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="input-box">
                                                <label>Code</label>
                                                <input type="text" name="code" value={(couponData && couponData.code) ? couponData.code : '' } maxLength="6" onChange={this.handleChange} className="form-style" />
                                                <div className="msg-error">{(formErrors.code) ? formErrors.code : ''}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-box">
                                                <label>Percentage</label>
                                                <input 
                                                    type="number"
                                                    name = "percentage" 
                                                    value = {(couponData && couponData.percentage) ? couponData.percentage : '' }
                                                    onChange={this.handleChange}
                                                    className="form-style"
                                                    />
                                                    <div className="msg-error">{(formErrors.percentage) ? formErrors.percentage : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="input-box">
                                                <label>Start Date</label>
                                                <div className="input-date-box full-width">
                                                    <i className="icon-date"></i>
                                                    <DatePicker 
                                                        dateFormat="P" 
                                                        name="from_date" 
                                                        selected={(couponData && couponData.from_date) ? new Date(couponData.from_date) : ''} 
                                                        onChange={this.handleFromDate} 
                                                        placeholderText="Form date" 
                                                        className="form-style" 
                                                        minDate={currentDate}
                                                        />
                                                </div>
                                                <div className="msg-error">{(formErrors.from_date) ? formErrors.from_date : ''}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-box">
                                                <label>End Date</label>
                                                <div className="input-date-box full-width">
                                                    <i className="icon-date"></i>
                                                    <DatePicker 
                                                        dateFormat="P" 
                                                        name="end_date" 
                                                        selected={(couponData && couponData.to_date) ? new Date(couponData.to_date) : ''} 
                                                        onChange={this.handleToDate} 
                                                        placeholderText="End date" 
                                                        className="form-style" 
                                                        minDate={(couponData && couponData.from_date) ? new Date(couponData.from_date) : currentDate}
                                                        />
                                                </div>
                                                <div className="msg-error">{(formErrors.to_date) ? formErrors.to_date : ''}</div>
                                            </div>
                                        </div>
                                    </div>                                    

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label>Select Package</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                    {packagesList.map((item, index) => {

                                        let isCheckboxCheck = false
                                        if(item.title && item.title.length > 0 && item.status === 1){
                                            isCheckboxCheck  = true
                                        }

                                    return (
                                        <div className="col-sm-6" key={index}>
                                        <label className="checkbox"><input type="checkbox" onChange={(e) => this.handleSelectPackages(e,item)} defaultChecked={(isCheckboxCheck) ? true : false} /> <span>{item.title}</span></label>
                                        </div>


                                    )
                                }
                                )}
                                    </div>

                                    <div className="input-box inline-block mr-4">
                                        <label>Status</label>
                                        <label className="checkbox"><input type="checkbox" name="status" onChange={this.handleChange} checked={(couponData && couponData.status === true) ? true : false} /> <span>Active</span></label>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.close} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(couponData && couponData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.addCouponsActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddCoupons;