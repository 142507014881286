import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { dateTimeToDisplay } from '../../../utils.js';
class ActivityList extends Component{

    activityEdit = (activity) => {
        this.props.activityEdit(activity);
    }

    render(){
        const activityList = this.props.state.activityList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th width="10%">User</th>
                                <th width="60%">Title</th>
                                <th width="20%">Date</th>
                                <th width="10%"></th>
                            </tr>
                            {activityList.map((item, index) => (
                            <tr key={index} >
                                <td>
                                    <div className="user-with-thumb flex align-items-center">
                                        <div className="user-thumb thumb-lg"><img src={(item.photo_url) ? item.photo_url : UserThumb} alt={item.name} /></div>
                                        <div className="user-text">
                                            <div className="user-name">
                                            {item.name}
                                            </div>
                                            <div className="member-email">{item.email}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                {item.title}
                                </td>
                                <td>
                                {dateTimeToDisplay(item.created_at)}
                                </td>
                                <td>
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.activityEdit.bind(this, item)} >View</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default ActivityList;