import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
var config = require('../../../config.js');
class AddActivity extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'id':'',
            'username':'',
            'name':'',
            'email':'',
            'password':'',
            'photo_url':'',
            'status':true,
        };
        this.state = {
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : newData,
            errors: {},
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var editData = this.state.editData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox')
        {
            editData[name] = (event.target.checked) ? true : false
        }
        else
        {
            editData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('editData')
        // console.log(editData)
        this.setState({editData});
    }


    handleSubmit = async () => {
        var _this1 = this;
        if(this.state.editData && this.state.editData.username !== '' && this.state.editData.name !== '' && this.state.editData.email !== '')
        {            
            // const data = new FormData();
            const data = _this1.state.editData;
            var url = config.master_api_url+'activity/add';
            if(_this1.state.editData && _this1.state.editData.id !== '')
            {
                url = config.master_api_url+'activity/update/'+_this1.state.editData.id;
            }
            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (response) {
                //console.log(response);
                if(response.data.status === 'success')
                {                    
                    if(_this1.state.editData && _this1.state.editData.id !== '')
                    {
                        _this1.props.activityEdited(response.data.record);
                    }
                    else
                    {
                        _this1.props.activityAdded(response.data.record)
                    }                   
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
            }).catch(function (error) {
                //console.log(error);
                alert(error);
            });
        } else {
            var errors = {}
            if(_this1.state.editData.username === ''){
                errors['username'] = 'Please enter username';
            }
            if(_this1.state.editData.name === ''){
                errors['name'] = 'Please enter name';
            }
            if(_this1.state.editData.email === ''){
                errors['email'] = 'Please enter email'
            }
            this.setState({errors})
        }        
    }

    render(){
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {/*(this.state.editData && this.state.editData.id) ? 'Update': 'Add'*/}View Activity</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <label>User:</label>
                                        Braham
                                    </div>
                                    <div className="input-box">
                                        <label>Details:</label>
                                        sdfsdfsdf
                                    </div>
                                    <div className="input-box">
                                        <label>Date:</label>
                                        sdfsdfsdf
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddActivity} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                {/*<button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>*/}
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addActivityActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddActivity;