import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import HttpsRedirect from './https-redirect';
import store from './store';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import './styles/og-style-front.css';
import './styles/og-style.css';
import './styles/color-theme.css';

const persistor = persistStore(store());

const config = require('./config.js');
const isHttps = config.isHttps;

const httpsDisabled = (!isHttps)

ReactDOM.render(
	<Provider store={store()}>
		<HttpsRedirect disabled={httpsDisabled}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</HttpsRedirect>
	</Provider>,
    document.getElementById('root')
  )
  // registerServiceWorker()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
