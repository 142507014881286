import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Navbar from '../../components/front/Navbar'
import Footer from '../../components/front/Footer'

import Loader from './../../components/loader'
import Alert from './../../components/alert'
import AlertClosable from './../../components/alert-closable'
import { validateEmail } from '../../utils.js';
import { callByJsonData } from './../../ajax-call.js';
var config = require('./../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class Contact extends Component{
    constructor(props) {
        super(props);

        this.state = {
            name : '',
            email : '',
            phone : '',
            details : '',
            formErrors: {},
            showLoader: false,
            msgSuccess: false,
            msgError: false,
            msgSuccClosable: false,
            msgErrClosable: false,
        };
    }


    componentDidMount = () => {
        window.scrollTo(0, 0)
    }


    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox'){
            this.setState({[name]: (event.target.checked) ? 1 : 0});
        }
        else{
            this.setState({[name]: event.target.value});
        }
    }


    validateForm = () => {

        var formErrors = {}

        let name = this.state.name
        let email = this.state.email
        let phone = this.state.phone

        // console.log('formData=',formData)

        // let nameRegex = new RegExp(/(\b(?:([A-Za-z0-9])(?!\2{2}))+\b)/)
        let nameRegex = new RegExp(/(\b(?:([A-Za-z])(?!\2{2}))+\b)/)
        // let nameRegex = new RegExp(/^(?!.*(.)\2)(?=.*[A-Za-z])\w{2,15}$/)

        let phoneRegex = /^\+?([0-9]{2})\)?[]?([0-9]{4})[]?([0-9]{4})$/;

        var errorsCount = 0

        // console.log(formData['name'])

        if(!name || name.trim() === ''){
            formErrors['name'] = 'Please enter name.'
            errorsCount++
        }
        else if(!name.match(nameRegex)){
            formErrors['name'] = 'Please enter valid .'
            errorsCount++
        }
        else if(name.length < 2 || name.length > 30){
            formErrors['name'] = 'Name should contain minimum 2 and maximum 30 characters.'
            errorsCount++
        }

        if(!phone || phone.trim() === ''){
            formErrors['phone'] = 'Please enter phone.'
            errorsCount++
        }
        else if( !(phone.match(phoneRegex)) ){
            formErrors['phone'] = 'Please enter valid phone.'
            errorsCount++
        }

        if(!email || email.trim() === ''){
            formErrors['email'] = 'Please enter email.'
            errorsCount++
        }
        else if(!validateEmail(email)){
            formErrors['email'] = 'Please enter valid email address.'
            errorsCount++
        }

        this.setState({formErrors})

        if(errorsCount > 0){

            this.scrollToErrors()

            return false
        }

        // return false
        return true

    }


    scrollToErrors = () => {
        setTimeout(function(){
            var msgErrorElmnt = document.getElementsByClassName("msg-error");

            if(msgErrorElmnt && msgErrorElmnt[0]){
                // console.log(msgErrorElmnt)
                msgErrorElmnt[0].parentElement.scrollIntoView();
            }
        }, 100);
    }

    handleSubmit = (e) => {

        e.preventDefault()
                    
        var _this1 = this;

        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(config.GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(recaptcha_token) {
              // Send form value as well as token to the server

                if(_this1.validateForm()){

                    let data = {}

                    data['name'] = _this1.state.name
                    data['email'] = _this1.state.email
                    data['phone'] = _this1.state.phone
                    data['details'] = _this1.state.details
                    data['recaptcha_token'] = recaptcha_token
                    
                    var url = masterApiUrl+'enquiries/add';

                    // _this1.setState({showLoader: true, msgSuccess: ''})
                    // return false

                    callByJsonData(data, url, 'POST')
                    .then((resp) => {
                        if(resp){
                            _this1.setState({showLoader: false})
                        }
                        if(resp.success){                    
                            _this1.setState({msgSuccess: 'Your enquiry has been submitted successfully.'})
                            
                            _this1.contactFormRef.reset();
                            
                            _this1.setState({
                                name : '',
                                email : '',
                                phone : '',
                                details : ''
                            })
                            
                        }
                        else if(resp.errors && Object.keys(resp.errors).length > 0){
                            _this1.setState({formErrors: resp.errors})
                            _this1.scrollToErrors()
                        }
                        else if(resp.error){
                            _this1.setState({msgErrClosable: resp.error})
                        }
                        else{
                            _this1.setState({msgErrClosable: 'Something went wrong, please try again.'})
                        }
                    })
                    .catch((error) => {
                        console.log('contact-form-error=', error)
                        _this1.setState({msgErrClosable: 'Something went wrong, please try again.'})
                        _this1.setState({showLoader: false})
                    });

                }

            });
        });
    }

    closeAlert = () => {
        this.setState({msgSuccClosable: false, msgErrClosable: false})
    }


    render(){

        const formErrors = this.state.formErrors

        return(
            <React.Fragment>

                {
                    this.state.showLoader ? <Loader /> : ''
                }

                {
                    this.state.msgSuccess ? (<Alert message={this.state.msgSuccess} type='success' />) : ''
                }
                {
                    this.state.msgError ? (<Alert message={this.state.msgError} type='error' />) : ''
                }

                {
                    this.state.msgErrClosable ? (<AlertClosable message={this.state.msgErrClosable} type='error'  onClose={this.closeAlert} />) : ''
                }

                {
                    this.state.msgErrClosable ? (<AlertClosable message={this.state.msgErrClosable} type='error'  onClose={this.closeAlert} />) : ''
                }

                <Navbar fixed active={3} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                        <div className="fp-header-caption">
                            <div className="fp-page-title">Talk with our <span>sales team</span></div>
                            <p className="fp-text-lg">Looking for more information? Submit your information and our representative will follow up with you as soon as possible. </p>                   
                        </div>
                    </div>
                </header>
                <section className="fp-section fp-section-contact">
                    <div className="container">
                        <div className="fp-contact-form-panel">
                            <form onSubmit={this.handleSubmit} ref={(el) => this.contactFormRef = el}>
                                <div className="fp-form-group">
                                    <label>Name <span className="fp-color-red">*</span></label>
                                    <input type="text" name="name" onChange={this.handleChange} placeholder="e.g. John Smith" className="fp-form-style2" autoFocus />
                                    {formErrors['name'] ? (<span className="msg-error">{formErrors['name']}</span>) : ''}
                                </div>
                                <div className="fp-form-group">
                                    <label>Company Email <span className="fp-color-red">*</span></label>
                                    <input type="text" name="email" onChange={this.handleChange} placeholder="name@company.com" className="fp-form-style2" />
                                    {formErrors['email'] ? (<span className="msg-error">{formErrors['email']}</span>) : ''}
                                </div>
                                <div className="fp-form-group">
                                    <label>Phone Number <span className="fp-color-red">*</span></label>
                                    <input type="text" name="phone" onChange={this.handleChange} placeholder="9874563214" className="fp-form-style2" />
                                    {formErrors['phone'] ? (<span className="msg-error">{formErrors['phone']}</span>) : ''}
                                </div>
                                <div className="fp-form-group">
                                    <label>What would you like to discuss? </label>
                                    <textarea name="details" onChange={this.handleChange} className="fp-form-style2" rows="7" placeholder="Tell us about your team and provide some details about a project or process you would like to track in OpenGrowth Hub."></textarea>
                                </div>
                                <div className="fp-btn-outer">
                                    <button className="fp-btn-main fp-btn-lg">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default Contact