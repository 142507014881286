import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import UserThumb from '../../../images/user1.jpg';
import { getBillingTypeLabel } from './orders-add';
import { dateTimeToDisplay, AmountDisplay } from '../../../utils.js';

class OrdersList extends Component{

    handleEditOrder = (orderData) => {
        this.props.onEditOrder(orderData);
    }

    handleEditOrderFeatures = (orders) => {
        this.props.onEditOrderFeatures(orders);
    }
    
    getPackageName = (item) => {
        if(item.package_details)
        {
            var package_details = JSON.parse(item.package_details)
            return package_details['name']
        }
    }

    getWorkspaceName = (item) => {
        if(item.workspace_details)
        {
            var workspace_details = JSON.parse(item.workspace_details)
            return workspace_details['workspace']
        }
    }

    render(){
        const ordersList = this.props.ordersList;

        // console.log('ordersList=', ordersList)

        /*if(ordersList && ordersList.length > 0){
            ordersList.sort((a,b) => {
                if(parseInt(a.sort_order) > parseInt(b.sort_order)){
                    return 1
                }

                return -1
            })
        }*/

        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th width="25%">Name / Email / Order ID</th>
                                <th width="10%">Invoice Number</th>
                                <th width="10%">Workspace</th>
                                <th width="10%">Plan</th>
                                <th width="10%">Billing</th>
                                <th width="10%">Price</th>
                                <th width="15%">Created At</th>
                                <th width="10%" align="right" >Action</th>
                            </tr>
                            {ordersList.map((item, index) => (
                            <tr key={index} >
                                <td>
                                    {item.company} <br />
                                    {item.email_address} <br />
                                    <span><Link to={`/admin/order-items/${item.id}`} target="_blank" className="button-like-link" >({item.id})</Link></span>
                                    
                                </td>
                                <td>
                                    {item.invoice_number}
                                </td>
                                <td>
                                    {this.getWorkspaceName(item)}
                                </td>
                                <td>
                                    {this.getPackageName(item)}
                                </td>
                                <td>
                                    {item.billing.toUpperCase()}
                                </td>
                                <td>
                                    <AmountDisplay
                                        amount = {item.amount}
                                        currency = {item.currency}
                                    />
                                </td>
                                <td>
                                    {dateTimeToDisplay(item.created_at)}
                                </td>
                                <td>
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li>
                                                                <Link to={`/admin/order-items/${item.id}`} target="_blank" className="button-like-link" >View Details</Link>
                                                            </li>
                                                            {/*<li>
                                                                <button onClick={()=>this.props.testUpdate(item)} className="button-like-link" >update</button>
                                                            </li>*/}
                                                            {/*<li><button onClick={() => this.props.onCopyOrder(item)} className="button-like-link" title="Copy this order" >Copy</button></li>*/}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default OrdersList;