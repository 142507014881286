import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import CoachList from '../../components/admin/coach/coach-list'
import AddCoach from '../../components/admin/coach/coach-add';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader'
import axios from 'axios';
var config = require('../../config.js');
class Coach extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addCoachActive:false,
            loadingData:true,
            search_text:'',
            coachList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getCoach();
    }

    getCoach = async () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        axios({
            method: 'post',
            url: config.master_api_url+'coach/list',
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
                _this1.setState({coachList:resp.data.result});
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            //console.log(error);
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    handleCreateCoach = () => {
        if(this.state.addCoachActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addCoachActive: false})
            },620);
        } else {
            this.setState({addCoachActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddCoach = () => {
        var editData = {}
        this.setState({editData});
        this.handleCreateCoach();
    }

    coachAdded = (coach) => {
        this.state.coachList.push(coach);
        this.handleCreateCoach();
    }

    coachEdit = (coach) => {
        this.setState({editData: coach});
        this.handleCreateCoach();
    }

    coachEdited = (coach) => {
        const state = this.state.coachList;
        const index = state.findIndex(row => row.id === coach.id);
        state[index] = coach;
        this.setState({coachList: state});
        this.handleCreateCoach();
    }

    coachDeleted = (coach) => {
        var state = this.state.coachList;
        const index = state.findIndex(row => row.id === coach.id);
        if (index === -1) {
            state = [];
        }
        state = [...state.slice(0, index), ...state.slice(index + 1)];
        this.setState({coachList: state});
    }

    render(){

    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Coach</h2></div></div></div>
        <div className="container-area">
            {
                this.state.loadingData ? <Loader /> : ''
            }
            <div className="inv-body">
                <div className="box">
                    {/*<div className="box-title">
                        <div className="box-filter-left flex align-items-center">
                            <div className="sort-box box-search flex-1">
                                <div className="filter-search-box">
                                    <i className="icon-search"></i>
                                    <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search coach" />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end">
                            <button onClick={this.handleCreateCoach} className="btn-main btn-lg">Create New Coach</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <CoachList state={this.state} coachEdit={this.coachEdit} />
                    </div>
                    {
                        this.state.addCoachActive ? (
                            <AddCoach state={this.state} cancelAddCoach={this.cancelAddCoach} coachAdded={this.coachAdded} coachEdited={this.coachEdited} />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default Coach;