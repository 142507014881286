import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftContainer from '../../../containers/users/LeftContainer';
import $ from 'jquery';
import Loader from '../../../components/admin/loader';

import { isUserPrimaryOrAdmin } from '../../../utils.js';
var md5 = require('md5');

var config = require('../../../config.js');

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
  //setUserDetails: user => dispatch(setUserDetails(user)),
})

class ChangePassword extends Component {
    constructor(props) {
        super(props);    
        this.state = {
            uid:(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')): '',
            providerId:'',
            formData:{},
            password:'',
            new_password:'',
            conf_password:'',
            password_message:'',
            showLoader: false,
            errors: {},
            currentUserClaims: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleChange(event)
    {
        var name = event.target.name;
        var formData = this.state.formData;
        var value = event.target.value;
        if(name !== "password"){
            value = md5(event.target.value);
        }
        formData[name] = value;
        this.setState({formData});
    }

    validateForm(fields){
        var resp = true;
        var errors = {}
        var old_password = this.state.uid.password

        if(!fields.password){
            errors['password'] = "Current Password required."
            resp = false;
        }else{
            if(old_password !== md5(fields.password)){
                errors['password'] = "Current Password is wrong."
                resp = false;
            }
        }
        if(!fields.new_password){
            errors['new_password'] = "New Password required."
            resp = false;
        }

        if(!fields.conf_password){
            errors['conf_password'] = "Confirm Password required."
            resp = false;
        }

        if(fields.new_password && fields.conf_password){
            if(fields.conf_password!==fields.new_password){
                errors['conf_password'] = "Confirm Password should be equal to new password."
                resp = false;
            }
        }


        this.setState({errors});

        return resp;
    }

    async handleSubmit(event)
    {
        event.preventDefault();
        var form_data = this.state.formData;
        var uid = (this.state.uid) ? this.state.uid.id: '';
        var _this1 = this;
        const url = config.master_api_url+'users/change-password/'+uid;
        const validation = await this.validateForm(form_data)
        if(validation === true){
            _this1.setState({
                showLoader: true
            })
            $.ajax({
                type: 'POST',
                url,
                headers: {
                    'tenantId':''
                },
                contentType: 'application/json',
                data: JSON.stringify(form_data),
                dataType: 'json',
                success: function(resp) {
                    var msg = "Password changed successfully.";
                    _this1.setState({
                        showLoader: false,
                        password_message:msg,
                        formData:{},
                        errors:{}
                    })
                    console.log(resp)
                    if(resp.record){
                        localStorage.setItem('user', JSON.stringify(resp.record));
                    }
                }
            }).catch();
        }
    }
  render() {
    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Profile</h2></div></div></div>
        <div className="container-area">
            {
                this.state.showLoader ? <Loader /> : ''
            }
            <div className="user-profile-container">
                <LeftContainer signOut={this.props.signOut} />
                <div className="profile-main-container">
                    <div className="profile-main-inner">
                        <div className="profile-inner-view">
                            <div className="profile-section-top">
                                <ul className="nav-tab">
                                    {/*<li><Link to="/admin/profile-update">Edit Profile</Link></li>*/}
                                    <li className="active"><Link to="/admin/change-password">Change Password</Link></li>
                                </ul>
                            </div>
                            <div className="row">
                              <form onSubmit={this.handleSubmit}>
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>Current Password</label>
                                        <input type="password" name="password" placeholder="Enter your current password" className="form-style" onChange={this.handleChange} />
                                        <div className="color-danger"> {this.state.errors.password}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>New Password</label>
                                        <input type="password" name="new_password" placeholder="Set new password" className="form-style" onChange={this.handleChange} />
                                        <div className="color-danger"> {this.state.errors.new_password}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>Confirm Password</label>
                                        <input type="password" name="conf_password" placeholder="Confirm new password" className="form-style" onChange={this.handleChange} />
                                        <div className="color-danger"> {this.state.errors.conf_password}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box mb0">
                                        <Link to="/admin/profile" className="btn-main btn-outline btn-form">Cancel</Link>
                                        <button type="submit" className="btn-main btn-form">Update</button>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div style={{ "color": "green", "fontWeight": "bold", "marginTop":"10px"}}> {this.state.password_message}</div>
                                </div>
                              </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);