import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { dateTimeToDisplay } from '../../../utils.js';
class EnquiriesList extends Component{

    enquiriesEdit = (enquiries) => {
        this.props.enquiriesEdit(enquiries);
    }

    render(){
        const enquiriesList = this.props.state.enquiriesList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th width="20%">Name</th>
                                <th width="30%">Email</th>
                                <th width="20%">Phone</th>
                                <th width="20%">Date</th>
                                <th width="10%"></th>
                            </tr>
                            {enquiriesList.map((item, index) => (
                            <tr key={index} >
                                <td>
                                {item.name}
                                </td>
                                <td>
                                {item.email}
                                </td>
                                <td>
                                    {item.phone}
                                </td>
                                <td>
                                {dateTimeToDisplay(item.created_at)}
                                </td>
                                <td>
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.enquiriesEdit.bind(this, item)} >View</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default EnquiriesList;