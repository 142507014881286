import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../front/Navbar'
import Footer from '../front/Footer'
import Loader from '../loader'

import $ from 'jquery';

var config = require('../../config.js');

const api_url = config.api_url;

class ConfirmEmail extends Component {

    constructor(props) {
        super(props);

        //console.log(props);

        /*if(props.signup && props.signup.email && props.signup.email !== '' && props.signup.email !== null ){
            localStorage.setItem('signup_email', props.signup.email);
        }*/

        var signupDetails = (props.state.signupDetails)?props.state.signupDetails:{}
        // var signupDetails = (localStorage.getItem('signupDetails'))?localStorage.getItem('signupDetails'):{}

        var signup_email = (signupDetails.email)?signupDetails.email:''
        var signup_name = (signupDetails.name)?signupDetails.name:''
        var affiliate_code = (signupDetails.affiliate_code)?signupDetails.affiliate_code:''

        // console.log('ConfirmEmail-signupDetails')
        // console.log(signupDetails)

        this.state = {
        showLoader:false,
        msg_success:false,
        msg_error:false,
          //email:localStorage.getItem('signup_email'),
          signupDetails: signupDetails,
          signup_message:'',
          otp:(props.signup && props.signup.otp)?props.signup.otp:'',
          signup_email:signup_email,
          signup_name:signup_name,
          affiliate_code:affiliate_code,
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        //console.log(props);

        if(!this.state.signup_email || this.state.signup_email === '' || this.state.signup_email === null ){
            window.location.hash = '';
        }
    }

    handleChange(event){
      var name = event.target.name;
      var state = {};
      state[name] = event.target.value;
      this.setState(state);

      //console.log(this.state);
    }


    handleSubmit(event){
      event.preventDefault();
      var _this1 = this;

      var signup_message = [];

      var isValidated = true;

      //console.log(this.state);

      if(this.state.singupEmail === ''){
        signup_message.push('E-mail address can not be empty')
        isValidated = false;
      }
      if(this.state.otp === ''){
        signup_message.push('Please enter OTP')
        isValidated = false;
      }

      if(!isValidated){
        //console.log(signup_message);
        //console.log(signup_message);
        signup_message = signup_message.join("|");
        //console.log(signup_message);
        _this1.setState({signup_message});

        //return true;
      }
      else{
        _this1.setState({signup_message});

        var data = {};

            data['email'] = _this1.state.signup_email;
            data['otp'] = _this1.state.otp;

            var _this2 = _this1;

            const url = api_url+'signup/confirmemail';

            $.ajax({
                  type: 'POST',
                  url: url,
                  contentType: 'application/json',
                  data: JSON.stringify(data),
                  dataType: 'json',
                  beforeSend: function(){
                    _this1.setState({showLoader: true});
                  },
                  success: function(resp) {

                     if(resp){
                      _this1.setState({showLoader: false});
                    }
                    //console.log(_this1.props);
                    //console.log(_this1.state.email);

                    // var signupData = {};
                    // signupData['email'] = _this1.state.signup_email;
                    // signupData['emailVerified'] = true;
                    // _this1.props.onSetSignupDetails(signupData);

                    //_this1.props.onSetIsEmailVerified(true);
                    //console.log(_this1.props);

                    if(resp.success){

                      var signupDetails = _this1.state.signupDetails

                      signupDetails['emailVerified'] = true
                      _this1.props.updateSignupDetails(signupDetails);
                        //_this1.setState({invitations:resp.invitations});
                        //_this2.props.history.push("#confirmemail");
                       window.location.hash = '#companyname';

                      }
                      else{
                        var signup_message = 'please enter valid OTP.'
                        // _this1.setState({signup_message});
                        _this1.showMessage('error', signup_message);
                      }
                  }
            }).catch(function(error){
              console.log(error);
              _this1.setState({showLoader: false});
            });

      }   
    }


    resendOtp = (event) => {
      event.preventDefault();
      var _this1 = this;

      var conf = window.confirm("Are you really want to resend OTP code?")

      if(conf){
        //alert(this.state.email)
        
            //localStorage.setItem('signup_email', _this1.state.email);

            var data = {};

            data['name'] = _this1.state.signup_name;
            data['email'] = _this1.state.signup_email;
            data['affiliate_code'] = _this1.state.affiliate_code;

            const url = api_url+'signup/email';
            // const url = 'http://127.0.0.1:8081/signup/email';

            $.ajax({
                  type: 'POST',
                  url: url,
                  contentType: 'application/json',
                  data: JSON.stringify(data),
                  dataType: 'json',
                  beforeSend: function(){
                    _this1.setState({showLoader: true, msg_success: false, msg_error:false});
                  },
                  success: function(resp) {

                    if(resp){
                      _this1.setState({showLoader: false});
                    }

                    if(resp.success){
                      // _this1.setState({msg_success:"OTP has been sent successfully."});
                      _this1.showMessage('success', "OTP has been sent successfully.");
                    }
                    else if(resp.error){
                      // _this1.setState({msg_error:resp.error});
                      _this1.showMessage('error', resp.error);
                    }
                  }
            }).catch(function(error){
              console.log(error);
              _this1.setState({showLoader: false});
            });

            //_this1.props.history.push('confirmemail');
            //window.location = 'signup2';
          
        //window.location = 'signup2';
        //return true;          
      }
      else
      {
        // const signup_message = 'E-mail address can not be empty';
        // _this1.setState({signup_message});
        return false;
      }      
    

    }

    showMessage = (type='', msg='') => {
      if(type !== '' && msg !== ''){
        if(type === 'success'){
          this.setState({msg_success:msg});
          setTimeout(function(){ this.setState({msg_success:false}); }.bind(this), 3000);
        }
        if(type === 'error'){
          this.setState({msg_error:msg});
          setTimeout(function(){ this.setState({msg_error:false}); }.bind(this), 3000);
        }
      }
    }


  render() {
    return (
      <React.Fragment>
        <Navbar fixed hideNav />
        <div className="fp-main-full-inner flex align-items-center justify-content-center container">
            <div className="singup-box">
        {(this.state.showLoader) ? <Loader /> : null}
                <div className="login-box-inner">
                    <div className="login-box-header">
                        <div className="fp-heading2">Check your email!</div>
                        <p className="fp-text-light">We've sent a 6-digit confirmation code to <strong>{this.state.signupDetails.email}</strong>. <br />It will expire shortly, so enter it soon.</p>
                    </div>
                    <div className="form-group-box">
                        <div className="user-form-box login-form-main form-steps active show">

                        {(this.state.msg_error) ? (
                          <div className="msg-error">{this.state.msg_error}</div>
                          ) : ('')}

                        {(this.state.msg_success) ? (
                          <div className="msg-success">{this.state.msg_success}</div>
                          ) : ('')}

                            <form action="">
                                <div className="user-form-inner">
                                    <div className="verification-box input-box">
                                        <div className="row no-gutters">
                                          <input type="text" name="otp" placeholder="" className="form-style input-otp" defaultValue="" onChange={this.handleChange} />                                            
                                        </div> 
                                        <p className="resend_code_text">Didn’t you receive any code? <a onClick={this.resendOtp} >Resend New Code</a></p>
                                    </div>
                                    <div className="msg-error" id="login_error"></div>
                                    <div className="input-box mb0">
                                        <button type="button" className="btn-main btn-form btn-submit btn-full" onClick={this.handleSubmit} >Confirm</button>
                                    </div>                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Footer hideNav />
      </React.Fragment>
    );
  }
}
export default ConfirmEmail;