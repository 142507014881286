import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Navbar from '../../components/front/Navbar'
import Header from '../../components/front/Header'
import Infobox from '../../components/front/Info-box'
import Feature from '../../components/front/Feature'
import Ctapanel from '../../components/front/Cta-panel'
import Testimonials from '../../components/front/Testimonials'
import Footer from '../../components/front/Footer'

import imgWhyus from '../../images/img-whyus.jpg'
import imgHeader from '../../images/img-header.jpg'
import img1 from '../../images/img1.png'
import img2 from '../../images/img2.png'
import img3 from '../../images/img3.png'

class Home extends Component{
    state = {
        testimonials: [
            {text: 'OpenGrowth Hub is an excellent project collaboration tool. Managing remote working teams is seamless now. Everyone on the team knows where the project stands and what needs to be done; what is lagging behind or is behind schedule. The business channel feature has helped my team to interact without getting involved in a long email thread.', author: 'Akriti Verma', designation: 'Co-Founder, Director - OpenGrowthUniversity', image: require('../../images/akriti.jpg')},
            {text: 'We are using open growth hub and it really helped us to optimize the efficiency of the work and also helped us to reduce the business operational cost. It is simple and easy to use. We highly recommend it.', author: 'Prakash Jain', designation: 'Co-founder, India Internets, DigitalOye & VayuHost', image: require('../../images/prakash.jpg')},
            {text: 'As a fully remote and distributed organization, we needed a platform on which not only could teams collaborate but also enable a framework for remote team management. OpenGrowth Hub is providing that platform. Tools like Slack, Zoom, Google apps allow for collaboration but don\'t solve the problem of how to manage a remote and virtual team. OpenGrowth Hub brings together both the collaboration and management layer into a seamless and integrated platform.', author: 'Aakash Prasad', designation: 'Founder CEO DesignEverest', image: require('../../images/aakash.jpg')},
            {text: 'OpenGrowth hub has been a very useful tool for enhancing the overall team productivity and team management. It has helped us streamline a lot of things which is utmost important for a remote team like ours. It’s easy, clear and time-saving.', author: 'Shraddha Varma', designation: 'Co Founder, Director - Fuzia', image: require('../../images/shradha.jpg')},
            {text: 'Open Growth Hub has been a monumental help to organize tasks for the team members, collaborate. You can. easily track progress and assign projects. A true help that allowed us to take our business to the next level.', author: 'Negin Shiraghaei', designation: 'Founder and CEO, Open Growth UK', image: require('../../images/negin.jpg')}
        ]
    }
    componentDidMount = () => {
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <React.Fragment>
                <Navbar />
                <Header bgImage={imgHeader} caption={{title:'Work and Growth Management for Founders, Entrepreneurs and their Winning Teams!', description:['Team that want to drive aggressive Growth and scale their business.', 'Teams that want to delight customers and grow serious profits.', 'Team that want to change the world and drive real impact in the world!'], btnText:'Signup for free!', btnLink:'/plans-and-pricing'}} />
                <div className="fp-full-panel fp-bg-primary">
                    <div className="container">
                        <div className="fp-boxes-outer row">
                            <Infobox title={'Get Organized'} description={'Plan and structure work in a way that’s best for you. Set priorities and deadlines. Share details and assign tasks. All in one place.'} iconClass={'fp-icon-organize'} />                            
                            <Infobox title={'Stay on Track'} description={'Follow projects and tasks through every stage. You know where work stands and can keep everyone aligned on goals.'} iconClass={'fp-icon-check-list'} />                            
                            <Infobox title={'Hit Deadlines'} description={'Create visual project plans to see how every step maps out over time. Pinpoint risks. Eliminate roadblocks. Even when plans change.'} iconClass={'fp-icon-deadline'} />                             
                        </div>
                    </div>
                </div>
                <div className="fp-features-section-group">
                    <Feature title={'Set priorities <br />and deadlines'} description={'Set priority of your tasks and track percentage completion. Keep a tab on the deadlines with the help of Priority feature on the OpenGrowthHub '} image={img1} class={'fp-feature-1'} />
                    <Feature title={'Follow projects <br />and tasks through every stage'} description={'With our Daily and Weekly Huddle feature, people can go back and see what transpired in the huddles. Percentage completion will enable you track and  manage your task more effectively and track projects which are behind schedule.'} image={img2} class={'fp-feature-2'} />
                    <Feature title={'Create visual <br />project plans'} description={'Create visual plans with Channels, add a business categories where relevant documents, messages and	announcements, and alerts can be shared. '} image={img3} class={'fp-feature-3'} />
                </div>
                <section className="fp-section fp-section-whyus fp-bg-darkgrey">
                    <div className="container">
                        <div className="fp-whyus-heading-box text-center">
                            <div className="fp-heading2">Why <span>OpenGrowth Hub</span>?</div>
                            <p className="fp-text-md fp-para">Explore endless possibilities with our open growth collaboration platform, OpenGrowthHub! <br />We bring winning teams to life and believe in Leadership for all!! </p>
                        </div>
                        <div className="fp-whyus-box row">
                            <div className="col-lg-7">
                                <div className="fp-whyus-media"><img src={imgWhyus} /></div>
                            </div>
                            <div className="col-lg-5">
                                <div className="fp-whyus-info">                                    
                                    <p className="fp-text-md about-text">The OpenGrowthHub empowers remote teams to work efficiently by enabling them to collaborate virtually.  We help create transparency between teams which will enhance employees' and manager's performance. Schedule daily meetings to keep your team informed about current tasks, ask for help using the stuck feature and let experts from your team know where you are stuck and get their help and feedback. Get guidance from industry experts and enhance your skills and knowledge. </p>
                                    <div className="fp-btn-outer"><Link to="/about" className="fp-btn-main fp-btn-lg">Read more</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fp-section fp-section-testimonials">
                    <div className="container fp-text-center">
                        <div className="fp-heading2">Testimonials</div>
                        <div className="fp-testimonial-box">
                            <Testimonials testimonials={this.state.testimonials} />
                        </div>
                    </div>
                </section>
                <section className="fp-section fp-section-cta fp-bg-primary fp-text-center">
                    <div className="container">
                        <Ctapanel />
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default Home