import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import BlogsList from '../../../components/admin/blogs/blog-list'
import AddBlog from '../../../components/admin/blogs/blog-add';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader'
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');
const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'
class Blogs extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addBlogActive: false,
            showLoader: false,
            showLoading: false,
            search_text:'',
            blogsList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox'){
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else{
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getBlogsList();
    }

    getBlogsList = () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text){
            data['search_text'] = _this1.state.search_text;
        }

        var url = masterApiUrl+'blogs/list'

        this.setState({showLoader: true, showLoading: true})

        let apiResp = callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                this.setState({showLoader: false, showLoading: false})
            }

            if(resp.result){
                _this1.setState({blogsList: resp.result});
            }

        })
        .catch((error) => {
            console.log('blogs-list-error=',error)
            this.setState({showLoader: false, showLoading: false})
        });

    }

    handleDelete = (blogData) => {
        if(blogData && blogData.id > 0){

            var conf = window.confirm("Are you really want to delete this record?")

            if(conf){
                
                var _this1 = this;
                
                var data = {};

                var url = masterApiUrl+'blogs/delete/'+blogData.id

                this.setState({showLoader: true})

                let apiResp = callByJsonData(data, url, 'POST')
                .then((resp) => {

                    if(resp){
                        this.setState({showLoader: false})
                    }

                    if(resp.success){
                        _this1.blogSaved(blogData, 'delete')
                    }

                })
                .catch((error) => {
                    console.log('blog-delete-error=',error)
                    this.setState({showLoader: false})
                });

            }

        }

    }

    handleCreateBlog = () => {
        if(this.state.addBlogActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addBlogActive: false})
            },620);
        } else {
            this.setState({addBlogActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddBlog = () => {
        this.setState({editData: {}, addBlogActive: false})
    }

    blogSaved = (blogData, saveType='') => {
        this.getBlogsList()
        this.cancelAddBlog()
        // if(blogData && blogData.id){
        //     let blogsList = this.state.blogsList

        //     let blogIndx = blogsList.findIndex((l) => l.id === blogData.id)

        //     if(saveType === 'delete'){
        //         if(blogIndx >= 0){
        //             blogsList.splice(blogIndx, 1)
        //         }
        //     }
        //     else{

        //         if(blogIndx >= 0){
        //             blogsList[blogIndx] = blogData
        //         }
        //         else{
        //             blogsList.unshift(blogData)
        //         }

        //     }

        //     this.setState({blogsList})
        //     this.cancelAddBlog()
        // }
    }

    handleEdit = (blog) => {
        this.setState({editData: blog});
        this.handleCreateBlog();
    }

    render(){

        return (
        <div className="page-main-holder">
            <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Blogs</h2></div></div></div>
            <div className="container-area">
                {
                    this.state.showLoader ? <Loader /> : ''
                }
                <div className="inv-body">
                    <div className="box">
                        {/*<div className="box-title">
                            <div className="box-filter-left flex align-items-center">
                                <div className="sort-box box-search flex-1">
                                    <div className="filter-search-box">
                                        <i className="icon-search"></i>
                                        <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search coach" />
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        <div className="box-title">
                            <div className="section-btn-outer align-self-end">
                                <button onClick={this.handleCreateBlog} className="btn-main btn-lg">Create New Blog</button>
                            </div>
                        </div>

                        <div className="box-body">
                            <BlogsList
                            showLoading={this.state.showLoading}
                            blogsList={this.state.blogsList}
                            handleEdit={this.handleEdit}
                            handleDelete={this.handleDelete}
                            />
                        </div>
                        {
                            (this.state.addBlogActive === true) && (
                                <AddBlog
                                blogData={this.state.editData}
                                cancelAddBlog={this.cancelAddBlog}
                                blogSaved={this.blogSaved}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default Blogs;