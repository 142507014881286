import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { dateTimeToDisplay, titleCase } from '../../../utils.js'

export default class MenuRows extends Component {

	render(){

		var pages = this.props.pages
		var superParentId = (this.props.superParentId) ? this.props.superParentId : 0

		pages = JSON.parse(JSON.stringify(pages))

		pages.sort((a, b) => {
			if(a.sort_order > b.sort_order){
				return 1
			}
			return -1
		})

		const level = this.props.level

		// if(level === 0){
		// 	console.log('PageRows-pages=', pages)
		// }

    var levelIndent = ''

    for(var i=0; i<level; i++){
        levelIndent = levelIndent+' - '
    }

		return (

			pages.map((page, pdx) => {

				if(page.type === 'main'){
					superParentId = page.id
				}

				var pageUrl = '/'+page.slug

				if(page.type === 'static'){
					pageUrl = '/page/'+page.slug
				}

				var boldTitle = {'fontWeight':'600'}

				if(page.type !== 'main'){
					boldTitle = {}
				}
				
				var rowStyle = {}
				var mainBlockStyle = {}
				var mainBlockTitle = ''

				rowStyle['display'] = 'none'

				if(page.type !== 'main' && this.props.showChildPages[superParentId]){
					delete rowStyle['display']
				}
				else if(page.type === 'main'){
					delete rowStyle['display']

					mainBlockStyle['cursor'] = 'pointer'
					mainBlockTitle = 'Click to expand.'
				}

				return (

					<React.Fragment key={page.id}>

					<tr style={rowStyle}>

						<td onClick={() => this.props.handleShowChildPages(page.id)} style={mainBlockStyle} title={mainBlockTitle}>
						{levelIndent}
						<span style={boldTitle} >{page.title} ({titleCase(page.type)})</span>
						{/*(page.type !== 'main') ? (page.title) : (
							<span>{page.title} ({titleCase(page.type)})</span>
							)*/}

						{
								(page.type === 'static') && (

										<Link to={pageUrl} title="Goto this page" className="goto-link"><i className="icon-goto-page"></i></Link>

										)
						}

						{
							(page.form && page.form.form_submissions_count) && (

								<span>Form(
								<Link to={`/form-submissions?fid=${page.form.form_id}`} title="Goto this page" className="goto-link">{page.form.form_submissions_count}</Link>

								)</span>
								)
						}
						</td>

						<td>
						{
								(page.user_details && page.user_details.name) ? (page.user_details.name) : ('')
						}
						</td>

						<td>
						{(page.status === 0) && 'Draft'}
						{(page.status === 1) && 'Published'}
						{(page.status === 2) && 'Hidden'}
						</td>

						<td>
						{/*page.id*/}
						<input type="number" name="sort_order" defaultValue={page.sort_order} onChange={(e) => this.props.handleSortOrder(e, page.id, page.sort_order)} style={{'width':'60px'}} />
						</td>

						<td>{dateTimeToDisplay(page.created_at)}</td>
						<td>{dateTimeToDisplay(page.updated_at)}</td>

						<td>
						<div className="meeting-notes-action-box">
								<div className="dropdown-parent more-action-box">
										<ul className="actions right flex">

										{
												(page.type === 'static') && (

														<React.Fragment>

														
														
														<li><span onClick={() => this.props.handleDeletePage(page, pdx)} title="Delete"><i className="icon-delete"></i></span></li>
														
														<li>
															<Link to={`/cms-page-add/${page.id}`} >
																	<span title="Edit"><i className="icon-edit"></i></span>
															</Link>
														</li>
														
														</React.Fragment>

														)
										}

										{
												(page.type !== 'static' ) && (
														<li>
															<span onClick={() => this.props.handleShowHide(page, level)} title="Edit title">
															{(page.status === 2) ? ('Show') : ('Hide')}
															</span>
														</li>

														)
										}

										{
												(page.type !== 'static') && (
														<li><span onClick={() => this.props.openUpdateTitlePopup(page, level)} title="Edit title"><i className="icon-edit"></i></span></li>

														)
										}
										
												
										</ul>
								</div>
						</div>
						</td>
				</tr>


				{
						(page.child_pages.length > 0) && (

				<MenuRows
                 level={level+1}
                 pages={page.child_pages}
                 superParentId={superParentId}
                 handleShowChildPages={this.props.handleShowChildPages}
                 showChildPages={this.props.showChildPages}
                 handleDeletePage={this.props.handleDeletePage}
                 openUpdateTitlePopup={this.props.openUpdateTitlePopup}
                 handleSortOrder={this.props.handleSortOrder}
                 handleShowHide={this.props.handleShowHide}
                 />

								)
				}

				</React.Fragment>

				)
			})

			)
	}
}