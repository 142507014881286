import { connect } from 'react-redux';

import Details from '../../components/signup/details';

import {
    setSignupDetails,
} from '../../actions/signupAction';

import Email from '../../components/signup/email';

export function mapStateToProps(state) {
	/*console.log('DetailContainer=');
	console.log(state);*/
    return {
        signup: state.signup,
    };
}

export function mapDispatchToProps(dispatch) {
  return {
  	onSetSignupDetails: data => dispatch(setSignupDetails(data)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Details);