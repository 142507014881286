import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

class ModulesList extends Component{

    modulesEdit = (module) => {
        this.props.modulesEdit(module);
    }

    modulesDelete = (module) => {
        var conf = window.confirm('Are you sure you want to delete '+module.name+' ?');
        if(conf)
        {
            this.props.modulesDelete(module);
        }
    }

    permissionAdd = (module) => {
        this.props.permissionAdd(module);
    }

    render(){
        const modulesList = this.props.state.modulesList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                            {modulesList.map((item, index) => (
                            <tr key={index} >
                                <td width="20%">
                                    {item.name}
                                </td>
                                <td width="40%">
                                    {item.description}
                                </td>
                                <td width="5%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.modulesEdit.bind(this, item)} >Edit</a></li>
                                                            <li><a href="#" onClick={this.modulesDelete.bind(this, item)} >Delete</a></li>
                                                            <li><a href="#" onClick={this.permissionAdd.bind(this, item)} >Manage Permission</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default ModulesList;