import React, {Component} from 'react'
import OwlCarousel from 'react-owl-carousel3'

class Testimonials extends Component{
    state = {
        testimonials: this.props.testimonials ? this.props.testimonials : []
    }
    render(){
        return(
            <OwlCarousel loop={false} margin={30} nav dots={false} items={1} >
                {
                    this.state.testimonials.map((item,i) => {
                        return (
                            <div className="fp-testi-item" key={i}>
                                <p className="fp-text-md fp-para">{item.text}</p>
                                <div className="fp-testi-author">
                                    <div className="fp-testi-author-inner">
                                        <div className="flex align-items-center">
                                            <div className="fp-author-thumb"><img src={item.image} /></div>
                                            <div className="fp-author-info">{item.author} <span>{item.designation}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }                
            </OwlCarousel>
        )
    }
}

export default Testimonials