import React, {Component} from 'react'
import Navbar from '../../components/front/Navbar'
import Footer from '../../components/front/Footer'
import axios from 'axios';
import { validateEmail } from '../../utils.js';
var config = require('../../config.js');
class MentorshipRegistration extends Component{

    constructor(props) {
        super(props);
        var newData = {
            'name':'',
            'email':'',
            'job_title':'',
            'company':'',
            'phone':'',
            'location':'',
            'bio':'',
            'linkedin':'',
        };
        this.state = {
            formData: (props.state && props.state.formData && props.state.formData.id) ? props.state.formData : newData,
            formErrors: {},
            form_status: false,
            remaining_length:150
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var formData = this.state.formData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox')
        {
            formData[name] = (event.target.checked) ? true : false
        }
        else
        {
            formData[name] = event.target.value;
        }
        var remaining_length = 150
        if(name === 'bio' && event.target.value.length)
        {
            remaining_length = 150-event.target.value.length
            if(remaining_length < 0)
            {
                remaining_length = 0
            }
        }
        //alert(event.target.value)
        // console.log('formData')
        // console.log(formData)
        this.setState({formData,remaining_length:remaining_length});
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
    }

    handleSubmit = (event) => {
        event.preventDefault();
        var _this1 = this;

        let isFormValidated = this.validateForm()

        if(isFormValidated)
        {
            let stateFormData = this.state.formData

            const data = new FormData();
            data.append('name', ((stateFormData.name) ? stateFormData.name : ''));
            data.append('email', ((stateFormData.email) ? stateFormData.email : ''));
            data.append('job_title', ((stateFormData.job_title) ? stateFormData.job_title : ''));
            data.append('company', ((stateFormData.company) ? stateFormData.company : ''));
            data.append('phone', ((stateFormData.phone) ? stateFormData.phone : ''));
            data.append('location', ((stateFormData.location) ? stateFormData.location : ''));
            data.append('bio', ((stateFormData.bio) ? stateFormData.bio : ''));
            data.append('linkedin', ((stateFormData.linkedin) ? stateFormData.linkedin : ''));

            var url = config.master_api_url+'login/mentor_request';
            // var url = 'http://127.0.0.1:8081/master/'+'login/mentor_request';
            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'multipart/form-data'}
                //headers:{'content-type': 'application/json'}
            }).then(function (response) {
                // console.log(response);
                if(response.data.status === 'success')
                {
                    let form_status = true
                    _this1.setState({form_status})
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
            }).catch(function (error) {
                //console.log(error);
                alert(error);
            });
        }
    }


    validateForm(){

        var formErrors = {}

        let formData = this.state.formData

        // console.log('formData=',formData)

        // let nameRegex = new RegExp(/(\b(?:([A-Za-z0-9])(?!\2{2}))+\b)/)
        let nameRegex = new RegExp(/(\b(?:([A-Za-z])(?!\2{2}))+\b)/)
        // let nameRegex = new RegExp(/^(?!.*(.)\2)(?=.*[A-Za-z])\w{2,15}$/)

        let phoneRegex = /^\+?([0-9]{2})\)?[]?([0-9]{4})[]?([0-9]{4})$/;

        var linkedinRegex = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        var instagramRegex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im
        var twitterRegex = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;

        var errorsCount = 0

        // console.log(formData['name'])

        if(!formData['name'] || formData['name'].trim() === ''){
            formErrors['name'] = 'Please enter name.'
            errorsCount++
        }
        else if(!formData['name'].match(nameRegex)){
            formErrors['name'] = 'Please enter valid name.'
            errorsCount++
        }
        else if(formData['name'].length < 2 || formData['name'].length > 30){
            formErrors['name'] = 'Name should contain minimum 2 and maximum 30 characters.'
            errorsCount++
        }

        /*if(!formData['phone'] || formData['phone'].trim() === ''){
            formErrors['phone'] = 'Please enter phone.'
            errorsCount++
        }
        else if( !(formData['phone'].match(phoneRegex)) ){
            formErrors['phone'] = 'Please enter valid phone.'
            errorsCount++
        }*/

        if(!formData['email'] || formData['email'].trim() === ''){
            formErrors['email'] = 'Please enter email.'
            errorsCount++
        }
        else if(!validateEmail(formData['email'])){
            formErrors['email'] = 'Please enter valid email address.'
            errorsCount++
        }

        if(!formData['bio'] || formData['bio'].trim() === ''){
            formErrors['bio'] = 'Please enter bio.'
            errorsCount++
        }
        else if(formData['bio'].length < 150){
            formErrors['bio'] = 'Bio should contain minimum 150 characters.'
            errorsCount++
        }


        if(!formData['linkedin'] || formData['linkedin'].trim() === ''){
            formErrors['linkedin'] = 'Please enter Linkedin URL.'
            errorsCount++
        }
        else if(!formData['linkedin'].match(linkedinRegex)){
            formErrors['linkedin'] = 'Please enter valid Linkedin URL.'
            errorsCount++
        }
        
        /*if(formData['instagram'] && !formData['instagram'].match(instagramRegex)){
            formErrors['instagram'] = 'Please enter valid Instagram URL.'
            errorsCount++
        }
        
        if(formData['twitter'] && !formData['twitter'].match(twitterRegex)){
            formErrors['twitter'] = 'Please enter valid Twitter URL.'
            errorsCount++
        }*/

        this.setState({formErrors})

        if(errorsCount > 0){

            this.scrollToErrors()

            return false
        }

        // return false
        return true

    }

    scrollToErrors = () => {
        setTimeout(function(){
            var msgErrorElmnt = document.getElementsByClassName("msg-error");

            if(msgErrorElmnt && msgErrorElmnt[0]){
                // console.log(msgErrorElmnt)
                msgErrorElmnt[0].parentElement.scrollIntoView();
            }
        }, 100);
    }

    render(){
        const formErrors = this.state.formErrors
        return(
            <React.Fragment>
                <Navbar fixed active={3} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                        <div className="fp-header-caption mentorshipReg-header">
                            <div className="fp-page-title fp-text-lg">Share your expertise</div>
                            <div className="fp-page-title fp-text-lg">Empower the workforce and make a difference!</div>                            
                        </div>
                        <div className="header-outer" >
                            <p className="fp-page-title my-3 my-sm-4">Are you ready to be a <span>Mentor?</span></p>
                                <div className="row mentorship-registration-boxes">
                                    <div className="col-lg-4">
                                        <div className="box p-4">
                                            <h3 className="fp-title" >Your experience <br /> matters</h3>
                                            <p className="fp-text-light">Share your industry expertise and give mentorship to the workforce</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="box p-4">
                                            <h3 className="fp-title" >Work anytime, <br /> anywhere</h3>
                                            <p className="fp-text-light">Share your expertise with anyone on the globe according to your preferred timing</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="box p-4">
                                            <h3 className="fp-title" >Work according to your comfort</h3>
                                            <p className="fp-text-light">Flexibility to set your own slots, select the medium of mentorship and the number of mentees</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </header>
                <section className="fp-section fp-section-mentorship-registration pt-0">
                    <div className="container">
                        <div className="fp-register-form">
                            {(this.state.form_status)?(
                                <div><h3>Your application has been submitted. We will review your request and proceed further.</h3></div>
                            ):(                            
                            <form className="row" onSubmit={this.handleSubmit}>
                                <div className="fp-form-group col-lg-6">
                                    <label>Name <span className="fp-color-red">*</span></label>
                                    <input type="text" placeholder="e.g. John Smith" className="fp-form-style2" name="name" onChange={this.handleChange} autoFocus required />
                                    {formErrors['name'] ? (<span className="msg-error">{formErrors['name']}</span>) : ''}
                                </div>

                                <div className="fp-form-group col-lg-6">
                                    <label>Email Id <span className="fp-color-red">*</span></label>
                                    <input type="email" placeholder="name@company.com" className="fp-form-style2" name="email" onChange={this.handleChange} required />
                                    {formErrors['email'] ? (<span className="msg-error">{formErrors['email']}</span>) : ''}
                                </div>

                                <div className="fp-form-group col-lg-6">
                                    <label>Job Title</label>
                                    <input type="text" placeholder="Job Title" className="fp-form-style2" name="job_title" onChange={this.handleChange} />
                                    {formErrors['job_title'] ? (<span className="msg-error">{formErrors['job_title']}</span>) : ''}
                                </div>
                               
                                <div className="fp-form-group col-lg-6">
                                    <label>Company</label>
                                    <input type="text" placeholder="Company Name.pvt.ltd" className="fp-form-style2" name="company" onChange={this.handleChange} />
                                    {formErrors['company'] ? (<span className="msg-error">{formErrors['company']}</span>) : ''}
                                </div>
                                
                                <div className="fp-form-group col-lg-6">
                                    <label>Phone Number</label>
                                    <input type="tel" placeholder="+91-9876-543-210" className="fp-form-style2" name="phone" onChange={this.handleChange} />
                                    {formErrors['phone'] ? (<span className="msg-error">{formErrors['phone']}</span>) : ''}
                                </div>
                                
                                <div className="fp-form-group col-lg-6">
                                    <label>Location</label>
                                    <input type="text" placeholder="Location" className="fp-form-style2" name="location" onChange={this.handleChange} />
                                    {formErrors['location'] ? (<span className="msg-error">{formErrors['location']}</span>) : ''}
                                </div>

                                <div className="fp-form-group col-lg-12">
                                    <label>Short Bio <span className="fp-color-red">*</span></label>
                                    <textarea className="fp-form-style2" rows="7" placeholder="About yourself" name="bio" onChange={this.handleChange}></textarea>
                                    {(this.state.remaining_length > 0)&&(
                                    <span>Minimum {this.state.remaining_length} characters remaining</span>
                                    )}
                                    {formErrors['bio'] ? (<span className="msg-error">{formErrors['bio']}</span>) : ''}
                                </div>

                                <div className="fp-form-group col-lg-12">
                                    <label>Linkedin <span className="fp-color-red">*</span></label>
                                    <input type="text" placeholder="Linkedin" className="fp-form-style2" name="linkedin" onChange={this.handleChange} />
                                    {formErrors['linkedin'] ? (<span className="msg-error">{formErrors['linkedin']}</span>) : ''}
                                </div>


                                <div className="fp-form-group col-lg-12">
                                    <div className="fp-btn-outer">
                                        <button className="fp-btn-main fp-btn-lg" >Become a mentor</button>
                                    </div>
                                </div>
                            </form>
                            )}
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default MentorshipRegistration