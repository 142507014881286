import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Logo from '../../images/logo.png'

class Navbar extends Component{
    state = {
        scrollTop: 0,
        nav: [
                {title: 'Why OpenGrowth Hub', link: '/about'},
                {title: 'Features', link: '/features'},
                // {title: 'Solutions', link: '/'},
                {title: 'Pricing', link: '/plans-and-pricing'},
                {title: 'Contact', link: '/contact'}
            ],
        mobileMenu: false
    }
    componentDidMount = () => {
        window.addEventListener('scroll', this.onScroll)
    }
    onScroll = (e) => {
        this.setState({
            scrollTop: window.scrollY
        })
    }
    handleMobileMenu = () => {
        this.setState({
            mobileMenu: !this.state.mobileMenu
        })
    }
    render(){
        return(
            <nav className={(this.props.fixed) ? "fp-nav-full sticky" : this.state.scrollTop > 50 ? "fp-nav-full sticky" : "fp-nav-full"} data-mobile-menu={this.state.mobileMenu ? 'active' : ''}>
                <div className="container">
                    <div className="fp-nav-inner">
                        {
                            this.props.hideNav ? '' : (
                                <span className="fp-nav-toggle" onClick={this.handleMobileMenu}><i className="fp-icon-nav"></i></span>
                            )
                        }                        
                        <Link to="/" className="fp-logo"><img src={Logo} alt="OpenGrowth" /></Link>
                        {
                            this.props.hideNav ? '' : (
                                <div className="fp-nav-primary-box flex">
                                    <div className="fp-nav-primary">
                                        <span className="fp-close-toggle" onClick={this.handleMobileMenu}><i className="fp-icon-close"></i></span>
                                        <ul className="fp-navbar">
                                            {
                                                this.state.nav.map((item,i) => {
                                                    return(
                                                        <li key={i} className={(this.props.active === i) ? 'active' : ''}><Link to={item.link}>{item.title}</Link></li> 
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="fp-user-top-box">
                                        <Link to="/login" className="fp-btn-main fp-btn-outline">Log In</Link>
                                    </div>
                                </div>
                            )
                        }                        
                    </div>
                </div>
            </nav>
        )
    }
}
export default Navbar