import { connect } from 'react-redux';

import Thankyou from '../../components/signup/thankyou';

export function mapStateToProps(state) {
    return {
    };
}

export function mapDispatchToProps(dispatch) {
  return {
  	
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);