import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../../components/loader'
import Alert from '../../../components/alert'
import { callByJsonData, callByFormData } from '../../../ajax-call.js';
import { dateTimeToDisplay, AmountDisplay } from '../../../utils.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'


const statusTypesArr = [
{label: 'Yes', value: 'yes'},
{label: 'No', value: 'no'},
]

class OrdersItems extends Component{
    constructor(props) {
        super(props);

        var orderId = (props.match.params.orderId)?props.match.params.orderId:0

        this.state = {
            orderId: orderId,
            orderData: {},
            cmsPagesList: {},
            orderItemsList:{},
            showLoader: false,
            msgSuccess: '',
            msgError: '',
        };
    }

    componentDidMount(){
        this.getOrder();
        this.getCmsPagesList();
        this.getOrderItems();
    }

    getOrder = async () => {
        var _this1 = this;
        var data = {};

        let url = masterApiUrl+'orders/get/'+this.state.orderId

        callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                _this1.setState({showLoader: false});
            }
            
            if(resp.result){
                _this1.setState({orderData: resp.result});
            }
            
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });
    }

    getCmsPagesList = async () => {
        var _this1 = this;
        var data = {};
        
        let url = masterApiUrl+'cms/templates_list'
        // let url = 'http://127.0.0.1:8081/master/cms/templates_list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){

                let cmsPagesList = resp.result

                if(cmsPagesList && cmsPagesList.length > 0){
                    cmsPagesList.sort((a, b) => {
                        if(a.sort_order > b.sort_order){
                            return 1
                        }
                        return -1
                    })
                }

                _this1.setState({cmsPagesList: cmsPagesList});
            }
            _this1.setState({showLoader: false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });

    }

    getOrderItems = async () => {
        var _this1 = this;
        var data = {};
        //data['orders_id'] = this.state.orderData.id;

        let url = masterApiUrl+'orders/items_list/'+this.state.orderId

        this.setState({showLoader: true})

        callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                _this1.setState({showLoader: false});
            }
            
            if(resp.result){
                let orderItemsList = resp.result

                _this1.setState({orderItemsList});
            }
            
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });
    }

    handleFormSubmit = (e) => {

        e.preventDefault()

        // console.log('handleFormSubmit-e=',e)
        // console.log('handleFormSubmit-e.target.elements=',e.target.elements['status[]'])

        let pagesEl = e.target.elements['pages[]']

        let pagesItems = []

        // console.log('statusEl=', statusEl.value)

        for(var el in pagesEl){

            if(pagesEl[el] && pagesEl[el].value){
                // console.log('pagesEl[el].value=', pagesEl[el].value)
                
                let cms_page_id = pagesEl[el].value

                // if(e.target.elements['status['+cms_page_id+']']){
                //     console.log('status=',e.target.elements['status['+cms_page_id+']'])
                // }

                let cms_page_slug = (e.target.elements['slugs['+cms_page_id+']'])?e.target.elements['slugs['+cms_page_id+']'].value:''
                let module_slug = (e.target.elements['module_slugs['+cms_page_id+']'])?e.target.elements['module_slugs['+cms_page_id+']'].value:''
                let status = (e.target.elements['status['+cms_page_id+']'])?e.target.elements['status['+cms_page_id+']'].value:''
                let value = (e.target.elements['value['+cms_page_id+']'])?e.target.elements['value['+cms_page_id+']'].value:''
                let label = (e.target.elements['label['+cms_page_id+']'])?e.target.elements['label['+cms_page_id+']'].value:''

                if(cms_page_slug && cms_page_slug.length > 0){

                    let itemObj = {}
                    itemObj['cms_page_id'] = cms_page_id
                    itemObj['cms_page_slug'] = cms_page_slug
                    itemObj['module_slug'] = module_slug
                    itemObj['status'] = status
                    itemObj['value'] = value
                    itemObj['label'] = label

                    pagesItems.push(itemObj)

                }
            }
        }

        // console.log('pagesItems=', pagesItems)

        // return false

        const data = pagesItems

        // const formEntries = data.entries();
        // const json = Object.assign(...Array.from(formEntries, ([x,y]) => ({[x]:y})));

        // Array.from(formEntries, ([x,y]) => {
        //     console.log('formEntries-x=',x)
        //     console.log('formEntries-y=',y)
        // })

        // console.log('handleFormSubmit-dataSerialize=', dataSerialize)

        // for(var cmsPageId in json){
        //     console.log('handleFormSubmit-cmsPageId=', json)
        // }

        let url = masterApiUrl+'orders/save_items/'+this.state.orderData.id


        this.setState({showLoader: true})


        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp){
                this.setState({showLoader: false})
            }

            if(resp.success){
                this.setState({msgSuccess: 'Order items have been updated.'})
            }
            else if(resp.message){
                this.setState({msgError: resp.message})
            }
            else{
                this.setState({msgSuccess: 'Something went wrong, please try again.'})
            }
            
        })
        .catch((error) => {
            console.log('save-order-items-error=',error);
            this.setState({showLoader: false})
        });

    }

    getItemDetails = (cmsPageData) => {
        let orderItemsList = this.state.orderItemsList

        let itemDetails = {}

        if(orderItemsList && orderItemsList.length > 0){
            itemDetails = orderItemsList.find((row) => row.cms_page_slug === cmsPageData.slug)
        }

        return itemDetails

    }

    getPackageName = (item) => {
        if(item.package_details)
        {
            var package_details = JSON.parse(item.package_details)
            return package_details['name']
        }
    }

    getWorkspaceName = (item) => {
        if(item.workspace_details)
        {
            var workspace_details = JSON.parse(item.workspace_details)
            if(workspace_details['workspace'])
            {
                return config.base_url.replace('www',workspace_details['workspace'])
            }
            else
            {
                return ''
            }
            
        }
    }

    render(){
        
        var cmsPagesList = this.state.cmsPagesList;
        

        return(

            <div className="page-main-holder">
                
                <div className="main-top top-full">
                    <div className="page-title">
                        <div className="page-title-box">
                            <h2>Order Details (#{(this.state.orderData.id)?this.state.orderData.id:''})</h2>
                        </div>
                    </div>
                </div>
                    
                    <div className="container-area">
                        
                        {
                            this.state.showLoader ? <Loader /> : ''
                        }
                        
                        {
                          this.state.msgSuccess ? (<Alert message={this.state.msgSuccess} type='success' />) : ''
                        }
                        {
                          this.state.msgError ? (<Alert message={this.state.msgError} type='error' />) : ''
                        }
                        

                        <div className="inv-body">

                            <div className="box">

                            <div className="box-title">
                                <div className="page-title-box">
                                <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                                <tbody>
                                    <tr>
                                        <td width="20%"><b>Order ID:</b></td>
                                        <td width="30%">{(this.state.orderData.id)?this.state.orderData.id:''}</td>
                                        <td width="20%"><b>Email Address:</b></td>
                                        <td width="30%">{(this.state.orderData.email_address)?this.state.orderData.email_address:''}</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Company:</b></td>
                                        <td width="30%">{(this.state.orderData.company)?this.state.orderData.company:''}</td>
                                        <td width="20%"><b>Address:</b></td>
                                        <td width="30%">{this.state.orderData.city}{(this.state.orderData.city && this.state.orderData.state)?', ':''}{this.state.orderData.state}{( (this.state.orderData.city || this.state.orderData.state) && this.state.orderData.country)?', ':''}{this.state.orderData.country}</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Package:</b></td>
                                        <td width="30%">{(this.state.orderData.package_details)?this.getPackageName(this.state.orderData):''}</td>
                                        <td width="20%"><b>Amount:</b></td>
                                        <td width="30%">
                                        <AmountDisplay
                                            amount = {(this.state.orderData.amount)?this.state.orderData.amount:''}
                                            currency = {(this.state.orderData.currency)?this.state.orderData.currency:''}
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><b>Created On:</b></td>
                                        <td width="30%">{(this.state.orderData.created_at)?dateTimeToDisplay(this.state.orderData.created_at):''}</td>
                                        <td width="20%"><b>Workspace:</b></td>
                                        <td width="30%">{this.getWorkspaceName(this.state.orderData)}</td>
                                    </tr>
                                </tbody>
                                </table>
                                </div>
                            </div>

                                <div className="box-body">
                                    <div className="table-box">

                                        <form onSubmit={this.handleFormSubmit}>
                                    
                                            <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                                                    <tbody>
                                                    <tr>
                                                        <th width="30%">Plan Features</th>
                                                        <th width="15%">Status</th>
                                                        <th width="15%">Value(0 = Unlimited)</th>
                                                        <th width="30%">Label</th>
                                                    </tr>
                                                    
                                                    {(cmsPagesList.length > 0) ? (

                                                        
                                                        
                                                        <ChildPageTree
                                                            level={0}
                                                            pages={cmsPagesList}
                                                            getItemDetails={this.getItemDetails}
                                                            />
                                                        
                                                        ):null
                                                    }
                                                </tbody>
                                            </table>

                                        


                                        {
                                            (cmsPagesList && cmsPagesList.length > 0) && (

                                                <div className="table-box">

                                                    <Link to={`/admin/orders`} className="btn-main btn-outline btn-form btn-cancel" >Cancel</Link>
                                            
                                                    {/*<button type="button" onClick={this.props.cancelAddOrdersFeatures} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>*/}
                                                    {/*<button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>Save</button>*/}
                                                    <button type="submit" className="btn-main btn-form btn-submit">Save</button>
                                                </div>

                                                )
                                        }

                                        </form>
                                    


                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>







                
                    
        )
    }
}
export default OrdersItems;


export class ChildPageTree extends Component {
    render(){
        const pages = this.props.pages

        const level = this.props.level

        var levelIndent = ''

        for(var i=0; i<level; i++){
            levelIndent = levelIndent+' - '
        }

        return (

            <React.Fragment>

            {
                (pages && pages.length > 0) && (
                    pages.map((item, index) =>  {

                        let itemData = this.props.getItemDetails(item)

                        let itemStatus = (itemData && itemData.status)?itemData.status:''

                        var boldTitle = {'fontWeight':'600'}

                        if(item.type !== 'main'){
                            boldTitle = {}
                        }

                        return (
                            <React.Fragment key={item.id} >
                            <tr key={index} >
                                <td>
                                    {levelIndent}
                                    <span style={boldTitle} >{item.title}</span>

                                    <input type="hidden" name={`pages[]`} value={item.id}/>
                                    <input type="hidden" name={`slugs[${item.id}]`} value={item.slug}/>
                                    <input type="hidden" name={`module_slugs[${item.id}]`} value={item.module_slug}/>
                                </td>
                                
                                <td>

                                    <select name={`status[${item.id}]`} className="form-style">

                                    <option value=""></option>
                                    {
                                        statusTypesArr.map((opt, oidx) => {
                                            return(
                                                <option key={oidx} value={opt.value} selected={(itemStatus === opt.value)} >{opt.label}</option>
                                                )
                                        })
                                    }
                                    </select>
                                    
                                </td>
                                
                                <td>
                                 {
                                    (!item.child_pages || item.child_pages.length === 0) && (
                                        <input type="text" name={`value[${item.id}]`} defaultValue={(itemData && itemData.value)?itemData.value:''} className="form-style" />
                                        )
                                }
                                    
                                </td>
                                
                                <td>
                                    {
                                        (!item.child_pages || item.child_pages.length === 0) && (
                                            <input type="text" name={`label[${item.id}]`} defaultValue={(itemData && itemData.label)?itemData.label:''} className="form-style" />
                                            )
                                    }
                                                                        
                                </td>
                            </tr>

                            {
                               (item.child_pages && item.child_pages.length > 0) && (

                                <ChildPageTree
                                level={level+1}
                                pages={item.child_pages}
                                getItemDetails={this.props.getItemDetails}
                                />
                                )
                            }

                            </React.Fragment>
                        )
                        })
                    
                    )
                }


                </React.Fragment>

            )
    }
}