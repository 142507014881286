import $ from 'jquery';
// import { get_id_token } from './utils.js';
var config = require('./config.js');
const masterApiUrl = config.master_api_url


export function ajaxErrorHandler(ajaxError) {
    return ajaxError
    // console.log('ajaxErrorHandler-event=',event)
    // console.log('ajaxErrorHandler-jqxhr=',jqxhr)
    // console.log('ajaxErrorHandler-settings=',settings)
    // console.log('ajaxErrorHandler-thrownError=',thrownError)
    // const message = responseJSON.message || statusText;
    // return Promise.reject(Error(ajaxError));
    // return Promise.reject(ajaxError);
}

export async function callByJsonData(data, url, method, headers={}){

    // if(data){
    //     data['id_token'] = await get_id_token();
    // }

    return $.ajax({
        type: method,
        url: url,
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: 'json',
        headers: headers,
        success: function(resp) {
            return resp;
        }
    }).catch(ajaxErrorHandler);
}

export async function callByFormData(data, url, method, headers={}){

    // let id_token = await get_id_token()

    // if(data){
    //     data.append('id_token', id_token)
    // }
    
    return $.ajax({
        type: method,
        url: url,
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        headers: headers,
        success: function(resp) {
            return resp;
        }
    }).catch(ajaxErrorHandler);
}

export function getGeoInfo(){
    var url = 'https://ipapi.co/json/';
    return fetch(url)
    .then((response) => response.json())
    .then((responseJson) => {
        console.log(responseJson);
        return responseJson
    })
    .catch((error) => {
        console.error('getGeoInfo-error=', error);
    });
};