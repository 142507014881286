import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import Loader from '../../components/loader'
var config = require('../../config.js');

class Dashboard extends Component{
	constructor(props){
		super(props)
		this.state = {
			loadingData: false
		}
	}
  render(){
	return(
	  <div className="page-main-holder">
		<div className="main-top top-full"><div className="page-title"><h2>Dashboard</h2></div></div>
		{
			this.state.loadingData ? <Loader /> : ''
		}
		<div className="container-area">
			<div className="box goal-box-view disabled">
				<div className="box-body">
					Welcome to Admin Dashboard
				</div>
			</div>
		</div>
	  </div>
	)
  }
}
export default Dashboard;