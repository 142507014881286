import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

import Loader from './../../../components/loader';

var config = require('../../../config.js');

const master_api_url = config.master_api_url;

const payment_modes = config.payment_modes;

class ModalAddPaymentDetails extends Component{

	constructor(props){
		super(props)

		this.state = {
			showLoader: '',
			amount: '',
			paymentDate: '',
			errorPaymentDate: '',
			formErrors: {},
			currentWorkspace: (props.state.currentWorkspace)?props.state.currentWorkspace:{},
		}

		this.handleChange = this.handleChange.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event){
		var name = event.target.name;
		// console.log('handleChange-event.target');
		// console.log(event.target);

		if(event.target.type === 'checkbox'){
			this.setState({[name]: (event.target.checked) ? 1 : 0});
		}
		else{
			this.setState({[name]: event.target.value});
		}
	}
	handleLink = (e) => {
		this.setState({
			newLink: e.target.value
		})
	} 
	handleDataSend = () => {
		this.setState({
			newLink: this.props.action(this.state.newLink)
		})
	}
	handleDataCancel = () => {
		this.props.showHidePaymentPopup(false)
	}

	handleChangePaymentDate = (newDate) => {
		this.setState({
			payment_date: newDate
		});
		if(newDate !== ''){
			this.setState({
				errorPaymentDate: ''
			});
		}
	}

	validateForm(){
        var isValidate = true;

        var formErrors = {}

        var amount = (this.state.amount)?parseFloat(this.state.amount):0;

        if(amount <= 0){
            formErrors['amount'] = 'please enter amount';
            isValidate = false;
        }
        if(!this.state.payment_date || this.state.payment_date === ''){
            formErrors['payment_date'] = 'please enter payment date';
            isValidate = false;
        }
        if(!this.state.payment_mode || this.state.payment_mode === ''){
            formErrors['payment_mode'] = 'please enter payment mode';
            isValidate = false;
        }
        if(!this.state.transaction_id || this.state.transaction_id === ''){
            formErrors['transaction_id'] = 'please enter transaction ID';
            isValidate = false;
        }

        if(!isValidate){
            this.setState({formErrors});
        }

        return isValidate;
        
    }

	handleSubmit(){

		var isFromValid = this.validateForm();

		if(isFromValid){
		
			var _this1 = this;

			var data = {};

			var currentWorkspace = this.state.currentWorkspace;

			if(currentWorkspace.id){

				var id = currentWorkspace.id;

				data['amount'] = this.state.amount;
				data['payment_date'] = this.state.payment_date;
				data['payment_mode'] = this.state.payment_mode;

				data['transaction_id'] = this.state.transaction_id;
				data['details'] = this.state.details;

				// console.log('data=');
				// console.log(data); return false;

				const url = master_api_url+'workspace/add_payment/'+id;
				// const url = 'http://127.0.0.1:8081/master/workspace/add_payment/'+id;

				$.ajax({
					type: 'POST',
					url,
					data: JSON.stringify(data),
					contentType: 'application/json',
					dataType: 'json',
					beforeSend: function(){
						_this1.setState({showLoader: true});
					},
					success: function(resp) {
						if(resp){
							_this1.setState({showLoader: false});
						}
						if(resp.success){
							alert('Payment details has been added successfully.');

							_this1.handleDataCancel()

							// document.getElementById("payment-form").reset();
						}
					}
				}).catch(function (error) {
					console.log(error);
					// alert(error);
					_this1.setState({showLoader:false});
				});

			}
			else{
				alert('invalid details...')
			}
		}
		
	}

	render(){

		// console.log('payment_modes=')
		// console.log(payment_modes)

		const payment_modes_arr = [];
		if(payment_modes){
			for (var key in payment_modes){
				payment_modes_arr.push({ value: key, label: payment_modes[key] })
			}
		}

		// console.log('payment_modes_arr=')
		// console.log(payment_modes_arr)

		return(
			<div className="popup-full">
				<div className="popup-main-box">

				{(this.state.showLoader)? <Loader /> : null}

					<div className="popup-body">

						<form name="paymentForm" id="payment-form">

							<h2>Add payment details </h2>
							
							<div className="input-box full-width">
								<label>Amount</label>
								<div className="input-box"><input type="text" name="amount" onChange={this.handleChange} className="form-style" /></div>
								{this.state.formErrors.amount ? (<span className="msg-error">{this.state.formErrors.amount}</span>) : null}
							</div>

							<div className="input-box">
								<label>Payment Date</label>
								<div className="input-date-box full-width">
									<i className="icon-date"></i>
									<DatePicker dateFormat="P" selected={this.state.payment_date} onChange={this.handleChangePaymentDate} placeholderText="Select date" className="form-style" />
									{this.state.formErrors.payment_date !== '' ? (<span className="msg-error">{this.state.formErrors.payment_date}</span>) : null} 
									
								</div>
							</div>

							<div className="input-box">
								<label>Payment mode</label>
								<Select 
									name="priority_id"
									value={ payment_modes_arr.find(o => o.value === this.state.payment_mode) }
									options={payment_modes_arr}
									className="form-style"
									placeholder="Start typing to search payment mode"
									onChange={(val)=> {this.handleChange({target: { type:'select', name:'payment_mode', value: val.value }})}}
									/>

								{this.state.formErrors.payment_mode ? (<span className="msg-error">{this.state.formErrors.payment_mode}</span>) : null}
							</div>
							
							<div className="input-box">
								<label>Transaction ID</label>
								<div className="input-box"><input type="text" name="transaction_id" onChange={this.handleChange} className="form-style" /></div>

								{this.state.formErrors.transaction_id ? (<span className="msg-error">{this.state.formErrors.transaction_id}</span>) : null}
							</div>
							
							<div className="input-box">
								<label>Details</label>
								<div className="input-box"><input type="text" name="details" onChange={this.handleChange} className="form-style" /></div>
							</div>

							<div className="form-bottom text-right">
								<button type="button" className="btn-main btn-form btn-outline" onClick={this.handleDataCancel}>Cancel</button>    
								<button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>Save</button>
							</div>

						</form>
					</div>
				</div>
			</div>
		)
	}
}
export default ModalAddPaymentDetails;