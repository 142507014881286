import { connect } from 'react-redux';

import {
  setUserDetails,
  setUserPhoto,
} from '../../actions/userAction';

import Left from '../../components/admin/profile/left';

export function mapStateToProps(state) {
	// console.log('LeftContainer=');
	// console.log(state);
    return {
        //email_verified: state.signup.emailVerified,
        //photo: state.photo,
        userDetails: state.userDetails,
    };
}

export function mapDispatchToProps(dispatch) {
  return {
  	onSetUserDetails: user => dispatch(setUserDetails(user)),
    onSetUserPhoto: photo => dispatch(setUserPhoto(photo)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Left);