import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { get_users_group } from '../../../utils.js';
var md5 = require('md5');
var config = require('../../../config.js');
class AddUsers extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'groups_id':3,
            'id':'',
            'username':'',
            'name':'',
            'email':'',
            'password':'',
            'photo_url':'',
            'status':true,
        };
        this.state = {
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : newData,
            errors: {},
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }        

    handleChange(event){
        var editData = this.state.editData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox')
        {
            editData[name] = (event.target.checked) ? true : false
        }
        else
        {
            editData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('editData')
        // console.log(editData)
        this.setState({editData});
    }


    handleChangePassword(event){
        var editData = this.state.editData
        var name = event.target.name;
        editData[name] = md5(event.target.value);
        this.setState({editData});
    }

    handleSubmit = async () => {
        var _this1 = this;
        var edit = false;
        if(_this1.state.editData && _this1.state.editData.id !== '')
        {
            edit = true;
        }

        if(this.state.editData && this.state.editData.username !== '' && this.state.editData.name !== '' && this.state.editData.email !== '' && ((!edit && _this1.state.editData.password !== '') || (edit)))
        {   
            // console.log('Test');         
            // const data = new FormData();
            const data = _this1.state.editData;
            var url = config.master_api_url+'users/add';
            if(_this1.state.editData && _this1.state.editData.id !== '')
            {
                url = config.master_api_url+'users/update/'+_this1.state.editData.id;
            }
            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (response) {
                // console.log(response);
                if(response.data.status === 'success')
                {                    
                    if(_this1.state.editData && _this1.state.editData.id !== '')
                    {
                        _this1.props.usersEdited(response.data.record);
                    }
                    else
                    {
                        _this1.props.usersAdded(response.data.record)
                    }                   
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
            }).catch(function (error) {
                //console.log(error);
                alert(error);
            });
        } else {
            // console.log('Test 2'); 
            var errors = {}
            if(_this1.state.editData.username === ''){
                errors['username'] = 'Please enter username';
            }
            if(_this1.state.editData.name === ''){
                errors['name'] = 'Please enter name';
            }
            if(_this1.state.editData.email === ''){
                errors['email'] = 'Please enter email'
            }

            if((!edit && _this1.state.editData.password === '')){
                errors['password'] = 'Please enter password'
            }
            this.setState({errors})
        }        
    }

    render(){
        const group_options = get_users_group();
        var selected_groups_id = (this.state.editData && this.state.editData.groups_id) ? this.state.editData.groups_id : 3;
        //console.log('group_options')
        //console.log(group_options)
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.editData && this.state.editData.id) ? 'Update': 'Add'} Users</h2>
                                </div>
                                <div className="form-fields-box">

                                    <div className="input-box">
                                        <label>Group:</label>
                                        <Select 
                                            name="groups_id"
                                            value={group_options.find(o => o.value === selected_groups_id )}
                                            options={group_options}
                                            className="form-style"
                                            placeholder="Select group"
                                            onChange={(val)=> {this.handleChange({target: { type:'select', name:'groups_id', value: val.value }})}}
                                            />
                                    </div>
                                    <div className="input-box">
                                        <label>Username</label>
                                        <input 
                                            className="form-style"
                                            name = "username" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter username"
                                            type="text"
                                            value = {(this.state.editData && this.state.editData.username) ? this.state.editData.username : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.username) ? this.state.errors.username : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Name</label>
                                        <input 
                                            className="form-style"
                                            name = "name" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter name"
                                            type="text"
                                            value = {(this.state.editData && this.state.editData.name) ? this.state.editData.name : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.name) ? this.state.errors.name : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Email</label>
                                        <input 
                                            className="form-style"
                                            name = "email" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter email"
                                            type="text"
                                            value = {(this.state.editData && this.state.editData.email) ? this.state.editData.email : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.email) ? this.state.errors.email : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Password (Only if need to update new password)</label>
                                        <input 
                                            className="form-style"
                                            name = "password" 
                                            onChange={this.handleChangePassword} 
                                            placeholder="Enter password"
                                            type="text"
                                            />
                                            <div className="msg-error">{(this.state.errors.password) ? this.state.errors.password : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label className="checkbox"><input type="checkbox" name="status" onChange={this.handleChange} checked={(this.state.editData && this.state.editData.status === true) ? true : false} /> <span>Active</span></label>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddUsers} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addUsersActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddUsers;