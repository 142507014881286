import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import StaticPagesList from '../../components/admin/static-pages/static-pages-list';
import AddStaticPages from '../../components/admin/static-pages/static-pages-add';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader'
import axios from 'axios';
var config = require('../../config.js');

class StaticPages extends Component{

    constructor(props){
        super(props);
        this.state = {
            name:(this.props.match.params.name) ? this.props.match.params.name : '',
            showClass:'',
            activeClass:'',
            addStaticPagesActive:false,
            loadingData:true,
            search_text:'',
            staticPagesList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getStaticPages(this.state.name);
    }

    getStaticPages = async (name) => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        data['name'] = name;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        axios({
            method: 'post',
            url: config.master_api_url+'static-pages/list',
            // url: 'http://127.0.0.1:8081/master/static-pages/list',
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
                _this1.setState({staticPagesList:resp.data.result});
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    handleCreateStaticPages = () => {
        if(this.state.addStaticPagesActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addStaticPagesActive: false})
            },620);
        } else {
            this.setState({addStaticPagesActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddStaticPages = () => {
        this.setState({editData: {}});
        this.handleCreateStaticPages();
    }

    staticPagesAdded = (pages) => {
        this.state.staticPagesList.unshift(pages);
        this.setState({editData: {}});
        this.handleCreateStaticPages();
    }

    staticPagesEdit = (pages) => {
        this.setState({editData: pages});
        this.handleCreateStaticPages();
    }

    staticPagesEdited = (pageData) => {
        const state = this.state.staticPagesList;
        const index = state.findIndex(row => row.id === pageData.id);
        state[index] = pageData;
        this.setState({staticPagesList: state, editData: {}});
        this.handleCreateStaticPages();
    }


    staticPagesDelete = (data) => {
        console.log(data);
        if(data.id)
        {
            var _this1 = this;
            _this1.setState({loadingData:true});
            var url = config.master_api_url+'static-pages/delete/'+data.id;
            // var url = 'http://127.0.0.1:8081/master/static-pages/delete/'+data.id;
            console.log(url);
            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (response) {
                if(response.data.success)
                {
                    _this1.staticPagesDeleted(response.data.record);
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
                _this1.setState({loadingData:false});
            }).catch(function (error) {
                //console.log(error);
                alert(error);
                _this1.setState({loadingData:false});
            });
        }
    }

    staticPagesDeleted = (pages) => {
        var state = this.state.staticPagesList;
        const index = state.findIndex(row => row.id === pages.id);
        if (index === -1) {
            state = [];
        }
        state = [...state.slice(0, index), ...state.slice(index + 1)];
        this.setState({staticPagesList: state});
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.match.params.name !== this.state.name)
        {
            this.setState({name: nextProps.match.params.name});
            this.getStaticPages(nextProps.match.params.name);
        }
    }


    render(){

    return (
        <div className="page-main-holder static-pages">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Static Pages</h2></div></div></div>
        <div className="container-area">
            {
                this.state.loadingData ? <Loader /> : ''
            }
            <div className="inv-body">
                <div className="box">
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end">
                            <button onClick={this.handleCreateStaticPages} className="btn-main btn-lg">Create New Static Pages</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <StaticPagesList state={this.state} staticPagesEdit={this.staticPagesEdit} staticPagesDelete={this.staticPagesDelete} />
                    </div>
                    {
                        this.state.addStaticPagesActive ? (
                            <AddStaticPages state={this.state} cancelAddStaticPages={this.cancelAddStaticPages} staticPagesAdded={this.staticPagesAdded} staticPagesEdited={this.staticPagesEdited} />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default StaticPages;