import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {tooltipIcon} from  './SvgIcons';

class Tooltip extends Component{
    render(){
        return(
            <span className="tooltip">
                <div className="tooltip-icon" dangerouslySetInnerHTML={{__html: tooltipIcon}}></div>
                <div className="tooltip-content">{this.props.content}  
                {this.props.readMoreLink? <Link to={this.props.readMoreLink}>{this.props.readMoreText}</Link>: ""}
                
                </div>
            </span>
        )
    }
}
export default Tooltip;