export const SET_BUSINESS_CHANNELS_LIST = 'SET_BUSINESS_CHANNELS_LIST';
export const SET_LOGO_TITLE = 'SET_LOGO_TITLE';
export const SET_LOGO_URL = 'SET_LOGO_URL';

export function setBusinessChannelsList(channels) {
	/*console.log('setUserDetails=');
	console.log(user);*/
    return { type: SET_BUSINESS_CHANNELS_LIST, channels };
}

export function setLogoTitle(title) {
	// console.log('setLogoTitle=');
	// console.log(title);
    return { type: SET_LOGO_TITLE, title };
}

export function setLogoUrl(logoUrl) {
	// console.log('setLogoUrl=');
	// console.log(logoUrl);
    return { type: SET_LOGO_URL, logoUrl };
}