import React, {Component} from 'react'

class Infobox extends Component{
    render(){
        return(
            <div className="fp-box flex col-lg-4">
                <div className="fp-box-icon">
                    {
                        this.props.icon ? (
                            <img src={this.props.icon} />
                        ) : (
                            <i className={this.props.iconClass ? "fp-icon-box " + this.props.iconClass : "fp-icon-box"}></i>
                        )
                    }
                </div>
                <div className="fp-box-info">
                    <div className="fp-title">{this.props.title}</div>
                    <p className="fp-text-light">{this.props.description}</p>
                </div>
            </div>
        )
    }
}
export default Infobox