import React, {Component} from 'react';
import * as firebase from "firebase/app";
export var imgExtArr = ["jpeg", "jpg", "png"];
export var imgMimeTypesArr = {"image/jpeg": "jpeg, jpg", "image/png": "png", "image/gif": "gif"};

export function get_id_token(){
	return new Promise((resolve, reject) => {
		firebase.auth().onAuthStateChanged(user => {
			if(user) {
				user.getIdToken().then(function(idToken) {
					resolve(idToken)
				});
			}else{
        resolve(false)
      }
		});
	})
}

export function searchByForTable(e, $num=0) {
        //var input = document.getElementById("search_member");
        //console.log(search_member_input.value);
        // console.log('searchBy='+e.target.value);
        var filter = e.target.value.toUpperCase();

        var table = document.getElementById("list_box");
        var tr = table.getElementsByTagName("tr");
        var td, i;

        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[$num];
            if (td) {
                if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

export function getQueryStringValue(key) {  
	return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
}

export function slugify(string) {
	if(string){
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
		const p = new RegExp(a.split('').join('|'), 'g')

		return string.toString().toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '') // Trim - from end of text
	}
	else{
		return string;
	}
}

export function checkFile(files, ext = ["image/jpeg", "image/png"], size = 1024*1024*5) {

	 // ext = ["image/jpeg", "image/png"];

			var is_image_valid = true;

			var err_arr = []

	if(files.length > 0){
		let file = files[0];

		if(ext.indexOf(file.type) < 0){
			err_arr.push("Please upload jpg/jpeg, png image only.");
			is_image_valid = false;
		}

		if(file.size > size ){
			err_arr.push("Please select image with less than "+size+'MB.');
			is_image_valid = false;
		}
	}
	else{
		err_arr.push("Please choose file/image");
	}
}

export function checkFiles(string) {

}


export function isUrlValid(input) {
	if(input && input !== ''){
		var res = input.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

		var regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

		/*if(res === null){
			return false;
		}
		else{
			return true;
		}*/

		if (regexp.test(input)){
			return true;
		}
		else{
			return false;
		}
	}
	else{
		return false;
	}
}


export function isHavingAnchorTag(string) {
	if(string && string !== ''){
		var n = string.search("href=");
		if(n >= 0){
			return true;
		}
		else{
			return false;
		}
	}
	else{
		return false;
	}
}

export function  validateImages(e){

        var is_file_valid = true;

        var result = []

        var err_arr = []
        var selectedFiles = []

        var errors = ""

        if(e.target.files.length > 0){

            var files = e.target.files

            let file

            let mimeNamesArr = []

            Object.keys(imgMimeTypesArr).map((indx) => {
            	mimeNamesArr.push(imgMimeTypesArr[indx])
            })

            // console.log('mimeNamesArr=', mimeNamesArr.join(", "))

            let mimeTypeStr = mimeNamesArr.join(", ")

            let errTxt = 'Only '+mimeTypeStr+' images are allowed.'

            for (var i = 0; i < files.length; i++) {
                // console.log('file=',files[i])

                file = files[i]

                // if(imgMimeTypesArr.indexOf(file.type) < 0){
                if(!imgMimeTypesArr[file.type]){
                    err_arr.push(errTxt);
                    is_file_valid = false;
                }

                if(file.size > (1024*1024*5) ){
                    err_arr.push('Please select images less than 5 MB.');
                    is_file_valid = false;
                }
            }

            if(is_file_valid === true){

                for (var i = 0; i < files.length; i++) {
                    let file = files[i]
                    selectedFiles.push(file)
                }
            }
            else{
                var errors = err_arr.join("\n");

                // alert(errors);
            }
        }

        result['is_file_valid'] = is_file_valid
        result['errors'] = errors
        result['selectedFiles'] = selectedFiles

        return result

    }


export function isUserPrimary(user_claims){

  var key = 'primary_owner';
  // console.log('this.state.current_user_claims=');
  // console.log(this.state.current_user_claims);
  // var current_user = this.state.current_user;
  // var user_claims = this.state.current_user_claims;
  if(user_claims){
    if (user_claims.hasOwnProperty(key)) {
      return true;
    }
  }
  return false;
}

export function isUserAdmin(user_claims){
  var key = 'admin';
  // console.log('this.state.current_user_claims=');
  // console.log(this.state.current_user_claims);
  // var current_user = this.state.current_user;
  // var user_claims = this.state.current_user_claims;
  if(user_claims){
    if (user_claims.hasOwnProperty(key)) {
      return true;
    }
  }
  return false;
}

export function isUserPrimaryOrAdmin(user_claims){
	// console.log('isUserPrimaryOrAdmin-user_claims=');
	// console.log(user_claims);
  var key1 = 'primary_owner';
  var key2 = 'admin';
  if(user_claims){
    if ( (user_claims.hasOwnProperty(key1) && user_claims[key1] === true ) || (user_claims.hasOwnProperty(key2) && user_claims[key2] === true ) ) {
    	// console.log('isUserPrimaryOrAdmin-yes=');
      return true;
    }
  }
  return false;
}

export function dateToDisplay(string)
{
	var dateToDisplay = '';
	if(string)
	{
		var options = { year: 'numeric', month: 'long', day: 'numeric' };
		dateToDisplay = new Date(string).toLocaleDateString([],options);
	}
	return dateToDisplay;
}

export function dateTimeToDisplay(string)
{
	var dateToDisplay = '';
	if(string)
	{
		var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
		dateToDisplay = new Date(string).toLocaleDateString([],options);
	}
	return dateToDisplay;
}

export function titleCase(str) { 
  str = str.toLowerCase().split(' '); 
  for (var i = 0; i < str.length; i++) { 
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);  
  } 
  return str.join(' '); 
}

export function get_priority_type(priority_type){
	var priority_type_arr = {1:'KPI DRIVEN',2:"ROLLUP",3:"TASK DRIVEN"};
	if(priority_type)
	{
		priority_type_arr = priority_type_arr[priority_type];
	}
	return priority_type_arr;
}

export function get_priority_visibility(visibility){
  var priority_visibility_arr = [{id:0, name:"Everyone"}, {id:1, name:"Selected Users"}, {id:2, name:"Selected Team"}];
  if(visibility != '')
  {
  	// console.log('get_task_visibility')
  	// console.log(visibility)
  	priority_visibility_arr.forEach(function(row){
  		// console.log('get_task_visibility_row')
  		// console.log(row)
  		// console.log(row['id'])
  		if(row['id'] && parseInt(row['id']) === parseInt(visibility))
  		{
  			priority_visibility_arr = row['name'];
  		}
  	});
  }
  return priority_visibility_arr;
}

export function get_task_visibility(visibility){
  var task_visibility_arr = [{id:0, name:"Everyone"}, {id:1, name:"Selected Users"}, {id:2, name:"Selected Team"}];
  if(visibility)
  {
  	// console.log('get_task_visibility')
  	// console.log(visibility)
  	task_visibility_arr.forEach(function(row){
  		// console.log('get_task_visibility_row')
  		// console.log(row)
  		// console.log(row['id'])
  		if(row['id'] && parseInt(row['id']) === parseInt(visibility))
  		{
  			task_visibility_arr = row['name'];
  		}
  	});
  }
  return task_visibility_arr;
}

export function getGoogleDriveFileEmbedCode(link){
	var urlTypeArr = ["drive.google.com","docs.google.com"];

	var embedCode = false;

	for(var i in urlTypeArr){
		if(link.indexOf(urlTypeArr[i]) >= 0 ){
			embedCode = '<iframe src="'+link+'&rm=minimal" width="100%" height="480"></iframe>';
			break;
		}
	}
	return embedCode;
}


export function formatDate(date, toFormat='MM/DD/YYYY'){

	if(date){
		var date = new Date(date);
		var monthsFullName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		let monthsShortName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		let day = date.getDate();
		let monthIndex = date.getMonth();
		var monthNumber = monthIndex+1;
		let shortMonthName = monthsShortName[monthIndex];
		let fullMonthName = monthsFullName[monthIndex];
		let year = date.getFullYear();
		// return monthNames[monthIndex] + ' ' + day + ', ' + year;
		// return year + '-' + (monthIndex+1) + '-' + day;

		// alert(monthNumber.length);

		monthNumber = parseInt(monthNumber);
		day = parseInt(day);
			// alert(monthNumber);

		if(monthNumber < 10){
			// alert(monthNumber);
			monthNumber = '0'+monthNumber;
		}

		if(day < 10){
			day = '0'+day;
		}


		// MM = Full eg-January
		// MMM = Short eg-Jan


		if(toFormat === 'MM/DD/YYYY'){
			return monthNumber + '/' + day + '/' + year;
		}
		else if(toFormat === 'YYYY-MM-DD'){
			return year + '-' + monthNumber + '-' + day;
		}
		else if(toFormat === 'MMM DD, YYYY'){
			return shortMonthName + ' ' + day + ', ' + year;
		}
		else{
			return new Date(date);
		}
	}
	return date;
}

export function formatTime(date, toFormat='HH:MM:SS'){
	// console.log('formatTime-date='+date);
	
	if(date){
		var date = new Date(date);
		// console.log('formatTime-date='+date);
		var hours = date.getHours();
		var minutes = date.getMinutes();
		var seconds = date.getSeconds();
		var ampm = hours >= 12 ? 'PM' : 'AM';
		// hours = hours % 12;
		// hours = hours ? hours : 12; 
		minutes = minutes < 10 ? '0'+minutes : minutes;
		// var strTime = ('0' + hours).slice(-2) + ':' + minutes + ' ' + ampm;
		// return strTime;

		// return hours + ':' + minutes + ':00';

		// AA = AM/PM
		// A = am/pm

		if(toFormat === 'HH:MM:SS'){
			return hours + ':' + minutes + ':' + seconds;
		}
		if(toFormat === 'HH:MM AA'){
			return hours + ':' + minutes + ' ' + ampm;
		}
		else{
			return hours + ':' + minutes + ':' + seconds;
		}

	}
	return date;
}


export function randomAlphaNumString(length) {
	var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}


export function get_users_group(group_id=null){
  var group_arr = [{value:1, label:"Admin"}, {value:2, label:"Sub-Admin"}, {value:3, label:"Members"}];
  if(group_id)
  {
  	group_arr.forEach(function(row){
  		// console.log('get_task_visibility_row')
  		// console.log(row)
  		// console.log(row['id'])
  		if(row.value && parseInt(row.value) === parseInt(group_id))
  		{
  			group_arr = row.label;
  		}
  	});
  }
  return group_arr;
}

export function validateEmail(value) {
	return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
}

export function isJSON(data) {
   var ret = true;
   try {
      JSON.parse(data);
   }catch(e) {
      ret = false;
   }
   return ret;
}

export function encode(str) {
  if(str)
  {
    str = str.toString()
    return str.replace(/./g, function(c) {
      return ('00' + c.charCodeAt(0)).slice(-3);
    });
  }
}

export function decode(str) {
  if(str)
  {
    str = str.toString()
    return str.replace(/.{3}/g, function(c) {
      return String.fromCharCode(c);
    });
  }
}

export class AmountDisplay extends Component {
	render(){
		const amount = this.props.amount
		const currency = this.props.currency
		let currencySymbol = '$'
    if(currency === 'INR'){
        currencySymbol = '&#8377;'
    }
    return(    	
    		<React.Fragment>
    			{(currency)&&(
        	<span dangerouslySetInnerHTML={{__html: currencySymbol }}></span> 
        	)}
        	{amount}
        </React.Fragment>
      
    )
  }
}