import React, {Component} from 'react';
import { connect } from 'react-redux';
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
var config = require('../../config.js');

class MyEditor extends Component {

    componentDidMount = () => {
        // this.theFocus.focus()
    }

    handleEditorData = ( event, editor ) => {
		const data = editor.getData();
		
        this.setState({content: data})

        this.props.handleContent(data)
    }

    handleEditorInit = ( editor ) => {
        
        this.ConvertDivAttributes(editor, 'div')
        this.ConvertDivAttributes(editor, 'svg')
        this.ConvertDivAttributes(editor, 'path')
    }

    

    ConvertDivAttributes = ( editor, htmlTag ) => {
    // Allow <div> elements in the model.
    editor.model.schema.register( htmlTag, {
        allowWhere: '$block',
        allowContentOf: '$root'
    } );

    // Allow <div> elements in the model to have all attributes.
    editor.model.schema.addAttributeCheck( context => {
        if ( context.endsWith( htmlTag ) ) {
            return true;
        }
    } );

    // The view-to-model converter converting a view <div> with all its attributes to the model.
    editor.conversion.for( 'upcast' ).elementToElement( {
        view: htmlTag,
        model: ( viewElement, { writer: modelWriter } ) => {
            return modelWriter.createElement( htmlTag, viewElement.getAttributes() );
        }
    } );

    // The model-to-view converter for the <div> element (attributes are converted separately).
    editor.conversion.for( 'downcast' ).elementToElement( {
        model: htmlTag,
        view: htmlTag
    } );

    // The model-to-view converter for <div> attributes.
    // Note that a lower-level, event-based API is used here.
    editor.conversion.for( 'downcast' ).add( dispatcher => {
        dispatcher.on( 'attribute', ( evt, data, conversionApi ) => {
            // Convert <div> attributes only.
            if ( data.item.name != htmlTag ) {
                return;
            }

            const viewWriter = conversionApi.writer;
            const viewDiv = conversionApi.mapper.toViewElement( data.item );

            // In the model-to-view conversion we convert changes.
            // An attribute can be added or removed or changed.
            // The below code handles all 3 cases.
            if ( data.attributeNewValue ) {
                viewWriter.setAttribute( data.attributeKey, data.attributeNewValue, viewDiv );
            } else {
                viewWriter.removeAttribute( data.attributeKey, viewDiv );
            }
        } );
    } );
}
    render(){

        // console.log('SectionEditor-this.props=')
        // console.log(this.props)

        const content = (this.props.content) ? this.props.content : ''

        // console.log('my-editor-content=', content)

        return(
            <CKEditor
                editor={ ClassicEditor }
                onReady={this.handleEditorInit}
                data={content}
                onChange={this.handleEditorData}
                config={{
                    // toolbar: ['bold',  'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                    toolbar: [
                        'sourceEditing',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageUpload',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'undo',
                        'redo'
                    ],
                    allowedContent: true,
                    // removePlugins: [ 'Image', 'ImageUpload', 'ImageToolbar', 'ImageCaption', 'ImageStyle' ],
                    // removeButtons: [ 'Image', 'ImageButton' ],
                    //image: {},
                    mediaEmbed: {
                        previewsInData: true
                    },
                    ckfinder: {
                         uploadUrl: config.api_url+'bucket/ckupload?command=QuickUpload&type=Images&responseType=json&tenantId=master_tenant',
                        // options: {
                        //     resourceType: 'Images'
                        // }
                        /*headers:{
                            'tenant-id': firebase.auth().tenantId,
                            'content-type': 'multipart/form-data'
                        }*/
                    },
                    image: {
                        // You need to configure the image toolbar, too, so it uses the new style buttons.
                        toolbar: [ 'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight' ],

                        styles: [
                            // This option is equal to a situation where no style is applied.
                            'full',

                            // This represents an image aligned to the left.
                            'alignLeft',

                            // This represents an image aligned to the right.
                            'alignRight'
                        ]
                    },
                    // simpleUpload: {
                    //     // The URL that the images are uploaded to.
                    //     uploadUrl: config.syscore_api_url+'bucket/ckupload',

                    //     // Headers sent along with the XMLHttpRequest to the upload server.
                    //     headers: {
                    //         'X-CSRF-TOKEN': 'CSFR-Token',
                    //         Authorization: 'Bearer <JSON Web Token>'
                    //     }
                    // }
                }}
            />
        )
    }
}
export default connect()(MyEditor)