import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import PackagesList from '../../../components/admin/packages/packages-list'
import AddPackage from '../../../components/admin/packages/packages-add';
import AddPackageFeatures from '../../../components/admin/packages/packages-add-features';
import SendEmail from '../../../components/admin/packages/send-email';
import Loader from '../../../components/loader'
import Alert from '../../../components/alert'
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class Packages extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addPackagesActive:false,
            addPackagesFeaturesActive:false,
            showLoader:true,
            search_text:'',
            packagesList: [],
            selectedPackage: {},
            msgSuccess: '',
            msgError: '',
            activeSendEmail: false,
        }
    }

    componentDidMount(){
        this.getPackages();
    }

    getPackages = async () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        let url = masterApiUrl+'packages/list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){

                let packagesList = resp.result

                if(packagesList && packagesList.length > 0){
                    packagesList.sort((a,b) => {
                        if(parseInt(a.sort_order) > parseInt(b.sort_order)){
                            return 1
                        }

                        return -1
                    })
                }
                _this1.setState({packagesList});
            }
            _this1.setState({showLoader:false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader:false});
        });

    }

    handleCopyPackage = async (packageData) => {

        console.log('handleCopyPackage-packageData=', packageData)
        // return false
        if(window.confirm("Are you really want to make a copy of this package?")){

            var data = {};

            let url = masterApiUrl+'packages/copy/'+packageData.id

            this.setState({showLoader: true});

            callByJsonData(data, url, 'POST')
            .then((resp) => {
                if(resp){
                    this.setState({showLoader: false});
                }
                
                if(resp.result){
                    this.onPackageSaved(resp.result, 'copy');
                }
            })
            .catch((error) => {
                console.log('copy-package-error=', error)
                this.setState({showLoader: false});
            });

        }

    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    handleCreatePackages = (activeType=true) => {
        if(this.state.addPackagesActive === true || activeType === false) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addPackagesActive: false})
            },620);
        } else {
            this.setState({addPackagesActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    handleCreatePackagesFeatures = () => {
        if(this.state.addPackagesFeaturesActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addPackagesFeaturesActive: false})
            },620);
        } else {
            this.setState({addPackagesFeaturesActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }


    cancelAddPackages = () => {
        var selectedPackage = {}
        this.setState({selectedPackage});
        this.handleCreatePackages();
    }

    onPackageSaved = (packageData, saveType='') => {
        let alertMsg = ''
        if(saveType === 'add' || saveType === 'copy'){
            let packagesList = this.state.packagesList;
            packagesList.push(packageData);
            this.setState({packagesList});

            alertMsg = 'Package has been added successfully.'

            if(saveType === 'copy'){
                alertMsg = 'Package has been copied successfully.'
            }
        }
        else if(saveType === 'update' || saveType === 'custom_email'){
            const packagesList = this.state.packagesList;
            const index = packagesList.findIndex(row => row.id === packageData.id);
            if(index >= 0){
                packagesList[index] = packageData;
                this.setState({packagesList});
            }

            alertMsg = 'Package has been updated successfully.'

            if(saveType === 'custom_email'){
                alertMsg = 'Email has been sent successfully.'
            }
            
        }

        if(alertMsg && alertMsg.length > 0){
            this.setState({msgSuccess: alertMsg})
        }
        
        this.handleCreatePackages(false);
    }

    onEditPackage = (selectedPackage) => {
        this.setState({selectedPackage: selectedPackage});
        this.handleCreatePackages();
    }

    activateSendEmail = (packageData) => {
        if(packageData && packageData.id){
            this.setState({selectedPackage: packageData, activeSendEmail: true})
        }
    }

    cancelSendEmail = () => {
        this.setState({selectedPackage: {}, activeSendEmail: false})
    }

    render(){

    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Packages</h2></div></div></div>
        <div className="container-area">
            
            {
                this.state.showLoader ? <Loader /> : ''
            }
            {
              this.state.msgSuccess ? (<Alert message={this.state.msgSuccess} type='success' />) : ''
            }
            {
              this.state.msgError ? (<Alert message={this.state.msgError} type='error' />) : ''
            }
            
            <div className="inv-body">
                <div className="box">
                    {/*<div className="box-title">
                        <div className="box-filter-left flex align-items-center">
                            <div className="sort-box box-search flex-1">
                                <div className="filter-search-box">
                                    <i className="icon-search"></i>
                                    <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search packages" />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end flex justify-content-end">
                            <Link to={`/admin/modules`} className="btn-main btn-lg">Manage Modules</Link>
                            <button onClick={this.handleCreatePackages} className="btn-main btn-lg">Create New Packages</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <PackagesList
                        packagesList={this.state.packagesList}
                        onEditPackage={this.onEditPackage}
                        onCopyPackage={this.handleCopyPackage}
                        activateSendEmail={this.activateSendEmail}
                        onEditPackageFeatures={this.onEditPackageFeatures}
                        />
                    </div>
                    {
                        this.state.addPackagesActive ? (
                            <AddPackage
                            packageData={this.state.selectedPackage}
                            close={this.cancelAddPackages}
                            onSaved={this.onPackageSaved}
                            addPackagesActive={this.state.addPackagesActive}
                            />
                        ) : null
                    }


                    {
                        (this.state.activeSendEmail === true) && (
                            <SendEmail
                            packageData={this.state.selectedPackage}
                            close={this.cancelSendEmail}
                            onSaved={this.onPackageSaved}
                            addPackagesActive={this.state.addPackagesActive}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default Packages;