import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserThumb from '../../../images/user1.jpg';
import * as firebase from "firebase/app";
import axios from 'axios';
import $ from 'jquery';
import { get_id_token } from '../../../utils.js';
import Loader from './../../../components/loader';
var config = require('../../../config.js');
const mapStateToProps = state => ({
  ...state
})

/*const mapStateToProps = function mapStateToProps(state) {
  // console.log('Left-mapStateToProps-state=');
  // console.log(state);
  return {
    checkinCheckoutType: state.checkinCheckoutType,
  };
}*/

const mapDispatchToProps = dispatch => ({
  // onUpdateCheckinCheckoutList: data => dispatch(updateCheckinCheckoutList(data)),
  // onChangeCheckinCheckoutType: checkType => dispatch(changeCheckinCheckoutType(checkType)),
})

class Left extends React.Component {
    constructor(props) {
      // console.log('left.props');
      // console.log(props);
        super(props);
        this.state = {
            photoURL:'',
            uploadedFile:'',
            uploadedFileUrl:'',
            showLoader: false,
            imgLoading: false,
            isCheckedin: (props.checkinCheckoutType)?props.checkinCheckoutType:false,
        };  
        //this.closeModal = this.closeModal.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleCheckinCheckout = this.handleCheckinCheckout.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    handleImageChange(e)
    {
      e.preventDefault();
      var _this1 = this;
      _this1.setState({'imgLoading': true});
      var ext = ["image/jpeg", "image/png"];
      var is_image_valid = true;
      var err_arr = []      
      if(e.target.files.length > 0)
      {
        let file = e.target.files[0];
        //console.log(file.type);
        //if(file.type === 'image/jpeg' || file.type === 'image/png'){
        if(ext.indexOf(file.type) < 0){
          err_arr.push("Please upload jpg/jpeg, png image only.");
          is_image_valid = false;
        }
        
        if(file.size > (1024*1024*5) ){
          err_arr.push("Please select image with less than 5MB.");
          is_image_valid = false;
        }

        if(is_image_valid === true ){
          let file_name = file.name;
          var uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
          if(uploadedFileUrl)
          {
            setTimeout(function(){
              _this1.setState({
                uploadedFile: file,
                uploadedFileUrl: uploadedFileUrl
              });
              if(_this1.state.uploadedFile)
              {
                var updateData = {};
                updateData['image'] = _this1.state.uploadedFile;
                axios({
                  method: 'post',
                  url: config.master_api_url+'users/update/',
                  data: updateData,
                  headers:{'content-type': 'multipart/form-data'},
                }).then(function (resp) {
                  if(resp.data.status && resp.data.status === 'success' && resp.data.user){
                    _this1.props.onSetUserDetails(resp.data.user);
                    _this1.setState({'imgLoading': false});
                  }
                  else
                  {
                    alert('Something went wrong, please try again.');
                    _this1.setState({'imgLoading': false});
                  }
                }).catch(function (error) {
                  console.log(error);
                  _this1.setState({'imgLoading': false});
                });
              }
            },1500);
          }
          else
          {
            alert('Something went wrong, please try again.');
            _this1.setState({'imgLoading': false});
          }
        }
        else
        {
          var errors = err_arr.join("\n");
            alert(errors);
        }
      }
      else
      {
        setTimeout(function(){
          _this1.setState({
            uploadedFile: '',
            uploadedFileUrl: ''
          });
        },1500);
      }
    }


  async handleCheckinCheckout(e, type='in'){
    e.preventDefault();

    var _this1 = this;
    var id_token = await get_id_token();
    var data = {};
    data['id_token'] = id_token;
    data['type'] = type;

    this.setState({showLoader:true});

    axios({
      method: 'post',
      url: config.syscore_api_url+'checkin-checkout/mark',
      data: JSON.stringify(data),
      headers:{'content-type': 'application/json'}
    }).then(function (resp) {
      if(resp){
        _this1.setState({showLoader:false});
      }
      if(resp.data.success){
        if(type === 'in'){
          var checkType = true
          // _this1.setState({isCheckedin});

          _this1.props.onChangeCheckinCheckoutType(checkType);
        }
        if(resp.data.checkin_checkout){
          _this1.props.onUpdateCheckinCheckoutList(resp.data.checkin_checkout);
        }
      }
    }).catch(function (error) {
      // alert(error)
    });
  }

  signOut(event){
    event.preventDefault();
  }

  componentWillReceiveProps(nextProps){

    // console.log('Left-componentWillReceiveProps=');
    // console.log(nextProps);

    if(nextProps.user){
        this.setState({user:nextProps.user});
    }

    if(nextProps.checkinCheckoutType && nextProps.checkinCheckoutType === true){
        this.setState({isCheckedin:true});
    }
  }

  render() {
    const user = (this.props.userDetails) ? this.props.userDetails : {};
    return (
        <div className="profile-left">
          {
            this.state.imgLoading ? <Loader /> : ''
          }
        {(this.state.showLoader)?(
          <li><Loader /></li>
          ):null}
            <div className="profile-left-top">
                <div className="signup-thumb-box">
                    <span className="upload_pic">Upload <input type="file" onChange={(e)=>this.handleImageChange(e)} /></span>
                    <div className="user-thumb thumb-xl"><img src={(user.photo_url) ? user.photo_url : UserThumb} alt={(user.name) ? (user.name) : ('')} /></div>
                </div>
                <div className="user-name">{(user && user.name) ? (user.name) : ('')}</div>
                <div className="user-email">{(user.email) ? (user.email) : ('')}</div>
            </div>
            <div className="profile-left-menu">
                <ul className="nav">
                    <li className="nav-divider"></li>
                    <li><a onClick={this.props.signOut}className="color-danger" >Log Out</a></li>
                </ul>
            </div>
        </div>
    );
  }
}
// export default Left;
export default connect(mapStateToProps, mapDispatchToProps)(Left);