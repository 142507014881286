import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Navbar from '../../components/front/Navbar'
import Infobox from '../../components/front/Info-box'
import Ctapanel from '../../components/front/Cta-panel'
import Footer from '../../components/front/Footer'

class Features extends Component{
    state = {
        
    }
    componentDidMount = () => {
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <React.Fragment>
                <Navbar fixed active={1} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                        <div className="fp-header-caption">
                            <div className="fp-page-title">The easiest way to manage <span>team</span>, <span>projects</span> and <span>tasks</span></div>
                            <p className="fp-text-lg fp-para">OpenGrowth Hub gives you everything you need to stay in sync, hit deadlines, and reach your goals.</p>
                            <Link to="" className="fp-btn-main fp-btn-lg">Get Started</Link>                            
                        </div>
                    </div>
                </header>
                <section className="fp-section fp-section-features">
                    <div className="container fp-features-container">
                        <div className="fp-heading2 fp-text-center">OpenGrowth Hub Features</div>
                        <div className="fp-boxes-outer row fp-features-list-outer">
                            <Infobox title={'Mentorship'} description={'Meet industry experts right here; where coaches and mentors can empower the workforce through their knowledge and expertise. Meanwhile mentees can gain knowledge and insights from the mentors while working leading to experiential learning.'} icon={require('../../images/icons/coach.png')} />                            
                            <Infobox title={'Channels'} description={'OpenGrowth Hub allows you to collaborate and communicate with team members through channels. It enables the users to share ideas or interact with a team privately or publicly on a particular topic. The users can star the important channels.'} icon={require('../../images/icons/channel.png')} />                            
                            <Infobox title={'311 Planning'} description={'To ease out errors on planning and monitor it,  we have a feature known as 311 planning. Users can segregate tasks and goals which they want to achieve in three different segments: 3 year plan, 1 year plan and next 1 quarter plan.'} icon={require('../../images/icons/plan.png')} />                             
                            <Infobox title={'Forum'} description={'Ask any question, exchange and discuss ideas and get answers/replies from team members.'} icon={require('../../images/icons/qa.png')} />                             
                            <Infobox title={'Huddles'} description={'List down and view top 3 tasks, priority and stucks. Team Huddles are short meetings where each team member shares their priority task of the day and important updates. The user can update the daily and weekly huddle dashboard.'} icon={require('../../images/icons/huddle.png')} />                             
                            <Infobox title={'Stucks'} description={'When you get stuck on a piece of work and it is taking up your valuable time, the best approach is to redirect and get help from the appropriate person. Hub’s stuck feature enables the user to get help immediately from a team member or a person who is best positioned to help.'} icon={require('../../images/icons/stuck.png')} />

                            <Infobox title={'Parking Lot'} description={'Parking lot is an exciting feature where users can connect and  share their ideas. It helps to keep a track of important ideas. By this, all levels of employees can enjoy the challenges and have fun in the working environment.'} icon={require('../../images/icons/stuck.png')} />    

                            <Infobox title={'Reports'} description={'This feature makes working and reporting much easier and simpler.  The users can fill daily, weekly and monthly reports. The reports can also be shared via e-mail to team members.'} icon={require('../../images/icons/report.png')} />


                            <Infobox title={'Community'} description={'Community is a platform to collaborate and share ideas. The users can create and browse communities and can access and gain knowledge from a wide variety of community resources available. External and internal communities can be joined.'} icon={require('../../images/icons/vision.png')} />

                            <Infobox title={'Task'} description={'A task is more than a to-do list. It enables the users to track their work. The user can create a new task, assign it to any team member and set a deadline to make sure projects are delivered on time. CheckIn and checkout of tasks is also possible.'} icon={require('../../images/icons/raci.png')} />

                            <Infobox title={'Meetings'} description={'Schedule meetings from the Hub and invite the team members. Add meeting notes and make work easier.'} icon={require('../../images/icons/meeting-notes.png')} />

                            <Infobox title={'Timesheet'} description={'ITrack the start and end time of tasks or just the duration. Timesheet contains a detailed breakdown of tasks accomplished throughout the project. This information may be used for payroll, client billing, and increasingly for project costing, estimation, tracking, and management.'} icon={require('../../images/icons/metrics.png')} />

                            <Infobox title={'Checklist'} description={'Build and create checklists. Checklists are important for running business on a system.'} icon={require('../../images/icons/checklist.png')} />

                            <Infobox title={'OpenGrowth Academy'} description={'Start your entrepreneurial journey with OpenGrowth academy. It is a next-gen university for teaching entrepreneurship in a live action oriented effectual framework learning while working.'} icon={require('../../images/icons/wow.png')} />                          
                        </div>
                    </div>
                </section>
                <section className="fp-section fp-section-cta fp-bg-primary fp-text-center">
                    <div className="container">
                        <Ctapanel />
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default Features