import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Navbar from '../../components/front/Navbar'
import Accordion from '../../components/front/Accordion'
import Ctapanel from '../../components/front/Cta-panel'
import Footer from '../../components/front/Footer'

import enterpriseIcon from '../../images/enterprise-icon.png'
import axios from 'axios';
var config = require('../../config.js');

function isJSON(data) {
   var ret = true;
   try {
      JSON.parse(data);
   }catch(e) {
      ret = false;
   }
   return ret;
}

class Pricing extends Component{
    state = {
        faq: [
            {title:'How does OpenGrowth Hub’s pricing work?', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
            {title:'What forms of payment do you accept?', description:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
            {title:'We need to change the number of people in our team. How will that work?', description:'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
            {title:'How secure is OpenGrowth Hub?', description:'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
            {title:'Should I upgrade my organization or my team?', description:'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
            {title:'Does OpenGrowth Hub offer a discount for nonprofits?', description:'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
            {title:'What is your refund policy?', description:'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'}
        ]
    }
    componentDidMount = () => {
        window.scrollTo(0, 0)
          this.getPackages();
          this.getFeatures();
          this.getPermission();
    }
      constructor(props){
          super(props);
          this.state = {
              packagesList:[],
              featuresList:[],
              permissionList:[],
          }
      }

      getPackages = async () => {
          var _this1 = this;
          var data = {};
          data['cursor'] = 0;
          data['limit'] = 100;
          if(_this1.state.search_text)
          {
              data['search_text'] = _this1.state.search_text;
          }
          axios({
              method: 'post',
              url: config.master_api_url+'packages/list',
              data: data,
              headers:{'content-type': 'application/json'}
          }).then(function (resp) {
              if(resp.data.result){
                  _this1.setState({packagesList:resp.data.result});
              }
              _this1.setState({loadingData:false});
          }).catch(function (error) {
              //console.log(error);
              alert(error);
              _this1.setState({loadingData:false});
          });
      }

      getFeatures = async () => {
          var _this1 = this;
          var data = {};
          data['cursor'] = 0;
          data['limit'] = 100;
          if(_this1.state.search_text)
          {
              data['search_text'] = _this1.state.search_text;
          }
          axios({
              method: 'post',
              url: config.master_api_url+'modules/list',
              // url: 'http://127.0.0.1:8081/master/modules/list',
              data: data,
              headers:{'content-type': 'application/json'}
          }).then(function (resp) {
              if(resp.data.result){
                // console.log(resp.data.result);  
                  _this1.setState({featuresList:resp.data.result});
              }
              _this1.setState({loadingData:false});
          }).catch(function (error) {
              //console.log(error);
              alert(error);
              _this1.setState({loadingData:false});
          });
      }

      getPermission = async () => {
          var _this1 = this;
          var data = {};
          data['cursor'] = 0;
          data['limit'] = 100;
          if(_this1.state.search_text)
          {
              data['search_text'] = _this1.state.search_text;
          }
          axios({
              method: 'post',
              url: config.master_api_url+'packages/check-list',
              // url: 'http://127.0.0.1:8081/master/packages/check-list',
              data: data,
              headers:{'content-type': 'application/json'}
          }).then(function (resp) {
              if(resp.data.result){
                // console.log(resp.data.result);  
                  _this1.setState({permissionList:resp.data.result});
              }
              _this1.setState({loadingData:false});
          }).catch(function (error) {
              //console.log(error);
              alert(error);
              _this1.setState({loadingData:false});
          });
      }

      checkCSS = (name) => {
        var className = ''
        if (name === 'Basic'){
            className=' fp-pt-col fp-pt-red'
        }
        else if (name === 'Standard'){
            className=' fp-pt-col fp-pt-orange'
        }
        else if (name === 'Pro'){
            className='fp-pt-col fp-pt-green'
        }
        else if (name === 'Enterprise'){
            className='fp-pt-col fp-pt-blue'
        }
        return className
      }

      checkCSSBtn = (name) => {
        var className = ''
        if (name === 'Basic'){
            className="fp-btn-main fp-btn-outline fp-btn-sm fp-btn-red"
        }
        else if (name === 'Standard'){
            className="fp-btn-main fp-btn-outline fp-btn-sm fp-btn-orange"
        }
        else if (name === 'Pro'){
            className="fp-btn-main fp-btn-outline fp-btn-sm fp-btn-green"
        }
        else if (name === 'Enterprise'){
            className="fp-btn-main fp-btn-outline fp-btn-sm fp-btn-blue"
        }
        return className
      }

      checkCSSIcon = (name) => {
        var className = ''
        if (name === 'Basic'){
            className="fp-icon-check fp-icon-red"
        }
        else if (name === 'Standard'){
            className="fp-icon-check fp-icon-orange"
        }
        else if (name === 'Pro'){
            className="fp-icon-check fp-icon-green"
        }
        else if (name === 'Enterprise'){
            className="fp-icon-check fp-icon-blue"
        }
        return className
      }

      isPackageHasModule = (packageId, moduleId) => {

        packageId = parseInt(packageId)
        moduleId = parseInt(moduleId)

        var permissionList = this.state.permissionList

        for( var i=0; i<permissionList.length; i++){
            var permission = permissionList[i]

            var packages_id = parseInt(permission.packages_id)
            var permissions_id = parseInt(permission.permissions_id)

            if(packages_id === packageId && permissions_id === moduleId){
                return true
                break
            }
        }

        return false

      }

      buyPackage = (packageId) =>{

          var signupDetails = {}

          if(localStorage.getItem('signupDetails') ){
            if( isJSON(localStorage.getItem('signupDetails')) ){
              signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
            }
            else{
              signupDetails = localStorage.getItem('signupDetails')
            }
          }
          signupDetails['packageId'] = packageId
          localStorage.setItem('signupDetails', JSON.stringify(signupDetails));
      }

    render(){

        const packagesList = this.state.packagesList;
        const featuresList = this.state.featuresList;
        const permissionList = this.state.permissionList;

        return(
            <React.Fragment>
                <Navbar fixed active={2} />
                <header className="fp-header-inner fp-text-center fp-bg-grey">
                    <div className="container">
                        <div className="fp-header-caption">
                            <div className="fp-page-title">Start your <span>free</span> trial</div>
                            <p className="fp-text-lg">Unlimited Users and Boards. No credit card needed.</p>
                            <Link to="/signup" className="fp-btn-main fp-btn-lg">Get Started</Link>
                        </div>
                    </div>
                </header>
                <section className="fp-section fp-section-pricing">
                    <div className="container">
                        <div className="fp-pricing-table-box">
                            <div className="fp-pt-head fp-pt-row flex">
                                <div className="fp-pt-col fp-pt-col-left"><div className="fp-text-xl">Compare <br></br>our pricing <br></br>plans</div></div>
                                {packagesList.map(item => (
                                        <div className={this.checkCSS(item.name)} key={item.id}>
                                            <div className="fp-pt-head-title">{item.name}</div>
                                            <div className="fp-pt-head-info">
                                                {(item.name === 'Enterprise') ? (
                                                <div>
                                                    <div className="fp-pt-info-inner"><img src={enterpriseIcon} /></div>
                                                    <Link to="/contact" className="fp-btn-main fp-btn-outline fp-btn-sm fp-btn-blue">Contact Us</Link>
                                                </div>
                                                ) : (
                                                <div>
                                                  <div className="fp-pt-info-inner">
                                                    <div className="fp-pt-price">$<span>{item.price}</span></div>
                                                    <div className="fp-text-light">USD /month</div>
                                                    <div>Billed annually</div>
                                                  </div>
                                                    <Link to="/signup" className={this.checkCSSBtn(item.name)} onClick={() => this.buyPackage(item.id)} >Get Started</Link>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                ))}
                            </div>
                          
                            {featuresList.map(feature => (
                            <div className="fp-pt-body fp-pt-row flex" key={feature.id}>
                                <div className="fp-pt-col fp-pt-col-left"><div>{feature.name}</div></div>
                                {packagesList.map(pack => (
                                    (this.isPackageHasModule(pack.id, feature.id)) ? (
                                    <div key={pack.id} className="fp-pt-col"><div className="fp-icon-check-box"><i className={this.checkCSSIcon(pack.name)}></i></div></div>
                                    ) : (
                                    <div key={pack.id} className="fp-pt-col"><div className="fp-icon-check-box"></div></div>
                                    )

                                ))}

                            </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="fp-section fp-section-faq fp-bg-grey fp-text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="fp-faq-box">
                                    <div className="fp-heading2">Frequently Asked Questions</div>
                                    <Accordion data={this.state.faq} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="fp-section fp-section-cta fp-bg-primary fp-text-center">
                    <div className="container">
                        <Ctapanel />
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default Pricing