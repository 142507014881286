import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//import Left from '../../components/profile/left';
import LeftContainer from '../../../containers/users/LeftContainer';
import axios from 'axios';
import { setUserDetails, setUserPhoto } from '../../../actions/userAction';
import { get_id_token, isUserPrimaryOrAdmin } from '../../../utils.js';
import Loader from '../../../components/admin/loader';

const mapStateToProps = state => ({
	...state
})

const mapDispatchToProps = dispatch => ({
  onSetUserDetails: user => dispatch(setUserDetails(user)),
  onSetUserPhoto: photo => dispatch(setUserPhoto(photo)),
})

var config = require('../../../config.js');

class ProfileUpdate extends Component {

	constructor(props) {
		super(props);
		var formData = {};
		formData['name'] = (props.userDetails) ? props.userDetails.name : '';
		formData['email'] = (props.userDetails) ? props.userDetails.email : '';
		formData['phone'] = (props.userDetails) ? props.userDetails.phone : '';
		formData['about'] = (props.userDetails) ? props.userDetails.about : '';
		this.state = {
			user_uid:(props.userDetails) ? props.userDetails.uid : '',
			formData:formData,
			emailReadonly:false,
			phoneReadonly:false,
			showLoader: false,
			currentUserClaims: {},
		};
		//console.log(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event){
	  var name = event.target.name;
	  var formData = this.state.formData;
	  formData[name] = event.target.value;
	  this.setState({formData});
	}

	async handleSubmit(event){
		event.preventDefault();
		var _this1 = this;
		var id_token = await get_id_token();
		var uid = this.state.user_uid;
		var form_data = this.state.formData;
		form_data['id_token'] = id_token;		
		var phone = form_data['phone'];
		if(phone)
		{
			var match = /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/;
			if(!match.test(phone))
			{
				alert('Phone number is invalid.');
				return false;
			}
		}
		_this1.setState({showLoader: true});
		axios({
			method: 'post',
			url: config.master_api_url+'users/update/'+uid,
			data: JSON.stringify(form_data),
			headers:{'content-type': 'application/json'}
		}).then(function (resp) {

		}).catch(function (error) {
			alert(error);
			_this1.setState({showLoader: false});
		});
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.userDetails){
			var formData = {};
			formData['name'] = (nextProps.userDetails) ? nextProps.userDetails.name : '';
			formData['email'] = (nextProps.userDetails) ? nextProps.userDetails.email : '';
			formData['phone'] = (nextProps.userDetails) ? nextProps.userDetails.phone : '';
			formData['about'] = (nextProps.userDetails) ? nextProps.userDetails.about : '';
			this.setState({formData:formData});
		}
	}

  render() {
  	//console.log('profile-update.state.formData');
  	//console.log(this.state.formData);
  	const formData = this.state.formData;
	return (
	<div className="page-main-holder">
		<div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Profile</h2></div></div></div>
		<div className="container-area">
			{
				this.state.showLoader ? <Loader /> : ''
			}
			<div className="user-profile-container">
				<LeftContainer signOut={this.props.signOut} />
				<div className="profile-main-container">
					<div className="profile-main-inner">
						<div className="profile-inner-view">
							<div className="profile-section-top">
								<ul className="nav-tab">
									{(isUserPrimaryOrAdmin(this.state.currentUserClaims) ) ? (
										<li><Link to="/manage-workspace">Manage Workspace</Link></li>
									):('')}
									<li className="active"><Link to="/admin/profile-update">Edit Profile</Link></li>
									<li><Link to="/admin/change-password">Change Password</Link></li>
								</ul>
							</div>

							<form onSubmit={this.handleSubmit}>
								<div className="row">
									<div className="col-sm-12">
										<div className="input-box">
											<label>Name</label>
											<input type="text" name="name" defaultValue={(formData) ? (formData.name) : ('')} onChange={this.handleChange} className="form-style" />
										</div>
									</div>
									<div className="col-sm-12">
										<div className="input-box">
											<label>Email</label>
											<input type="text" name="email" defaultValue={(formData) ? (formData.email) : ('')} onChange={this.handleChange} readOnly={this.state.emailReadonly} className="form-style" />
										</div>
									</div>
									<div className="col-sm-12">
										<div className="input-box">
											<label>Phone No.</label>
											<input type="text" name="phone" defaultValue={(formData) ? (formData.phone) : ('')} onChange={this.handleChange} className="form-style" readOnly={this.state.phoneReadonly} />
										</div>
									</div>
									<div className="col-sm-12">
										<div className="input-box">
											<label>What I do</label>
											<input type="text" name="about" defaultValue={(formData) ? (formData.about) : ('')} onChange={this.handleChange} placeholder="- - - - -" className="form-style" />
										</div>
									</div>
									<div className="col-sm-12">
										<div className="input-box mb0">
											<Link to="/admin/profile" className="btn-main btn-outline btn-form">Cancel</Link>
											<button type="submit" className="btn-main btn-form">Update</button>
										</div>
									</div>
								</div>
							</form>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUpdate);