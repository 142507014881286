import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import firebase from "firebase/app";
import Sidebar from '../../components/sidebar';
import Topbar from '../../components/topbar';
import dashboardPage from './dashboard';
import workspacePage from './workspace';
import websiteSettingsPage from './website-settings';
import websiteLabelsPage from './website-labels';
import modulesPage from './modules';
import moduleActionsPage from './module-actions';
import emailTemplatesPage from './email-templates';
import staticPages from './static-pages';
import usersPage from './users'
import packagesPage from './packages/packages'
import packageItems from './packages/package-items'
import CMSPage from './cms/cms'
import CMSMenuList from './cms/cms-menu-list'
import coachPage from './coach'
import enquiriesPage from './enquiries'
import activityPage from './activity'
import AffiliateList from './affiliates/affiliates-list'
import AffiliatePayments from './affiliates/affiliates-payments'
import Profile from './profile/profile';
import ProfileUpdate from './profile/profile-update';
import ChangePassword from './profile/change-password';
import Leadership from './leadership/leadership';
import Quizzes from './quizzes/quizzes';
import Courses from './courses/courses';
import Blogs from './blogs/blogs';
import Books from './books/books';
import MentorRequest from './mentorship/mentor_request';
import Mentors from './mentorship/mentors';
import ordersPage from './orders/orders'
import orderItems from './orders/order-items'
import CMSModulesList from './cms-modules/modules-list'
import couponsPage from './coupons/coupons'
// PAGES
import {
  setUserDetails,
} from '../../actions/userAction';
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  onSetUserDetails: user => dispatch(setUserDetails(user)),
})

class AdminPage extends Component {
  constructor(props) {
    super(props);
    if(localStorage.getItem('user'))
    {
      this.props.onSetUserDetails(JSON.parse(localStorage.getItem('user')));
    }
    else
    {
      this.props.history.push('/admin/login');
    }
  }

  signOut = (event) => {
    // localStorage.clear();
    if(firebase.auth().currentUser){
      firebase.auth().currentUser.delete()
    }
    firebase.auth().signOut().then(() => {
      // console.log('==firebase.auth().signOut==')
      // Sign-out successful.
      localStorage.clear();
      sessionStorage.clear();
      window.location = '/admin/login'
    }).catch((error) => {
      // An error happened.
    });
    // window.location = '/admin/login'

  }

  render() {
    return (
      <main id="main-full" className="main-full flex">
        <Sidebar history={this.props.history} signOut={this.signOut} />
        <div className="page-main flex-auto">
          <Topbar history={this.props.history} signOut={this.signOut} />
          <Route exact path="/admin" component={dashboardPage} />
          <Route exact path="/admin/change-password" signOut={this.signOut} component={ChangePassword} />
          <Route exact path="/admin/workspace" component={workspacePage} />
          <Route exact path="/admin/website-settings" component={websiteSettingsPage} />
          <Route exact path="/admin/website-labels" component={websiteLabelsPage} />
          <Route exact path="/admin/website-labels/:parent_id" component={websiteLabelsPage} />
          {/*<Route exact path="/admin/modules" component={modulesPage} />*/}
          {/*<Route exact path="/admin/module-actions" component={moduleActionsPage} />*/}
          <Route exact path="/admin/email-templates" component={emailTemplatesPage} />
          <Route exact path="/admin/static-pages" component={staticPages} />
          <Route exact path="/admin/users" component={usersPage} />
          <Route exact path="/admin/packages" component={packagesPage} />
          <Route exact path="/admin/package-items/:packageId" component={packageItems} />
          <Route exact path="/admin/cms-pages" component={CMSPage} />
          <Route exact path="/admin/cms-pages-menu" component={CMSMenuList} />
          <Route exact path="/admin/manage-coach" component={coachPage} />
          <Route exact path="/admin/enquiries" component={enquiriesPage} />
          <Route exact path="/admin/activity" component={activityPage} />
          <Route exact path="/admin/affiliates" component={AffiliateList} />
          <Route exact path="/admin/payments" component={AffiliatePayments} />
          <Route exact path="/admin/profile" signOut={this.signOut} component={() => <Profile />} />
          <Route exact path="/admin/profile-update" signOut={this.signOut} component={() => <ProfileUpdate  history={this.props.history} />} />
          <Route exact path="/admin/leadership" component={Leadership} />
          <Route exact path="/admin/quizzes" component={Quizzes} />
          <Route exact path="/admin/courses" component={Courses} />
          <Route exact path="/admin/blogs" component={Blogs} />
          <Route exact path="/admin/books" component={Books} />

          <Route exact path="/admin/mentor_request" component={MentorRequest} />
          <Route exact path="/admin/mentors" component={Mentors} />

          <Route exact path="/admin/orders" component={ordersPage} />
          <Route exact path="/admin/order-items/:orderId" component={orderItems} />

          <Route exact path="/admin/modules" component={CMSModulesList} />
          <Route exact path="/admin/coupons" component={couponsPage} />
        </div> 
      </main>
    );
  }
}
//export default Dashboard;
export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);