import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Loader from './../../../components/loader';
import MyEditor from './../../../components/admin/my-editor';
var config = require('../../../config.js');
class AddStaticPages extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'id':'',
            'title':'',
            'status':'',
            'sort_order':'',
            'content':'',
            'meta_title':'',
            'meta_description':'',
            'meta_keyword':'',
        };

        var editData = (props.state.editData) ? props.state.editData : {}
        this.state = {
            showLoader: false,
            title: (editData.title) ? editData.title : '',
            content: (editData.content) ? editData.content : '',
            meta_title: (editData.meta_title) ? editData.meta_title : '',
            meta_description: (editData.meta_description) ? editData.meta_description : '',
            meta_keyword: (editData.meta_keyword) ? editData.meta_keyword : '',
            status: (editData.status && !isNaN(editData.status)) ? editData.status : 1,
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : newData,
            formErrors: {},
            errors: {},
        };

    }        

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox'){
            this.setState({[name]: (event.target.checked) ? 1 : 0});
        }
        else{
            this.setState({[name]: event.target.value});
        }
    }

    handleEditorContent = ( content ) => {
        console.log('handleSectionContent-content='+content)
        this.setState({content});
    }

    validateForm = () => {

        var formErrors = {}

        var errrorsCount = 0

        if(this.state.title.trim() === ''){
            formErrors['name'] = 'Please enter title.'
            errrorsCount++
        }

        this.setState({formErrors})

        if(errrorsCount > 0){            

            setTimeout(function(){
                var msgErrorElmnt = document.getElementsByClassName("msg-error");

                if(msgErrorElmnt && msgErrorElmnt[0]){
                    console.log(msgErrorElmnt)
                    msgErrorElmnt[0].scrollIntoView();
                }
            }, 100);

            return false
        }

        return true

    }

    handleSubmit = async () => {
        if(this.validateForm()){
            var _this1 = this;

            const data = new FormData();
            
            data.append('title', this.state.title);
            data.append('content', this.state.content)
            data.append('status', this.state.status)
            data.append('meta_title', this.state.meta_title)
            data.append('meta_description', this.state.meta_description)
            data.append('meta_keyword', this.state.meta_keyword)

            var url = config.master_api_url+'static-pages';
            // var url = 'http://127.0.0.1:8081/master/static-pages';

            if(this.state.editData && this.state.editData.id){
                url = url+'/update/'+_this1.state.editData.id
            }
            else{
                url = url+'/add'
            }

            $.ajax({
                type: 'post',
                url: url,
                data: data,
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function() {
                    _this1.setState({showLoader: true});
                },
                success: function(resp) {
                    // console.log('handleSubmit-resp=');
                    // console.log(resp);
                    if(resp){
                        _this1.setState({showLoader: false});
                    }

                    if(resp.success){
                        if(_this1.state.editData && _this1.state.editData.id){
                            _this1.props.staticPagesEdited(resp.static_page_data);
                        }
                        else{
                            _this1.props.staticPagesAdded(resp.static_page_data)
                        }
                    }
                    else if(resp.error){
                        alert(resp.error);
                    }
                    else{
                        alert('Something went wrong, please try again.');
                    }

                }
            
            }).catch(function (error) {
                console.log(error)

                if(error.statusText){
                    alert(error.statusText)
                }
                else{
                    alert("network error.")
                }
                _this1.setState({showLoader: false});
            });
        }
    }

    render(){
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        {(this.state.showLoader)? <Loader /> : null}
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.editData && this.state.editData.id) ? 'Update': 'Add'} Static Pages</h2>
                                </div>
                                <div className="form-fields-box">

                                    <div className="input-box">
                                        <label>Name</label>
                                        <input
                                            className="form-style"
                                            name = "title" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter title"
                                            type="text"
                                            value = {this.state.title}
                                            />
                                            <div className="msg-error">{(this.state.formErrors.title) ? this.state.formErrors.title : ''}</div>
                                    </div>

                                    <div className="input-box">
                                        <label className="checkbox"><input type="checkbox" name="status" onChange={this.handleChange} checked={this.state.status} /> <span>Active</span></label>
                                    </div>

                                    <div className="input-box">
                                        <label>Meta Tile</label>
                                        <input
                                            className="form-style"
                                            name = "meta_title" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter meta title"
                                            type="text"
                                            value = {this.state.meta_title}
                                            />
                                            <div className="msg-error">{(this.state.formErrors.meta_title) ? this.state.formErrors.meta_title : ''}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>Meta Description</label>
                                        <input
                                            className="form-style"
                                            name = "meta_description" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter meta description"
                                            type="text"
                                            value = {this.state.meta_description}
                                            />
                                            <div className="msg-error">{(this.state.formErrors.meta_description) ? this.state.formErrors.meta_description : ''}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>Meta Keyword</label>
                                        <input
                                            className="form-style"
                                            name = "meta_keyword" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter meta keyword"
                                            type="text"
                                            value = {this.state.meta_keyword}
                                            />
                                            <div className="msg-error">{(this.state.formErrors.meta_keyword) ? this.state.formErrors.meta_keyword : ''}</div>
                                    </div>


                                    <div className="input-box">
                                        <label>Content</label>

                                        <MyEditor
                                        content={(this.state.content)?this.state.content:''}
                                        handleContent={this.handleEditorContent}
                                        />

                                        <div className="msg-error">{(this.state.formErrors.content) ? this.state.formErrors.content : ''}</div>
                                    </div>

                                    {/*<div className="input-box">
                                        <label>Sort Order</label>
                                        <input 
                                            className="form-style"
                                            name = "sort_order" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter sort order"
                                            min = "0"
                                            type="number"
                                            value = {(this.state.editData && this.state.editData.sort_order) ? this.state.editData.sort_order : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.sort_order) ? this.state.errors.sort_order : ''}</div>
                                    </div>*/}
                               
                                </div>


                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddStaticPages} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addStaticPagesActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddStaticPages;