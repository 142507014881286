import React, {Component} from 'react';
import Navbar from '../../components/front/Navbar';
import Footer from '../../components/front/Footer';
import BillingCard from '../../components/billing/BillingCard';
import { isJSON } from './../../utils.js';
class BillingCycle extends Component{

    constructor(props) {
        super(props);

        var signupDetails = {}
        if( isJSON(localStorage.getItem('signupDetails')) ){
          signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
        }
        else{
          signupDetails = localStorage.getItem('signupDetails')
        }
        console.log('signupDetails=',signupDetails)
        if(!(signupDetails && signupDetails['packageId'] && signupDetails['packageId'] !== '' && signupDetails['signup_success'] && signupDetails['signup_success'] === true))
        {
            // window.location.href = '/plans-and-pricing'
        }
        this.state = {

        };
    }

    render(){
        return(
            <React.Fragment>
                <Navbar fixed active={2} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container billing-cycle-header">
                       <div className="fp-header-caption">
                            <div className="fp-page-title">Billing <span>Cycle</span></div>
                            <p className="fp-text-lg">Choose Billing plans as per your needs and necessity.<br/> We will provide the features accordingly,<br/> more than your expectation.</p>
                       </div>
                    </div>
                </header>
                <section className="fp-section fp-section-mentorship-registration pt-0">
                    <div className="container">
                        <BillingCard/>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}
export default BillingCycle;