import React, { Component } from 'react';
import Loader from '../../loader'
import { callByFormData } from '../../../ajax-call.js';
import { isUrlValid, validateImages } from '../../../utils.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class AddQuizz extends Component{
    constructor(props) {
        super(props);

        let quizzData = (props.quizzData && props.quizzData.id) ? props.quizzData : {}
        
        this.state = {
            showLoader: false,
            quizzData: quizzData,
            title: (quizzData.title) ? quizzData.title : '',
            description: (quizzData.description) ? quizzData.description : '',
            link: (quizzData.link) ? quizzData.link : '',
            status: (quizzData.hasOwnProperty('status')) ? quizzData.status : true,
            formErrors: {},
            errors: {},
        };
    }        

    handleChange = (event) => {
        var fieldName = event.target.name;

        if(event.target.type === 'checkbox'){
            this.setState({[fieldName]: event.target.checked});
        }
        else{
            this.setState({[fieldName]: event.target.value});
        }
        
    }


    handleImageChange = (event) => {
        let validationResult = validateImages(event)

        let formErrors = this.state.formErrors

        if(validationResult && validationResult['is_file_valid']){
            // console.log('validationResult=', validationResult)
            var fileUrl = URL.createObjectURL(event.target.files[0]);
            var file = event.target.files[0];
            this.setState({file:file,fileUrl:fileUrl});
        }
        else if(validationResult['errors']){
            formErrors['image'] = validationResult['errors']
        }
        this.setState({formErrors})

        this.gotoErrors()
    }


  removePic = () => {
    this.setState({file:'',fileUrl:'',image_delete: true});
  }


    validateForm = () => {

        var formErrors = {}

        var errrorsCount = 0

        if(this.state.title.trim() === ''){
            formErrors['title'] = 'Please enter title.'
            errrorsCount++
        }


        if(this.state.link && this.state.link !== ''){
            // console.log('isUrlValid(this.state.link)=', isUrlValid(this.state.link))
            if(!isUrlValid(this.state.link)){
                formErrors['link'] = 'Please enter valid link.'
                errrorsCount++
            }
        }

        this.setState({formErrors})

        if(errrorsCount > 0){            

            this.gotoErrors()

            return false
        }

        return true

    }

    gotoErrors = () => {
        setTimeout(function(){
            var msgErrorElmnt = document.getElementsByClassName("msg-error");

            if(msgErrorElmnt && msgErrorElmnt[0]){
                // console.log(msgErrorElmnt)
                msgErrorElmnt[0].parentElement.scrollIntoView();
            }
        }, 100);
    }

    handleSubmit = async () => {
        var _this1 = this;
        let isFormValid = true
        // if(this.validateForm()){
        if(isFormValid){

            let quizzId = (this.state.quizzData && this.state.quizzData.id)

            const formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('description', this.state.description);
            formData.append('link', this.state.link);
            formData.append('status', this.state.status);
            
            var url = masterApiUrl+'quizzes/add';
            
            if(quizzId && parseInt(quizzId) > 0){
                url = url+'/'+quizzId
            }

            this.setState({showLoader: true})

            let apiResp = callByFormData(formData, url, 'POST')
            .then((resp) => {

                if(resp){
                    this.setState({showLoader: false})
                }

                if(resp.success && resp.result){
                    let saveType = 'add'
                    this.props.quizzSaved(resp.result, saveType)
                }
                if(resp.errors){
                    let formErrors = {}
                    Object.keys(resp.errors).map((fieldName) => {
                        // console.log('fieldName=', fieldName);
                        formErrors[fieldName] = resp.errors[fieldName]
                    })
                    this.setState({formErrors})
                }

            })
            .catch((error) => {
                console.log('quizz-save-error=', error)
                this.setState({showLoader: false})
            });

        }
    }

    render(){


        return(
            <React.Fragment>
                
                <div className="form-panel active show">

                    {
                        this.state.showLoader ? <Loader /> : ''
                    }
                
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.quizzData && this.state.quizzData.id) ? 'Update': 'Add'} Quizz</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="user-input-img-box flex">
                                        <div className="user-input-box flex-auto">
                                          <div className="input-box">
                                            <label>Title</label>
                                            <input type="text" name="title" placeholder="Enter title" className="form-style" value={this.state.title} onChange={this.handleChange}/>
                                            {this.state.formErrors.title ? (<span className="msg-error">{this.state.formErrors.title}</span>) : null}
                                          </div>

                                          <div className="input-box">
                                            <label>Description</label>
                                            <textarea name="description" value={this.state.description} onChange={this.handleChange} className="form-style" ></textarea>
                                            {this.state.formErrors.description ? (<span className="msg-error">{this.state.formErrors.description}</span>) : null}
                                          </div>

                                          <div className="input-box">
                                            <label>Link</label>
                                            <input type="text" name="link" value={this.state.link} onChange={this.handleChange} placeholder="Enter link" className="form-style" />
                                            {this.state.formErrors.link ? (<span className="msg-error">{this.state.formErrors.link}</span>) : null}
                                          </div>
                                        
                                        </div>

                                      </div>

                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddQuizz} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.quizzData && this.state.quizzData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>

            </React.Fragment>
        )
    }
}
export default AddQuizz;