import React, {Component} from 'react'

import LoginForm from '../../components/signup/login'


class Login extends Component {

    constructor(props) {
        super(props);
    }

    render(){
        return(
            <div className="singup-pages">
                <LoginForm history={this.props.history} />
            </div>            
        )
    }
}

export default Login