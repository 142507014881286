import React, { Component } from 'react';
import Select from 'react-select';
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');

// const masterApiUrl = config.master_api_url
const masterApiUrl = 'http://127.0.0.1:8081/master/'

class AddOrdersFeatures extends Component{
    constructor(props) {
        super(props);
        this.state = {
            orderData: props.orderData,
            orderFeaturesList:{},
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var orderFeaturesList = this.state.orderFeaturesList;
        var name = event.target.name;
        //alert(name);
        const result_arr = []
        orderFeaturesList.forEach(function(data, key) {
            var new_row = data;
            //alert("status['"+new_row.id+"']"+" == "+name)
            if("status['"+new_row.id+"']" === name)
            {
                new_row.status = (event.target.checked) ? true : false
            }
            result_arr.push(new_row);
        })
        /*if(event.target.type === 'checkbox')
        {
            orderData[name] = (event.target.checked) ? true : false
        }
        else
        {
            orderData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('orderData')
        // console.log(orderData)
        this.setState({orderData});*/
        //console.log(result_arr)
        this.setState({orderFeaturesList:result_arr});
    }

    componentDidMount(){
        this.getOrdersFeatures();
    }

    getOrdersFeatures = async () => {
        var _this1 = this;
        var data = {};
        //data['orders_id'] = this.state.orderData.id;

        let url = masterApiUrl+'orders/features-list/'+this.state.orderData.id

        callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                _this1.setState({loadingData:false});
            }
            
            if(resp.result){
                _this1.setState({orderFeaturesList:resp.result});
            }
            
        })
        .catch((error) => {
            alert(error);
            _this1.setState({loadingData:false});
        });
    }


    handleSubmit = async () => {
        var _this1 = this;
        if(_this1.state.orderData.id)
        {
            let data = this.state.orderFeaturesList

            let url = masterApiUrl+'orders/features-list-update/'+_this1.state.orderData.id


            callByJsonData(data, url, 'POST')
            .then((resp) => {

                if(resp.status === 'success'){
                    _this1.props.onOrderFeaturesSaved(_this1.state.orderData);
                }
                else if(resp.message){
                    alert(resp.message);
                }
                else{
                    alert('Something went wrong, please try again.');
                }
                
            })
            .catch((error) => {
                alert(error);
            });
        
        }
        else {
            alert('Something went wrong, please try again.');
        }
    }

    render(){
        var orderFeaturesList = this.state.orderFeaturesList;
        return(
            <React.Fragment>
                <div className={"form-panel active show " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.orderData && this.state.orderData.id) ? 'Update': 'Add'} Orders Features</h2>
                                </div>
                                <div className="form-fields-box">

                                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                                        <tbody>
                                            <tr>
                                                <th width="50%">Features</th>
                                                <th width="50%">Status</th>
                                            </tr>
                                            {(orderFeaturesList.length > 0)?(
                                                orderFeaturesList.map((item, index) => (
                                            <tr key={index} >
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    <div className="input-box">
                                                        <label className="checkbox"><input type="checkbox" name={`status['${item.id}']`} onChange={this.handleChange} checked={(item.status === true) ? true : false} /> <span>Active</span></label>
                                                    </div>
                                                </td>
                                            </tr>
                                          ))
                                                ):null
                                            }
                                        </tbody>
                                    </table>                                     
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddOrdersFeatures} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.orderData && this.state.orderData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.addOrdersFeaturesActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddOrdersFeatures;