import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
class BooksList extends Component{

    handleEdit = (book) => {
        this.props.handleEdit(book);
    }

    handleDelete = (book) => {
        this.props.handleDelete(book);
    }

    render(){
        const booksList = this.props.booksList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Title</th>
                                <th>Link</th>
                                <th>Featured</th>
                                {/*<th>Status</th>*/}
                                <th></th>
                            </tr>

                            {
                                (booksList && booksList.length > 0) ? (

                                    booksList.map((book, index) => (
                            <tr key={index} >
                                <td width="30%">

                                    <div className="user-with-thumb flex align-items-center">
                                        <div className="user-thumb thumb-lg"><img src={(book.image) ? book.image : UserThumb} alt={book.title} /></div>
                                        <div className="user-text">
                                            <div className="user-name">
                                            {book.title}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td width="30%">
                                {
                                    (book.link && book.link.length > 0) && (
                                        <div className="post-img-link">
                                            <a href={book.link} target="_blank">{book.link}</a>
                                        </div>
                                        )
                                }
                                </td>
                                
                                
                                <td width="30%">
                                    {(book.is_featured === true || book.is_featured === 'true')?'Yes':'No'}
                                </td>
                                
                                {/*
                                    <td width="30%">
                                    {(book.status === true || book.status === 'true')?'Active':'In-active'}
                                </td>
                                */}
                                
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><button onClick={() => this.handleEdit(book)} className="button-like-link" >Edit</button></li>
                                                            <li><button onClick={() => this.props.handleDelete(book)} className="button-like-link" >Delete</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))

                                    ) : (


                                   
                                <tr>
                                <td colSpan="4">
                                {(this.props.showLoading) ? 'Loading...' : ('No record(s) found!')}
                                </td>
                                </tr>
                                
                               

                                    )
                            }

                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default BooksList;