import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Ctapanel extends Component{
    render(){
        return(
            <div className="fp-cta-box">
                <div className="fp-heading3">Try OpenGrowth Hub for your team</div>
                <div className="fp-btn-outer"><Link to="/contact" className="fp-btn-main fp-btn-white fp-btn-lg">Get Started <i className="fp-icon-arrow-right"></i></Link></div>
            </div>
        )
    }
}
export default Ctapanel