import React, {Component} from 'react';
import Navbar from '../../components/front/Navbar';
import Footer from '../../components/front/Footer';
import Infobox from '../../components/front/Info-box';
class PlanDetails extends Component{
    render(){
        return(
            <React.Fragment>
                <Navbar fixed active={2} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                       <div className="fp-header-caption">
                            <div className="fp-page-title">Upgrade to <span>OGHUB</span> pro</div>
                            <p className="fp-text-lg">
                                <span className="head-price"><small>$</small>99</span>
                                per user per month
                            </p>
                            <button className="fp-btn-main fp-btn-lg">Upgrade Now to PRO</button>
                       </div>
                    </div>
                </header>
                <section className="fp-section fp-section-features">
                    <div className="container fp-features-container">
                        <div className="fp-heading2 fp-text-center">With OpenGrowth Hub pro you get access to</div>
                        <div className="fp-boxes-outer row fp-features-list-outer">

                            <Infobox title={'Coaches'} description={'Meet Industry experts right here; Meet Coaches and get all the guidance you require for a game changing venture ahead!'} icon={require('../../images/icons/coach.png')} />                            
                            <Infobox title={'Channels'} description={'Watch your team spirits grow with better communication with OpenGrowth Hub Business Channel. Share relevant documents, messages, alerts and announcements. '} icon={require('../../images/icons/channel.png')} />                            
                            <Infobox title={'311 Plans'} description={'Increase focus and motivation with 311 plan. Plan your goal for the next 3 years, in the upcoming year and in the next quarter.'} icon={require('../../images/icons/plan.png')} />                             
                            <Infobox title={'Q & A'} description={'Discuss on a private or a public Q&A channel with your team mates or with people in your organization.'} icon={require('../../images/icons/qa.png')} />                             
                            <Infobox title={'Huddles'} description={'Daily and Weekly huddles. List down and view Top 3 tasks, Priority, and where you are stuck. '} icon={require('../../images/icons/huddle.png')} />                             
                            <Infobox title={'Stucks'} description={'If you are stuck on a piece of work and it is taking up your valuable time just add a stuck and get help. Help others if they are stuck and require your input.'} icon={require('../../images/icons/stuck.png')} />                             
                            <Infobox title={'Daily Reporting'} description={'Keep track of your time and makes sure you only focus on important things each day. List all the works of the day.'} icon={require('../../images/icons/report.png')} />                             
                            <Infobox title={'Purpose / Mission / Vision'} description={'Provides employees to comment, discuss their perspectives on company mission/vision/purpose'} icon={require('../../images/icons/vision.png')} />                             
                            <Infobox title={'RACI Teams'} description={'Prioritize team members’ actions, so everyone knows precisely what they’re responsible for. Clarify roles and eliminate confusion, Keep projects on track and ensures nothing falls through the cracks.'} icon={require('../../images/icons/raci.png')} />                             
                            <Infobox title={'MeetingNotes'} description={'Maintain a one stop MOM space for all the meetings.'} icon={require('../../images/icons/meeting-notes.png')} />                             
                            <Infobox title={'OKR'} description={'Increase productivity through focus on goals. Track regular progress towards goals and make more effective and informed decisions. Achieve measurement, accountability, and transparency with OKR.'} icon={require('../../images/icons/metrics.png')} />                             
                            <Infobox title={'Checklists'} description={'Build and create checklists. Checklists are important for running business on a system.'} icon={require('../../images/icons/checklist.png')} />                             
                            <Infobox title={'WoWPoints'} description={'Give WoW points to your colleagues, employees and manage your own WoW points. Exchange it for a Gift card and many more..'} icon={require('../../images/icons/wow.png')} />                             
                            <Infobox title={'Awards'} description={'Rewards and Recognition section where we showcase award winning performances by employees.'} icon={require('../../images/icons/award.png')} />                             
                            <Infobox title={'PIP'} description={'A feature which will help an employee with performance deficiencies the opportunity to succeed.'} icon={require('../../images/icons/pip.png')} />                             
                            <Infobox title={'Performance Review'} description={'Get evaluated and rewarded for the task performed.'} icon={require('../../images/icons/review.png')} />                             
                            <Infobox title={'University'} description={'Learn about Entrepreneurship, Business, Technology..'} icon={require('../../images/icons/study.png')} />

                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}
export default PlanDetails;