import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import WorkspaceList from '../../components/admin/workspace/workspace-list'
import ViewWorkspace from '../../components/admin/workspace/workspace-view';
import DeleteWorkspace from '../../components/admin/workspace/workspace-delete';
import WorkspacePermissions from '../../components/admin/workspace/workspace-permissions';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader'
import { callByJsonData } from './../../ajax-call.js';
import { get_id_token } from './../../utils.js';
var config = require('../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class Workspace extends Component{

    constructor(props){
        super(props)
        this.state = {
            showClass: '',
            activeClass: '',
            workspaceViewActive: false,
            workspacePermissionsActive: false,
            showLoader: false,
            search_text: '',
            workspaceList: [],
            dbSizesInMb: {},
            bucketSizesInMb: {},
            showDeletedWorkspaces: false,
            editData: {},
        }
    }

    componentDidMount(){
        this.getWorkspace();
        // this.getWorkspaceSizes();
    }

    getWorkspace = async () => {
        var _this1 = this;
        var data = {};
        data['id_token'] = await get_id_token()
        data['cursor'] = 0;
        data['limit'] = 100;
        
        if(_this1.state.search_text){
            data['search_text'] = _this1.state.search_text;
        }
        
        const url = masterApiUrl+'workspace/list'
        // const url = 'http://127.0.0.1:8081/master/workspace/list'

        this.setState({showLoader: true})

        let apiResp = callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp){
                this.setState({showLoader: false})
            }
            if(resp.result){
                let workspaceList = resp.result
                let workspace = workspaceList.find((ws) => {
                    let wsDeleted = (ws.is_deleted) ? parseInt(ws.is_deleted) : 0
                    // return (wsDeleted === 0 && ws.db_name === 'oghublive')
                    return (wsDeleted === 0)
                })
                // console.log('workspace=',workspace)
                if(workspace && workspace.tenant_id){
                    this.getWorkspaceSizes(workspace.tenant_id)
                }
                _this1.setState({workspaceList:resp.result});
            }
        })
        .catch((error) => {
            console.log('get-workspace-error=',error)
            this.setState({showLoader: false})
        });
    }

    getWorkspaceSizes = async (tenantId) => {
        var _this1 = this;
        var data = {};
        data['id_token'] = await get_id_token()
        
        const url = config.api_url+'users/get_workspaces_sizes'
        // const url = 'http://127.0.0.1:8081/users/get_workspaces_sizes'

        // this.setState({showLoader: true})

        let apiResp = callByJsonData(data, url, 'POST', {'tenantId': tenantId})
        .then((resp) => {
            if(resp.db_sizes_mb){
                this.setState({dbSizesInMb: resp.db_sizes_mb})                
            }
            if(resp.bucket_sizes_mb){
                this.setState({bucketSizesInMb: resp.bucket_sizes_mb})
            }
        })
        .catch((error) => {
            console.log('get-workspace-error=',error)
            // this.setState({showLoader: false})
        });
    }

    workspaceLogin = (workspaceData) => {
        var _this1 = this;
        var data = {};

        var workspace = workspaceData.workspace
        
        data['workspace'] = workspace;

        const url = masterApiUrl+'impersonate/get-custom-token'
        // const url = 'http://127.0.0.1:8081/master/impersonate/get-custom-token'

        this.setState({showLoader:true});

        let apiResp = callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp){
                _this1.setState({showLoader:false});
            }
            if(resp.token){
                // const token = resp.result.body.idToken;
                // window.open('http://' + workspace + '.localhost:3000/impersonate?token='+ resp.token)
                const current_protocol = window.location.protocol
                // alert(current_protocol)

                // if (current_protocol !== "https:") {
                //     current_protocol = 'http:'
                // }

                // alert(current_protocol)

                const current_domain = window.location.hostname

                window.open(current_protocol + '//' + workspace + '.' +current_domain + '/impersonate?token='+ resp.token)
            }
            
        })
        .catch((error) => {
            console.log('workspace-custom-token-error=', error);
            _this1.setState({showLoader:false});
        });
    }

    workspaceDelete = async (workspaceData) => {
        var conf = window.confirm('Are you sure to continue?')
        if(conf)
        {
            var _this1 = this;
            var data = {};
            
            _this1.setState({workspaceData:workspaceData});
            _this1.handleDeleteWorkspace()

        }

    }


    workspaceDeleted = async (workspaceData) => {
        //var conf = window.confirm('Are you sure to continue?')
        //if(conf) {

            var _this1 = this;
            var data = {};

            var workspace = workspaceData.workspace
            data['workspace'] = workspace;
            
            const url = masterApiUrl+'workspace/delete'

            this.setState({showLoader:true});


            let apiResp = callByJsonData(data, url, 'POST')
            .then((resp) => {
                //console.log(response.data.success);
                _this1.cancelDeleteWorkspace()                

                if(resp.success)
                {
                    if(workspaceData){
                        //console.log('workspaceData');
                        var state = _this1.state.workspaceList;
                        //console.log('state');
                        //console.log(state);
                        const index = state.findIndex(row => row.id === workspaceData.id);
                        if (index === -1) {
                            state = [];
                            //console.log('workspaceData-1');
                        }
                        else
                        {
                            //console.log('workspaceData-2');
                            state = [...state.slice(0, index), ...state.slice(index + 1)];
                        }

                        _this1.setState({workspaceList:state});
                        alert('Workspace Deleted successfully');

                    }

                    //console.log('A1');
                    _this1.setState({showLoader:false});


                }
                else if(resp.message)
                {
                    //console.log('A2');
                    alert(resp.message);
                    _this1.setState({showLoader:false});
                }
                else
                {
                    //console.log('A3');
                    alert('Something went wrong, please try again.');
                    _this1.setState({showLoader:false});
                }
            })
            .catch((error) => {
                console.log('workspace-delete-error=', error);
                _this1.setState({showLoader:false});
            });
        //}
    }


    handleViewWorkspace = () => {
        if(this.state.workspaceViewActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({workspaceViewActive: false})
            },620);
        } else {
            this.setState({workspaceViewActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    workspaceEdit = (workspace) => {
        if(this.state.dbSizesInMb[workspace.db_name]){
            workspace['db_size'] = this.state.dbSizesInMb[workspace.db_name]
        }
        if(this.state.bucketSizesInMb[workspace.bucket]){
            workspace['bucket_size'] = this.state.bucketSizesInMb[workspace.bucket]
        }
        this.setState({editData: workspace});
        this.handleViewWorkspace();
    }

    cancelViewWorkspace = () => {
        var editData = {}
        this.setState({editData});
        this.handleViewWorkspace();
    }

    cancelDeleteWorkspace = () => {
        var editData = {}
        this.setState({editData});
        this.handleDeleteWorkspace();
    }


    handleWorkspacePermissions = () => {
        if(this.state.workspacePermissionsActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({workspacePermissionsActive: false})
            },620);
        } else {
            this.setState({workspacePermissionsActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    workspacePermissions = (workspace) => {
        this.setState({editData: workspace});
        this.handleWorkspacePermissions();
    }


     handleDeleteWorkspace = () => {
        if(this.state.workspaceDeleteActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({workspaceDeleteActive: false})
            },620);
        } else {
            this.setState({workspaceDeleteActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelWorkspacePermissions = () => {
        var editData = {}
        this.setState({editData});
        this.handleWorkspacePermissions();
    }

    toggleDeletedWorkspaces = () => {
        this.setState({showDeletedWorkspaces: !this.state.showDeletedWorkspaces})
    }
    

    render(){
        // const dbSizesInMb = this.state.dbSizesInMb

    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Workspace</h2></div></div></div>
        <div className="container-area">
            {
                this.state.showLoader ? <Loader /> : ''
            }

            <div className="inv-body">
                <div className="box">
                    {/*<div className="box-title">
                        <div className="box-filter-left flex align-items-center">
                            <div className="sort-box box-search flex-1">
                                <div className="filter-search-box">
                                    <i className="icon-search"></i>
                                    <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search members" />
                                </div>
                            </div>
                        </div>
                    </div>*/}

                    <div className="box-title">
                    <div className="box-filter-left flex align-items-center">
                        <div className="section-btn-outer align-self-end">
                            <label className="checkbox"><input type="checkbox" checked={(this.state.showDeletedWorkspaces)} onChange={this.toggleDeletedWorkspaces} /> <span>Deleted Workspaces</span></label>
                        </div>
                        </div>
                    </div>

                    <div className="box-body">
                        <WorkspaceList
                        state={this.state}
                        workspaceEdit={this.workspaceEdit}
                        workspacePermissions={this.workspacePermissions}
                        workspaceLogin={this.workspaceLogin}
                        workspaceDelete={this.workspaceDelete}
                        
                        />
                    </div>
                    {
                        this.state.workspaceViewActive ? (
                            <ViewWorkspace state={this.state} cancelViewWorkspace={this.cancelViewWorkspace} />
                        ) : null
                    }
                    
                    {
                        this.state.workspacePermissionsActive ? (
                            <WorkspacePermissions state={this.state} cancelWorkspacePermissions={this.cancelWorkspacePermissions} />
                        ) : null
                    }

                    {
                        this.state.workspaceDeleteActive ? (
                            <DeleteWorkspace state={this.state} cancelDeleteWorkspace={this.cancelDeleteWorkspace} workspaceDeleted={this.workspaceDeleted}/>
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default Workspace;