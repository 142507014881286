import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Navbar from '../../components/front/Navbar'
import Accordion from '../../components/front/Accordion'
import Ctapanel from '../../components/front/Cta-panel'
import Footer from '../../components/front/Footer'
import {Helmet} from "react-helmet";
import enterpriseIcon from '../../images/enterprise-icon.png'
import axios from 'axios';
var config = require('../../config.js');

class StaticPage extends Component{
    constructor(props){
        super(props);
        // console.log('constructor-StaticPage-props=', props)

        let slug = (props.slug) ? props.slug : '';
        if(props.match.params && props.match.params.slug) {
            slug = props.match.params.slug;
        }
        if(slug == '' && props.match.url) {
            slug = props.match.url;
            if(slug) {
                slug = slug.substring(1);
            }
        }

        this.state = {
            slug: slug,
            pageDetails: {},
            loadingData: true
        }
    }

    componentDidMount = () => {
        // window.scrollTo(0, 0)
          this.getPage();
    }

    componentWillReceiveProps (nextProps, prevState) {
        // console.log('page-detail.js-nextProps=',nextProps)

        if(nextProps && nextProps.slug){
            var currentSlug = this.state.slug
            if(currentSlug !== nextProps.slug){
                var slug = nextProps.slug

                if(slug == '' && nextProps.match.url) {
                    slug = nextProps.match.url;
                    if(slug) {
                        slug = slug.substring(1);
                    }
                }

                this.setState({
                  pageDetails: {},
                  slug: slug
                }, () => {
                  this.getPage()
                })
            }
        }
    }

    getPage = async () => {
        var _this1 = this;

        let slug = this.state.slug
        if(slug) {
            var data = {};

            let url = config.master_api_url+'static-pages/get/'+slug
            // let url = 'http://127.0.0.1:8081/master/static-pages/get/'+slug          

            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (resp) {
                if(resp.data.result){
                    _this1.setState({pageDetails: resp.data.result});
                }
                _this1.setState({loadingData: false});
            }).catch(function (error) {
                console.log('get-static-page-error=', error);
                _this1.setState({loadingData: false});
            });
        }
    }

    render(){

        const pageDetails = this.state.pageDetails

        return(
            <React.Fragment>
                <Helmet>
                    <title>{(pageDetails.meta_title) ? pageDetails.meta_title : ((pageDetails.title)?pageDetails.title:'')}</title>
                    <meta name="Description" content={(pageDetails.meta_description) ? pageDetails.meta_description : ''}/>
                    <meta name="Keywords" content={(pageDetails.meta_keyword) ? pageDetails.meta_keyword : ''}/>
                </Helmet>

                <Navbar fixed active={0} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                        <div className="fp-header-caption">
                            <div className="fp-page-title">{(pageDetails.title) ? pageDetails.title : ''}</div>
                        </div>
                    </div>
                </header>
                <section className="fp-section fp-section-about">
                    <div className="container">
                      <div dangerouslySetInnerHTML={{__html: (pageDetails.content) ? pageDetails.content : '' }} className="content-box" ></div>
                         {/*(pageDetails.content) ? pageDetails.content : ''*/}
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default StaticPage