import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Navbar from '../../components/front/Navbar'
import Footer from '../../components/front/Footer'
import imgWhyus from '../../images/img-whyus.jpg'


class About extends Component{
    componentDidMount = () => {
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <React.Fragment>
                <Navbar fixed active={0} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                        <div className="fp-header-caption">
                            <div className="fp-page-title">Why <span>OpenGrowth Hub</span></div>                  
                            <p className="fp-text-lg fp-para">Explore endless possibilities with our open growth collaboration platform, OpenGrowthHub!  We bring winning teams to life and believe in Leadership for all!! </p>                   
                        </div>
                    </div>
                </header>
                <section className="fp-section fp-section-about">
                    <div className="container">
                         <p className="fp-text-md text-center">The OpenGrowthHub empowers remote teams to work efficiently by enabling them to collaborate virtually because it's the era of virtual teams "working together" and making ever lasting impact on the industry. Team members can share their ideas, facilitate questions and answers because a good idea becomes a great idea only when you open up and seek out help and advise. Our software will maintain transparency between teams which will enhance employees' and manager's performance. Schedule daily meetings to keep your team informed about current tasks, ask for help using the stuck feature and let experts from your team know where you are stuck and get their help and feedback. Get guidance from industry experts and enhance your skills and knowledge. </p>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

export default About