import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LogoFooter from '../../images/logo-footer.png';
import Logo from '../../images/logo.png'

class Footer extends Component{
    render(){
        return(
            <footer className="fp-footer-full">
                <div className="container">
                    {
                        this.props.hideNav ? '' : (
                            <div className="fp-footer-inner flex">
                                <div className="fp-f-col fp-f-col-1 fp-f-logo"><Link to="/"><img src={Logo} /></Link></div>
                                <div className="fp-f-col fp-f-col-2">
                                    <ul className="fp-f-nav">
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/features">Features</Link></li>
                                        <li><Link to="/mentorship-registration">Register for Mentorship</Link></li>
                                    </ul>
                                </div>
                                <div className="fp-f-col fp-f-col-3">
                                    <ul className="fp-f-nav">
                                        <li><Link to="/plans-and-pricing">Pricing</Link></li>
                                        <li><Link to="/refund-cancellation-policy">Refund / Cancellation Policy</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                        {/* <li><Link to="/login">Login</Link></li> */}
                                    </ul>
                                </div>
                                <div className="fp-f-col fp-f-col-4">
                                    <div className="fp-title2">Follow us</div>
                                    <ul className="fp-f-social-links">
                                        <li><a target="_blank" href="https://www.facebook.com/opengrowthcommunity"><i className="fp-icon-fb"></i></a></li>
                                        <li><a target="_blank" href="https://twitter.com/OpenGrowth1"><i className="fp-icon-tw"></i></a></li>
                                        <li><a target="_blank" href="https://www.instagram.com/opengrowthcommunity/"><i className="fp-icon-insta"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    <div className="fp-f-bottom text-center">
                    &copy; Copyright 2021 OpenGrowth Hub. All Right Reserved. 
                        <span className="fp-f-links">
                            <Link to="/privacy">Privacy</Link>
                            <Link to="/terms" >Terms & Conditions</Link>
                            <Link to="/admin" >.</Link>
                        </span>
                    </div>
                </div>
            </footer>
        )
    }
}
export default Footer