import React, {Component} from 'react'

class Alert2 extends Component{
    state = {
        alert: true,
        show: ''
    }
    handleAutoHide = () => {
        setTimeout(() => {
            this.setState({
                show: ''
            })
        },2000)
        setTimeout(() => {
            this.setState({
                alert: false
            })
        },3500)
    }
    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                show: 'show'
            })
        },50)
        // this.handleAutoHide()

        // alert('topbar-alert2')
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.state.alert ? (
                        <div className={(this.props.type) ? ('alert-full ' + this.props.type + ' ' + this.state.show) : ('alert-full ' + this.state.show)}>
                            <div className="alert-full-inner">
                                <div className="alert-box flex">
                                    <div className="alert-txt" dangerouslySetInnerHTML={{__html: this.props.message}}></div>
                                    <div className="alert-close" onClick={this.props.onClose}><i className="icon-close"></i></div>
                                </div>
                            </div>
                        </div>
                    ) : ('')
                }
            </React.Fragment>
        )
    }
}
export default Alert2;