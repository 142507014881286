import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserDetails } from '../../../actions/userAction'
//import Left from '../../components/profile/left';
import LeftContainer from '../../../containers/users/LeftContainer';
import { get_id_token, randomAlphaNumString } from '../../../utils.js';
import $ from 'jquery';
var config = require('../../../config.js');

const mapStateToProps = state => ({
  ...state
})

class Profile extends Component {
    constructor(props) {
        super(props);
    }
  render() {
    const user = (this.props.userDetails) ? this.props.userDetails : {}
    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Profile</h2></div></div></div>
        <div className="container-area">
            <div className="user-profile-container">

                {/*<Left user={this.state.user} />*/}
                <LeftContainer signOut={this.props.signOut} />

                <div className="profile-main-container">
                    <div className="profile-main-inner">
                        <div className="profile-inner-view">
                            <div className="profile-section-top"><h2 className="profile-page-title">My Profile</h2></div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>Name</label>
                                        <div className="value-box">
                                        {(user.name) ? (user.name) : ('')}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>Email</label>
                                        <div className="value-box">
                                        {(user.email) ? (user.email) : ('')}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>Phone No.</label>
                                        <div className="value-box">
                                        {(user.phone) ? (user.phone) : ('')}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box">
                                        <label>What I do</label>
                                        <div className="value-box">{(user.about) ? (user.about) : ('----')}</div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="input-box mb0">
                                        <Link to={`/admin/profile-update#${randomAlphaNumString(6)}`} className="btn-main btn-outline btn-form">Edit Profile</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

export default connect(mapStateToProps)(Profile);