import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

class WebsiteLabelsList extends Component{

    labelsEdit = (labels) => {
        this.props.labelsEdit(labels);
    }

    labelsRestore = (labels) => {
        var conf = window.confirm('Are you sure to restore old values?');
        if(conf)
        {
            this.props.labelsRestore(labels);
        }
    }
    render(){
        const websiteLabelsList = this.props.state.websiteLabelsList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Labels Var Name</th>
                                <th>Labels Title</th>
                                <th>Labels Info</th>
                                <th></th>
                            </tr>
                            {websiteLabelsList.map((item, index) => (
                            <tr key={index} >
                                <td width="20%">
                                    {item.var_name}
                                </td>
                                <td width="30%">                        
                                    <Link to={`/admin/website-labels/${item.id}`}>{item.var_title}</Link>
                                </td>
                                <td width="40%">
                                    {item.var_info}
                                </td>
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a onClick={() => this.labelsEdit(item)} >Edit</a></li>
                                                            <li><a onClick={() => this.labelsRestore(item)} >Restore</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default WebsiteLabelsList;