import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import rootReducer from './reducers/index';

/**
 * @param {object} initialState 
 * @returns {func} createStore
 */

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['signupReducer', 'signup']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  return createStore(
    //rootReducer,
    persistedReducer,
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    //applyMiddleware(thunk)
    composeEnhancer(applyMiddleware(thunk))
  );
}