import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapDispatchToProps = dispatch => ({
  /*hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }))
  }*/
})

var config = require('../../config.js');

const api_url = config.api_url;

class Refer extends Component {

    constructor(props) {
      super(props);
      this.state = {

      };
      /*console.log('email=');
      console.log(props);*/

      const query = new URLSearchParams(props.location.search);

      const referralCode = query.get('r')
      // console.log('referralCode='+referralCode);

      if(referralCode){
        // alert(referralCode);

        sessionStorage.setItem('referralCode', referralCode);
      }

      props.history.push('/signup');
    }
    componentDidMount(){
      // console.log('this.props.location=');
      // console.log(this.props.location);

      
    }

  render() {
    return (
      <div className="main-full-inner flex align-items-center justify-content-center">
      
      </div>    
    );
  }
}
export default connect(null, mapDispatchToProps)(Refer);