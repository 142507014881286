import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import Loader from './../../loader'
import AlertClosable from '../../../components/alert-closable'
import { callByJsonData } from '../../../ajax-call.js';
import { validateEmail } from '../../../utils.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'


export const billingTypesArr = [
{ value: 'monthly', label: 'Monthly' },
{ value: 'annualy', label: 'Annualy' }
]

export function getBillingTypeLabel(value){
    let billingLabel = ''
    if(value){
        // console.log('getBillingTypeLabel(value)=', value)
        for(var i in billingTypesArr){
            let billingType = billingTypesArr[i]
            
            if(billingType['value'] === value){
                billingLabel = billingType['label']
                break
            }
        }
    }

    return billingLabel
}

class SendEmail extends Component{
    constructor(props) {
        super(props);

        let packageData = (props.packageData && props.packageData.id) ? props.packageData : {}

        let customEmails = (packageData['custom_emails']) ? packageData['custom_emails'] : ''

        if(customEmails && customEmails.length > 0){
            customEmails = JSON.parse(customEmails)
        }

        // console.log('customEmails=', JSON.parse(customEmails))

        let selectedCustomEmails = []

        // if(customEmails && customEmails.length > 0 && Array.isArray(customEmails)){
        //     customEmails.map((email) => {
        //         selectedCustomEmails.push({label: email, value: email})
        //     })
        // }

        let emailContent = 'The custom plan is created for you, click on below url to view details:'


        this.state = {
            packageData: (props.packageData && props.packageData.id) ? props.packageData : {},
            selectedCustomEmails: selectedCustomEmails,
            emailContent: emailContent,
            formErrors: {},
            showLoader: false,
            msgSuccClosable: false,
            msgErrClosable: false,
        };
    }        

    handleChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        // alert(name);
        if(event.target.type === 'checkbox'){
            value = (event.target.checked) ? true : false
        }
        this.setState({[name]: value});
    }

    handleCustomEmails = (selectedCustomEmails) => {
        this.setState({selectedCustomEmails});
    }

    validateForm = () => {

        var formErrors = {}

        let errorsCount = 0
        

        let selectedCustomEmails = this.state.selectedCustomEmails

        if(selectedCustomEmails && selectedCustomEmails.length > 0){
            selectedCustomEmails.map((r) => {
                if(!validateEmail(r.value)){
                    formErrors['emails'] = 'Please enter valid email(s).'
                    errorsCount++
                }
            })
        }

        this.setState({formErrors})

        if(errorsCount > 0){

            setTimeout(function(){
                var msgErrorElmnt = document.getElementsByClassName("msg-error");

                if(msgErrorElmnt && msgErrorElmnt[0]){
                    // console.log(msgErrorElmnt)
                    msgErrorElmnt[0].parentElement.scrollIntoView();
                }
            }, 100);

            return false
        }

        return true
    }

    handleSubmit = async () => {
        var _this1 = this;

        if(this.validateForm()){
        
            if(window.confirm("Are you really want to send email?")){

                let packageData = this.state.packageData;
                let selectedCustomEmails = this.state.selectedCustomEmails;

                let custom_emails = []

                if(selectedCustomEmails && selectedCustomEmails.length > 0){
                    selectedCustomEmails.map(r => {
                        custom_emails.push(r.value)
                    })
                }

                let emails = JSON.stringify(custom_emails)

                let data = {}

                data['emails'] = emails
                data['content'] = this.state.emailContent

                
                var url = masterApiUrl+'packages/emails_custom/'+packageData.id;

                this.setState({showLoader: true})

                callByJsonData(data, url, 'POST')
                .then((resp) => {
                    if(resp){
                        this.setState({showLoader: false})
                    }
                    if(resp.success){
                        _this1.props.onSaved(resp.record, 'custom_email')
                        _this1.props.close()
                    }
                    else if(resp.error){
                        this.setState({msgErrClosable: resp.message})
                    }
                    else if(resp.errors){
                        this.setState({formErrors: resp.errors})
                    }
                    else{
                        this.setState({msgErrClosable: 'Something went wrong, please try again.'})
                    }
                })
                .catch((error) => {
                    console.log('save-package-error=', error)
                    this.setState({msgErrClosable: 'Something went wrong, please try again.'})
                    this.setState({showLoader: false})
                });
            

            }

        }
    
    }

    closeAlert = () => {
        this.setState({msgSuccClosable: false, msgErrClosable: false})
    }

    render(){

        const packageData = this.state.packageData

        const billingCycle = (packageData.billing_cycle) ? packageData.billing_cycle : 'monthly'

        return(
            <React.Fragment>
                <div className={"form-panel active show" + " " + this.state.formScrollable}>
            
                {
                    this.state.showLoader ? <Loader /> : ''
                }

                {
                    this.state.msgErrClosable ? (<AlertClosable message={this.state.msgErrClosable} type='error'  onClose={this.closeAlert} />) : ''
                }
                    
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2>Send email for package: {(packageData && packageData.id) ? packageData.name: ''}</h2>
                                </div>
                                <div className="form-fields-box">

                                    <div className="input-box">
                                        <label>Email(s)</label>

                                        <CreatableSelect
                                        name="custom_emails"
                                        value={this.state.selectedCustomEmails}
                                        onChange={this.handleCustomEmails}
                                        // options={permissionOptions}
                                        // menuIsOpen={false}
                                        // isValidNewOption={() => false}

                                        // formatCreateLabel={() => undefined}
                                        noOptionsMessage={() => null}
                                        isMulti
                                        />
                                        
                                        <div className="msg-error">{(this.state.formErrors.emails) ? this.state.formErrors.emails : ''}</div>
                                    </div>


                                    <div className="input-box">
                                        <label>Content</label>
                                        <textarea 
                                            className="form-style"
                                            name = "emailContent" 
                                            onChange={this.handleChange}
                                            value = {this.state.emailContent}
                                            rows="12"
                                            >
                                            </textarea>
                                            <div className="msg-error">{(this.state.formErrors.emailContent) ? this.state.formErrors.emailContent : ''}</div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.close} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>Send</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.addPackagesActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default SendEmail;