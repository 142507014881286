import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import WebsiteSettingsList from '../../components/admin/website-settings/website-settings-list'
import AddSettings from '../../components/admin/website-settings/website-settings-add';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader'
import axios from 'axios';
var config = require('../../config.js');
class WebsiteSettings extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addSettingsActive:false,
            loadingData:true,
            search_text:'',
            websiteSettingsList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getWebsiteSettings();
    }

    getWebsiteSettings = async () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }

        const url = config.master_api_url+'website-settings/list'
        // const url = 'http://127.0.0.1:8081/master/website-settings/list'

        axios({
            method: 'post',
            url: url,
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
                _this1.setState({websiteSettingsList:resp.data.result});
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            //console.log(error);
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    handleCreateSettings = () => {
        if(this.state.addSettingsActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addSettingsActive: false})
            },620);
        } else {
            this.setState({addSettingsActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddSettings = () => {
        var editData = {}
        this.setState({editData});
        this.handleCreateSettings();
    }

    settingsAdded = (settings) => {
        this.state.websiteSettingsList.push(settings);
        this.handleCreateSettings();
    }

    settingsEdit = (settings) => {
        this.setState({editData: settings});
        this.handleCreateSettings();
    }

    settingsEdited = (settings) => {
        const state = this.state.websiteSettingsList;
        const index = state.findIndex(row => row.id === settings.id);
        state[index] = settings;
        this.setState({websiteSettingsList: state});
        this.handleCreateSettings();
    }

    settingsDeleted = (settings) => {
        var state = this.state.websiteSettingsList;
        const index = state.findIndex(row => row.id === settings.id);
        if (index === -1) {
            state = [];
        }
        state = [...state.slice(0, index), ...state.slice(index + 1)];
        this.setState({websiteSettingsList: state});
    }

    render(){

    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Website Settings</h2></div></div></div>
        <div className="container-area">
            {
                this.state.loadingData ? <Loader /> : ''
            }
            <div className="inv-body">
                <div className="box">
                    {/*<div className="box-title">
                        <div className="box-filter-left flex align-items-center">
                            <div className="sort-box box-search flex-1">
                                <div className="filter-search-box">
                                    <i className="icon-search"></i>
                                    <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search members" />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end">
                            <button onClick={this.handleCreateSettings} className="btn-main btn-lg">Create New Settings</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <WebsiteSettingsList state={this.state} settingsEdit={this.settingsEdit} />
                    </div>
                    {
                        this.state.addSettingsActive ? (
                            <AddSettings state={this.state} cancelAddSettings={this.cancelAddSettings} settingsAdded={this.settingsAdded} settingsEdited={this.settingsEdited} />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default WebsiteSettings;