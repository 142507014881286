import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import BooksList from '../../../components/admin/books/book-list'
import AddBook from '../../../components/admin/books/book-add';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader'
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');
const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'
class Books extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addBookActive: false,
            showLoader: false,
            showLoading: false,
            search_text:'',
            booksList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox'){
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else{
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getBooksList();
    }

    getBooksList = () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text){
            data['search_text'] = _this1.state.search_text;
        }

        var url = masterApiUrl+'books/list'

        this.setState({showLoader: true, showLoading: true})

        let apiResp = callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                this.setState({showLoader: false, showLoading: false})
            }

            if(resp.result){
                _this1.setState({booksList: resp.result});
            }

        })
        .catch((error) => {
            console.log('books-list-error=',error)
            this.setState({showLoader: false, showLoading: false})
        });

    }

    handleDelete = (bookData) => {
        if(bookData && bookData.id > 0){

            var conf = window.confirm("Are you really want to delete this record?")

            if(conf){
                
                var _this1 = this;
                
                var data = {};

                var url = masterApiUrl+'books/delete/'+bookData.id

                this.setState({showLoader: true})

                let apiResp = callByJsonData(data, url, 'POST')
                .then((resp) => {

                    if(resp){
                        this.setState({showLoader: false})
                    }

                    if(resp.success){
                        _this1.bookSaved(bookData, 'delete')
                    }

                })
                .catch((error) => {
                    console.log('book-delete-error=',error)
                    this.setState({showLoader: false})
                });

            }

        }

    }

    handleCreateBook = () => {
        if(this.state.addBookActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addBookActive: false})
            },620);
        } else {
            this.setState({addBookActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddBook = () => {
        this.setState({editData: {}, addBookActive: false})
    }

    bookSaved = (bookData, saveType='') => {
        this.getBooksList()
        this.cancelAddBook()
        /*if(bookData && bookData.id){
            let booksList = this.state.booksList

            let bookIndx = booksList.findIndex((l) => l.id === bookData.id)

            if(saveType === 'delete'){
                if(bookIndx >= 0){
                    booksList.splice(bookIndx, 1)
                }
            }
            else{

                if(bookIndx >= 0){
                    booksList[bookIndx] = bookData
                }
                else{
                    booksList.unshift(bookData)
                }

            }

            this.setState({booksList})
            this.cancelAddBook()
        }*/
    }

    handleEdit = (book) => {
        this.setState({editData: book});
        this.handleCreateBook();
    }

    render(){

        return (
        <div className="page-main-holder">
            <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Books</h2></div></div></div>
            <div className="container-area">
                {
                    this.state.showLoader ? <Loader /> : ''
                }
                <div className="inv-body">
                    <div className="box">
                        {/*<div className="box-title">
                            <div className="box-filter-left flex align-items-center">
                                <div className="sort-box box-search flex-1">
                                    <div className="filter-search-box">
                                        <i className="icon-search"></i>
                                        <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search coach" />
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        <div className="box-title">
                            <div className="section-btn-outer align-self-end">
                                <button onClick={this.handleCreateBook} className="btn-main btn-lg">Create New Book</button>
                            </div>
                        </div>

                        <div className="box-body">
                            <BooksList
                            showLoading={this.state.showLoading}
                            booksList={this.state.booksList}
                            handleEdit={this.handleEdit}
                            handleDelete={this.handleDelete}
                            />
                        </div>
                        {
                            (this.state.addBookActive === true) && (
                                <AddBook
                                bookData={this.state.editData}
                                cancelAddBook={this.cancelAddBook}
                                bookSaved={this.bookSaved}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default Books;