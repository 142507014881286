import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../../components/loader'
import Alert from '../../../components/alert'
import { callByJsonData, callByFormData } from '../../../ajax-call.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'


const statusTypesArr = [
{label: 'Yes', value: 'yes'},
{label: 'No', value: 'no'},
]

class PackagesItems extends Component{
    constructor(props) {
        super(props);

        var packageId = (props.match.params.packageId)?props.match.params.packageId:0

        this.state = {
            packageId: packageId,
            packageData: {},
            cmsPagesList: {},
            packageItemsList:{},
            showLoader: false,
            msgSuccess: '',
            msgError: '',
        };
    }

    componentDidMount(){
        // this.getPackage();
        this.getCmsPagesList();
        // this.getPackageItems();
    }

    getPackage = async () => {
        var _this1 = this;
        var data = {};

        let url = masterApiUrl+'packages/get/'+this.state.packageId

        callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                _this1.setState({showLoader: false});
            }
            
            if(resp.result){
                _this1.setState({packageData: resp.result});
            }
            
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });
    }

    getCmsPagesList = async () => {
        var _this1 = this;
        var data = {};
        
        let url = masterApiUrl+'cms/templates_list'
        // let url = 'http://127.0.0.1:8081/master/cms/templates_list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){

                let cmsPagesList = resp.result

                if(cmsPagesList && cmsPagesList.length > 0){
                    cmsPagesList.sort((a, b) => {
                        if(a.sort_order > b.sort_order){
                            return 1
                        }
                        return -1
                    })
                }

                _this1.setState({cmsPagesList: cmsPagesList});
            }
            _this1.setState({showLoader: false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });

    }

    getPackageItems = async () => {
        var _this1 = this;
        var data = {};
        //data['packages_id'] = this.state.packageData.id;

        let url = masterApiUrl+'packages/items_list/'+this.state.packageId

        this.setState({showLoader: true})

        callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                _this1.setState({showLoader: false});
            }
            
            if(resp.result){
                let packageItemsList = resp.result

                _this1.setState({packageItemsList});
            }
            
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });
    }

    handleFormSubmit = (e) => {

        e.preventDefault()

        // console.log('handleFormSubmit-e=',e)
        // console.log('handleFormSubmit-e.target.elements=',e.target.elements['status[]'])

        let pagesEl = e.target.elements['pages[]']

        let pagesItems = {}

        // console.log('statusEl=', statusEl.value)

        for(var el in pagesEl){

            if(pagesEl[el] && pagesEl[el].value){
                // console.log('pagesEl[el].value=', pagesEl[el].value)
                
                let cms_page_id = pagesEl[el].value

                // if(e.target.elements['status['+cms_page_id+']']){
                //     console.log('status=',e.target.elements['status['+cms_page_id+']'])
                // }

                let cms_page_slug = (e.target.elements['slugs['+cms_page_id+']'])?e.target.elements['slugs['+cms_page_id+']'].value:''
                let module_status = (e.target.elements['module_status['+cms_page_id+']'])?e.target.elements['module_status['+cms_page_id+']'].checked:false
                let description = (e.target.elements['description['+cms_page_id+']'])?e.target.elements['description['+cms_page_id+']'].value:''
                let short_description = (e.target.elements['short_description['+cms_page_id+']'])?e.target.elements['short_description['+cms_page_id+']'].value:''

                if(cms_page_slug && cms_page_slug.length > 0){

                    let itemObj = {}
                    itemObj['cms_page_id'] = cms_page_id
                    itemObj['cms_page_slug'] = cms_page_slug
                    itemObj['module_status'] = module_status
                    itemObj['description'] = description
                    itemObj['short_description'] = short_description

                    pagesItems[cms_page_id] = itemObj

                    // pagesItems.push(itemObj)

                }
            }
        }

        // console.log('pagesItems=', pagesItems)

        // return false

        const data = pagesItems

        let url = masterApiUrl+'cms/save_templates'


        this.setState({showLoader: true})


        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp){
                this.setState({showLoader: false})
            }

            if(resp.success){
                this.setState({msgSuccess: 'Modules have been updated.'})
            }
            else if(resp.message){
                this.setState({msgError: resp.message})
            }
            else{
                this.setState({msgSuccess: 'Something went wrong, please try again.'})
            }
            
        })
        .catch((error) => {
            console.log('save-package-items-error=',error);
            this.setState({showLoader: false})
        });

    }

    getItemDetails = (cmsPageData) => {
        let packageItemsList = this.state.packageItemsList

        let itemDetails = {}

        if(packageItemsList && packageItemsList.length > 0){
            itemDetails = packageItemsList.find((row) => row.cms_page_slug === cmsPageData.slug)
        }

        return itemDetails

    }

    render(){
        
        var cmsPagesList = this.state.cmsPagesList;
        

        return(

            <div className="page-main-holder">
                
                <div className="main-top top-full">
                    <div className="page-title">
                        <div className="page-title-box">
                            <h2>Manage Modules</h2>
                        </div>
                    </div>
                </div>
                    
                    <div className="container-area">
                        
                        {
                            this.state.showLoader ? <Loader /> : ''
                        }
                        
                        {
                          this.state.msgSuccess ? (<Alert message={this.state.msgSuccess} type='success' />) : ''
                        }
                        {
                          this.state.msgError ? (<Alert message={this.state.msgError} type='error' />) : ''
                        }
                        

                        <div className="inv-body">

                            <div className="box">

                                <div className="box-body">
                                    <div className="table-box">

                                        <form onSubmit={this.handleFormSubmit}>
                                    
                                            <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                                                    <tbody>
                                                    <tr>
                                                        <th width="30%">Features</th>
                                                        <th width="10%">Status</th>
                                                        <th width="25%">Description</th>
                                                        <th width="25%">Short Description (Tooltip)</th>
                                                    </tr>
                                                    
                                                    {(cmsPagesList.length > 0) ? (

                                                        
                                                        
                                                        <ChildPageTree
                                                            level={0}
                                                            pages={cmsPagesList}
                                                            />
                                                        
                                                        ):null
                                                    }
                                                </tbody>
                                            </table>

                                        


                                        {
                                            (cmsPagesList && cmsPagesList.length > 0) && (

                                                <div className="table-box">

                                                    {/*<Link to={`/admin/packages`} className="btn-main btn-outline btn-form btn-cancel" >Cancel</Link>*/}
                                            
                                                    {/*<button type="button" onClick={this.props.cancelAddPackagesFeatures} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>*/}
                                                    {/*<button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>Save</button>*/}
                                                    <button type="submit" className="btn-main btn-form btn-submit">Save</button>
                                                </div>

                                                )
                                        }

                                        </form>
                                    


                                </div>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>







                
                    
        )
    }
}
export default PackagesItems;


export class ChildPageTree extends Component {
    render(){
        const pages = this.props.pages

        const level = this.props.level

        var levelIndent = ''

        for(var i=0; i<level; i++){
            levelIndent = levelIndent+' - '
        }

        return (

            <React.Fragment>

            {
                (pages && pages.length > 0) && (
                    pages.map((item, index) =>  {

                        var boldTitle = {'fontWeight':'600'}

                        if(item.type !== 'main'){
                            boldTitle = {}
                        }

                        return (
                            <React.Fragment key={item.id} >
                            <tr key={index} >
                                <td>
                                    {levelIndent}
                                    <span style={boldTitle} >{item.title}</span>

                                    <input type="hidden" name={`pages[]`} value={item.id}/>
                                    <input type="hidden" name={`slugs[${item.id}]`} value={item.slug}/>
                                </td>
                                
                                <td>

                                    <label className="checkbox"><input type="checkbox" name={`module_status[${item.id}]`} defaultChecked={(item.module_status && item.module_status === true) ? true : false} /> <span>Active</span></label>
                                    
                                </td>
                                
                                <td>
                                 {
                                    (!item.child_pages || item.child_pages.length === 0) && (
                                        <input type="text" name={`description[${item.id}]`} defaultValue={(item && item.description)?item.description:''} className="form-style" />
                                        )
                                }
                                    
                                </td>
                                
                                <td>
                                    {
                                        (!item.child_pages || item.child_pages.length === 0) && (
                                            <input type="text" name={`short_description[${item.id}]`} defaultValue={(item && item.short_description)?item.short_description:''} className="form-style" />
                                            )
                                    }
                                                                        
                                </td>
                            </tr>

                            {
                               (item.child_pages && item.child_pages.length > 0) && (

                                <ChildPageTree
                                level={level+1}
                                pages={item.child_pages}
                                />
                                )
                            }

                            </React.Fragment>
                        )
                        })
                    
                    )
                }


                </React.Fragment>

            )
    }
}