import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get_users_group } from '../../../utils.js';
class UsersList extends Component{

    usersEdit = (users) => {
        this.props.usersEdit(users);
    }

    render(){
        const usersList = this.props.state.usersList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Name/Email</th>
                                <th>Group</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            {usersList.map((item, index) => (
                            <tr key={index} >
                                <td width="30%">

                                    <div className="user-with-thumb flex align-items-center">
                                        <div className="user-thumb thumb-lg"><img src={(item.photo_url) ? item.photo_url : UserThumb} alt={item.name} /></div>
                                        <div className="user-text">
                                            <div className="user-name">
                                            {item.name}
                                            </div>
                                            <div className="member-email">{item.email}</div>
                                        </div>
                                    </div>
                                </td>
                                <td width="30%">
                                    {(item.groups_id) ? get_users_group(item.groups_id) : ''}
                                </td>
                                <td width="30%">
                                    {(item.status == true)?'Active':'In-active'}
                                </td>
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.usersEdit.bind(this, item)} >Edit</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default UsersList;