import { combineReducers } from 'redux';

import {
    SIGNUP_SET,
    SIGNUP_EMAIL_SET,
    IS_SIGNUP_EMAIL_VERIFIED,
    SIGNUP_COMPANY_NAME,
    SIGNUP_WORKSPACE,
} from './../actions/signupAction';

export default (state = null, action) => {
    /*console.log('action-signupReducer=');
    console.log(action);*/
  switch (action.type) {
    case SIGNUP_SET:
      return action.signup
    default:
      return state
  }
}

/*export function email(state = null, action) {
    switch (action.type) {
    case SIGNUP_EMAIL_SET:
        return action.email;
    default:
        return (state && state.signup_email)?state.signup_email:null;
    }
};

export function emailVerified(state = null, action) {
    switch (action.type) {
    case IS_SIGNUP_EMAIL_VERIFIED:
        return action.bool;
    default:
        return (state && state.email_verified)?state.email_verified:null;
    }
};


export function companyName(state = null, action) {
    switch (action.type) {
    case SIGNUP_COMPANY_NAME:
        return action.value;
    default:
        return (state && state.company_name)?state.company_name:null;
    }
};

export function workspace(state = null, action) {
    switch (action.type) {
    case SIGNUP_WORKSPACE:
        return action.value;
    default:
        return state;
    }
};

// Exports
export default combineReducers({
    email,
    emailVerified,
    companyName,
    workspace,
});*/
