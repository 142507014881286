import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { dateTimeToDisplay } from '../../../utils.js';
class MentorList extends Component{

    handleView = (mentor) => {
        this.props.handleView(mentor);
    }

    handleDelete = (mentor) => {
        this.props.handleDelete(mentor);
    }

    render(){
        const mentorList = this.props.mentorList;
        console.log('mentorList',mentorList)
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th></th>
                            </tr>

                            {
                                (mentorList && mentorList.length > 0) ? (

                                    mentorList.map((mentor, index) => (
                            <tr key={index} >
                                <td width="30%">
                                    <div className="user-text">
                                        <div className="user-name">
                                        {mentor.name}
                                        </div>
                                    </div>
                                </td>
                                <td width="30%">
                                    <div className="user-text">
                                        <div className="user-name">
                                        {mentor.email}
                                        </div>
                                    </div>
                                </td>

                                <td width="30%">
                                    <div className="user-text">
                                        <div className="user-name">
                                        {dateTimeToDisplay(mentor.created_at)}
                                        </div>
                                    </div>
                                </td>

                                <td width="30%">
                                    <div className="user-text">
                                        <div className="user-name">
                                        {(mentor.status===0) ? 'Pending' : (mentor.status==1)?'Approved':'Rejected'}
                                        </div>
                                    </div>
                                </td>

                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><button onClick={() => this.handleView(mentor)} className="button-like-link" >View</button></li>
                                                            <li><button onClick={() => this.props.handleDelete(mentor)} className="button-like-link" >Delete</button></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))

                                    ) : (


                                   
                                <tr>
                                <td colSpan="4">
                                {(this.props.showLoading) ? 'Loading...' : ('No record(s) found!')}
                                </td>
                                </tr>
                                
                               

                                    )
                            }

                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default MentorList;