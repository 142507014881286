import React, {Component} from 'react'
import {connect} from 'react-redux';

class ThemeColor extends Component{
    render(){
        let rootElm = document.getElementById('root');
        rootElm.setAttribute("class", "AppTheme_green");
        return ''
    }
}
const mapStateToProps = (state) => {
    return {
      defaultAppTheme: state.appTheme
    }
  }
export default connect(mapStateToProps)(ThemeColor)