import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import CouponsList from '../../../components/admin/coupons/coupons-list'
import AddCoupon from '../../../components/admin/coupons/coupons-add';
import Loader from '../../../components/loader'
import Alert from '../../../components/alert'
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class Coupons extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addCouponsActive:false,
            showLoader:true,
            search_text:'',
            couponsList: [],
            selectedCoupon: {},
            msgSuccess: '',
            msgError: '',
        }
    }

    componentDidMount(){
        this.getCoupons();
    }

    getCoupons = async () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        let url = masterApiUrl+'coupons/list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){

                let couponsList = resp.result

                if(couponsList && couponsList.length > 0){
                    couponsList.sort((a,b) => {
                        if(parseInt(a.sort_order) > parseInt(b.sort_order)){
                            return 1
                        }

                        return -1
                    })
                }
                _this1.setState({couponsList});
            }
            _this1.setState({showLoader:false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader:false});
        });

    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    handleCreateCoupons = (activeType=true) => {
        if(this.state.addCouponsActive === true || activeType === false) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addCouponsActive: false})
            },620);
        } else {
            this.setState({addCouponsActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }



    cancelAddCoupons = () => {
        var selectedCoupon = {}
        this.setState({selectedCoupon});
        this.handleCreateCoupons();
    }

    onCouponsaved = (couponData, saveType='') => {
        let alertMsg = ''
        if(saveType === 'add'){
            let couponsList = this.state.couponsList;
            couponsList.push(couponData);
            this.setState({couponsList});
            alertMsg = 'Coupon has been added successfully.'
        }
        else if(saveType === 'update'){
            const couponsList = this.state.couponsList;
            const index = couponsList.findIndex(row => row.id === couponData.id);
            if(index >= 0){
                couponsList[index] = couponData;
                this.setState({couponsList});
            }
            alertMsg = 'Coupon has been updated successfully.'            
        }

        if(alertMsg && alertMsg.length > 0){
            this.setState({msgSuccess: alertMsg})
        }
        
        this.handleCreateCoupons(false);
    }

    onEditCoupon = (selectedCoupon) => {
        this.setState({selectedCoupon: selectedCoupon});
        this.handleCreateCoupons();
    }

    handleDelete = (couponData) => {
        if(couponData && couponData.id > 0){

            var conf = window.confirm("Are you really want to delete this record?")

            if(conf){
                
                var _this1 = this;
                
                var data = {};

                var url = masterApiUrl+'coupons/delete/'+couponData.id

                this.setState({showLoader: true})

                let apiResp = callByJsonData(data, url, 'POST')
                .then((resp) => {

                    if(resp){
                        this.setState({showLoader: false})
                    }

                    if(resp.success){
                        _this1.onCouponsaved(couponData, 'delete')
                    }

                })
                .catch((error) => {
                    console.log('coupon-delete-error=',error)
                    this.setState({showLoader: false})
                });

            }

        }

    }

    render(){

    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Coupons</h2></div></div></div>
        <div className="container-area">
            
            {
                this.state.showLoader ? <Loader /> : ''
            }
            {
              this.state.msgSuccess ? (<Alert message={this.state.msgSuccess} type='success' />) : ''
            }
            {
              this.state.msgError ? (<Alert message={this.state.msgError} type='error' />) : ''
            }
            
            <div className="inv-body">
                <div className="box">
                    {/*<div className="box-title">
                        <div className="box-filter-left flex align-items-center">
                            <div className="sort-box box-search flex-1">
                                <div className="filter-search-box">
                                    <i className="icon-search"></i>
                                    <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search coupons" />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end flex justify-content-end">
                            <button onClick={this.handleCreateCoupons} className="btn-main btn-lg">Create New Coupon</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <CouponsList
                        couponsList={this.state.couponsList}
                        onEditCoupon={this.onEditCoupon}
                        />
                    </div>
                    {
                        this.state.addCouponsActive ? (
                            <AddCoupon
                            couponData={this.state.selectedCoupon}
                            close={this.cancelAddCoupons}
                            onSaved={this.onCouponsaved}
                            addCouponsActive={this.state.addCouponsActive}
                            />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default Coupons;