import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import LeadershipList from '../../../components/admin/leadership/leadership-list'
import AddLeadership from '../../../components/admin/leadership/leadership-add';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader'
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');
const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'
class Leadership extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addLeadershipActive: false,
            showLoader: false,
            showLoading: false,
            search_text:'',
            leadershipList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox'){
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else{
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getLeadershipList();
    }

    getLeadershipList = () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text){
            data['search_text'] = _this1.state.search_text;
        }

        var url = masterApiUrl+'leadership/list'

        this.setState({showLoader: true, showLoading: true})

        let apiResp = callByJsonData(data, url, 'POST')
        .then((resp) => {

            if(resp){
                this.setState({showLoader: false, showLoading: false})
            }

            if(resp.result){
                _this1.setState({leadershipList: resp.result});
            }

        })
        .catch((error) => {
            console.log('leadership-list-error=',error)
            this.setState({showLoader: false, showLoading: false})
        });

    }

    handleDelete = (leadershipData) => {
        if(leadershipData && leadershipData.id > 0){

            var conf = window.confirm("Are you really want to delete this record?")

            if(conf){
                
                var _this1 = this;
                
                var data = {};

                var url = masterApiUrl+'leadership/delete/'+leadershipData.id

                this.setState({showLoader: true})

                let apiResp = callByJsonData(data, url, 'POST')
                .then((resp) => {

                    if(resp){
                        this.setState({showLoader: false})
                    }

                    if(resp.success){
                        _this1.leadershipSaved(leadershipData, 'delete')
                    }

                })
                .catch((error) => {
                    console.log('leadership-delete-error=',error)
                    this.setState({showLoader: false})
                });

            }

        }

    }

    handleCreateLeadership = () => {
        if(this.state.addLeadershipActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addLeadershipActive: false})
            },620);
        } else {
            this.setState({addLeadershipActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddLeadership = () => {
        this.setState({editData: {}, addLeadershipActive: false})
    }

    leadershipSaved = (leadershipData, saveType='') => {
        if(leadershipData && leadershipData.id){
            let leadershipList = this.state.leadershipList

            let leadIndx = leadershipList.findIndex((l) => l.id === leadershipData.id)

            if(saveType === 'delete'){
                if(leadIndx >= 0){
                    leadershipList.splice(leadIndx, 1)
                }
            }
            else{

                if(leadIndx >= 0){
                    leadershipList[leadIndx] = leadershipData
                }
                else{
                    leadershipList.unshift(leadershipData)
                }

            }

            this.setState({leadershipList})
            this.cancelAddLeadership()
        }
    }

    handleEdit = (leadership) => {
        this.setState({editData: leadership});
        this.handleCreateLeadership();
    }

    render(){

        return (
        <div className="page-main-holder">
            <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Resources</h2></div></div></div>
            <div className="container-area">
                {
                    this.state.showLoader ? <Loader /> : ''
                }
                <div className="inv-body">
                    <div className="box">
                        {/*<div className="box-title">
                            <div className="box-filter-left flex align-items-center">
                                <div className="sort-box box-search flex-1">
                                    <div className="filter-search-box">
                                        <i className="icon-search"></i>
                                        <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search coach" />
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                        <div className="box-title">
                            <div className="section-btn-outer align-self-end">
                                <button onClick={this.handleCreateLeadership} className="btn-main btn-lg">Create New Resource</button>
                            </div>
                        </div>

                        <div className="box-body">
                            <LeadershipList
                            showLoading={this.state.showLoading}
                            leadershipList={this.state.leadershipList}
                            handleEdit={this.handleEdit}
                            handleDelete={this.handleDelete}
                            />
                        </div>
                        {
                            (this.state.addLeadershipActive === true) && (
                                <AddLeadership
                                leadershipData={this.state.editData}
                                cancelAddLeadership={this.cancelAddLeadership}
                                leadershipSaved={this.leadershipSaved}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
export default Leadership;