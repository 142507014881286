import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoMain from '../../logo-color.png';
import Navbar from '../front/Navbar'
import Footer from '../front/Footer'
import Loader from '../loader'

import $ from 'jquery';

var config = require('../../config.js');

const api_url = config.api_url;

//console.log(signup_details);

class CompanyName extends Component {

    constructor(props) {
        super(props);

        //console.log(props);

        /*if(props.signup && props.signup.emailVerified && props.signup.emailVerified !== '' && props.signup.emailVerified !== null ){
            localStorage.setItem('email_verified', props.signup.emailVerified);
        }*/

        // var signupDetails = (props.state.signupDetails)?props.state.signupDetails:{}

        // var signup_email = (signupDetails.email)?signupDetails.email:''

        // localStorage.setItem('signupDetails', signupDetails);

        var signupDetails = (props.state.signupDetails)?props.state.signupDetails:{}

        // var signupDetails = (localStorage.getItem('signupDetails'))?localStorage.getItem('signupDetails'):{}

        var email_verified = (signupDetails.emailVerified)?signupDetails.emailVerified:false

        // console.log('CompanyName-signupDetails')
        // console.log(signupDetails)

        this.state = {
          showLoader:false,
          signupDetails: signupDetails,
          email_verified: email_verified,
          email: (signupDetails.email) ? signupDetails.email : '',
          is_company_name_real:true,
          company_name:'',
          workspace:'',
          err_msg:'',
        };

        this.handleCheck = this.handleCheck.bind(this);
        this.handleCompanyName = this.handleCompanyName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        if(!this.state.email_verified){
            window.location.hash = '#confirmemail';
        }
    }

    handleCheck(value){
        this.setState({is_company_name_real:value});
        this.setState({company_name:this.state.company_name});

        //console.log(document.form.company_name.value);
    }

    handleCompanyName(event){
        //var name = event.target.name;
        this.setState({company_name:event.target.value});

        var workspace = this.slugify(event.target.value);
        this.setState({workspace:workspace});
        //console.log(workspace);
    }


    handleSubmit(event){
      event.preventDefault();
      //return false;
      var _this1 = this;

      var err_msg = [];

      var isValidated = true;

      if(this.state.company_name === '' || this.state.company_name === undefined){
        err_msg.push('Please enter Company name.');
        isValidated = false;
      }

      if(!isValidated){
        //console.log(err_msg);
        //console.log(err_msg);
        err_msg = err_msg.join("|");
        //console.log(err_msg);
        _this1.setState({err_msg});

        //return true;
      }
      else{
        _this1.setState({err_msg});

        var signupDetails = this.state.signupDetails

        var data = {};

        data['email'] = _this1.state.email;
        data['is_company_name_real'] = _this1.state.is_company_name_real;
        data['company_name'] = _this1.state.company_name;
        data['workspace'] = _this1.state.workspace;

        signupDetails['is_company_name_real'] = _this1.state.is_company_name_real
        signupDetails['company_name'] = _this1.state.company_name

        /*_this1.props.onSetCompanyName(_this1.state.company_name);
        _this1.props.onSetWorkspace(_this1.state.workspace);*/

        /*window.location.hash = '#details';
        return false;*/

        //console.log(data); return false;

        var _this2 = _this1;

        const url = api_url+'signup/companyname';
        // const url = 'http://127.0.0.1:8081/signup/companyname';


        $.ajax({
          type: 'POST',
          url: url,
          contentType: 'application/json',
          data: JSON.stringify(data),
          dataType: 'json',
          beforeSend: function(){
            _this1.setState({showLoader: true});
          },
          success: function(resp) {

            if(resp){
              _this1.setState({showLoader: false});
            }

            // var signupData = {};
            // signupData = data;
            // _this1.props.onSetSignupDetails(signupData);

            if(resp.success){
              if(resp.workspace){
                signupDetails['workspace'] = resp.workspace
                signupDetails['workspace_url'] = (resp.workspace_url)?resp.workspace_url:''
                _this1.props.updateSignupDetails(signupDetails);
              }
              window.location.hash = '#details';
            }
            else if(resp.error){
              _this1.setState({err_msg:resp.error});
            }
          }
        }).catch(function(error){
          console.log(error);
          _this1.setState({showLoader: false});
        });

      }   
    }


    slugify(string) {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      const p = new RegExp(a.split('').join('|'), 'g')

      return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
    }

  render() {
    return (
      <React.Fragment>
        <Navbar fixed hideNav />
    <div className="fp-main-full-inner flex align-items-center justify-content-center container">
      <div className="singup-box2">
        <div className="login-box-inner">
              
            <div className="form-group-box">
                <div className="user-form-box login-form-main">

                {(this.state.showLoader) ? <Loader /> : null}

                {(this.state.err_msg) ? (
                      <div className="msg-error">{this.state.err_msg}</div>
                      ) : ('')}

                    <form>
                        <div className="user-form-inner">
                            <input type="radio" name="company-type" id="company-yes" value="Yes" className="radio_action" defaultChecked />
                            <input type="radio" name="company-type" id="company-no" value="Yes" className="radio_action" />
                            <div className="is-company-name text-center justify-content-center">
                                <div className="fp-title">Is your Company Name real?</div>
                                <div className="radio-group">
                                    <label className="radio company-yes-check" htmlFor="company-yes" onClick={() => this.handleCheck(true)} >
                                        <input type="radio" value="true" /> <span>Yes</span>
                                    </label>
                                    <label className="radio company-no-check" htmlFor="company-no" onClick={() => this.handleCheck(false)} >
                                        <input type="radio" value="false" /> <span>No</span>
                                    </label>
                                </div>
                            </div>
                            <div className="company-box-outer" id="company-yes-box">
                                <div className="input-box">
                                    <div className="flex company-name-box">
                                        <div className="flex-auto company-name-box">
                                            <input type="text" name="company_name" placeholder="Enter Registerd Company Name" className="form-style" onChange={this.handleCompanyName} defaultValue={this.state.company_name} />                                        
                                        </div>
                                        <div className="company-domain-box"><div className="company-domain-text">.opengrowthhub.com</div></div>
                                    </div>
                                </div>
                                <p className="text-sm text-center">You can't modify it.</p>
                            </div>
                            <div className="company-box-outer" id="company-no-box">
                                <div className="input-box">
                                    <div className="flex company-name-box">
                                        <div className="flex-auto company-name-box">
                                            <input type="text" name="company_name" placeholder="Enter Dummy Company Name" className="form-style" onChange={this.handleCompanyName} defaultValue={this.state.company_name} />                                        
                                        </div>
                                        <div className="company-domain-box"><div className="company-domain-text">.opengrowthhub.com</div></div>
                                    </div>
                                </div>
                                <p className="text-sm text-center">You can change it anytime later.</p>
                            </div>
                            <div className="msg-error"></div>
                            <div className="input-box mb0">
                                <button type="button" className="btn-main btn-form btn-submit btn-full" onClick={this.handleSubmit}>Continue</button>
                                {/*<Link to="/invite-people" className="btn-main btn-form btn-submit btn-full">Continue</Link>*/}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </div>
    </div>
    <Footer hideNav />
      </React.Fragment> 
    );
  }
}

export default CompanyName;
