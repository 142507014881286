import React, {Component} from 'react'
import logoMain from '../logo-color.png'
import SetupLoadingImg from '../images/setup-loading.gif'

class SetupProcess extends Component{

    constructor(props) {
        super(props);
    }

    /*componentDidMount(){
        setTimeout(() => {
            this.props.history.push('dashboard');
        },5000)
    }*/

    render(){
        return(
            <div className="setup-full-page">
                <div className="setup-box-main flex align-items-center">
                    <div className="setup-inner">
                        <div className="setup-logo"><img src={logoMain} alt="OpenGrowth Hub" /></div>
                        <div className="setup-heading">One moment, your site is starting up</div>
                        <div className="setup-loading" style={{"text-align": "center"}}>
                            <div className="setup-loading-img"></div>
                        </div>
                        <div className="setup-loading-bottom">
                            Thanks for signing up! Our robots are working on your OpenGrowth Hub workspace. <br />
                            This won't take more than a minute or two. You'll be taken there once it's ready.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SetupProcess