import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Loader from './../../loader'
import AlertClosable from '../../../components/alert-closable'
import { callByJsonData } from '../../../ajax-call.js';
var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'


export const billingTypesArr = [
{ value: 'monthly', label: 'Monthly' },
{ value: 'annualy', label: 'Annualy' }
]

export function getBillingTypeLabel(value){
    let billingLabel = ''
    if(value){
        // console.log('getBillingTypeLabel(value)=', value)
        for(var i in billingTypesArr){
            let billingType = billingTypesArr[i]
            
            if(billingType['value'] === value){
                billingLabel = billingType['label']
                break
            }
        }
    }

    return billingLabel
}

class AddOrders extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'id':'',
            'name':'',
            'monthly_price':'',
            'annual_price':'',
            'status':true,
        };

        let orderData = (props.orderData && props.orderData.id) ? props.orderData : {}

        let customEmails = (orderData['custom_emails']) ? orderData['custom_emails'] : ''

        if(customEmails && customEmails.length > 0){
            customEmails = JSON.parse(customEmails)
        }

        // console.log('customEmails=', JSON.parse(customEmails))

        let selectedCustomEmails = []

        if(customEmails && customEmails.length > 0 && Array.isArray(customEmails)){
            customEmails.map((email) => {
                selectedCustomEmails.push({label: email, value: email})
            })
        }


        this.state = {
            orderData: (props.orderData && props.orderData.id) ? props.orderData : newData,
            selectedCustomEmails: selectedCustomEmails,
            errors: {},
            showLoader: false,
            msgSuccClosable: false,
            msgErrClosable: false,
        };
        // console.log('props.state');
        // console.log(props.state);
    }        

    handleChange = (event) => {
        var orderData = this.state.orderData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox'){
            if(name === 'billing_cycle'){
                orderData[name] = event.target.value
            }
            else{
                orderData[name] = (event.target.checked) ? true : false
            }
            
        }
        else{
            orderData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('orderData')
        // console.log(orderData)
        this.setState({orderData});
    }

    handleCustomEmails = (selectedCustomEmails) => {
        this.setState({selectedCustomEmails});
    }

    handleSubmit = async () => {
        var _this1 = this;
        if(this.state.orderData && this.state.orderData.username !== '' && this.state.orderData.name !== '' && this.state.orderData.email !== '')
        {            
            // const data = new FormData();
            let data = this.state.orderData;
            let selectedCustomEmails = this.state.selectedCustomEmails;

            let custom_emails = []

            if(selectedCustomEmails && selectedCustomEmails.length > 0){
                selectedCustomEmails.map(r => {
                    custom_emails.push(r.value)
                })
            }

            custom_emails = JSON.stringify(custom_emails)

            data['custom_emails'] = custom_emails

            
            var url = masterApiUrl+'orders/add';
            
            if(_this1.state.orderData && _this1.state.orderData.id !== ''){
                url = masterApiUrl+'orders/update/'+_this1.state.orderData.id;
            }

            this.setState({showLoader: true})

            callByJsonData(data, url, 'POST')
            .then((resp) => {
                if(resp){
                    this.setState({showLoader: false})
                }
                if(resp.success){
                    let saveType = 'add'
                    if(_this1.state.orderData && _this1.state.orderData.id !== ''){
                        saveType = 'update'
                    }

                    _this1.props.onSaved(resp.record, saveType)
                }
                else if(resp.message){
                    this.setState({msgErrClosable: resp.message})
                }
                else{
                    this.setState({msgErrClosable: 'Something went wrong, please try again.'})
                }
            })
            .catch((error) => {
                console.log('save-order-error=', error)
                this.setState({msgErrClosable: 'Something went wrong, please try again.'})
                this.setState({showLoader: false})
            });
        

        } else {
            var errors = {}
            if(_this1.state.orderData.name === ''){
                errors['name'] = 'Please enter name';
            }
            if(_this1.state.orderData.price === ''){
                errors['price'] = 'Please enter price'
            }
            this.setState({errors})
        }        
    }

    closeAlert = () => {
        this.setState({msgSuccClosable: false, msgErrClosable: false})
    }

    render(){

        const orderData = this.state.orderData

        const billingCycle = (orderData.billing_cycle) ? orderData.billing_cycle : 'monthly'

        return(
            <React.Fragment>
                <div className={"form-panel active show" + " " + this.state.formScrollable}>
            
                {
                    this.state.showLoader ? <Loader /> : ''
                }

                {
                    this.state.msgErrClosable ? (<AlertClosable message={this.state.msgErrClosable} type='error'  onClose={this.closeAlert} />) : ''
                }
                    
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(orderData && orderData.id) ? 'Update': 'Add'} Orders</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <label>Name</label>
                                        <input 
                                            className="form-style"
                                            name = "name" 
                                            onChange={this.handleChange} 
                                            placeholder="Enter name"
                                            type="text"
                                            value = {(orderData && orderData.name) ? orderData.name : '' }
                                            />
                                            <div className="msg-error">{(this.state.errors.name) ? this.state.errors.name : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Monthly Price</label>
                                        <input 
                                            type="number"
                                            name = "monthly_price" 
                                            value = {(orderData && orderData.monthly_price) ? orderData.monthly_price : '' }
                                            onChange={this.handleChange}
                                            className="form-style"
                                            />
                                            <div className="msg-error">{(this.state.errors.monthly_price) ? this.state.errors.monthly_price : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Annual Price</label>
                                        <input 
                                            type="number"
                                            name = "annual_price" 
                                            value = {(orderData && orderData.annual_price) ? orderData.annual_price : '' }
                                            onChange={this.handleChange}
                                            className="form-style"
                                            />
                                            <div className="msg-error">{(this.state.errors.annual_price) ? this.state.errors.annual_price : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>Sort Order</label>
                                        <input 
                                            type="number"
                                            name = "sort_order" 
                                            value = {(orderData && orderData.sort_order) ? orderData.sort_order : '' }
                                            onChange={this.handleChange}
                                            className="form-style"
                                            />
                                            <div className="msg-error">{(this.state.errors.sort_order) ? this.state.errors.sort_order : ''}</div>
                                    </div>
                                    

                                    {/*<div className="input-box">
                                        <label>Billing</label>

                                        <Select 
                                        name="status"
                                        value={ billingTypesArr.find(item => item.value === billingCycle) }
                                        options={billingTypesArr}
                                        className="form-style mentor-form"
                                        placeholder="Search status"
                                        onChange={(val)=> {this.handleChange({target: { type:'select', name:'billing_cycle', value: val.value }})}}
                                        />
                                        
                                        <div className="msg-error">{(this.state.errors.billing_cycle) ? this.state.errors.billing_cycle : ''}</div>
                                    </div>*/} 
                                    

                                    <div className="input-box">
                                        <label>Custom Plan</label>
                                        <label className="checkbox"><input type="checkbox" name="is_custom" onChange={this.handleChange} checked={(orderData && orderData.is_custom === true) ? true : false} /> <span>Active</span></label>
                                    </div>

                                    {
                                        (orderData && orderData.is_custom && orderData.is_custom === true) && (
                                            <div className="input-box">
                                                <label>Emails for Custom Plan</label>
                                                <CreatableSelect
                                                name="custom_emails"
                                                value={this.state.selectedCustomEmails}
                                                onChange={this.handleCustomEmails}
                                                // options={permissionOptions}
                                                // menuIsOpen={false}
                                                // isValidNewOption={() => false}

                                                // formatCreateLabel={() => undefined}
                                                noOptionsMessage={() => null}
                                                isMulti
                                                />
                                            </div>
                                            )
                                    }
                                    

                                    <div className="input-box">
                                        <label>Call us button</label>
                                        <label className="checkbox"><input type="checkbox" name="is_call_us_btn" onChange={this.handleChange} checked={(orderData && orderData.is_call_us_btn === true) ? true : false} /> <span>Active</span></label>
                                    </div>
                                    

                                    <div className="input-box">
                                        <label>Most Popular</label>
                                        <label className="checkbox"><input type="checkbox" name="is_popular" onChange={this.handleChange} checked={(orderData && orderData.is_popular === true) ? true : false} /> <span>Active</span></label>
                                    </div>
                                    

                                    <div className="input-box">
                                        <label>Status</label>
                                        <label className="checkbox"><input type="checkbox" name="status" onChange={this.handleChange} checked={(orderData && orderData.status === true) ? true : false} /> <span>Active</span></label>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.close} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(orderData && orderData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.addOrdersActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddOrders;