import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import UserThumb from '../../../images/user1.jpg';
import { getBillingTypeLabel } from './coupons-add';
import { dateToDisplay } from '../../../utils.js';
class CouponsList extends Component{

    handleEditCoupon = (couponData) => {
        this.props.onEditCoupon(couponData);
    }

    render(){
        const couponsList = this.props.couponsList;

        // console.log('couponsList=', couponsList)


        let customPackStyle = {'fontSize': '12px', 'fontStyle': 'italic'}


        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table manage-pakage-table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th width="30%">Name</th>
                                <th width="15%">Code</th>
                                <th width="15%">Percentage</th>
                                <th width="15%">Status</th>
                                <th width="15%">Date</th>
                                <th width="10%"></th>
                            </tr>
                            {couponsList.map((item, index) => 

                                {

                                return (
                                
                                <tr key={index} >
                                    <td>
                                        {item.name}
                                    </td>
                                    <td>
                                        {item.code}
                                    </td>
                                    <td>
                                        {(item.percentage)?item.percentage+'%':''}
                                    </td>
                                    <td>
                                        {(item.status == true)?'Active':'In-active'}
                                    </td>
                                    <td>
                                        From: {dateToDisplay(item.from_date)}<br />
                                        To: {dateToDisplay(item.to_date)}
                                    </td>
                                    <td>
                                        <div className="dropdown-parent more-action-box">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <div className="dropdown">
                                                        <div className="dropdown-group">
                                                            <ul className="nav">
                                                                <li><button onClick={() => this.handleEditCoupon(item)} className="button-like-link" >Edit</button></li>
                                                                <li><button onClick={() => this.props.handleDelete(item)} className="button-like-link" >Delete</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </td>
                                </tr>
                              )}

                            )}
                        

                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default CouponsList;