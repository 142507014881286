import React, { Component } from 'react'
import CreatableSelect from 'react-select/creatable';
import { Link } from 'react-router-dom'

import Loader from './../../../components/loader';
import AddPaymentPopup from './../../../components/admin/affiliates/popup-add-payment-details';

import $ from 'jquery';

import { get_id_token, formatDate } from '../../../utils.js';

var config = require('../../../config.js');
const master_api_url = config.master_api_url;
const http_type = config.http_type;
const frontend_domain = config.frontend_domain;
const payment_modes = config.payment_modes;

class AffiliatesPayments extends Component {

	constructor(props){
		super(props);

		this.state = {
			showWaiting: false,
			showLoader: false,
			paymentList: [],
			workspaceList: [],
			searchOption: [],
		};

		this.getWorkspaceList = this.getWorkspaceList.bind(this);
		this.getPayments = this.getPayments.bind(this);
		this.handleSearchCancel = this.handleSearchCancel.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	handleOptionChange = searchOption => {
        // console.log('searchOption=');
        // console.log(searchOption);
        
        this.setState({ searchOption });
       
    };

	getWorkspaceList(){

		var _this1 = this;

			var data = {};

			data['affiliate_code'] = this.state.referralCode;

			// console.log('data=');
			// console.log(data); return false;

			const url = master_api_url+'workspace/list';
			// const url = 'http://127.0.0.1:8081/master/workspace/list';

			$.ajax({
				type: 'POST',
				url,
				data: JSON.stringify(data),
				contentType: 'application/json',
				dataType: 'json',
				beforeSend: function(){
					_this1.setState({showWaiting: true});
				},
				success: function(resp) {
					if(resp){
						_this1.setState({showWaiting: false});
					}
					if(resp.status){
						if(resp.result){
							var workspaceList = {};

							resp.result.map(function(work){
								workspaceList[work.id] = work;
							});
							_this1.setState({workspaceList});
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
				// alert(error);
				_this1.setState({showWaiting:false});
			});

	}

	getPayments(){

		var _this1 = this;

		var data = {};

		// console.log('data=');
		// console.log(data); return false;

		const url = master_api_url+'workspace/payments';
		// const url = 'http://127.0.0.1:8081/master/workspace/payments';

		$.ajax({
			type: 'POST',
			url,
			data: JSON.stringify(data),
			contentType: 'application/json',
			dataType: 'json',
			beforeSend: function(){
				_this1.setState({showWaiting: true});
			},
			success: function(resp) {
				if(resp){
					_this1.setState({showWaiting: false});
				}
				
				if(resp.payments){
					var paymentList = resp.payments;
					_this1.setState({paymentList});
					_this1.setState({paymentListAll: paymentList});
				}
			}
		}).catch(function (error) {
			console.log(error);
			// alert(error);
			_this1.setState({showWaiting:false});
		});

	}

	handleSearchCancel(){

		// console.log('this.state.searchOption=',this.state.searchOption)
		if(this.state.searchOption && this.state.searchOption.length){
			this.setState({searchOption: null});
			var paymentList = this.state.paymentListAll;
			this.setState({paymentList})
		}
	}

	handleSearch(){
		var searchOption = this.state.searchOption;
		var paymentList = this.state.paymentListAll;
		// console.log('searchOption=')
		// console.log(searchOption)

		var searchOptionArr = []

		searchOption.map(function(so){
			searchOptionArr.push(parseInt(so.value))
		})

		paymentList = paymentList.filter(function(pay){
			// console.log('searchOptionArr.includes(pay.tenant_id)=')
			// console.log(searchOptionArr.includes(pay.tenant_id))
			return searchOptionArr.includes(pay.tenant_id)
		})
		// console.log('searchOptionArr=')
		// console.log(searchOptionArr)
		// console.log('paymentList=')
		// console.log(paymentList)

		this.setState({paymentList})

	}

	componentDidMount(){
		this.getWorkspaceList();
		this.getPayments();
	}


  render() {
		const workspaceList = this.state.workspaceList;

		const workspace_arr = [];
		if(workspaceList){
			for (var key in workspaceList){
				workspace_arr.push({ value: key, label: workspaceList[key].workspace })
			}
		}

	return (
	<div className="page-main-holder">
				
		<div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Affiliates</h2></div></div></div>
		<div className="container-area">
			<div className="user-profile-container">
				{(this.state.showLoader)?<Loader />:null}


				<div className="profile-main-container">
					<div className="profile-main-inner">
						<div className="profile-inner-view">

							<form name="searchForm" id="search-form">

							{(this.state.showLoader) ? <Loader /> : null}

								<div className="input-box">
									<label>search workspace(s)</label>

										<CreatableSelect
				                        value={this.state.searchOption}
				                        onChange={this.handleOptionChange}
				                        options={workspace_arr}
				                        isMulti
				                        />
								</div>

								<div className="form-bottom text-right">

								{
									(this.state.searchOption && this.state.searchOption.length) ? (

										<React.Fragment>
										<button type="button" className="btn-main btn-form btn-outline" onClick={this.handleSearchCancel}>Cancel</button>

										<button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSearch}>Search</button>
										</React.Fragment>
										) : (null)
								}
									    
									
								</div>

							</form>

								<div className="profile-section-top">
									<ul className="nav-tab">
										<li><Link to="/admin/affiliates">Workspaces</Link></li>
										<li className="active"><Link to="/admin/payments">Payments</Link></li>
									</ul>
								</div>

							<div className="box">
								<div className="box-title"><h4>Workspace Payments</h4></div>
								<div className="box-body">

									<div className="table-box">
										<table className="table" cellSpacing="0" cellPadding="0">
											<tbody>
												<tr>
													<th width="15%">Workspace Name</th>
													<th width="10%">Amount</th>
													<th width="10%">Payment Date</th>
													<th width="10%">Payment Mode</th>
													<th width="10%">Transaction ID</th>
													<th width="10%">Added Date</th>
												</tr>

												{ (this.state.paymentList.length) ? (this.state.paymentList.map((payment, idx)=>(

													<tr key={idx}>
														<td>
														{(workspaceList[payment.tenant_id]) ? (
															<a target="_blank" >{workspaceList[payment.tenant_id].workspace}</a>
														) : (
															<a target="_blank" >{payment.tenant_id}</a>
														)}
														</td>

														<td>{payment.amount}</td>

														<td>{formatDate(payment.payment_date, 'MMM DD, YYYY')}</td>
														<td>{(payment_modes[payment.payment_mode])?payment_modes[payment.payment_mode]:''}</td>
														<td>{payment.transaction_id}</td>
														<td>{formatDate(payment.created_at, 'MMM DD, YYYY')}</td>

													</tr>

													))):(

													<tr>
														<td colSpan="6">
															{(this.state.showWaiting)?'please wait, while we are fetching data...':'No record(s) found.'}
														</td>
													</tr>

												)}

											</tbody>
										</table>
									</div>

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
  }
}
export default AffiliatesPayments