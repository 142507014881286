export const SIGNUP_SET = 'SIGNUP_SET';
export const SIGNUP_EMAIL_SET = 'SIGNUP_EMAIL_SET';
export const IS_SIGNUP_EMAIL_VERIFIED = 'IS_SIGNUP_EMAIL_VERIFIED';
export const SIGNUP_COMPANY_NAME = 'SIGNUP_COMPANY_NAME';
export const SIGNUP_WORKSPACE = 'SIGNUP_WORKSPACE';

export function setSignupDetails(signup) {
	/*console.log('setUserDetails=');
	console.log(user);*/
    return { type: SIGNUP_SET, signup };
}

export function setSignupEmail(email) {
	/*console.log('setUserDetails=');
	console.log(user);*/
    return { type: SIGNUP_EMAIL_SET, email };
}

export function setIsEmailVerified(bool) {
    return { type: IS_SIGNUP_EMAIL_VERIFIED, bool };
}

export function setCompanyName(value) {
    return { type: SIGNUP_COMPANY_NAME, value };
}

export function setWorkspace(value) {
    return { type: SIGNUP_WORKSPACE, value };
}
