import React, {Component} from 'react'
import {Link} from 'react-router-dom'

class Header extends Component{
    state = {
        bgImg: this.props.bgImage ? this.props.bgImage : '',
        title: this.props.caption ? this.props.caption.title : '',
        description: this.props.caption ? this.props.caption.description : '',
        btnText: this.props.caption ? this.props.caption.btnText : '',
        btnLink: this.props.caption ? this.props.caption.btnLink : ''
    }
    render(){
        return(
            <header className="fp-header-full fp-header-home" style={{backgroundImage: 'url('+ this.props.bgImage +')'}}>
                <div className="container">
                    <div className="fp-header-caption">
                        <h1 className="fp-heading1">{this.state.title}</h1>
                        <ul className="fp-check-list">
                        {
                            this.state.description.map((item,i)=>{
                                return <li className="text-lg">{item}</li>
                            })
                        }
                        </ul>
                        {
                            (!this.state.btnText == '' && !this.state.btnLink == '') ? (
                                <div className="fp-btn-outer">
                                    <Link to={this.state.btnLink} className="fp-btn-main fp-btn-lg">{this.state.btnText}</Link>
                                </div>
                            ) : ''
                        }                        
                    </div>
                </div>
            </header>
        )
    }
}
export default Header