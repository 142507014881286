import React, { Component } from 'react';
import axios from 'axios';
import Loader from './../../../components/loader';
import CreatableSelect from 'react-select/creatable';
var config = require('../../../config.js');
class AddPermission extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'id':'',
            'name':'',
            'description':'',
        };

        var selectedActions = []
        var moduleActionPermissionsList = []
        var moduleActionList = []
        this.state = {
            showLoader: false,
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : newData,
        	moduleActionPermissions:[],
        	moduleActionPermissionsList:moduleActionPermissionsList,
        	moduleActionList:moduleActionList,
            errors: {},
            selectedActions: selectedActions,
        };
        this.handlePermission = this.handlePermission.bind(this);

    }        

    componentDidMount(){
        this.getModuleActions(this.state.name);
        this.getPermission(this.state.id);
    }

    getModuleActions = async (name) => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        data['name'] = name;
        axios({
            method: 'post',
            url: config.master_api_url+'module-actions/list',
            // url: 'http://127.0.0.1:8081/master/module-actions/list',
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
                _this1.setState({moduleActionPermissions:resp.data.result});
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    getPermission = async (id) => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        data['id'] = _this1.state.editData.id;
        axios({
            method: 'post',
            url: config.master_api_url+'permission/list',
            // url: 'http://127.0.0.1:8081/master/permission/list',
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
            	// console.log(resp.data.result);
                _this1.setState({moduleActionPermissionsList:resp.data.result});
                var selectedActions = []
                resp.data.result.map((permission) => {
                	selectedActions.push({value: permission.module_action_id, label: permission.module_action_name, slug:permission.slug})
                })
                _this1.setState({selectedActions})
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    handleActions = (actions) => {
    	this.setState({
    		selectedActions: actions
    	})
    }

    handlePermission = async () => {
        var _this1 = this;
        if(_this1.state.editData  && _this1.state.selectedActions.length > 0)
        {
            var dataPermission = JSON.stringify({"modules":_this1.state.editData, "moduleActions":_this1.state.selectedActions});
            console.log(dataPermission);
            var url = config.master_api_url+'permission/add';
            // var url = 'http://127.0.0.1:8081/master/permission/add';
            axios({
                method: 'post',
                url: url,
                data: dataPermission,
                headers:{'content-type': 'application/json'}
            }).then(function (response) {
                if(response.data.status === 'success')
                {                    
                    alert("Added Permissions");                  
                }
                else if(response.data.message)
                {	
                	console.log("Permission Not Updated")
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
                _this1.setState({showLoader: false});
            }).catch(function (error) {
            	console.log("error");
                alert(error);
                _this1.setState({showLoader: false});
            });
        } else {
            var errors = {}
            if(_this1.state.selectedActions1.length === 0){
                errors['name'] = 'Please Selected Actions';
            }
            this.setState({errors})
        }
    }


    render(){
	    var permissionOptions = []

        if(this.state.moduleActionPermissions && this.state.moduleActionPermissions.length > 0){
        	this.state.moduleActionPermissions.map((tg) => {
        		permissionOptions.push({value: tg.id, label: tg.name, slug:tg.slug})
        	})
        }
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        {(this.state.showLoader)? <Loader /> : null}
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> Manage Permissions</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <label>Modules Name</label>
                                        <input
                                            className="form-style"
                                            name = "name" 
                                            type="text"
                                            value = {this.state.editData.name}
                                            readOnly
                                            />
                                    </div>
									<div className="input-box">
										<label>Module Actions</label>
										<CreatableSelect
										value={this.state.selectedActions}
				                        onChange={this.handleActions}
				                        options={permissionOptions}
				                        isMulti
				                        />
                                        <div className="msg-error">{(this.state.errors.name) ? this.state.errors.name : ''}</div>
									</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddPermission} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handlePermission}>Add</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addModulesActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddPermission;