import React, { Component } from 'react';
import EmailTemplatesList from '../../components/admin/email-templates/email-templates-list';
import AddEmailTemplates from '../../components/admin/email-templates/email-templates-add';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader'
import axios from 'axios';
var config = require('../../config.js');
class EmailTemplates extends Component{

    constructor(props){
        super(props);
        this.state = {
            name:(this.props.match.params.name) ? this.props.match.params.name : '',
            showClass:'',
            activeClass:'',
            addEmailTemplatesActive:false,
            loadingData:true,
            search_text:'',
            emailTemplatesList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getEmailTemplates(this.state.name);
    }

    getEmailTemplates = async (name) => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        data['name'] = name;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        axios({
            method: 'post',
            url: config.master_api_url+'email-templates/list',
            // url: 'http://127.0.0.1:8081/master/email-templates/list',
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
                console.log(resp.data.result);
                _this1.setState({emailTemplatesList:resp.data.result});
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    handleCreateEmailTemplates = () => {
        if(this.state.addEmailTemplatesActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addEmailTemplatesActive: false})
            },620);
        } else {
            this.setState({addEmailTemplatesActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddEmailTemplates = () => {
        this.setState({editData: {}});
        this.handleCreateEmailTemplates();
    }

    emailTemplatesAdded = (module) => {
        this.state.emailTemplatesList.push(module);
        this.setState({editData: {}});
        this.handleCreateEmailTemplates();
    }

    emailTemplatesEdit = (module) => {
        this.setState({editData: module});
        this.handleCreateEmailTemplates();
    }

    emailTemplatesEdited = (module) => {
        const state = this.state.emailTemplatesList;
        const index = state.findIndex(row => row.id === module.id);
        state[index] = module;
        this.setState({emailTemplatesList: state, editData: {}});
        this.handleCreateEmailTemplates();
    }


    componentWillReceiveProps(nextProps){

        if(nextProps.match.params.name !== this.state.name)
        {
            this.setState({name: nextProps.match.params.name});
            this.getEmailTemplates(nextProps.match.params.name);
        }
    }


    render(){

    return (
        <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Email Templates</h2></div></div></div>
        <div className="container-area">
            {
                this.state.loadingData ? <Loader /> : ''
            }
            <div className="inv-body">
                <div className="box">
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end">
                            <button onClick={this.handleCreateEmailTemplates} className="btn-main btn-lg">Create New Email Template</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <EmailTemplatesList state={this.state} emailTemplatesEdit={this.emailTemplatesEdit} />
                    </div>
                    {
                        this.state.addEmailTemplatesActive ? (
                            <AddEmailTemplates state={this.state} cancelAddEmailTemplates={this.cancelAddEmailTemplates} emailTemplatesAdded={this.emailTemplatesAdded} emailTemplatesEdited={this.emailTemplatesEdited} />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default EmailTemplates;