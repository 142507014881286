import React, { Component } from 'react';
import Loader from '../../loader'
import { callByJsonData } from '../../../ajax-call.js';
import { isUrlValid, validateImages, get_id_token } from '../../../utils.js';
import renderHTML from 'react-render-html'
var config = require('../../../config.js');

class ViewMentor extends Component{
    constructor(props) {
        super(props);

        let mentorData = (props.mentorData && props.mentorData.id) ? props.mentorData : {}
        
        this.state = {
            showLoader: false,
            mentorData: mentorData,
            title: (mentorData.title) ? mentorData.title : '',
            link: (mentorData.link) ? mentorData.link : '',
            is_featured: (mentorData.hasOwnProperty('is_featured')) ? mentorData.is_featured : true,
            status: (mentorData.hasOwnProperty('status')) ? mentorData.status : true,
            file: '',
            fileUrl: (mentorData.image) ? mentorData.image : '',
            image_delete: false,
            formErrors: {},
            errors: {},
        };
    }        

    handleChange = (event) => {
        var fieldName = event.target.name;

        if(event.target.type === 'checkbox'){
            this.setState({[fieldName]: event.target.checked});
        }
        else{
            this.setState({[fieldName]: event.target.value});
        }
        
    }


    handleImageChange = (event) => {
        let validationResult = validateImages(event)

        let formErrors = this.state.formErrors

        if(validationResult && validationResult['is_file_valid']){
            // console.log('validationResult=', validationResult)
            var fileUrl = URL.createObjectURL(event.target.files[0]);
            var file = event.target.files[0];
            this.setState({file:file,fileUrl:fileUrl});
        }
        else if(validationResult['errors']){
            formErrors['image'] = validationResult['errors']
        }
        this.setState({formErrors})

        this.gotoErrors()
    }


  removePic = () => {
    this.setState({file:'',fileUrl:'',image_delete: true});
  }


    validateForm = () => {

        var formErrors = {}

        var errrorsCount = 0

        if(this.state.title.trim() === ''){
            formErrors['title'] = 'Please enter title.'
            errrorsCount++
        }


        if(this.state.link && this.state.link !== ''){
            // console.log('isUrlValid(this.state.link)=', isUrlValid(this.state.link))
            if(!isUrlValid(this.state.link)){
                formErrors['link'] = 'Please enter valid link.'
                errrorsCount++
            }
        }

        this.setState({formErrors})

        if(errrorsCount > 0){            

            this.gotoErrors()

            return false
        }

        return true

    }

    gotoErrors = () => {
        setTimeout(function(){
            var msgErrorElmnt = document.getElementsByClassName("msg-error");

            if(msgErrorElmnt && msgErrorElmnt[0]){
                // console.log(msgErrorElmnt)
                msgErrorElmnt[0].parentElement.scrollIntoView();
            }
        }, 100);
    }

    handleSubmit = async (status) => {
        var _this1 = this;
        let isFormValid = true
        // if(this.validateForm()){
        if(isFormValid){

            let mentorId = (this.state.mentorData && this.state.mentorData.id)
            this.setState({showLoader: true})
            let data = {}
            data['id_token'] = await get_id_token()
            data['action'] = 'update'
            data['status'] = status
            var url = config.api_url+'mentorship/request_action/'+mentorId
            // var url = 'http://127.0.0.1:8081/'+'mentorship/request_action/'+mentorId
            var headers = {'tenant-id':'master_tenant','Content-Type': 'application/json'}
            let apiResp = callByJsonData(data, url, 'POST', headers)
            .then((resp) => {

                if(resp){
                    this.setState({showLoader: false})
                }

                if(resp.success && resp.result){
                    let saveType = 'add'
                    this.props.mentorSaved(resp.result, saveType)
                }
                else if(resp.message){
                    alert(resp.message)
                }
                if(resp.errors){
                    let formErrors = {}
                    Object.keys(resp.errors).map((fieldName) => {
                        // console.log('fieldName=', fieldName);
                        formErrors[fieldName] = resp.errors[fieldName]
                    })
                    this.setState({formErrors})
                }

            })
            .catch((error) => {
                console.log('mentor-save-error=', error)
                this.setState({showLoader: false})
            });

        }
    }

    render(){
        let mentorData = this.state.mentorData
        return(
            <React.Fragment>
                
                <div className="form-panel active show">

                    {
                        this.state.showLoader ? <Loader /> : ''
                    }
                
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> View Mentor Request</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="user-input-img-box flex">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td><b>Name:</b></td>
                                                <td>
                                                    {mentorData.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Email:</b></td>
                                                <td>{mentorData.email}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Job Title:</b></td>
                                                <td>{mentorData.job_title}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td><b>Company:</b></td>
                                                <td>{mentorData.company}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Phone:</b></td>
                                                <td>{mentorData.phone}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Location:</b></td>
                                                <td>{mentorData.location}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Bio:</b></td>
                                                <td>{renderHTML(mentorData.bio)}</td>
                                            </tr>
                                            <tr>
                                                <td><b>linkedin:</b></td>
                                                <td>{mentorData.linkedin}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Status</b></td>
                                                <td>{(mentorData.status===0)?'Pending':(mentorData.status===1)?'Approved':'Rejected'}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                      </div>

                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelViewMentor} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                {(mentorData.status === 0)?(
                                <React.Fragment>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit.bind(this, '1')}>Approve</button>

                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit.bind(this, '2')}>Reject</button>
                                </React.Fragment>
                                ):null}

                            </div>
                        </div>
                    </form>
                </div>

            </React.Fragment>
        )
    }
}
export default ViewMentor;