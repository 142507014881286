// var sandbox_mode = true;
var sandbox_mode = false;

const apiKey = "AIzaSyAVqx3oNiGtoqeoRaJPEFJWTa_G0SlprzA";
const authDomain = "opengrowth-test-1.firebaseapp.com";
const databaseURL = "https://opengrowth-test-1.firebaseio.com";
const projectId = "opengrowth-test-1";
const storageBucket = "opengrowth-test-1.appspot.com";
const messagingSenderId = "4807907862";
const appId = "1:4807907862:web:3d2c44b6051bf7e7ccb337";

const GOOGLE_RECAPTCHA_SITE_KEY = '6LeTNEocAAAAAD8Fd58avvrr14G-ZacFurhbQmmc'
const GOOGLE_RECAPTCHA_SECRET_KEY = '6LeTNEocAAAAACTrryHvfn2gL8qelV1xHchC2d1D'

if(sandbox_mode)
{
    var base_url = 'http://127.0.0.1:8081/';
    var api_url = 'http://127.0.0.1:8081/';
    var master_api_url = 'http://127.0.0.1:8081/master/';

    var syscore_nj_api_url = 'http://webapi-nj.opengrowthhub.com/api/';
}
else
{
    var base_url = 'https://www.opengrowthhub.com/';
    var api_url = 'https://webapi.opengrowthhub.com/';
    var master_api_url = 'https://webapi.opengrowthhub.com/master/';

    var syscore_nj_api_url = 'https://webapi-nj.opengrowthhub.com/api/';
}

var http_type = 'http://';
// var frontend_domain = 'foniqca.com';
var frontend_domain = 'opengrowthhub.com';

var user_groups = {
    'primary_owner':'Primary Owner',
    'workspace_owner':'Workspace Owner',
    'workspace_admin':'Workspace Admin',
    'workspace_member':'Workspace Member',
    'workspace_guest':'Workspace Guest',
};

var payment_modes = {
    'bank_transfer':'Bank transfer/NEFT/IMPS',
    'online':'Online',
    'cheque':'Cheque',
};

const isHttps = true

module.exports = {
    'apiKey': apiKey,
    'authDomain': authDomain,
    'databaseURL': databaseURL,
    'projectId': projectId,
    'storageBucket': storageBucket,
    'messagingSenderId': messagingSenderId,
    'appId': appId,
    'base_url': base_url,
    'api_url': api_url,
    'master_api_url': master_api_url,
    'syscore_nj_api_url': syscore_nj_api_url,
    'isHttps': isHttps,
    'user_groups': user_groups,
    'http_type': http_type,
    'frontend_domain': frontend_domain,
    'payment_modes': payment_modes,
    'GOOGLE_RECAPTCHA_SITE_KEY': GOOGLE_RECAPTCHA_SITE_KEY,
    'GOOGLE_RECAPTCHA_SECRET_KEY': GOOGLE_RECAPTCHA_SECRET_KEY,
};