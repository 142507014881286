import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
class CmsList extends Component{

    cmsEdit = (cms) => {
        this.props.cmsEdit(cms);
    }

    render(){
        const cmsList = this.props.state.cmsList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Slug</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            {cmsList.map((item, index) => (
                            <tr key={index} >
                                <td width="30%">
                                    {item.name}
                                </td>
                                <td width="30%">
                                    {item.slug}
                                </td>
                                <td width="30%">
                                    {(item.status === true)?'Active':'In-active'}
                                </td>
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.cmsEdit.bind(this, item)} >Edit</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default CmsList;