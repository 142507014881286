import { connect } from 'react-redux';

import {
    setSignupEmail,
    setSignupDetails,
} from '../../actions/signupAction';

import Email from '../../components/signup/email';

export function mapStateToProps(state) {
	/*console.log('topBar=');
	console.log(state);*/
    return {
        
    };
}

export function mapDispatchToProps(dispatch) {
  return {
  	//onSetSignupEmail: email => dispatch(setSignupEmail(email)),
  	onSetSignupDetails: data => dispatch(setSignupDetails(data)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Email);