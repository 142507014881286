import React, { Component } from 'react';
import Select from 'react-select';
import Loader from '../../../components/loader'
import { callByJsonData } from '../../../ajax-call.js';
import { dateTimeToDisplay, get_id_token } from '../../../utils.js';

var config = require('../../../config.js');

const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

class AddActivity extends Component{
    constructor(props) {
        super(props);
        this.state = {
            showLoader:true,
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : {},
            errors: {},
            workspaceActiveUsers: 0,
            workspaceWeekActiveUsers: 0,
            workspaceMonthActiveUsers: 0,
            workspaceAllUsers: 0,
            totalActiveUsers: 0,
            todayActiveUsers: 0,
            yesterdayActiveUsers: 0,
            weeklyActiveUsers: 0,
            monthlyActiveUsers: 0,
        };
    }   

    componentDidMount(){

        // this.getWorkspaceUsers()
        this.getUsersCount()
    }

    

    getWorkspaceUsers = async () => {
        var _this1 = this;

        let data = {}

        const url = masterApiUrl+'users'

        this.setState({showLoader: true})

        let apiResp = callByJsonData(data, url, 'POST', {'tenantId':this.state.editData.tenant_id})
        .then((resp) => {

           // console.log(apiResp.users)
            if(apiResp.users){

                    var users = apiResp.users
                    var active_users = []
                    var active_week_users = []
                    var active_month_users = []
                   // console.log(users.length)
                   if(users.length)
                   {
                    _this1.setState({workspaceAllUsers:users.length})

                   }
                   
                   var date = new Date();
                   var two_day_before = new Date(date.getTime() - (2 * 24 * 60 * 60 * 1000));

                    users.map((user, i) => {
                        var d2 = new Date(user.metadata.lastSignInTime);
                        //console.log('two_day_before <= user.metadata.lastSignInTime'+two_day_before +'<='+ d2)
                            if(two_day_before.getTime() <= d2.getTime())
                            {

                                //active_users[i] = user.uid
                                active_users.push(user.uid)
                                //console.log('active_users date'+user.metadata.lastSignInTime)
                            }

                            //console.log(user.metadata.lastSignInTime.getTime())
                    })

                    // start active user for week 

                    var date = new Date();
                    var week_before = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));

                    users.map((user, i) => {
                        var d2 = new Date(user.metadata.lastSignInTime);
                        if(week_before.getTime() <= d2.getTime())
                        {
                            active_week_users.push(user.uid)
                        }
                    })

                    //start active user for month

                    var date = new Date();
                    var month_before = new Date(date.getTime() - (30 * 24 * 60 * 60 * 1000));

                    users.map((user, i) => {
                        var d2 = new Date(user.metadata.lastSignInTime);
                        if(month_before.getTime() <= d2.getTime())
                        {
                            active_month_users.push(user.uid)
                        }
                    })

                    // _this1.setState({
                    //     workspaceActiveUsers:active_users.length,
                    //     workspaceWeekActiveUsers:active_week_users.length,
                    //     workspaceMonthActiveUsers:active_month_users.length,
                    // })
                }
                _this1.setState({showLoader:false});


            

           // alert(resp.users.length)
            //if(apiResp.result){
            //    _this1.setState({workspaceList:apiResp.result});
            //}
            //_this1.setState({showLoader:false});
        })
        .catch((error) => {
            console.log('workspace-users-error=',error)
            this.setState({showLoader: false})
        });
    }

    

    getUsersCount = async () => {
        var _this1 = this;
        let data = {}
        data['id_token'] = await get_id_token()
        
        const url = config.api_url+'users/get_active_counts';
        // const url = 'http://127.0.0.1:8081/users/get_active_counts';

        this.setState({showLoader: true})

        let tenantId = this.state.editData.tenant_id

        let apiResp = callByJsonData(data, url, 'POST', {"tenantId": tenantId})
        .then((resp) => {

            if(resp){
                _this1.setState({showLoader: false});
            }

            if(resp.success){

                // let activeUsersCount = (resp.activity_counts) ? resp.activity_counts : {}

                let totalActiveUsers = (resp.total_count) ? resp.total_count : 0
                let todayActiveUsers = (resp.today_count) ? resp.today_count : 0
                let yesterdayActiveUsers = (resp.yesterday_count) ? resp.yesterday_count : 0
                let weeklyActiveUsers = (resp.weekly_count) ? resp.weekly_count : 0
                let monthlyActiveUsers = (resp.monthly_count) ? resp.monthly_count : 0

                _this1.setState({
                    totalActiveUsers:totalActiveUsers,
                    todayActiveUsers:todayActiveUsers,
                    yesterdayActiveUsers:yesterdayActiveUsers,
                    weeklyActiveUsers:weeklyActiveUsers,
                    monthlyActiveUsers:monthlyActiveUsers,
                })
            }
        })
        .catch((error) => {
            console.log('workspace-user-count-error=',error)
            this.setState({showLoader: false})
        });
    }

    render(){
        const item = this.state.editData;
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    {
                        this.state.showLoader ? <Loader /> : ''
                    }
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2>View Workspace</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="input-box">
                                        <table>
                                            
                                            <tr>
                                                <td align="right"><b>Company Name (Name):</b></td>
                                                <td>
                                                    {item.company_name} ({item.name})
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Email:</b></td>
                                                <td>{item.email_address}</td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Workspace:</b></td>
                                                <td>{item.workspace}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td align="right"><b>Bucket:</b></td>
                                                <td>{item.bucket}</td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Bucket size:</b></td>
                                                <td>{(item.bucket_size) ? (item.bucket_size + ' mb') : ''}</td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Database:</b></td>
                                                <td>{item.db_name}</td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Database size:</b></td>
                                                <td>{(item.db_size) ? (item.db_size + ' mb') : ''}</td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Tenant:</b></td>
                                                <td>{item.tenant_id}</td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Logo:</b></td>
                                                <td>
                                                {(item.logo_url)?(
                                                <a href={item.logo_url} target="_blank"><img src={(item.logo_url) ? item.logo_url : ''} alt={item.workspace} width="30"  /></a>
                                                ):null}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="right"><b>Created At:</b></td>
                                                <td>{dateTimeToDisplay(item.created_at)}</td>
                                            </tr>

                                            <tr>
                                                <td align="right"><b>Total User:</b></td>
                                                <td>{this.state.totalActiveUsers}</td>
                                            </tr>

                                            <tr>
                                                <td align="right"><b>Today's Active User:</b></td>
                                                <td>{this.state.todayActiveUsers}</td>
                                            </tr>

                                            <tr>
                                                <td align="right"><b>Yesterday's Active User:</b></td>
                                                <td>{this.state.yesterdayActiveUsers}</td>
                                            </tr>

                                            <tr>
                                                <td align="right"><b>Active User In Week:</b></td>
                                                <td>{this.state.weeklyActiveUsers}</td>
                                            </tr>

                                            <tr>
                                                <td align="right"><b>Active User In Month:</b></td>
                                                <td>{this.state.monthlyActiveUsers}</td>
                                            </tr>
                                            {(item.is_deleted==1)?(
                                            <tr>
                                                <td align="right"><b>Status:</b></td>
                                                <td>Deleted</td>
                                            </tr>
                                            ):null}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelViewWorkspace} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                {/*<button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>*/}
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addActivityActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddActivity;