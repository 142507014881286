import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../front/Navbar'
import Footer from '../front/Footer'
import axios from 'axios';
var md5 = require('md5');
var config = require('../../config.js');

export default class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        // console.log('props');
        // console.log(props);
        this.state = {
            hidden: true,
            workspace:'',
            // password:'',
            login_message:'',
        };
        this.toggleShow = this.toggleShow.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    handleChange(event) {
        var name = event.target.name;
        this.setState({[name]: event.target.value});
    }

    handleChangePassword(event){
        var password = md5(event.target.value);
        this.setState({password});
    }

    handleSubmit(event) {
        event.preventDefault();
        var _this1 = this;
        if(_this1.state.workspace !== '')
        {
            var login_message = '';
            _this1.setState({login_message});
            var data = {};
            data['subdomain'] = _this1.state.workspace;
            // data.append('password', _this1.state.password);
            axios({
                method: 'post',
                url: config.api_url+'workspace/check',
                data: data,
                headers:{'content-type': 'application/json'}
            }).then(function (resp) {
                // console.log(resp);
                if(resp.data.success === true)
                {
                    window.location = config.http_type+resp.data.workspace+'.'+config.frontend_domain;
                }
                else if(resp.data.message)
                {
                    login_message = resp.data.message;
                    _this1.setState({login_message});
                }
                else
                {
                    login_message = 'Something went wrong, please try again.';
                    _this1.setState({login_message});
                }
            }).catch(function (error) {
                //console.log(error);
                alert(error);
            });         
        }
        else
        {
            const login_message = 'Workspace can not be empty.';
            _this1.setState({login_message});
        }
        return false;
    }
    
  render() {
    return (
      <React.Fragment>
        <Navbar fixed hideNav />
        <div className="fp-main-full-inner flex align-items-center justify-content-center container">
            <div className="login-box">
                <div className="login-box-inner">
                    <div className="login-box-header">
                        <div className="fp-heading2">Log In</div>
                    </div>
                    <div className="form-group-box">
                      <div className="user-form-box signup-form-main">
                        {(this.state.login_message) ? (
                        <div className="msg-error">{this.state.login_message}</div>
                        ) : ('')}
                        <form onSubmit={this.handleSubmit}>
                            <div className="user-form-inner">
                                <div className="input-box">
                                    <label>Workspace</label>
                                    <input type="text" name="workspace" placeholder="Workspace" className="form-style" onChange={this.handleChange} />
                                </div>
                                {/*<div className="input-box">
                                    <div className="pwd-toggle-lable-outer">
                                        <label>Password</label>
                                        <span className="password-toggle" onClick={this.toggleShow} data-target="#login_pwd">{this.state.hidden ? "Show" : "Hide"}</span>
                                    </div>
                                    <input type={this.state.hidden ? "password" : "text"} name="password" placeholder="Password" className="form-style" onChange={this.handleChangePassword} />
                                </div>*/}
                                <div className="msg-error" id="signup_error"></div>
                                <div className="input-box">
                                    {/* <button type="button" className="btn-main btn-form btn-submit btn-full" id="signup-btn">Continue</button> */}
                                    {/*<Link to="/signup2" className="btn-main btn-form btn-submit btn-full">Continue</Link>*/}
                                    <button type="submit" className="btn-main btn-form btn-submit btn-full" id="signup-btn">Continue</button>
                                </div>
                            </div>
                        </form>
                        <div className="user-form-bottom">
                            <div className="user-new">Don't have an account? <Link to="/plans-and-pricing">Sign Up</Link> here.</div>
                        </div>                        
                    </div>                      
                  </div>
                </div>
            </div>
        </div> 
        <Footer hideNav />
      </React.Fragment>   
    );
  }
}