import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import * as firebase from "firebase/app";
// import { get_id_token, formatDate, formatTime } from '../../../utils.js';
// import Alert from '../../components/alert';
import Loader from '../../../components/loader';
// import { ShowForPermission } from '../../components/ShowForPermission';
// import UpdateTitlePopup from './modal-update-title';

import MenuRows from '../../../components/admin/cms/menu-rows';

var config = require('../../../config.js');

// const masterApiUrl = config.master_api_url
const masterApiUrl = 'http://127.0.0.1:8081/master/'

const recordsLimit = config.recordsPerPage
// const recordsLimit = 10

class CmsMenuList extends Component {

    constructor(props){
        super(props)

        var currentUser = {}
        const currentUserUid = (currentUser.uid)?currentUser.uid:''

        this.state = {
            currentUser: currentUser,
            currentUserUid: currentUserUid,
            currentUserClaims: {},
            clientEdit: [],
            cmsPagesList: [],
            showChildPages: {},
            showLoader: false,
            showLoading:false,
            offset: 0,
            loadingMore: false,
            showTitlePopup: false,
            pageData: {},
            search_text: '',
            pageSortOrders: [],
            alertSuccess: '',
            alertError: '',
        }

    }

    handleCreatePage = () => {
        this.props.history.push('/cms-page-add')
    }

    openUpdateTitlePopup = (pageData, level) => {
        this.setState({pageData: pageData})
        this.setState({showTitlePopup: true})
    }

    closeUpdateTitlePopup = (pageData, level) => {
        this.setState({showTitlePopup: false})
        this.setState({pageData: {}})
    }

    handleSortOrder = (e, id, current_sort_order) => {
        e.preventDefault()

        var sort_order = e.target.value

        if(sort_order < 0){

            e.target.value = 0

            sort_order = 0
        }

        var pageSortOrders = this.state.pageSortOrders

        pageSortOrders = JSON.parse(JSON.stringify(pageSortOrders))

        var indx = pageSortOrders.findIndex((row) => row.id === id)

        if (indx >= 0) {
            pageSortOrders[indx] = {id: id, sort_order: sort_order}
        }
        else{
            pageSortOrders.push({id: id, sort_order: sort_order})
        }

        this.setState({pageSortOrders})

        // console.log('pageSortOrders=', pageSortOrders)
    }

    updatePageInList = (page) => {

        if(page && page.id){

            var cmsPageData = JSON.parse(JSON.stringify(page))

            // console.log('updatePageInList-page.id=', page.id)

            this.props.onUpdateCmsPage(cmsPageData)

            var cmsPagesList = this.state.cmsPagesList            

            cmsPagesList.forEach(function iterate(row, index, arr) {
                if (row.id === page.id) {
                    arr[index] = cmsPageData
                }
                Array.isArray(row.child_pages) && row.child_pages.forEach(iterate)
            })

            // console.log('new-cmsPagesList=', cmsPagesList)
            this.setState({cmsPagesList})
            this.props.onSetCmsPagesList(cmsPagesList)
        }


    }

    removePageFromList = (page) => {

        if(page && page.id){
            var cmsPagesList = this.state.cmsPagesList

            cmsPagesList.forEach(function iterate(row, index, arr) {
                if (page.id === row.id) {
                    arr.splice(index, 1)
                }
                Array.isArray(row.child_pages) && row.child_pages.forEach(iterate)
            })

            // console.log('new-cmsPagesList=', cmsPagesList)
            this.setState({cmsPagesList})
        }


    }

    handleShowChildPages = (pageId) => {
        var showChildPages = this.state.showChildPages

        if(showChildPages[pageId] && showChildPages[pageId] === true){
            showChildPages[pageId] = false
        }
        else{
            showChildPages[pageId] = true
        }

        // console.log('showChildPages=')
        // console.log(showChildPages)

        this.setState({showChildPages})
    }

    handleShowHide = async (pageData, level) => {

        var showHideType = (pageData.status === 2) ? 'show' : 'hide'

        var conf = window.confirm("Are really want to "+showHideType+" this page?")

        if(conf){

            var _this1 = this
                
            var newStatus = (pageData.status === 2) ? 1 : 2

            var id_token = ''

            var data = {};

            data['status'] = newStatus
            data['id_token'] = id_token

            const url = masterApiUrl+'cms-pages/update_status/'+pageData.id
            // const url = 'http://127.0.0.1:8081/cms-pages/update_status/'+pageData.id

            $.ajax({
                type: 'POST',
                url,
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json',
                beforeSend: function() {
                    _this1.setState({showLoader: true, alertSuccess: '', alertError: ''});
                },
                success: function(resp) {
                    if(resp){
                        _this1.setState({showLoader: false});
                    }

                    if(resp.success){
                        var alertMsg = 'Page has been updated successfully.';
                        pageData['status'] = newStatus
                        _this1.updatePageInList(pageData)
                    }
                }
            }).catch(function (error) {
                console.log('update-page-status-error=')
                console.log(error)
                _this1.setState({
                    showLoader: false,
                    alertError: 'Something went wrong, please try again.',
                })
                // _this1.setState({alertError: 'Something went wrong, please try again.'});
            });

        }
    
    }

    handleDeletePage = async (cmsPageData, pageIndex, childPageIndex) => {

        var conf = window.confirm("Are you sure to delete this page permanently?")
        
        var cmsPageId = cmsPageData.id

        if(conf && cmsPageId > 0){

            cmsPageData = JSON.parse(JSON.stringify(cmsPageData))

            // this.removePageFromList(cmsPageData)
            // this.props.onDeleteCmsPage(cmsPageData)
            // return false

            var _this1 = this

            var id_token = ''

            const data = new FormData()

            data.append('id_token', id_token)

            var url = masterApiUrl+'cms-pages/delete/'+cmsPageId
            // var url = 'http://127.0.0.1:8081/cms-pages/delete/'+cmsPageId

            $.ajax({
                type: 'post',
                url: url,
                data: data,
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function() {
                    _this1.setState({showLoader: true, alertSuccess: '', alertError: ''});
                },
                success: function(resp) {
                    // console.log('handleAddMeetingNotes-resp=');
                    // console.log(resp);
                    if(resp){
                        _this1.setState({showLoader: false});
                    }

                    var alertMsg = 'CMS page has been deleted successfully.';

                    if(resp.success){

                        /*var cmsPagesList = _this1.state.cmsPagesList

                        if(cmsPagesList[pageIndex]){

                            if(childPageIndex >= 0 && cmsPagesList[pageIndex].child_pages[childPageIndex]){

                                cmsPagesList[pageIndex].child_pages.splice(childPageIndex, 1)

                            }
                            else{
                                cmsPagesList.splice(pageIndex, 1)
                            }
                            

                            _this1.setState({cmsPagesList})
                        }*/

                        _this1.removePageFromList(cmsPageData)
                        _this1.props.onDeleteCmsPage(cmsPageData)
                        _this1.setState({alertSuccess: alertMsg})

                    }
                }
            
            }).catch(function (error) {
                console.log('delete-page-error=')
                console.log(error)
                _this1.setState({
                    showLoader: false,
                    alertError: 'Something went wrong, please try again.',
                })
                // _this1.setState({alertError: 'Something went wrong, please try again.'});
            })
        }
    }

    updateListSortOrders = (pageSortOrders) => {

        if(pageSortOrders && pageSortOrders.length > 0){
            var cmsPagesList = this.state.cmsPagesList

            pageSortOrders.map((page) => {

                cmsPagesList.forEach(function iterate(row, index, arr) {
                    if (row.id === page.id) {
                        arr[index]['sort_order'] = page.sort_order
                    }
                    Array.isArray(row.child_pages) && row.child_pages.forEach(iterate)
                })

            })

            // console.log('new-cmsPagesList=', cmsPagesList)
            this.setState({cmsPagesList})

        }

    }

    updateSortOrder = async () => {

        var conf = window.confirm("Are you really want to update sort orders?")
        
        var pageSortOrders = this.state.pageSortOrders

        if(conf && pageSortOrders && pageSortOrders.length > 0){

            // this.updateListSortOrders(pageSortOrders)
            // this.props.onUpdateSortOrders(pageSortOrders)
            // this.setState({pageSortOrders: []})
            // this.props.onUpdateSortOrders([])
            // return false

            var _this1 = this

            var id_token = ''

            const data = new FormData()

            data.append('id_token', id_token)
            data.append('page_sort_orders', JSON.stringify(pageSortOrders))

            var url = masterApiUrl+'cms-pages/update_sort_orders'
            // var url = 'http://127.0.0.1:8081/cms-pages/update_sort_orders'

            $.ajax({
                type: 'post',
                url: url,
                data: data,
                cache: false,
                processData: false,
                contentType: false,
                beforeSend: function() {
                    _this1.setState({showLoader: true, alertSuccess: '', alertError: ''});
                },
                success: function(resp) {
                    // console.log('handleAddMeetingNotes-resp=');
                    // console.log(resp);
                    if(resp){
                        _this1.setState({showLoader: false});
                    }

                    var alertMsg = 'Sort orders has been updated successfully.';

                    if(resp.success){

                        _this1.updateListSortOrders(pageSortOrders)
                        _this1.setState({alertSuccess: alertMsg})
                        _this1.props.onUpdateSortOrders(pageSortOrders)
                        _this1.setState({pageSortOrders: []})

                    }
                    else if(resp.error){
                        _this1.setState({
                            alertError: resp.error,
                        })
                    }
                }
            
            }).catch(function (error) {
                console.log('delete-page-error=')
                console.log(error)
                _this1.setState({
                    showLoader: false,
                    alertError: 'Something went wrong, please try again.',
                })
                // _this1.setState({alertError: 'Something went wrong, please try again.'});
            })
        }
    }

    getCMSPagesList = async () => {
        var _this1 = this;

        var data = {};

        data['parent_id'] = 0
        data['with'] = ['child_pages', 'user_details']
        data['id_token'] = ''

        if(_this1.state.search_text){
            data['search_text'] = _this1.state.search_text
        }

        var url = masterApiUrl+'cms/cms_template_list'
        // var url = 'http://127.0.0.1:8081/cms-pages/list'

        $.ajax({
            type: 'post',
            url: url,
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            beforeSend: function() {
                _this1.setState({showLoader: true, showLoading: true, alertError: ''})
            },
            success: function(resp){

                if(resp){
                    _this1.setState({showLoader: false, showLoading: false})
                }

                if(resp.success && resp.cms_pages_list){
                    _this1.setState({cmsPagesList: resp.cms_pages_list})
                }
                else if(resp.error){
                    _this1.setState({alertError: resp.error})
                }

            }
        }).catch(function (error) {
            console.log('get-cms-pages-list-error=', error)

            _this1.setState({showLoader: false, showLoading: false})
        })
    }


    componentDidMount(){
        this.getCMSPagesList();
    }


    render(){

        const cmsPagesList = this.state.cmsPagesList

        return(
        
        <div className="page-main-holder">

        {(this.state.showLoader) && (<Loader />)}

        {/*
            this.state.alertSuccess && (<Alert message={this.state.alertSuccess} type='success' />)
        }
        {
            this.state.alertError && (<Alert message={this.state.alertError} type='error' />)
        */}

        {/*
            this.state.showTitlePopup &&
            <UpdateTitlePopup
            pageData={this.state.pageData}
            closeModal={this.closeUpdateTitlePopup}
            updatePageInList={this.updatePageInList}
            />
        */}


            <div className="main-top top-full">
                <div className="page-title">
                    <div className="page-title-box">
                        <h2>CMS Pages </h2>
                    </div>
                </div>
            </div>
            <div className="container-area main-panel-left">
                <div className="flex section-top">
                    <div className="box-filter-left flex-auto"></div>

                    {
                        this.state.pageSortOrders.length > 0 && (
                            <div className="section-btn-outer"><button onClick={this.updateSortOrder} className="btn-main btn-lg">Update Sort Orders</button></div>
                            )
                    }

                    

                        
                        <div className="section-btn-outer add-cms-btn-outer"><button onClick={this.handleCreatePage} className="btn-main btn-lg">Create New Page</button></div>
                    </div>
                <div className="panel-main-outer">
                    <div className="box">
                        <div className="box-body">
                            <div className="table-box">
                            <table className="table" cellSpacing="0" cellPadding="0">
                                <tbody>
                                    <tr>
                                        <th width="25%">Page Title</th>
                                        <th width="15%">Created by</th>
                                        <th width="10%">Status</th>
                                        <th width="10%">Sort Order</th>
                                        <th width="20%">Created on</th>
                                        <th width="20%">Updated on</th>
                                        <th width="10%"></th>
                                    </tr>

                                    {
                                        (cmsPagesList.length > 0) ? (

                                         <MenuRows
                                         level={0}
                                         pages={cmsPagesList}
                                         handleShowChildPages={this.handleShowChildPages}
                                         showChildPages={this.state.showChildPages}
                                         handleDeletePage={this.handleDeletePage}
                                         openUpdateTitlePopup={this.openUpdateTitlePopup}
                                         handleSortOrder={this.handleSortOrder}
                                         handleShowHide={this.handleShowHide}
                                         />

                                            ) : (


                                            <tr>
                                                <td colSpan="5">
                                                {(this.state.showLoading) ? ('Loading...') : (
                                                    'No Record(s).'
                                                )}
                                                </td>
                                            </tr>


                                            )
                                    }



                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>          
        </div>
        
        )
    }
}

export default CmsMenuList;