import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logoMain from '../../logo-color.png';
import axios from 'axios';
import Navbar from '../front/Navbar'
import Footer from '../front/Footer'
import Loader from '../loader'
import SetupProcess from '../setup-process'
import { callByJsonData } from './../../ajax-call.js';
var config = require('../../config.js');
const masterApiUrl = config.master_api_url
class Detail extends Component {
    constructor(props) {
        super(props);

        /*console.log('props-details=');
        console.log(props);*/

        /*if(props.signup && props.signup.company_name && props.signup.company_name !== '' && props.signup.company_name !== null ){
            localStorage.setItem('company_name', props.signup.company_name);
        }

        if(props.signup && props.signup.workspace && props.signup.workspace !== '' && props.signup.workspace !== null ){
            localStorage.setItem('workspace', props.signup.workspace);
        }

        if(props.signup && props.signup.is_company_name_real && props.signup.is_company_name_real !== '' && props.signup.is_company_name_real !== null ){
            localStorage.setItem('is_company_name_real', props.signup.is_company_name_real);
        }*/

        var signupDetails = (props.state.signupDetails)?props.state.signupDetails:{}

        // var signupDetails = (localStorage.getItem('signupDetails'))?localStorage.getItem('signupDetails'):{}

        var email = (signupDetails.email) ? signupDetails.email : ''
        var email_verified = (signupDetails.email_verified) ? signupDetails.email_verified : false
        var company_name = (signupDetails.company_name) ? signupDetails.company_name : ''
        var is_company_name_real = (signupDetails.is_company_name_real) ? signupDetails.is_company_name_real : false
        var workspace = (signupDetails.workspace) ? signupDetails.workspace : ''
        var name = (signupDetails.name) ? signupDetails.name : ''

        // console.log('details.js-signupDetails')
        // console.log(signupDetails)

        this.state = {
          showWaiting:false,
          showLoader:false,
          hidden: true,
          hidden2: true,
          packageId: (signupDetails['packageId']) ? signupDetails['packageId'] : '',
          billing: (signupDetails['billing']) ? signupDetails['billing'] : '',
          currency: (signupDetails['currency']) ? signupDetails['currency'] : '',
          signupDetails: signupDetails,
          email: email,
          email_verified: email_verified,
          company_name: company_name,
          is_company_name_real: is_company_name_real,
          workspace: workspace,
          name: name,
          password:'',
          repassword:'',
          photoURL:'',
          errors:'',
          err_msg:'',
          workspace_url:'',
          uploadedFile:'',
          uploadedFileUrl:'',
        };

        //console.log(this.state);

        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.toggleShow2 = this.toggleShow2.bind(this);

        if(!this.state.company_name){
            window.location.hash = '#companyname';
        }
    }

    componentDidMount(){
        this.getPackageDetails();
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    toggleShow2() {
        this.setState({ hidden2: !this.state.hidden2 });
    }

    handleChange(event){
      var name = event.target.name;
      this.setState({[name]: event.target.value});
    }

    handleImageChange(e){
      e.preventDefault();
      var _this1 = this;


      var ext = ["image/jpeg", "image/png"];

      var is_image_valid = true;

      var err_arr = []

      if(e.target.files.length > 0){
        let file = e.target.files[0];

        if(ext.indexOf(file.type) < 0){
          err_arr.push("Please upload jpg/jpeg, png image only.");
          is_image_valid = false;
        }
        
        if(file.size > (1024*1024*5) ){
          err_arr.push("Please select image with less than 5MB.");
          is_image_valid = false;
        }

        /*console.log(file.type);
        console.log(file.size);

        return false;*/

        if(is_image_valid === true){
          let file_name = file.name;
          var uploadedFileUrl = URL.createObjectURL(e.target.files[0]);
          if(uploadedFileUrl){
            setTimeout(function(){
              _this1.setState({
                uploadedFile: file,
                uploadedFileUrl: uploadedFileUrl
              });
            },1500);
          }
          else{
            alert('Something went wrong, please try again.');
          }
        }
        else{
          var errors = err_arr.join("\n");
            alert(errors);
        }
      }
      else{
        setTimeout(function(){
          _this1.setState({
            uploadedFile: '',
            uploadedFileUrl: ''
          });
        },1500);
      }
    }

    getPackageDetails = () => {
        let _this1 = this
        var data = {};

        data['is_package_items'] = true

        let url = masterApiUrl+'packages/get/'+this.state.packageId

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){
                let packages = resp.result

                let PriceCards = [
                    {
                        price: '₹'+packages.monthly_price,
                        duration: 'monthly',
                        selected: (_this1.state.billing === 'monthly') ? true : false,
                    },
                    {
                        price: '₹'+packages.annual_price,
                        duration: 'annual',
                        selected: (_this1.state.billing === 'annual') ? true : false,
                    }
                ]
                // console.log('PriceCards=',PriceCards)
                let selectedPrice = PriceCards.map((selectedPrice)=>selectedPrice.selected?selectedPrice.price:"")

                _this1.setState({packageData: resp.result, PriceCards, selectedPrice});            
            }
        })
        .catch((error) => {
            console.log('packages-details-error=', error);
        });

    }

    handleSubmit(event){
      event.preventDefault();
      var _this1 = this;

      var err_msg = [];
      var errors = {};

      var isValidated = true;

      if( this.state.name === '' || this.state.name === undefined ){
        err_msg.push('Please enter Name.');
        errors['name'] = 'Please enter Name.';
        isValidated = false;
      }

      if( this.state.password === '' || this.state.password === undefined ){
        err_msg.push('Please enter Password.');        
        errors['password'] = 'Please enter Password.';
        isValidated = false;
      }
      else if(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/.test(this.state.password))
      {

      }
      else
      {
        // err_msg.push('Password should have minimum 6 character and one capital letter and one special character.');
        // errors['password'] = 'Password should have minimum 6 character and one capital letter and one special character.';
        err_msg.push('Password must contain at least 6 characters, including UPPERCASE, lowercase, numbers and special characters.');
        errors['password'] = 'Password must contain at least 6 characters, including UPPERCASE, lowercase, numbers and special characters.';
        isValidated = false;
      }

      if( this.state.password !== this.state.repassword ){
        err_msg.push('Password and confirm password should be match.');
        errors['repassword'] = 'Password and confirm password should be match.';
        isValidated = false;
      }

      if(!isValidated)
      {
        //err_msg = err_msg.join(" | ");
        //console.log(err_msg);
        //_this1.setState({err_msg});
        _this1.setState({errors});
        //return true;
      }
      else
      {
        var signupDetails = this.state.signupDetails

        /********* Remove after testing ***********/
        /*signupDetails['signup_success'] = true
        _this1.props.updateSignupDetails(signupDetails);
        // window.location.hash = '#thankyou';
        window.location.href = '/billing-cycle';
        return false;*/
        /********* Remove after testing ***********/

        _this1.setState({errors:{}});
        const data = new FormData();
        // data.append('company_name', _this1.state.company_name);
        // data.append('email_verified', _this1.state.email_verified);
        // data.append('is_company_name_real', _this1.state.is_company_name_real);
        // data.append('workspace', _this1.state.workspace);
        data.append('email_address', _this1.state.email);
        data.append('name', _this1.state.name);
        data.append('password', _this1.state.password);
        data.append('image', _this1.state.uploadedFile);

        _this1.setState({showWaiting: true});

        const url = config.api_url+'signup/details'
        // const url = 'http://127.0.0.1:8081/signup/details'

        axios({
            method: 'post',
            url: url,
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (response) {

          if(response){
            _this1.setState({showWaiting: false});
          }
            //console.log(response);
            if(response.data.status === 'success')
            {
              signupDetails['workspace_id'] = response.data.workspace_id
              signupDetails['workspace_url'] = response.data.workspace_url
              signupDetails['signup_success'] = true
              _this1.props.updateSignupDetails(signupDetails);

              // localStorage.setItem('workspace_url', response.data.workspace_url);
              // localStorage.setItem('signup_success', true);
              // window.location.hash = '#thankyou';              
              if(_this1.state.packageData && _this1.state.packageData.monthly_price > 0 && _this1.state.packageData.annual_price > 0)
              {
                window.location.href = '/billing-cycle';
              }
              else
              {
                window.location.hash = '#thankyou';
              }
            }
            else if(response.data.message)
            {
              alert(response.data.message);
            }
            else
            {
              alert('Something went wrong, please try again.');
            }
        }).catch(function (error) {
            console.log('workspace-details-error=')
            console.log(error)
            // _this1.setState({showWaiting: false});
            signupDetails['signup_success'] = true
            _this1.props.updateSignupDetails(signupDetails);
            window.location.hash = '#thankyou';
        });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Navbar fixed hideNav />

          <div className="fp-main-full-inner flex justify-content-center container">
              <div className="singup-box">

              
                  <div className="login-box-inner singup-box-inner">

                  {(this.state.showWaiting) ?
                    (<SetupProcess />) : (

                      <React.Fragment>
 
                      <div className="signup-top">
                          <div className="signup-thumb-box">
                              <span className="upload_pic">Upload <input type="file" onChange={(e)=>this.handleImageChange(e)} id="upload_file"/></span>
                              <div className="user-thumb thumb-xl">{(this.state.uploadedFileUrl) ? (<img src={this.state.uploadedFileUrl} />) : <i className="icon-user"></i>}</div>
                          </div>
                          <div className="signup-user-email">{this.state.email}</div>
                      </div>
                      <div className="form-group-box">

                          <div className="user-form-box login-form-main form-steps active show">
                              {(this.state.err_msg) ? (
                              <div className="msg-error">{this.state.err_msg}</div>
                              ) : ('')}
                              <form onSubmit={this.handleSubmit}>
                                  <div className="user-form-inner">
                                      <div className="input-box">
                                          <label>Name</label>
                                          <input type="text" name="name" defaultValue={this.state.name} placeholder="Enter your name" className="form-style" onChange={this.handleChange} />
                                          {(this.state.errors.name) ? (
                                              <div className="msg-error">{this.state.errors.name}</div>
                                              ) : ('')}
                                      </div>
                                      <div className="input-box">
                                          <div className="pwd-toggle-lable-outer">
                                              <label>Password</label>
                                              <span className="password-toggle" onClick={this.toggleShow}>{this.state.hidden ? "Show" : "Hide"}</span>
                                          </div>
                                          <input type={this.state.hidden ? "password" : "text"} name="password" placeholder="********" id="signup_pwd" className="form-style" onChange={this.handleChange} />
                                          {(this.state.errors.password) ? (
                                              <div className="msg-error">{this.state.errors.password}</div>
                                              ) : ('')}
                                      </div>
                                      <div className="input-box">
                                          <div className="pwd-toggle-lable-outer">
                                              <label>Confirm Password</label>
                                              <span className="password-toggle" onClick={this.toggleShow2}>{this.state.hidden2 ? "Show" : "Hide"}</span>
                                          </div>
                                          <input type={this.state.hidden2 ? "password" : "text"} name="repassword" placeholder="********" id="confirm_pwd" className="form-style" onChange={this.handleChange} />
                                          {(this.state.errors.repassword) ? (
                                              <div className="msg-error">{this.state.errors.repassword}</div>
                                              ) : ('')}
                                      </div>
                                      <div className="msg-error" id="login_error"></div>
                                      <div className="input-box">

                                        {(this.state.showLoader) ? <Loader /> : null}
                                          {/* <button type="button" className="btn-main btn-form btn-submit btn-full">Continue</button> */}
                                          {/*<Link to="/signup3" className="btn-main btn-form btn-submit btn-full">Continue</Link>*/}
                                          <button type="submit" className="btn-main btn-form btn-submit btn-full">Continue</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>

                      </React.Fragment>

                      )}



                  </div>
              </div>
          </div>
        <Footer hideNav />
      </React.Fragment>
    );
  }
}
export default Detail;