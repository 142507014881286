import React, {Component} from 'react';
import Navbar from '../../components/front/Navbar';
import Footer from '../../components/front/Footer';
import {checkMark, crossMark} from  '../../components/SvgIcons';
import Tooltip from '../../components/tooltip';
import PriceCounter from './price-counter';
import { callByJsonData, getGeoInfo } from './../../ajax-call.js';
import { isJSON, decode } from './../../utils.js';
import Loader from '../../components/loader';
var config = require('./../../config.js');
const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

const available = <span className="svg-icon available" dangerouslySetInnerHTML={{__html: checkMark}}></span>
const notAvailable = <span className="svg-icon notAvailable" dangerouslySetInnerHTML={{__html: crossMark}}></span>

class Plans extends Component{

    constructor(props) {
        super(props);
        this.tableHead = React.createRef();

        var packageId = (props.match.params.packageId)?props.match.params.packageId:0
        var current_package = ''
        var current_workspace = ''
        const urlParams = new URLSearchParams(props.location.search)

        var signupDetails = {}
        if(localStorage.getItem('signupDetails') ){
            if( isJSON(localStorage.getItem('signupDetails')) ){
                signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
            }
            else{
                signupDetails = localStorage.getItem('signupDetails')
            }
            if(signupDetails['signup_success'])
            {
                signupDetails['workspace_id'] = ''
                signupDetails['signup_success'] = false
                signupDetails['current_package'] = ''
                signupDetails['current_workspace'] = ''
                localStorage.setItem('signupDetails', JSON.stringify(signupDetails))
            }
        }

        if(urlParams.get('token'))
        {
            var token = decode(urlParams.get('token'))
            // console.log('token=',token)
            var token_arr = token.split('@@@')
            // console.log('token=',token_arr)
            if(token_arr[0] && token_arr[0] === 'change_plan')
            {
                current_package = token_arr[1]
                current_workspace = token_arr[2]
            }
        }

        this.state = {
            scrollTop: 0,
            plan: "monthly",
            showLoader: false,
            packageId: packageId,
            current_package: current_package,
            current_workspace: current_workspace,
            packagesList: [],
            cmsPagesList: [],
            geoInfo: {},
            currency: 'INR',
            tableHeadHeight: "",
        };
    }

    componentDidMount(){
        this.getPackages();
        this.getCmsPagesList();
        

        getGeoInfo()
        .then((geoInfo) => {
            let currency = (geoInfo.currency) ? geoInfo.currency : 'INR'
            this.setState({geoInfo, currency})
        })

        
        
        
    }

    

    getPackages = () => {

        var data = {};

        data['package_id'] = this.state.packageId
        data['is_package_items'] = true
        data['is_for_front_user'] = true

        let url = masterApiUrl+'packages/list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){
                let packagesList = resp.result

                if(packagesList && packagesList.length > 0){
                    packagesList.sort((a,b) => {
                        if(parseInt(a.sort_order) > parseInt(b.sort_order)){
                            return 1
                        }

                        return -1
                    })
                }
                this.setState({packagesList: packagesList});
                
                const tableHeadHeight =  this.tableHead.current.clientHeight
                this.setState({tableHeadHeight: tableHeadHeight})
            }
        })
        .catch((error) => {
            console.log('package-list-error=', error);
        });

    }

    getCmsPagesList = async () => {
        var _this1 = this;
        var data = {};
        
        let url = masterApiUrl+'cms/templates_list'
        // let url = 'http://127.0.0.1:8081/master/cms/templates_list'

        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){

                let cmsPagesList = resp.result

                if(cmsPagesList && cmsPagesList.length > 0){
                    cmsPagesList.sort((a, b) => {
                        if(a.sort_order > b.sort_order){
                            return 1
                        }
                        return -1
                    })
                }

                _this1.setState({cmsPagesList: cmsPagesList});
            }
            _this1.setState({showLoader: false});
        })
        .catch((error) => {
            alert(error);
            _this1.setState({showLoader: false});
        });

    }
    
    onScroll = () => {
        const scrollY = window.scrollY
        this.setState({
          scrollTop: scrollY
      });
    }

    handleMonthlyPlan = () => {
        this.setState({
            plan: "monthly"
        });
        
    }

    handleAnnuallyPlan = () => {
        this.setState({
            plan: "annual"
        })
    }

    getItemDetails = (cmsPageData, packageData) => {

        // if(cmsPageData.slug === 'user'){

        // console.log('==getItemDetails==')
        // console.log('cmsPageData.slug=',cmsPageData.slug)
        // console.log('packageData=',packageData)

        // }

        let packageItems = (packageData && packageData.items) ? packageData.items : []

        let itemDetails = packageItems.find((r) => (r.cms_page_slug === cmsPageData.slug))

        return itemDetails

    }

    getCmsPageDetails = (packageItem) => {

        let cmsPagesList = this.state.cmsPagesList

        let itemCms = {}

        cmsPagesList.forEach(function iterate(row, index, arr) {
            if (row.slug === packageItem.cms_page_slug) {
                itemCms = row

                return true // to stop/break forEach loop
            }
            Array.isArray(row.child_pages) && row.child_pages.forEach(iterate)
        })

        return itemCms

    }
    

    buyPackage = (pack) =>{
        let _this1 = this
        if(pack.is_call_us_btn && pack.is_call_us_btn === true)
        {
            window.location.href = '/contact'
        }
        else
        {
            let packageId = pack.id
            var signupDetails = {}
            if(localStorage.getItem('signupDetails') ){
                if( isJSON(localStorage.getItem('signupDetails')) ){
                    signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
                }
                else{
                    signupDetails = localStorage.getItem('signupDetails')
                }
            }
            signupDetails['packageId'] = packageId
            signupDetails['billing'] = _this1.state.plan
            signupDetails['currency'] = _this1.state.currency
            signupDetails['is_custom_plan'] = (pack.is_custom) ? pack.is_custom : false
            signupDetails['custom_emails'] = (pack.custom_emails) ? pack.custom_emails : ''
            if(_this1.state.current_package && _this1.state.current_workspace)
            {
                if(pack && pack.monthly_price > 0 && pack.annual_price > 0)
                {
                    signupDetails['workspace_id'] = _this1.state.current_workspace
                    signupDetails['signup_success'] = true
                    signupDetails['current_package'] = _this1.state.current_package
                    signupDetails['current_workspace'] = _this1.state.current_workspace
                    localStorage.setItem('signupDetails', JSON.stringify(signupDetails))
                    window.location.href = '/billing-cycle';
                }
                else
                {
                    var data = {};                    
                    data['workspace_id'] = _this1.state.current_workspace;
                    data['billing'] = 'annual';

                    data['company'] = '';
                    data['address'] = '';
                    data['country'] = '';
                    data['state'] = '';
                    data['city'] = '';
                    data['pincode'] = '';
                    data['phone'] = '';
                    data['card_number'] = '';
                    data['card_expiry'] = '';
                    data['card_cvv'] = '';

                    const url = masterApiUrl+'packages/buy/'+packageId;
                    // const url = 'http://127.0.0.1:8081/master/packages/buy/'+packageId;

                    _this1.setState({showLoader: true});

                    callByJsonData(data, url, 'POST')
                    .then((resp) => {
                        _this1.setState({showLoader: false});
                        if(resp.record)
                        {
                            signupDetails['workspace_id'] = _this1.state.current_workspace
                            signupDetails['workspace_url'] = resp.record.workspace_url
                            signupDetails['signup_success'] = true
                            localStorage.setItem('signupDetails', JSON.stringify(signupDetails))

                            window.location.href = '/signup#success';
                        }
                        else if(resp.error){
                            _this1.setState({signup_message:resp.error});
                        }
                        else
                        {
                            alert('Something went wrong, please try again.')
                        }
                    }).catch(function (error) {
                        //console.log(error);
                        alert(error);
                        _this1.setState({showLoader:false});
                    });
                    // window.location.href = '/signup#thankyou';
                }
            }
            else
            {
                localStorage.setItem('signupDetails', JSON.stringify(signupDetails))
                window.location.href = '/signup'                
            }
        }
    }
    
    buyButton = (pack) =>{
        var buyButton = 'Subscribe now'
        if(this.state.current_package)
        {
            if(parseInt(this.state.current_package) === parseInt(pack.id))
            {
                buyButton = 'Current Plan'
            }
            else
            {
                const current_index = this.state.packagesList.findIndex(row => parseInt(row.id) === parseInt(this.state.current_package));
                const pack_index = this.state.packagesList.findIndex(row => parseInt(row.id) === parseInt(pack.id));
                if(pack_index >= 0 && current_index >= 0)
                {
                    buyButton = (pack_index < current_index) ? 'Downgrade' : 'Upgrade'
                }
                else
                {
                    buyButton = 'Subscribe now'
                }
                buyButton = (pack.is_call_us_btn && pack.is_call_us_btn === true) ? ('Contact sales') : buyButton
            }
        }
        else
        {
            buyButton = (pack.is_call_us_btn && pack.is_call_us_btn === true) ? ('Contact sales') : ('Subscribe now')

        }
        return buyButton
    }

    buyDisabled = (pack) =>{
        var buyDisabled = ''
        if(this.state.current_package)
        {
            if(parseInt(this.state.current_package) === parseInt(pack.id))
            {
                buyDisabled = 'disabled'
            }
        }
        return buyDisabled
    }

    fixCurrencyPriceDecimal = (price) => {

        let geoInfo = this.state.geoInfo

        let currency = (geoInfo.currency) ? geoInfo.currency : 'INR'

        if(price && price >= 0){

            if(currency === 'INR'){
                price = Math.round(price)
            }
            else{
                price = price.toFixed(2)
            }

        }

        return price
    }

      
    render(){
        
        document.addEventListener('scroll', this.onScroll)
        
        const geoInfo = this.state.geoInfo
        const packageId = this.state.packageId
        const packagesList = this.state.packagesList
        const cmsPagesList = this.state.cmsPagesList

        // console.log('geoInfo=', geoInfo)
        // console.log(geoInfo)
        // console.log('packagesList=', packagesList)
        // console.log('cmsPagesList=', cmsPagesList)

        if(packageId && packageId > 0){

            if(packagesList && packagesList[0]){
                let isPackageCustom = (packagesList[0].is_custom) ? packagesList[0].is_custom : false
            }

        }

        const currency = this.state.currency
        let currencySymbol = '$'
        if(this.state.currency === 'INR'){
            currencySymbol = '&#8377;' // rupee symbol
        }

        return(
            <React.Fragment>
                <Navbar fixed active={2} />
                <header className="fp-header-inner fp-text-center fp-bg-grey fp-header-inner-2">
                    <div className="container">
                       <div className="fp-header-caption">
                            <div className="fp-page-title">Plans <span>{`&`}</span> Pricing</div>
                            <p className="fp-text-lg">Scale your plan to suit your needs. Upgrade or downgrade anytime.</p>
                            <div className="tab-group">
                                <button className={`tab-btn ${this.state.plan == 'monthly'? "active": ""}`} onClick={this.handleMonthlyPlan}>Monthly billing</button>
                                <button className={`tab-btn ${this.state.plan == 'annual'? "active": ""}`} onClick={this.handleAnnuallyPlan}>Annual billing</button>
                            </div>
                       </div>
                    </div>
                </header>
                <section className="fp-section fp-section-mentorship-registration pt-0">
                    <div className="container table-container">
                        {
                            this.state.showLoader ? <Loader /> : ''
                        }
                        <table className={`pricing-and-plan-table ${this.state.scrollTop > 292 ? "sticky":"" }`}>
                            <thead ref={this.tableHead}>
                                <tr>
                                    <th>
                                        <p><span className="tbl-head">Plan {`&`} {this.state.plan == 'monthly'? "monthly": "Yearly"} Price</span> Additional taxes may apply depending on your country. {/*<a href="#">Learn more</a>*/}</p>
                                    </th>
                                    

                                    {
                                        (packagesList && packagesList.length > 0) && (
                                            packagesList.map((pack, idx) => {

                                                let isGstIncluded = (pack.hasOwnProperty('is_gst_included')) ? pack.is_gst_included : true
                                                let gstRate = (pack.hasOwnProperty('gst_rate')) ? parseInt(pack.gst_rate) : 0

                                                let actualPrice = (currency === 'INR') ? pack.actual_price : pack.actual_price_usd
                                                let monthlyPrice = (currency === 'INR') ? pack.monthly_price : pack.monthly_price_usd
                                                let annualPrice = (currency === 'INR') ? pack.annual_price : pack.annual_price_usd

                                                if(monthlyPrice && !isNaN(monthlyPrice)){

                                                    if(currency === 'INR' && monthlyPrice && isGstIncluded === false && gstRate && gstRate > 0){
                                                            monthlyPrice = monthlyPrice * ( (100 + gstRate)/100 )
                                                    }
                                                    if(monthlyPrice){
                                                        {/*monthlyPrice = Math.round(monthlyPrice)*/}
                                                        monthlyPrice = this.fixCurrencyPriceDecimal(monthlyPrice)
                                                    }
                                                    else{
                                                        monthlyPrice = 0
                                                    }

                                                }



                                                if(annualPrice && !isNaN(annualPrice)){

                                                    if(currency === 'INR' && annualPrice && isGstIncluded === false && gstRate && gstRate > 0){
                                                            annualPrice = annualPrice * ( (100 + gstRate)/100 )
                                                    }
                                                    if(annualPrice){
                                                        {/*annualPrice = Math.round(annualPrice)*/}
                                                        annualPrice = this.fixCurrencyPriceDecimal(annualPrice)
                                                    }
                                                    else{
                                                        annualPrice = 0
                                                    }

                                                }

                                                if(isNaN(monthlyPrice)){
                                                    monthlyPrice = 0
                                                }

                                                if(isNaN(annualPrice)){
                                                    annualPrice = 0
                                                }

                                                {/*if(isGstIncluded === false && gstRate && gstRate > 0){
                                                    if(monthlyPrice){
                                                        monthlyPrice = monthlyPrice * ( (100 + gstRate)/100 )
                                                    }
                                                    if(annualPrice){
                                                        annualPrice = annualPrice * ( (100 + gstRate)/100 )
                                                    }
                                                }
                                                if(monthlyPrice){
                                                    monthlyPrice = Math.round(monthlyPrice)
                                                }
                                                if(annualPrice){
                                                    annualPrice = Math.round(annualPrice)
                                                }*/}

                                                {/*console.log('monthlyPrice=', monthlyPrice)
                                                console.log('annualPrice=', annualPrice)*/}

                                                //monthlyPrice = Math.round(monthlyPrice)
                                                //annualPrice = Math.round(annualPrice)

                                                
                                                let perMonthPrice = 0

                                                if(this.state.plan === 'monthly'){
                                                    perMonthPrice = monthlyPrice
                                                }
                                                else{
                                                    if(annualPrice > 0){
                                                        {/*actualPrice = (monthlyPrice * 12)*/}
                                                        actualPrice = monthlyPrice
                                                        {/*perMonthPrice = Math.round(annualPrice / 12)*/}

                                                        perMonthPrice = this.fixCurrencyPriceDecimal((annualPrice / 12))
                                                    }
                                                }

                                                return (

                                                    <th key={idx}>
                                                    <div className="package-head" style={{"--height": `${this.state.tableHeadHeight}px`}}>
                                                        {console.log(this.state.tableHeadHeight)}
                                                        <span className="packege-name">{pack.name}</span>

                                                        {
                                                            (pack.brief && pack.brief.length > 0) && (
                                                                <p className="top-title">{pack.brief}</p>
                                                                )
                                                        }

                                                        
                                                            <div className="stripedPrice">

                                                                {
                                                                    ( (!pack.is_call_us_btn || pack.is_call_us_btn === false) &&annualPrice && annualPrice > 0) ? (
                                                                    <React.Fragment>

                                                                    <span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>

                                                                    {/* <PriceCounter 
                                                                            monthlyPlan = {monthlyPrice}
                                                                            YearlyPlan = {annualPrice}
                                                                            updatePlan = {actualPrice}
                                                                        /> */}
                                                                        {actualPrice}

                                                                    </React.Fragment>
                                                                    ) : ('')
                                                                }
                                                                    
                                                            </div>
                                                        

                                                        <span className="package-price">
                                                            <small>
                                                                {
                                                                    (pack.is_call_us_btn && pack.is_call_us_btn === true) ? <span className="custom-txt">Request Demo</span> : (<span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>)
                                                                }
                                                            </small>

                                                            {
                                                                (!pack.is_call_us_btn || pack.is_call_us_btn === false) && (
                                                                    <PriceCounter 
                                                                        monthlyPlan = {perMonthPrice}
                                                                        YearlyPlan = {perMonthPrice}
                                                                        updatePlan = {perMonthPrice}
                                                                    />
                                                                )
                                                            }
                                                            
                                                        
                                                        </span>

                                                        <div className="green-title">

                                                        {
                                                            ( (!pack.is_call_us_btn || pack.is_call_us_btn === false) && perMonthPrice && perMonthPrice > 0) ? (
                                                                <span>
                                                                per month, when billed {(this.state.plan) === 'monthly'? 'monthly' : 'annually'}
                                                                </span>
                                                                ) : ('')
                                                        }

                                                        {
                                                            (perMonthPrice === 0 && (!pack.is_call_us_btn || pack.is_call_us_btn === false)) ? ('Free forever') : ('')
                                                        }

                                                        </div>

                                                        
                                                        
                                                        <span className="pricing-button"><button className="fp-btn-main fp-btn-sm" onClick={() => this.buyPackage(pack)} disabled={this.buyDisabled(pack)} >{this.buyButton(pack)}</button></span>

                                                        {
                                                            (pack.is_popular && pack.is_popular === true) && (
                                                                <span className="popular-tag">MOST POPULAR</span>
                                                                )
                                                        }
                                                        </div>
                                                    </th>

                                                    )
                                            })
                                            )
                                    }

                                </tr>
                            </thead>
                            <tbody>

                                {(cmsPagesList && cmsPagesList.length > 0) ? (
                                        
                                        <ChildRowTree
                                        level={0}
                                        pages={cmsPagesList}
                                        packagesList={packagesList}
                                        getItemDetails={this.getItemDetails}
                                        getCmsPageDetails={this.getCmsPageDetails}
                                        />
                                    
                                    ):null
                                }
                            

                            </tbody>
                        </table>
                    </div>
                </section>

                <Footer />
            </React.Fragment>
        )
    }
}
export default Plans;


export class ChildRowTree extends Component {
    render(){
        const packagesList = this.props.packagesList
        const pages = this.props.pages

        const level = this.props.level

        const childClassName = (this.props.childClassName) ? this.props.childClassName : ''

        var levelIndent = ''

        // for(var i=0; i<level; i++){
        //     levelIndent = levelIndent+' - '
        // }

        let isPackageCustom = false
        let customPackage = {}

        if(packagesList && packagesList[0]){
            isPackageCustom = (packagesList[0].is_custom) ? packagesList[0].is_custom : false

            if(isPackageCustom === true){
                customPackage = packagesList[0]
            }
        }

        return (

            <React.Fragment>

            {
                (pages && pages.length > 0) && (
                    pages.map((item, index) =>  {

                        let itemCmsPage = this.props.getCmsPageDetails(item)

                        let shortDescription = (itemCmsPage && itemCmsPage.short_description) ? itemCmsPage.short_description : ''

                        {/*console.log('shortDescription=',shortDescription)*/}


                        var boldTitle = {'fontWeight':'600'}

                        if(item.type !== 'main'){
                            boldTitle = {}
                        }

                        if(customPackage && customPackage.is_custom === true){
                            let customItemDetails = this.props.getItemDetails(item, customPackage)
                        

                            if(customItemDetails.status === 'disable'){
                                return null
                            }

                        }

                        return (
                            <React.Fragment key={item.id} >
                            <tr key={index} className={childClassName} >
                                <td>
                                    {levelIndent}
                                    <span style={boldTitle} >
                                    {item.title}

                                    {
                                        (shortDescription && shortDescription.length > 0) && (
                                            <Tooltip content={shortDescription} />
                                            )
                                    }
                                    
                                    </span>
                                </td>

                                {
                                    (packagesList && packagesList.length > 0) && (

                                        packagesList.map((pack, idx) => {
                                            let itemdetails = this.props.getItemDetails(item, pack)
                                            let itemStatus = (itemdetails && itemdetails.status)?itemdetails.status:''
                                            let itemLabel = (itemdetails && itemdetails.label)?itemdetails.label:''
                                            return (
                                                <React.Fragment key={idx}>
                                                
                                                <td>
                                                {
                                                    (itemStatus === 'yes') && (
                                                        (itemLabel.length > 0) ? (itemLabel) : (available)
                                                        )
                                                }
                                                {
                                                    (itemStatus === 'no') && (notAvailable)
                                                }
                                                </td>

                                                </React.Fragment>
                                                )
                                        })

                                        )
                                }
                            
                            </tr>

                            {
                               (item.child_pages && item.child_pages.length > 0) && (

                                <ChildRowTree
                                level={level+1}
                                pages={item.child_pages}
                                packagesList={packagesList}
                                getItemDetails={this.props.getItemDetails}
                                getCmsPageDetails={this.props.getCmsPageDetails}
                                childClassName={"isSubChildren_"+(level+1)}
                                />
                                )
                            }

                            </React.Fragment>
                        )
                        })
                    
                    )
                }


                </React.Fragment>

            )
    }
}