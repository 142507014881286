import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import UserThumb from '../../../images/user1.jpg';
import { getBillingTypeLabel } from './packages-add';

class PackagesList extends Component{

    handleEditPackage = (packageData) => {
        this.props.onEditPackage(packageData);
    }

    handleEditPackageFeatures = (packages) => {
        this.props.onEditPackageFeatures(packages);
    }    

    render(){
        const packagesList = this.props.packagesList;

        // console.log('packagesList=', packagesList)


        let customPackStyle = {'fontSize': '12px', 'fontStyle': 'italic'}


        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table manage-pakage-table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th >Name</th>
                                <th width="15%">Monthly Price (INR)</th>
                                <th width="15%">Annual Price (INR)</th>
                                <th width="15%">Monthly Price (USD)</th>
                                <th width="15%">Annual Price (USD)</th>
                                <th width="15%">Sort Order</th>
                                <th width="10%">Status</th>
                                <th width="10%">Modules</th>
                                <th width="10%"></th>
                            </tr>
                            {packagesList.map((item, index) => 

                                {

                                return (
                                
                                <tr key={index} >
                                    <td>
                                        {item.name}
                                        {
                                            (item.is_custom && item.is_custom === true) && (
                                                <span style={customPackStyle}>&nbsp;(Custom)</span>
                                                )
                                        }
                                    </td>
                                    <td>
                                        {item.monthly_price}
                                    </td>
                                    <td>
                                        {item.annual_price}
                                    </td>
                                    <td>
                                        {item.monthly_price_usd}
                                    </td>
                                    <td>
                                        {item.annual_price_usd}
                                    </td>
                                    <td>
                                        {item.sort_order}
                                    </td>
                                    <td>
                                        {(item.status == true)?'Active':'In-active'}
                                    </td>
                                    <td>
                                        <Link to={`/admin/package-items/${item.id}`} className="button-like-link" >Modules</Link>
                                        {/*<button onClick={() => this.handleEditPackageFeatures(item)} className="button-like-link" >Features</button>*/}
                                    </td>
                                    <td>
                                        <div className="dropdown-parent more-action-box">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <div className="dropdown">
                                                        <div className="dropdown-group">
                                                            <ul className="nav">
                                                                <li><button onClick={() => this.handleEditPackage(item)} className="button-like-link" >Edit</button></li>
                                                                <li><button onClick={() => this.props.onCopyPackage(item)} className="button-like-link" title="Copy this package" >Copy</button></li>
                                                                {
                                                                    (item.is_custom && item.is_custom === true) && (
                                                                        <li><button onClick={() => this.props.activateSendEmail(item)} className="button-like-link" >Send Email</button></li>
                                                                        )
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </td>
                                </tr>
                              )}

                            )}
                        

                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default PackagesList;