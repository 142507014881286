import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch, BrowserRouter} from 'react-router-dom';
import * as firebase from "firebase/app";

/*import SignupEmail from './components/signup/email';
import SignupConfirmEmail from './components/signup/confirm-email';
import SignupCompanyName from './components/signup/company-name';
import SignupName from './components/signup/name';*/

//import Signup from './components/users/signup';
/*import Signup2 from './components/users/signup2';
import Signup3 from './components/users/signup3';*/
/*import InvitePeople from './components/users/invite-people';
import EmailVerification from './components/users/email-verification';
import LoginPhone from './components/users/login-with-phone';
import LoginPhoneVerify from './components/users/login-with-phone-verify';*/
// import Dashboard from './pages/dashboard';


import Refer from './pages/refer'
import Signup from './pages/signup'
import Login from './pages/login/'
import adminLogin from './pages/login/admin-login'
import Home from './pages/front/Home'
import Pricing from './pages/front/Pricing'
import Features from './pages/front/Features'
import Contact from './pages/front/Contact'
import About from './pages/front/About'
import StaticPage from './pages/front/static-page'

import ThemeColor from './theme'
import adminPage from './pages/admin/'

// mentorship
import MentorshipRegistration from './pages/mentorship/mentorshipRegistration'
import BillingCycle from './pages/billing/BillingCycle';

import plansAndPricing from './pages/plansAndPricing/plansAndPricing';
import PlanDetails from './pages/plansAndPricing/planDetails';

import Plans from './pages/plans-and-pricing/plans';


var config = require('./config.js');

const firebaseConfig = {
    apiKey: config.apiKey,
    authDomain: config.authDomain,
    databaseURL: config.databaseURL,
    projectId: config.projectId,
    storageBucket: config.storageBucket,
    messagingSenderId: config.messagingSenderId,
    appId: config.appId
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
      <ThemeColor />
        <Switch>
          {/*<Route exact path="/" component={Signup} /> 
          <Route exact path="/login" component={Login} /> 
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/signup/email" component={SignupEmail} />
          <Route exact path="/" component={Signup} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/admin" component={adminPage} />
          <Route exact path="/admin/:slug" component={adminPage} />
          
          <Route exact path="/login" component={Login} /> 
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/signup/email" component={SignupEmail} />
          <Route exact path="/signup/confirmemail" component={SignupConfirmEmail} />
          <Route exact path="/signup/companyname" component={SignupCompanyName} />
          <Route exact path="/signup/name" component={SignupName} />*/}


          {/*<Route exact path="/academy" render={() => (window.location.href = "https://opengrowth.university/")} />*/}

          {/*<Route exact path='/academy' component={() => { 
            window.location.replace("https://opengrowth.university");
            return null;
          }}/>*/}


          <Route exact path="/refer" component={Refer} />
          <Route exact path="/" component={Home} /> 
          <Route exact path="/pricing" component={Pricing} /> 
          <Route exact path="/features" component={Features} /> 
          <Route exact path="/contact" component={Contact} /> 
          <Route exact path="/about" component={About} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />

          <Route exact path="/refund-cancellation-policy" render={(props) => <StaticPage slug='refund-cancellation-policy' {...props} />} />
          <Route exact path="/terms" render={(props) => <StaticPage slug='terms' {...props} />} />
          <Route exact path="/privacy" render={(props) => <StaticPage slug='privacy' {...props} />} />

          <Route exact path="/page/:slug" render={(props) => <StaticPage {...props} />} />

          
          <Route exact path="/admin" component={adminPage} />
          <Route exact path="/admin/login" component={adminLogin} />
          <Route exact path="/admin/:slug" component={adminPage} />
          <Route exact path="/admin/:slug/:slug" component={adminPage} />
          {/*<Route exact path="/dashboard" component={Dashboard} />*/}



          <Route exact path="/mentorship-registration" component={MentorshipRegistration} />
          <Route exact path="/billing-cycle" component={BillingCycle} />
          <Route exact path="/plans-and-pricing/:packageId?" component={Plans} />
          <Route exact path="/pricing-and-plans" component={plansAndPricing} />
          <Route exact path="/plan-details" component={PlanDetails} />

          <Route exact path="*" render={(props) => <StaticPage {...props} />} />

        </Switch> 
      </BrowserRouter>     
    );
  }
}
export default App;