import React, { Component } from 'react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import CmsList from '../../../components/admin/cms/cms-list'
import AddCms from '../../../components/admin/cms/cms-add';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader'
import axios from 'axios';
var config = require('../../../config.js');
class Cms extends Component{

    constructor(props){
        super(props);
        this.state = {
            showClass:'',
            activeClass:'',
            addCmsActive:false,
            loadingData:true,
            search_text:'',
            cmsList:[],
            editData:{},
        }
    }

    handleChange = (event) => {
        var name = event.target.name;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else
        {
            this.setState({[name]: event.target.value});
        }
    }

    componentDidMount(){
        this.getCms();
    }

    getCms = async () => {
        var _this1 = this;
        var data = {};
        data['cursor'] = 0;
        data['limit'] = 100;
        if(_this1.state.search_text)
        {
            data['search_text'] = _this1.state.search_text;
        }
        axios({
            method: 'post',
            url: config.master_api_url+'cms/list',
            data: data,
            headers:{'content-type': 'application/json'}
        }).then(function (resp) {
            if(resp.data.result){
                _this1.setState({cmsList:resp.data.result});
            }
            _this1.setState({loadingData:false});
        }).catch(function (error) {
            //console.log(error);
            alert(error);
            _this1.setState({loadingData:false});
        });
    }

    handleCreateCms = () => {
        if(this.state.addCmsActive === true) {
            setTimeout(() => {
                this.setState({showClass: ""});
            },20);
            setTimeout(() => {
                this.setState({activeClass: ""})
                this.setState({addCmsActive: false})
            },620);
        } else {
            this.setState({addCmsActive: true})
            this.setState({activeClass: "active"});
            setTimeout(() => {
                this.setState({showClass: "show"});
            },20);
        }
    }

    cancelAddCms = () => {
        var editData = {}
        this.setState({editData});
        this.handleCreateCms();
    }

    cmsAdded = (cms) => {
        this.state.cmsList.push(cms);
        this.handleCreateCms();
    }

    cmsEdit = (cms) => {
        this.setState({editData: cms});
        this.handleCreateCms();
    }

    cmsEdited = (cms) => {
        const state = this.state.cmsList;
        const index = state.findIndex(row => row.id === cms.id);
        state[index] = cms;
        this.setState({cmsList: state});
        this.handleCreateCms();
    }

    cmsDeleted = (cms) => {
        var state = this.state.cmsList;
        const index = state.findIndex(row => row.id === cms.id);
        if (index === -1) {
            state = [];
        }
        state = [...state.slice(0, index), ...state.slice(index + 1)];
        this.setState({cmsList: state});
    }

    render(){

    return (
    <div className="page-main-holder">
        <div className="main-top top-full"><div className="page-title"><div className="page-title-box"><h2>Manage Cms</h2></div></div></div>
        <div className="container-area">
            {
                this.state.loadingData ? <Loader /> : ''
            }
            <div className="inv-body">
                <div className="box">
                    {/*<div className="box-title">
                        <div className="box-filter-left flex align-items-center">
                            <div className="sort-box box-search flex-1">
                                <div className="filter-search-box">
                                    <i className="icon-search"></i>
                                    <input type="text" id="search_member" onKeyUp={this.searchByName} className="form-style form-style-sm input-filter-search" placeholder="Search members" />
                                </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="box-title">
                        <div className="section-btn-outer align-self-end">
                            <button onClick={this.handleCreateCms} className="btn-main btn-lg">Create New Cms</button>
                        </div>
                    </div>

                    <div className="box-body">
                        <CmsList state={this.state} cmsEdit={this.cmsEdit} />
                    </div>
                    {
                        this.state.addCmsActive ? (
                            <AddCms state={this.state} cancelAddCms={this.cancelAddCms} cmsAdded={this.cmsAdded} cmsEdited={this.cmsEdited} />
                        ) : null
                    }
                </div>
            </div>
        </div>
    </div>
    );
    }
}
export default Cms;