import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoMain from '../../logo-color.png';

import Navbar from '../front/Navbar'
import Footer from '../front/Footer'

class CompanyName extends Component {

    constructor(props) {
        super(props);

        var signupDetails = (props.state.signupDetails)?props.state.signupDetails:{}

        var workspace_url = (signupDetails.workspace_url)?signupDetails.workspace_url:''
        var signup_success = (signupDetails.signup_success)?signupDetails.signup_success:false

        this.state = {
          err_msg:'',
          workspace_url: workspace_url,
        };

        if(signupDetails && signupDetails.email){
          localStorage.clear();
          sessionStorage.clear();
        }
        else{
          // window.location = './signup';          
        }
    }    

  render() {
    return (
      <React.Fragment>
        <Navbar fixed hideNav />
          <div className="main-full-inner flex align-items-center justify-content-center container">
            <div className="singup-box2">
              <div className="login-logo"><img src={logoMain} alt="OpenGrowth" /></div>
              <div className="login-box-inner">
                  <div className="form-group-box">
                      <div className="user-form-box login-form-main">

                      {(this.state.err_msg) ? (
                            <div className="msg-error">{this.state.err_msg}</div>
                            ) : ('')}

                              <div className="user-form-inner">
                                  <input type="radio" name="company-type" id="company-yes" value="Yes" className="radio_action" defaultChecked />
                                  <input type="radio" name="company-type" id="company-no" value="Yes" className="radio_action" />
                                  <div className="is-company-name text-center flex justify-content-center">
                                      <h3 className="title">Thank You now you are a member of open growth family</h3>
                                  </div>
                                  <p>Please check your email for further instructions.</p>
                                  <div className="input-box mb0">
                                      {/*<button type="button" className="btn-main btn-form btn-submit btn-full" onClick={this.handleSubmit}>Continue</button>*/}
                                      <a href={this.state.workspace_url} className="btn-main btn-form btn-submit btn-full">Continue</a>
                                  </div>
                              </div>
                              
                      </div>
                  </div>
              </div>
            </div>
          </div>
        <Footer hideNav />
      </React.Fragment>  
    );
  }
}

export default CompanyName;
