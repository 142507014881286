import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { dateTimeToDisplay } from '../../../utils.js';
class WorkspaceList extends Component{

    constructor(props){
        super(props)

        this.state = {
            showDeletedWorkspaces: false,
        }
    }

    workspaceEdit = (workspace) => {
        this.props.workspaceEdit(workspace);
    }

    workspacePermissions = (workspace) => {
        this.props.workspacePermissions(workspace);
    }

    workspaceLogin = (workspaceData) => {
        this.props.workspaceLogin(workspaceData);
    }

    workspaceDelete = (workspaceData) => {
        //this.props.workspaceDelete(workspaceData);
        this.props.workspaceDelete(workspaceData);
    }

    componentWillReceiveProps(nextProps){
        // console.log('nextProps=',nextProps)

        if(nextProps.state.showDeletedWorkspaces === true || nextProps.state.showDeletedWorkspaces === false){
            this.setState({showDeletedWorkspaces:nextProps.state.showDeletedWorkspaces})
        }



    }

    

    render(){
        // console.log('this.state.showDeletedWorkspaces=',this.state.showDeletedWorkspaces)
        const allWorkspaceList = this.props.state.workspaceList;

        var workspaceList = []

        if(this.state.showDeletedWorkspaces === false){
            workspaceList = allWorkspaceList.filter((item) => (!item.is_deleted || item.is_deleted === 0))
        }
        else{
            workspaceList = allWorkspaceList.filter((item) => (item.is_deleted && item.is_deleted === 1))
        }

        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Company Name</th>
                                <th>Workspace</th>
                                <th>Email</th>
                                <th>Plan</th>
                                <th></th>
                            </tr>
                            {workspaceList.map((item, index) => (
                            <tr key={index} >
                                <td width="30%">
                                    <div className="user-with-thumb flex align-items-center">
                                        <div className="user-thumb thumb-lg"><img src={(item.logo_url) ? item.logo_url : UserThumb} alt={item.company_name} /></div>
                                        <div className="user-text">
                                            <div className="user-name">
                                            {item.company_name}
                                            </div>
                                            <div className="member-email">{item.name}</div>
                                        </div>
                                    </div>
                                </td>
                                <td width="30%">
                                    <span className="label label-danger2 member_type_class">
                                    {item.workspace}
                                    </span>
                                </td>
                                <td width="15%">
                                    <div>
                                        {item.email_address}
                                    </div>
                                </td>
                                <td width="15%">
                                    <div>
                                        {(item.active_order_id)&&(
                                            <React.Fragment>
                                                <a href={`/admin/order-items/${item.active_order_id}`} target = "_blank">{item.active_plan}</a>
                                                <br />
                                                {(item.active_plan_date)&&(dateTimeToDisplay(item.active_plan_date))}
                                            </React.Fragment>)}
                                    </div>
                                </td>
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.workspaceEdit.bind(this, item)} >View Details</a></li>
                                                            <li><a href={`/admin/orders?workspace_id=${item.id}`} >View Orders</a></li>

                                                            {
                                                                (this.state.showDeletedWorkspaces === false) && (

                                                                    <React.Fragment>

                                                                    <li><a href="#" onClick={this.workspacePermissions.bind(this, item)} >Manage Permissions</a></li>

                                                            <li><a onClick={() => this.workspaceLogin(item)}>Workspace Login</a></li>

                                                            </React.Fragment>

                                                                    )
                                                            }
                                                            
                                                            {(item.is_deleted!==1) && (
                                                            <li><a onClick={() => this.workspaceDelete(item)}>Workspace Delete</a></li>
                                                            )
                                                        }
                                                            {/*<li><a href={'http://' + item.workspace + '.localhost:3001/impersonate?workspace='+ item.workspace} target = "_blank">Workspace Login</a></li>*/}
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default WorkspaceList;