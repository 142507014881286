import React, {Component} from 'react';

class BillingProgress extends Component{
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render(){
        return(
            <ul className="billing-progress" style={{"--progress": (this.props.step == 1 ? 0 : this.props.step == 2 ? 50 : 100 ) + '%'}}>
                <li className={this.props.step >= 1 ? 'active' : ""}>Billing Cycle</li>
                <li className={this.props.step >= 2 ? 'active' : ""}>Add Details</li>
                <li className={this.props.step >= 3 ? 'active' : ""}>Make Payment</li>
            </ul>
        )
    }

}
export default BillingProgress;