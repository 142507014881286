import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
var md5 = require('md5');
var config = require('../../../config.js');
class AddCoach extends Component{
    constructor(props) {
        super(props);
        var newData = {
            'id':'',
            'name':'',
            'designation':'',
            'description':'',
            'image':'',
            'connect':'',
            'status':true,
            'image_delete':false,
        };
        this.state = {
            editData: (props.state.editData && props.state.editData.id) ? props.state.editData : newData,
            errors: {},
            file: '',
            fileUrl: (props.state.editData && props.state.editData.id) ? props.state.editData.image : '',
        };
        // console.log('props.state');
        // console.log(props.state);
        this.handleChange = this.handleChange.bind(this);
    }        

    handleChange(event){
        var editData = this.state.editData
        var name = event.target.name;
        // alert(name);
        if(event.target.type === 'checkbox')
        {
            editData[name] = (event.target.checked) ? true : false
        }
        else
        {
            editData[name] = event.target.value;
        }
        //alert(event.target.value)
        // console.log('editData')
        // console.log(editData)
        this.setState({editData});
    }


  handleImageChange = (event) => {
    var fileUrl = URL.createObjectURL(event.target.files[0]);
    var file = event.target.files[0];
    this.setState({file:file,fileUrl:fileUrl});
  }
  removePic = () => {
    this.setState({file:'',fileUrl:'',image_delete:true});
  }

    handleSubmit = async () => {
        var _this1 = this;
        if(this.state.editData && this.state.editData.username !== '' && this.state.editData.name !== '' && this.state.editData.email !== '')
        {            
            // const data = new FormData();
            // const data = _this1.state.editData;

            const data = new FormData();
            data.append('name', ((_this1.state.editData.name) ? _this1.state.editData.name : ''));
            data.append('designation', ((_this1.state.editData.designation) ? _this1.state.editData.designation : ''));
            data.append('description', ((_this1.state.editData.description) ? _this1.state.editData.description : ''));
            data.append('connect', ((_this1.state.editData.connect) ? _this1.state.editData.connect : ''));
            data.append('status', ((_this1.state.editData.status) ? _this1.state.editData.status : false));
            data.append('image', _this1.state.file);
            data.append('image_delete', _this1.state.image_delete);
            var url = config.master_api_url+'coach/add';
            if(_this1.state.editData && _this1.state.editData.id !== '')
            {
                url = config.master_api_url+'coach/update/'+_this1.state.editData.id;
            }
            axios({
                method: 'post',
                url: url,
                data: data,
                headers:{'content-type': 'multipart/form-data'}
                //headers:{'content-type': 'application/json'}
            }).then(function (response) {
                //console.log(response);
                if(response.data.status === 'success')
                {                    
                    if(_this1.state.editData && _this1.state.editData.id !== '')
                    {
                        _this1.props.coachEdited(response.data.record);
                    }
                    else
                    {
                        _this1.props.coachAdded(response.data.record)
                    }                   
                }
                else if(response.data.message)
                {
                    alert(response.data.message);
                }
                else
                {
                    alert('Something went wrong, please try again.');
                }
            }).catch(function (error) {
                //console.log(error);
                alert(error);
            });
        } else {
            var errors = {}
            if(_this1.state.editData.name === ''){
                errors['name'] = 'Please enter name';
            }
            this.setState({errors})
        }        
    }

    render(){
        var selected_groups_id = (this.state.editData && this.state.editData.groups_id) ? this.state.editData.groups_id : 3;
        //console.log('group_options')
        //console.log(group_options)
        return(
            <React.Fragment>
                <div className={"form-panel " + this.props.state.activeClass + " " + this.props.state.showClass + " " + this.state.formScrollable}>
                    <form action="" className="form-main">
                        <div className="form-data">
                            <div className="form-body">
                                <div className="form-header">
                                    <h2> {(this.state.editData && this.state.editData.id) ? 'Update': 'Add'} Coach</h2>
                                </div>
                                <div className="form-fields-box">
                                    <div className="user-input-img-box flex">
                                        <div className="user-input-box flex-auto">
                                          <div className="input-box">
                                            <label>Coach name</label>
                                            <input type="text" name="name" placeholder="Enter coach name" className="form-style" value = {(this.state.editData && this.state.editData.name) ? this.state.editData.name : '' } onChange={this.handleChange}/>
                                          </div>
                                          <div className="input-box">
                                            <label>Coach designation</label>
                                            <input type="text" name="designation" placeholder="Enter coach designation" className="form-style" value = {(this.state.editData && this.state.editData.designation) ? this.state.editData.designation : '' } onChange={this.handleChange}/>
                                          </div>
                                        </div>
                                        <div className="user-img-box">
                                          <div className="input-box upload-pic-box">
                                            <label>Upload profile image</label>
                                            <div className="upload-pic-body">
                                              {
                                                (this.state.fileUrl === '') ? (
                                                  <div className="upload-btn btn-main btn-outline">Select <input type="file" onChange={this.handleImageChange} /></div>
                                                ) : (
                                                  <div className="uploaded-img-preview">
                                                  <img src={this.state.fileUrl} />
                                                  <span className="remove-uploaded msg-error" onClick={this.removePic}>X Remove</span>
                                                </div>
                                                )
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="input-box">
                                        <label>Coach detail</label>
                                        <textarea className="form-style" name="description" value = {(this.state.editData && this.state.editData.description) ? this.state.editData.description : '' } placeholder="Coach detail..." onChange={this.handleChange}></textarea>
                                      </div>
                                      <div className="input-box">
                                        <label>Connect</label>
                                        <input type="text" name="connect" placeholder="Connect" className="form-style" value = {(this.state.editData && this.state.editData.connect) ? this.state.editData.connect : '' } onChange={this.handleChange} />
                                      </div>                                    
                                    
                                    <div className="input-box">
                                        <label className="checkbox"><input type="checkbox" name="status" onChange={this.handleChange} checked={(this.state.editData && this.state.editData.status === true) ? true : false} /> <span>Active</span></label>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="form-action">
                            <div className="form-body">
                                <button type="button" onClick={this.props.cancelAddCoach} className="btn-main btn-outline btn-form btn-cancel" id="cancelBtn">Cancel</button>
                                <button type="button" className="btn-main btn-form btn-submit" onClick={this.handleSubmit}>{(this.state.editData && this.state.editData.id) ? 'Update': 'Add'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.props.state.addCoachActive ? <div className="form-overlay active"></div> : null
                }
            </React.Fragment>
        )
    }
}
export default AddCoach;