import React, { Component } from 'react';
import UserThumb from '../../../images/user1.jpg';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
class CoachList extends Component{

    coachEdit = (coach) => {
        this.props.coachEdit(coach);
    }

    render(){
        const coachList = this.props.state.coachList;
        return(
            <React.Fragment>
                <div className="table-box">
                    <table className="table" cellSpacing="0" cellPadding="0" id="listBox">
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            {coachList.map((item, index) => (
                            <tr key={index} >
                                <td width="30%">

                                    <div className="user-with-thumb flex align-items-center">
                                        <div className="user-thumb thumb-lg"><img src={(item.image) ? item.image : UserThumb} alt={item.name} /></div>
                                        <div className="user-text">
                                            <div className="user-name">
                                            {item.name}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td width="30%">
                                {item.designation}
                                </td>
                                <td width="30%">
                                    {(item.status == true)?'Active':'In-active'}
                                </td>
                                <td width="10%">
                                    <div className="dropdown-parent more-action-box">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle tag="span" className="dropdown-action more-action"><i className="icon-nav-dots"></i></DropdownToggle>
                                            <DropdownMenu>
                                                <div className="dropdown">
                                                    <div className="dropdown-group">
                                                        <ul className="nav">
                                                            <li><a href="#" onClick={this.coachEdit.bind(this, item)} >Edit</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
        </React.Fragment>
        )
    }
}
export default CoachList;