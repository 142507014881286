import React, {Component} from 'react';
import BillingProgress from './billing-progress';
import { countriesList } from '../../countries-list.js';
import { statesList } from '../../states-list.js';
import { callByJsonData, getGeoInfo } from './../../ajax-call.js';
import Loader from '../../components/loader'
var config = require('../../config.js');
const api_url = config.api_url;
const masterApiUrl = config.master_api_url
// const masterApiUrl = 'http://127.0.0.1:8081/master/'

var countriesArr = []
if(Object.keys(countriesList).length > 0){
    for(var c in countriesList){
        countriesArr.push({value: c, label:countriesList[c]})
    }
}

const BackIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 640"><defs/><path d="M189.3 128.4L89 233.4c-6 5.8-9 13.7-9 22.4s3 16.5 9 22.4l100.3 105.4c11.9 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2L184.4 288h217c16.9 0 30.6-14.3 30.6-32s-13.7-32-30.6-32h-217l48.2-50.4c11.9-12.5 11.9-32.7 0-45.2-12-12.5-31.3-12.5-43.3 0z"/></svg>
const PriceCards = []

function isJSON(data) {
   var ret = true;
   try {
      JSON.parse(data);
   }catch(e) {
      ret = false;
   }
   return ret;
}

class BillingCard extends Component{
    constructor(props) {
        super(props);        

        var signupDetails = {}
        if( isJSON(localStorage.getItem('signupDetails')) ){
          signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
        }
        else{
          signupDetails = localStorage.getItem('signupDetails')
        }
        console.log('signupDetails=',signupDetails)

        this.state = {
            activeStep: 1,
            packageId: (signupDetails['packageId']) ? signupDetails['packageId'] : '',
            workspace_id: (signupDetails['workspace_id']) ? signupDetails['workspace_id'] : '',
            current_workspace: (signupDetails['current_workspace']) ? signupDetails['current_workspace'] : '',
            PriceCards: [],
            billing: (signupDetails['billing']) ? signupDetails['billing'] : '',
            coupon: '',
            currency: (signupDetails['currency']) ? signupDetails['currency'] : 'INR',
            current_package: (signupDetails['current_package']) ? signupDetails['current_package'] : '',
            selectedPrice: '',

            company: '',
            address: '',
            country: '',
            state: '',
            city: '',
            pincode: '',
            phone: '',

            card_number: '4444333322221111',
            card_expiry: '12/22',
            card_cvv: '123',

            formErrors: {},
            geoInfo: {},
        };
    }

    componentDidMount(){
        this.getPackageDetails();        

        getGeoInfo()
        .then((geoInfo) => {
            this.setState({geoInfo})
        })
    }



    handleChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        if(event.target.type === 'checkbox')
        {
            this.setState({[name]: (event.target.checked) ? true : false});
        }
        else if(name === 'country'){
            var country = value;
            var state = '';
            var city = '';
            var statesArr = []
            if(value)
            {
                if(Object.keys(statesList).length > 0){
                    for(var c in statesList){
                        // console.log(c)
                        if(c === value)
                        {
                            statesList[c].forEach((ct)=>{
                                // console.log(ct)
                                statesArr.push({value: ct, label:ct})
                            })
                        }
                    }
                }            
            }
            this.setState({country, state, city, statesArr});
          }else if(name === 'state'){
            if(value === 'Other' && this.state.statesArr && this.state.statesArr.length > 0)
            {
                var statesArr = []
                var state = '';
                this.setState({state, statesArr});
            }
            else
            {
                var state = value;
                this.setState({state});
            }
          }
        else
        {
            this.setState({[name]: event.target.value});
        }

        if(event.target.type === 'radio')
        {
            /*var billing = event.target.value
            let selectedPrice = this.state.PriceCards.map((selectedPrice)=>selectedPrice.duration === billing?selectedPrice.price:"")
            this.setState({
                selectedPrice: selectedPrice
            })*/
            //event.target.nextSibling.children[0].children[0].innerText
            this.calcSelectedPrice(null, event.target.value, null)
        }

    }

    handleNextStep = () => {
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    handlePrevStep = () => {
        if(this.state.activeStep >= 2){

            this.setState({
                activeStep: this.state.activeStep - 1
            })
        }
    }

    getPackageDetails = () => {
        let _this1 = this
        var data = {};

        data['is_package_items'] = true

        let url = masterApiUrl+'packages/get/'+this.state.packageId
        let currency = this.state.currency
        callByJsonData(data, url, 'POST')
        .then((resp) => {
            if(resp.result){
                _this1.setState({packageData: resp.result});
                _this1.calcSelectedPrice(resp.result, null, null)
            }
        })
        .catch((error) => {
            console.log('packages-details-error=', error);
        });

    }

    handleBillingCycle = (event) => {
        // alert(this.state.priceCard)
        event.preventDefault();
        var _this1 = this;
        if(this.validateBillingCycle())
        {
            this.setState({
                activeStep: 2
            })
        }
        else
        {
            return false;
        }
    }

    validateBillingCycle(){
      var errorsArr = []
      var formErrors = []
      var errorCount = 0

      if(this.state.billing === ''){
        formErrors['billing'] = 'Please select billing cycle.'
        errorCount++
      }

      if(errorCount > 0){
        this.setState({formErrors});
        return false;
      }

      this.setState({formErrors});
      return true
    }



    handleCouponApply = (event, handle) => {
        // alert(this.state.priceCard)
        event.preventDefault();
        var _this1 = this;
        let currency = _this1.state.currency
        let billing = _this1.state.billing
        let pack = _this1.state.packageData
        if(handle === 1)
        {            
            if(this.state.coupon)
            {
                _this1.setState({loadingData: true, coupon_error:'', percentage: 0});

                var selectedPrice = 0
                var coupon_amount = 0
                // alert('selectedPrice=',selectedPrice)
                // alert('currency=',currency)

                var data = {};            
                data['workspace_id'] = _this1.state.workspace_id;
                data['package_id'] = _this1.state.packageId;
                data['billing'] = _this1.state.billing;
                data['currency'] = _this1.state.currency;
                data['coupon'] = _this1.state.coupon;
                const url = masterApiUrl+'coupons/apply';            

                callByJsonData(data, url, 'POST')
                .then((resp) => {
                    _this1.setState({loadingData: false});
                    if(resp.status && resp.status === 'success' && resp.percentage && parseInt(resp.percentage) > 0){

                        var percentage = parseInt(resp.percentage)
                        _this1.setState({percentage:percentage})
                        _this1.calcSelectedPrice(null, null, percentage)
                    }
                    else if(resp.error){
                        _this1.setState({coupon_error:resp.error});
                    }
                    else
                    {
                        alert('Something went wrong, please try again.')
                    }
                }).catch(function (error) {
                  console.log('coupon-applyerror=',error);
                  alert('Something went wrong, please try again.')
                  _this1.setState({loadingData:false});
                });
            }
            else
            {
                alert('Please enter coupon code')
            }
        }
        else
        {
            var conf = window.confirm('Are you sure to remove coupon?')
            if(conf)
            {
                let percentage = 0
                _this1.setState({percentage:percentage, coupon:''})
                _this1.calcSelectedPrice(null, null, percentage)                
            }
        }
    }

    calcSelectedPrice = (pack, billing, percentage) => {
        var currency = this.state.currency
        pack = (pack !== null) ? pack : this.state.packageData
        billing = (billing !== null) ? billing : this.state.billing
        percentage = (percentage !== null) ? parseInt(percentage) : parseInt(this.state.percentage)

        var selectedPrice = 0
        var coupon_amount = 0
        // console.log('pack=',pack)
        if(pack)
        {
            let isGstIncluded = (pack.hasOwnProperty('is_gst_included')) ? pack.is_gst_included : true
            let gstRate = (pack.hasOwnProperty('gst_rate')) ? parseInt(pack.gst_rate) : 0
            if(isGstIncluded === true || currency !== 'INR')
            {
                gstRate = 0
            }

            let actualPrice = (currency === 'INR') ? pack.actual_price : pack.actual_price_usd
            let monthlyPrice = (currency === 'INR') ? pack.monthly_price : pack.monthly_price_usd
            let annualPrice = (currency === 'INR') ? pack.annual_price : pack.annual_price_usd

            if(monthlyPrice && !isNaN(monthlyPrice)){

                if(billing === 'monthly' && percentage > 0)
                {
                    coupon_amount = (monthlyPrice * percentage)/100
                    selectedPrice = monthlyPrice * ((100 - percentage)/100)
                }

                if(monthlyPrice && isGstIncluded === false && gstRate && gstRate > 0){
                        monthlyPrice = monthlyPrice * ( (100 + gstRate)/100 )
                        if(billing === 'monthly' && percentage > 0)
                        {
                            selectedPrice = selectedPrice * ( (100 + gstRate)/100 )
                        }
                }
                if(monthlyPrice){
                    monthlyPrice = (currency === 'INR') ? Math.round(monthlyPrice) : monthlyPrice.toFixed(2)
                }
                else{
                    monthlyPrice = 0
                }
                // alert('selectedPrice.2222='+selectedPrice+" / percentage ="+percentage)
                if(percentage===100)
                {
                }
                else
                {
                if(billing === 'monthly' && selectedPrice === 0)
                {
                    selectedPrice = monthlyPrice
                }
                }
                
                // alert('selectedPrice.3333='+selectedPrice)
            }


            if(annualPrice && !isNaN(annualPrice)){
                if(billing === 'annual' && percentage > 0)
                {
                    coupon_amount = (annualPrice * percentage)/100
                    selectedPrice = annualPrice * ( (100 - percentage)/100 )
                }
                if(annualPrice && isGstIncluded === false && gstRate && gstRate > 0){
                    annualPrice = annualPrice * ( (100 + gstRate)/100 )
                    if(billing === 'annual' && percentage > 0)
                    {
                        selectedPrice = selectedPrice * ( (100 + gstRate)/100 )
                    }
                }
                if(annualPrice){
                    annualPrice = (currency === 'INR') ? Math.round(annualPrice) : annualPrice.toFixed(2)
                }
                else{
                    annualPrice = 0
                }
                if(billing === 'annual' && selectedPrice === 0)
                {
                    selectedPrice = annualPrice
                }
            }

            if(isNaN(monthlyPrice)){
                monthlyPrice = 0
            }

            if(isNaN(annualPrice)){
                annualPrice = 0
            }

            if(coupon_amount){
                coupon_amount = (currency === 'INR') ? Math.round(coupon_amount) : coupon_amount.toFixed(2)
            }

            let PriceCards = [
                {
                    price: monthlyPrice,
                    actualPrice: actualPrice,
                    perMonthPrice: monthlyPrice,
                    duration: 'monthly',
                    selected: (billing === 'monthly') ? true : false,
                },
                {
                    price: annualPrice,
                    actualPrice: monthlyPrice,
                    perMonthPrice: (currency === 'INR') ? Math.round(annualPrice / 12) : (annualPrice / 12).toFixed(2),
                    duration: 'annual',
                    selected: (billing === 'annual') ? true : false,
                }
            ]
            // alert('selectedPrice='+selectedPrice)
            selectedPrice = (currency === 'INR') ? Math.round(selectedPrice) : selectedPrice.toFixed(2)
            this.setState({PriceCards, selectedPrice, gstRate, coupon_amount});
        }   
    }

    handleAddDetails = (event) => {
        event.preventDefault();
        var _this1 = this;
        if(this.validateAddDetails())
        {
            this.setState({
                activeStep: 3
            })
        }
        else
        {
            return false;
        }
    }

    validateAddDetails(){
      var errorsArr = []
      var formErrors = []
      var errorCount = 0

      var company = this.state.company

      if(this.state.company === ''){
        formErrors['company'] = 'Please enter company.'
        errorCount++
      }
        else if(this.state.company.length < 2 || this.state.company.length > 100){
            formErrors['company'] = 'Company name should contain minimum 2 and maximum 100 characters.'
            errorCount++
        }

      if(this.state.address === ''){
        formErrors['address'] = 'Please enter address.'
        errorCount++
      }
      else if(this.state.address.length < 2 || this.state.address.length > 100){
            formErrors['address'] = 'Company name should contain minimum 2 and maximum 100 characters.'
            errorCount++
        }

      if(this.state.country === ''){
        formErrors['country'] = 'Please select country.'
        errorCount++
      }

      if(this.state.state === ''){
        formErrors['state'] = 'Please select / enter state.'
        errorCount++
      }

      if(this.state.city === ''){
        formErrors['city'] = 'Please enter city.'
        errorCount++
      }

      if(this.state.pincode === ''){
        formErrors['pincode'] = 'Please enter pincode.'
        errorCount++
      }

      // if(this.state.phone && this.state.phone.length > 0){
        
        if(!this.state.phone || this.state.phone.trim() === ''){
         //   formErrors['phone'] = 'Please enter phone.'
           // errorCount++
        }
        else if(isNaN(this.state.phone)){
            formErrors['phone'] = 'Please enter valid phone.'
            errorCount++
        }
        else if( this.state.phone.length < 8 || this.state.phone.length > 10){
            formErrors['phone'] = 'Phone should be min. 8 and max. 10 digit number.'
            errorCount++
        }
      // }


      if(errorCount > 0){
        this.setState({formErrors});
        return false;
      }

      this.setState({formErrors});
      return true
    }

    handleMakePayment = (event) => {
        event.preventDefault();
        var _this1 = this;
        if(this.validateMakePayment()){
            // alert('Hi');

            var signupDetails = {}
            if(localStorage.getItem('signupDetails') ){
                if( isJSON(localStorage.getItem('signupDetails')) ){
                    signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
                }
                else{
                    signupDetails = localStorage.getItem('signupDetails')
                }
            }

            var data = {};            
            data['workspace_id'] = _this1.state.workspace_id;
            data['billing'] = _this1.state.billing;
            data['currency'] = _this1.state.currency;
            data['coupon'] = _this1.state.coupon;

            data['company'] = _this1.state.company;
            data['address'] = _this1.state.address;
            data['country'] = _this1.state.country;
            data['state'] = _this1.state.state;
            data['city'] = _this1.state.city;
            data['pincode'] = _this1.state.pincode;
            data['phone'] = _this1.state.phone;
            data['card_number'] = _this1.state.card_number;
            data['card_expiry'] = _this1.state.card_expiry;
            data['card_cvv'] = _this1.state.card_cvv;

            const url = masterApiUrl+'packages/buy/'+_this1.state.packageId;
            // const url = 'http://127.0.0.1:8081/master/packages/buy/'+_this1.state.packageId;

            // console.log('data=',data)
            // window.location.href = '/signup#thankyou';
            // return false;

            _this1.setState({loadingData: true});


            callByJsonData(data, url, 'POST')
            .then((resp) => {
                _this1.setState({loadingData: false});
                if(resp.record){
                    if(_this1.state.current_workspace && parseInt(_this1.state.current_workspace) === parseInt(_this1.state.workspace_id))
                    {
                        signupDetails['workspace_id'] = _this1.state.workspace_id
                        signupDetails['workspace_url'] = resp.record.workspace_url
                        signupDetails['signup_success'] = true
                        localStorage.setItem('signupDetails', JSON.stringify(signupDetails))

                        window.location.href = '/signup#success';
                    }
                    else
                    {
                        window.location.href = '/signup#thankyou';
                    }
                    
                }
                else if(resp.error){
                    _this1.setState({signup_message:resp.error});
                }
                else
                {
                    alert('Something went wrong, please try again.')
                }
            }).catch(function (error) {
                  //console.log(error);
                  alert(error);
                  _this1.setState({loadingData:false});
              });
        }
        else
        {
            return false;
        }      
    }

    handleGeneratePaymentLink = (event) => {
        event.preventDefault();
        var _this1 = this;
        if(this.validateMakePayment()){
            // alert('Hi');

            //commented 573-574 on then on 21 nov
			//window.location.href = '/signup#payment-link-generated';
            //return false

            var signupDetails = {}
            if(localStorage.getItem('signupDetails') ){
                if( isJSON(localStorage.getItem('signupDetails')) ){
                    signupDetails = JSON.parse(localStorage.getItem('signupDetails'))
                }
                else{
                    signupDetails = localStorage.getItem('signupDetails')
                }
            }

            let packageId = this.state.packageId

            var data = {};            
            data['workspace_id'] = _this1.state.workspace_id;
            data['billing'] = _this1.state.billing;
            data['currency'] = _this1.state.currency;
            data['coupon'] = _this1.state.coupon;

            data['company'] = _this1.state.company;
            data['address'] = _this1.state.address;
            data['country'] = _this1.state.country;
            data['state'] = _this1.state.state;
            data['city'] = _this1.state.city;
            data['pincode'] = _this1.state.pincode;
            data['phone'] = _this1.state.phone;
            data['card_number'] = _this1.state.card_number;
            data['card_expiry'] = _this1.state.card_expiry;
            data['card_cvv'] = _this1.state.card_cvv;

            let url = masterApiUrl+'packages/generate_payment_link/'+packageId;
            // let url = 'http://127.0.0.1:8081/master/packages/generate_payment_link/'+packageId;

            // console.log('data=',data)
            // window.location.href = '/signup#thankyou';
            // return false;

            _this1.setState({loadingData: true});


            callByJsonData(data, url, 'POST')
            .then((resp) => {
                _this1.setState({loadingData: false});
                if(resp.record){
                    if(_this1.state.current_workspace && parseInt(_this1.state.current_workspace) === parseInt(_this1.state.workspace_id))
                    {
                        signupDetails['workspace_id'] = _this1.state.workspace_id
                        signupDetails['workspace_url'] = resp.record.workspace_url
                        signupDetails['signup_success'] = true
                        localStorage.setItem('signupDetails', JSON.stringify(signupDetails))

                        // window.location.href = '/signup#success';
                        window.location.href = '/signup#payment-link-generated';
                    }
                    else
                    {
                        // window.location.href = '/signup#thankyou';
                        window.location.href = '/signup#payment-link-generated';
                    }
                    
                }
                else if(resp.error){
                    _this1.setState({signup_message:resp.error});
                }
                else
                {
                    alert('Something went wrong, please try again.')
                }
            }).catch(function (error) {
                  //console.log(error);
                  alert(error);
                  _this1.setState({loadingData:false});
              });
        }
        else
        {
            return false;
        }      
    }

    validateMakePayment(){
      var errorsArr = []
      var formErrors = []

      var errorCount = 0

      let cardNumberRegex = /^\+?([0-9]{2})\)?[]?([0-9]{4})[]?([0-9]{4})$/;

      if(this.state.card_number === ''){
        formErrors['card_number'] = 'Please enter card number.'
        errorCount++
      }
        else if(this.state.card_number.length !== 16 ){
            formErrors['card_number'] = 'Card number should contain 16 characters.'
            errorCount++
        }
        /*else if( !(this.state.card_number.match(cardNumberRegex)) ){
            formErrors['card_number'] = 'Please enter valid card number.'
            errorCount++
        }*/

      if(this.state.card_expiry === ''){
        formErrors['card_expiry'] = 'Please enter card expiry.'
        errorCount++
      }
      else if(this.state.card_expiry.length != 5 ){
            formErrors['card_expiry'] = 'Please enter valid card expiry.'
            errorCount++
        }

      if(this.state.card_cvv === ''){
        formErrors['card_cvv'] = 'Please enter card cvv.'
        errorCount++
      }
      else if(this.state.card_cvv.length < 3 && this.state.card_cvv.length > 4 ){
            formErrors['card_cvv'] = 'Cvv number should contain minimum 3 and maximum 4 characters.'
            errorCount++
        }
        /*else if( !(this.state.card_cvv.match(cardNumberRegex)) ){
            formErrors['card_cvv'] = 'Please enter valid card number.'
            errorCount++
        }*/

      if(errorCount > 0){
        // console.log('formErrors=',formErrors)
        this.setState({formErrors});
        return false;
      }

      this.setState({formErrors});

      return true

    }

    render(){

        const geoInfo = this.state.geoInfo
        let formErrors = this.state.formErrors;
        let PriceCards = this.state.PriceCards;
        let statesArr = this.state.statesArr;
        let state = this.state.state

        if(statesArr && statesArr.length > 0 && state && state.length > 0)
        {
            var state_missing = true
            statesArr.map((ct,idx)=>{
                if(ct.label === state)
                {
                    state_missing = false;
                }
            })
            if(state_missing)
            {
                statesArr.push({value: state, label: state})
            }
        }

        const currency = this.state.currency
        // const currency = ''
        let currencySymbol = '$'

        if(currency === 'INR'){
            currencySymbol = '&#8377;' // rupee symbol
        }

        var selectedPrice = this.state.selectedPrice
        var coupon_amount = this.state.coupon_amount
        /*if(this.state.percentage && selectedPrice)
        {
            // console.log('selectedPrice=',selectedPrice)
            if(Array.isArray(selectedPrice))
            {
                selectedPrice = (selectedPrice[0]) ? selectedPrice[0] : selectedPrice[1]
            }
            if(selectedPrice)
            {
                selectedPrice = (currency === 'INR') ? Math.round(selectedPrice) : selectedPrice.toFixed(2)
                coupon_amount = (selectedPrice * parseInt(this.state.percentage))/100
                selectedPrice = selectedPrice - coupon_amount
            }
        }*/

        return(
            <div className="fp-contact-form-panel billing-cycle">
                {
                    this.state.loadingData ? <Loader /> : ''
                }
                <BillingProgress step={this.state.activeStep} />
                {
                    this.state.activeStep >= 2?
                <button className="cycle-back" onClick={this.handlePrevStep}>{BackIcon} Back</button>:""
                }
                
                    
                    <div style={{'display': this.state.activeStep == 1 ? 'block': 'none'}}>
                        <h3 className="billing-heading mb-2">Choose a billing cycle</h3>
                        <p className="plan-name">{(this.state.packageData && this.state.packageData.name)&&(this.state.packageData.name)} Plan:</p>
                        <div className="price-cards">
                        {PriceCards.map((card, index)=>
                            <React.Fragment key={index}>
                                <input type="radio" name="billing" onChange={this.handleChange} value={card.duration} id={`card${index}`} defaultChecked={card.selected}/>
                                
                                <label className="price-card" htmlFor={`card${index}`}>
                                    <div className="price-box">

                                        <div className="stripedPrice">
                                        <span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
                                        {card.actualPrice}
                                        </div>
                                        
                                        <div className="main-price">
                                        <span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>
                                        {card.perMonthPrice}
                                        </div>

                                        <p className="green-title">{(card.duration === 'monthly')?('per month, when billed monthly'):('per month, when billed annually')}</p>

                                    </div>
                                    <div className="price-card-footer">BILLED: {card.duration.toUpperCase()}</div>
                                </label>
                            </React.Fragment>
                            )}
                        </div>
                        {formErrors['billing'] ? (<span className="msg-error">{formErrors['billing']}</span>) : ''}

                        {(!this.state.percentage)&&(
                        <div className="row price-row">
                            <div className="col-lg-6">
                                <div className="input-box">
                                    <label>Apply Coupon</label>
                                    <input type="text" name="coupon" defaultValue={this.state.coupon} maxLength="6" onChange={this.handleChange} className="form-style" placeholder="Coupon code"/>
                                    {this.state.coupon_error ? (<span className="msg-error">{this.state.coupon_error}</span>) : ''}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-box">
                                    <label></label>
                                    <button className="fp-btn-main fp-btn" onClick={(event) => this.handleCouponApply(event, 1)} >Apply</button>
                                </div>
                            </div>
                        </div>
                        )}
                        <ul className="billing-inner-list">
                            {(this.state.percentage && parseInt(this.state.percentage) > 0 && coupon_amount)?(
                            <li className="coupan-code-list" >
                                {this.state.percentage}% coupon discount applied for code [{this.state.coupon}] : <a className="remove-btn" onClick={(event) => this.handleCouponApply(event, 0)} >Remove</a> <span><span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{coupon_amount}</span>
                            </li>
                            ):null}
                            <li><strong>Amount Payable {(currency==='INR')&&(<React.Fragment>({(this.state.gstRate > 0)&&(this.state.gstRate+'%')} GST included) </React.Fragment>)}: <span><span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{selectedPrice}</span></strong></li>
                        </ul>
                        <button className="fp-btn-main fp-btn-lg" onClick={this.handleBillingCycle} >Continue</button>
                    </div>
                    
                    <div style={{'display': this.state.activeStep == 2 ? 'block': 'none'}}>
                        <h3 className="billing-heading mb-0">Add your company details</h3>
                        <p className="billing-title mb-md-4 mb-2">Fill these details to get them included in your invoice</p>
                        <form>
                            <div className="input-box">
                                <label>Company Name <span className="color-red">*</span></label>
                                <input type="text" name="company" defaultValue={this.state.company} onChange={this.handleChange} className="form-style" placeholder="My Company"/>
                                {formErrors['company'] ? (<span className="msg-error">{formErrors['company']}</span>) : ''}
                            </div>
                            <div className="input-box">
                                <label>Company Address <span className="color-red">*</span></label>
                                <input type="text" name="address" defaultValue={this.state.address} onChange={this.handleChange} className="form-style" placeholder="3432  Isaacs Creek Road"/>
                                {formErrors['address'] ? (<span className="msg-error">{formErrors['address']}</span>) : ''}
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="input-box">
                                        <label>Country: <span className="color-red">*</span></label>
                                        <select name="country" value={this.state.country} onChange={this.handleChange} className="form-style">
                                        <option value="">--select--</option>
                                        {
                                            (countriesArr.length > 0) && (
                                                countriesArr.map((country, cdx) => {
                                                    return(
                                                        <option key={cdx} value={country.label}>{country.label}</option>
                                                        )
                                                })
                                                )
                                        }
                                        </select>

                                        {formErrors['country'] ? (<span className="msg-error">{formErrors['country']}</span>) : ''}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box">
                                        <label>State: <span className="color-red">*</span></label>
                                        {(statesArr && statesArr.length > 0)?(
                                        <select name="state" value={this.state.state} onChange={this.handleChange} className="form-style">
                                        <option value="">--select--</option>
                                        {
                                            (statesArr.length > 0) && (
                                                statesArr.map((ct, cdx) => {
                                                    return(
                                                        <option key={cdx} value={ct.label}>{ct.label}</option>
                                                        )
                                                })
                                                )
                                        }
                                        </select>
                                        ):(
                                        <input type="text" name="state" defaultValue={this.state.state} onChange={this.handleChange} placeholder="- - - - -" className="form-style"  maxLength="50" />
                                        )}
                                        {formErrors['state'] ? (<span className="msg-error">{formErrors['state']}</span>) : ''}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box">
                                        <label>City: <span className="color-red">*</span></label>
                                        <input type="text" name="city" defaultValue={this.state.city} onChange={this.handleChange} placeholder="- - - - -" className="form-style"  maxLength="50" />
                                        {formErrors['city'] ? (<span className="msg-error">{formErrors['city']}</span>) : ''}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box">
                                        <label>Pincode: <span className="color-red">*</span></label>
                                        <input type="text" name="pincode" defaultValue={this.state.pincode} onChange={this.handleChange} placeholder="- - - - -" className="form-style"  maxLength="50" />
                                        {formErrors['pincode'] ? (<span className="msg-error">{formErrors['pincode']}</span>) : ''}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-box">
                                        <label>Phone: </label>
                                        <input type="text" name="phone" defaultValue={this.state.phone} onChange={this.handleChange} placeholder="Phone" className="form-style"  maxLength="50" />
                                        {formErrors['phone'] ? (<span className="msg-error">{formErrors['phone']}</span>) : ''}
                                    </div>
                                </div>

                            </div>
                        </form>
                        <button className="fp-btn-main fp-btn-lg" onClick={this.handleAddDetails}>Continue</button>
                    </div>
                  
                    <div style={{'display': this.state.activeStep == 3 ? 'block': 'none'}}>
                        <h3 className="billing-heading">Make Payment</h3>
                        <ul className="final-plan-box">
                            <li>
                                <h3>{(this.state.packageData && this.state.packageData.name)&&(this.state.packageData.name)} Plan</h3>
                                <h3><span dangerouslySetInnerHTML={{__html: currencySymbol }}></span>{selectedPrice}</h3>
                            </li>
                            <li>
                                <span>BILLED: </span>
                                <span>{(this.state.billing)&&(this.state.billing.toUpperCase())}</span>
                            </li>

                        </ul>
                        <form onSubmit={this.handleGeneratePaymentLink} className="card-details">
                            {/*<div className="input-box">
                                <label>Card Details *</label>

                                <div className="card-details-wrap card-number">
                                    <input type="text" name="card_number" defaultValue={this.state.card_number} onChange={this.handleChange} className="form-style" placeholder="Card number"/>
                                    {formErrors['card_number'] ? (<span className="msg-error">{formErrors['card_number']}</span>) : ''}
                                </div>

                                <div className="card-details-wrap card-month">
                                <input type="text" name="card_expiry" defaultValue={this.state.card_expiry} onChange={this.handleChange} className="form-style" placeholder="MM/YY"/>
                                {formErrors['card_expiry'] ? (<span className="msg-error">{formErrors['card_expiry']}</span>) : ''}
                                </div>

                                <div className="card-details-wrap card-cvv">
                                <input type="text" name="card_cvv" defaultValue={this.state.card_cvv} onChange={this.handleChange} className="form-style" placeholder="CVV"/>
                                {formErrors['card_cvv'] ? (<span className="msg-error">{formErrors['card_cvv']}</span>) : ''}
                                </div>

                                <span className="card-text">Your card will be securely saved (without CVV) for future transactions</span>
                            </div>*/}
                            
                            <button type="submit" className="fp-btn-main fp-btn-lg">Continue</button>
                        </form>
                    </div>
                
                
            </div>
        )
    }

}
export default BillingCard;