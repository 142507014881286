export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_USER_PHOTO = 'SET_USER_PHOTO';

export function setUserDetails(user) {
	/*console.log('setUserDetails=');
	console.log(user);*/
    return { type: SET_USER_DETAILS, user };
}

export function setUserPhoto(photo) {
	/*console.log('setUserDetails=');
	console.log(user);*/
    return { type: SET_USER_PHOTO, photo };
}
